// This import loads the firebase namespace.
import * as firebase from "firebase/app";

// These imports load individual services into the firebase namespace.
// (import module for side effects)
import "firebase/auth";
import "firebase/storage";
import "firebase/firestore";

import { FIREBASE_CONFIG } from "./constants";

// prevents hot reload errors
if (firebase.apps.length === 0) {
  firebase.initializeApp(FIREBASE_CONFIG);
  firebase.firestore();
}

export default firebase;
