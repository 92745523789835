import React, { useEffect, useState } from "react";
import { View, StyleSheet, Text, ActivityIndicator } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import { SaveState } from "components/Notes/NoteEditor";
import { COLORS } from "utils/appStyles";
import { useString } from "hooks";
import { useDimensionsContext } from "contexts";
import moment from "moment";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "flex-start",
  },
});
export const SaveStateIndicator = ({
  saveState,
  lastSaved,
  showTimeAgo,
}: {
  saveState: SaveState;
  lastSaved?: number;
  showTimeAgo?: boolean;
}) => {
  const s = useString("noteEditor");
  const { getScaledFontSize } = useDimensionsContext();
  const [timeAgo, setTimeAgo] = useState<string>();

  useEffect(() => {
    if (lastSaved && showTimeAgo) {
      const getTimeAgo = () => setTimeAgo(moment(lastSaved).fromNow());
      getTimeAgo();
      const timer = setInterval(getTimeAgo, 60 * 1000);
      return () => clearInterval(timer);
    }
  }, [lastSaved, showTimeAgo, timeAgo]);

  let indicator = null;
  if (saveState === SaveState.UNTOUCHED) {
    indicator = (
      <Text
        style={{
          fontSize: getScaledFontSize(0.8),
          color: COLORS.disabledDark,
        }}
      >
        {s("autoSaveInstruction")}
      </Text>
    );
  } else if (saveState === SaveState.IS_SAVING) {
    indicator = (
      <ActivityIndicator animating color={COLORS.disabled} size="small" />
    );
  } else if (saveState === SaveState.SAVED) {
    indicator = (
      <>
        <Ionicons
          name="md-checkmark"
          size={getScaledFontSize(1.2)}
          color={COLORS.success}
        />
        {lastSaved && (
          <Text
            style={{
              fontSize: getScaledFontSize(0.8),
              color: COLORS.disabledDark,
              marginLeft: 8,
            }}
          >
            {showTimeAgo
              ? `Saved ${timeAgo}`
              : `Saved at ${moment(lastSaved).format("h:mm")}`}
          </Text>
        )}
      </>
    );
  }

  return (
    <View style={[styles.container, { height: getScaledFontSize(1) }]}>
      {indicator}
    </View>
  );
};
