import { RouteProp, useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import React, { useEffect, useMemo, useCallback } from "react";
import {
  View,
  Text,
  StyleSheet,
  SectionList,
  SafeAreaView,
} from "react-native";
import { Button as PaperButton, Appbar } from "react-native-paper";
import { StackProps } from "layouts/MobileLayout";

import {
  ContentCenteredView,
  FriendCard,
  KButton,
  TOAST_HEIGHT,
  Loading,
  ReceivedRequest,
  SentRequest,
  Subtitle,
} from "components";

import { useString } from "hooks";
import { useFriendService } from "hooks/friends/useFriendService";
import { useGetFriends, useGetSharedDays } from "redux/selectors";
import { FriendStatus } from "types/friends";
import { Logger } from "utils/Logger";
import { KHeader } from "components/UI/KHeader";
import { COLORS } from "utils/appStyles";
import { defaultKeyExtractor } from "utils/lists";
import { logTapAddFriendHeader } from "utils/analytics";
import { AddFriendWidget } from "components/Friends/AddFriendWidget";

const renderSectionHeader = ({ section }) =>
  section.title &&
  section.data.length > 0 && (
    <Subtitle style={styles.subtitle}>{section.title}</Subtitle>
  );

const ListFooterComponent = () => {
  const s = useString("friendListScreen");
  const navigation = useNavigation<StackNavigationProp<StackProps>>();
  // Add padding to bottom of list so that toast doesn't block bottom habit
  return (
    <View
      style={{
        height: TOAST_HEIGHT + 44, // Add a little more padding to avoid
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <PaperButton
        icon="account-plus"
        mode="contained"
        onPress={() => navigation.navigate("AddFriendScreen")}
      >
        {s("addFriend")}
      </PaperButton>
    </View>
  );
};

const FriendList = ({
  navigation,
  fetchState,
  acceptRequest,
  deleteFriend,
}) => {
  Logger.log("FriendList Re-rendering");
  const s = useString("friendListScreen");
  const { isFriendsLoaded, friends } = useGetFriends();
  const { isSharedDaysLoaded } = useGetSharedDays();

  // //TODO optimization
  //requires 3 filters eg. 3N time, could do in 1N
  //loop through once and order according to type, then render
  const friendMap = useMemo(
    () =>
      friends
        .filter(
          friend =>
            friend.status === FriendStatus.Accepted ||
            friend.status === FriendStatus.AcceptedRequestor
        )
        // Sort coach to the bottom
        .sort((a, b) => (a.type === b.type ? 0 : a.type ? 1 : -1))
        .map(f => f.id),
    [friends]
  );

  const receivedMap = useMemo(
    () =>
      friends
        .filter(friend => friend.status === FriendStatus.Pending)
        .map(f => f.id),
    [friends]
  );

  const sentMap = useMemo(
    () =>
      friends
        .filter(
          friend =>
            friend.status === FriendStatus.Requested ||
            friend.status === FriendStatus.Inviting
        )
        .map(f => f.id),
    [friends]
  );

  const renderFriendSection = useCallback(
    ({ item }: { item: typeof friendMap[0] }) => {
      return (
        <FriendCard
          deleteFriend={deleteFriend}
          friendid={item}
          key={item}
          state={fetchState[item]}
        />
      );
    },
    [fetchState]
  );

  const renderRequestSection = useCallback(
    ({ item }: { item: string }) => (
      <ReceivedRequest
        friendid={item}
        key={item}
        state={fetchState[item]}
        onAccept={() => acceptRequest(item)}
        onDecline={() => deleteFriend(item)}
      />
    ),
    [fetchState]
  );

  const renderSentSection = useCallback(
    ({ item }: { item: string }) => (
      <SentRequest
        friendid={item}
        key={item}
        state={fetchState[item]}
        deleteRequest={() => deleteFriend(item)}
      />
    ),
    [fetchState]
  );

  const sections = useMemo(
    () => [
      {
        title: s("friendRequestReceived"),
        data: receivedMap,
        renderItem: renderRequestSection,
      },
      {
        title: s("waitingOnResponse"),
        data: sentMap,
        renderItem: renderSentSection,
      },
      {
        //The title of the screen is Friends and is would be duplicative
        //to include Friends as section title, if there are no other sections
        title:
          (sentMap && sentMap.length > 0) ||
          (receivedMap && receivedMap.length > 0)
            ? "Friends"
            : null,
        data: friendMap,
        renderItem: renderFriendSection,
      },
    ],
    [friendMap, receivedMap, sentMap, fetchState]
  );

  if (!isFriendsLoaded || !isSharedDaysLoaded) {
    return <Loading />;
  }

  if (friends.length === 0) {
    return (
      <ContentCenteredView>
        <Text>{s("noOneHere")}</Text>
      </ContentCenteredView>
    );
  }

  return (
    <SectionList
      initialNumToRender={10}
      windowSize={15}
      sections={sections}
      keyExtractor={defaultKeyExtractor}
      stickySectionHeadersEnabled={false}
      renderSectionHeader={renderSectionHeader}
      ListFooterComponent={ListFooterComponent}
    />
  );
};

export const FriendListScreen = ({
  navigation,
  route,
}: {
  navigation: StackNavigationProp<StackProps, "FriendListScreen">;
  route: RouteProp<StackProps, "FriendListScreen">;
}) => {
  const { fetchState, deleteFriend, acceptRequest } = useFriendService({});
  const shouldDelete = route.params?.shouldDelete ?? false;
  const friendToDelete = route.params?.friend ?? null;

  useEffect(() => {
    if (shouldDelete && friendToDelete) {
      Logger.log("FriendListScreen should delete friend");
      deleteFriend(friendToDelete.id);
    }
  }, [shouldDelete, friendToDelete]);

  Logger.log("FriendListScreen re-rendering");

  return (
    <SafeAreaView style={styles.list}>
      <KHeader title="Friends">
        <Appbar.Action
          icon="account-plus"
          color={COLORS.logoBlue}
          onPress={() => {
            logTapAddFriendHeader();
            navigation.navigate("AddFriendScreen");
          }}
        />
      </KHeader>
      <AddFriendWidget />
      <FriendList
        navigation={navigation}
        fetchState={fetchState}
        acceptRequest={acceptRequest}
        deleteFriend={deleteFriend}
      />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  subtitle: {
    marginTop: 16,
    marginBottom: 8,
    textAlign: "center",
  },
  list: { flex: 1, backgroundColor: "white" },
});
