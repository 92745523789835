import React from "react";
import { View, Text, StyleSheet } from "react-native";
import { useGetAuth } from "redux/selectors";

import { useString } from "hooks";
import {
  isHabitChallenge,
  isHabitChallengeParticipant,
  Challenge,
} from "types/habits";
import { COLORS } from "utils/appStyles";

const styles = StyleSheet.create({
  challengeContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  challengeBadge: {
    backgroundColor: COLORS.challenge,
    paddingVertical: 2,
    paddingHorizontal: 6,
    borderRadius: 4,
  },
  challengeBadgeText: {
    fontSize: 12,
    textTransform: "uppercase",
  },
  participantsText: {
    margin: 6,
    color: COLORS.darkGray,
  },
});

export const ChallengeTitleBadge = ({
  habit: challengeHabit,
  ownHabit,
}: {
  habit: Challenge;
  ownHabit: boolean;
}) => {
  const s = useString("challenge");
  const { auth } = useGetAuth();
  const challengers = Object.keys(challengeHabit.challengers).length;
  const isChallengeOwner =
    (ownHabit && isHabitChallenge(challengeHabit)) ||
    (isHabitChallengeParticipant(challengeHabit) &&
      challengeHabit.challengeOwnerUid === auth.uid);

  return (
    <View style={styles.challengeContainer}>
      <View style={styles.challengeBadge}>
        <Text style={styles.challengeBadgeText}>
          {isChallengeOwner ? s("yourChallenge") : s("challenge")}
        </Text>
      </View>
      {challengers && (
        <Text style={styles.participantsText}>
          {challengers === 1
            ? isChallengeOwner
              ? s("justYou")
              : s("justThem")
            : s("numParticipants", challengers)}
        </Text>
      )}
    </View>
  );
};
