import React from "react";
import { Platform, Text, StyleSheet, TextStyle, TextProps } from "react-native";
import {
  material,
  iOSUIKit,
  systemWeights,
  iOSColors,
} from "react-native-typography";
import { useDimensionsContext } from "contexts";
import { COLORS } from "styles";

export const Title = ({
  style = {},
  children,
}: {
  style?: TextStyle | TextStyle[];
  children;
}) => <Text style={[styles.title, style]}>{children}</Text>;

export const Subtitle = ({
  style = {},
  children,
  ...rest
}: {
  style?: TextStyle | TextStyle[];
  children;
}) => (
  <Text style={[styles.subtitle, style]} {...rest}>
    {children}
  </Text>
);

export const SectionTitle = ({
  children,
  ...rest
}: {
  style?: TextStyle | TextStyle[];
  children;
}) => {
  const { getScaledFontSize } = useDimensionsContext();
  return (
    <Text
      style={{
        marginVertical: 10,
        fontSize: getScaledFontSize(1.2),
        fontWeight: "bold",
        marginBottom: 4,
      }}
      {...rest}
    >
      {children}
    </Text>
  );
};

export const SectionSubtitle = ({
  style = {},
  children,
  ...rest
}: {
  style?: TextStyle | TextStyle[];
  children;
}) => {
  const { getScaledFontSize } = useDimensionsContext();
  return (
    <Text
      style={[
        {
          fontSize: getScaledFontSize(0.9),
          fontWeight: "400",
          lineHeight: getScaledFontSize(1.2),
          color: COLORS.GRAY4,
        },
        style,
      ]}
      {...rest}
    >
      {children}
    </Text>
  );
};

export const Body = ({
  style = {},
  children,
  ...rest
}: {
  style?: TextStyle | TextStyle[];
  children;
} & TextProps) => (
  <Text style={StyleSheet.flatten([styles.body, style])} {...rest}>
    {children}
  </Text>
);

export const Footnote = ({
  style = {},
  children,
}: {
  style?: TextStyle | TextStyle[];
  children;
}) => <Text style={[styles.footnote, style]}>{children}</Text>;

const styles = StyleSheet.create({
  title: {
    ...Platform.select({
      ios: iOSUIKit.largeTitleEmphasizedObject,
      android: material.display1Object,
      web: material.display1Object,
    }),
    ...systemWeights.bold,
    color: iOSColors.black,
  },
  subtitle: {
    ...Platform.select({
      ios: iOSUIKit.title3Object,
      android: material.titleObject,
      web: material.titleObject,
    }),
    ...systemWeights.semibold,
    color: iOSColors.black,
  },
  body: {
    ...Platform.select({
      ios: iOSUIKit.bodyObject,
      android: material.subheadingObject,
      web: material.subheadingObject,
    }),
    ...systemWeights.regular,
    color: iOSColors.black,
  },
  footnote: {
    ...Platform.select({
      ios: iOSUIKit.footnoteObject,
      android: material.captionObject,
      web: material.captionObject,
    }),
    ...systemWeights.regular,
    color: iOSColors.black,
  },
});

export const cardTypography = scaledFontSize =>
  StyleSheet.create({
    cardTitle: {
      fontSize: scaledFontSize,
      fontWeight: "bold",
    },
    cardContent: {
      fontSize: scaledFontSize * 0.85,
      color: COLORS.GRAY4,
    },
  });
