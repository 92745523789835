import React, { useMemo } from "react";
import { StyleSheet, Text, View, ActivityIndicator } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { useNavigation } from "@react-navigation/native";
import { MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import {
  useGetAuth,
  useGetSpecificHabit,
  useGetFriends,
} from "redux/selectors";
import { useHabitGridStyles } from "hooks/habits/useHabitGridStyles";
import { KAvatar } from "components";
import { COLORS } from "utils/appStyles";
import { Layouts, useLayoutContext } from "contexts";
import { useHistory } from "utils/react-router";
import { API_HOST } from "utils/constants";
import axios from "utils/axios";
import { useEndpoint } from "hooks/useEndpoint";

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
    justifyContent: "center",
  },
  avatar: {
    alignSelf: "center",
    justifyContent: "center",
  },
  badge: {
    position: "absolute",
    width: 34,
    height: 24,
    borderRadius: 50,
    right: -17,
    zIndex: 20,
    justifyContent: "center",
    backgroundColor: "white",
    shadowColor: "black",
    shadowOpacity: 0.2,
    shadowRadius: 2,
    shadowOffset: { width: 1, height: 0 },
    elevation: 3,
  },
  badgeText: {
    fontSize: 12,
    textAlign: "center",
  },
  flameText: {
    fontSize: 10,
  },
  addFriendBadge: {
    borderRadius: 12,
    backgroundColor: "white",
    alignItems: "center",
    paddingHorizontal: 12,
    paddingVertical: 3,
    position: "absolute",
    right: -120,
    zIndex: 21,
    shadowOffset: { width: 0, height: 3 },
    shadowOpacity: 0.3,
  },
  addFriendBadgeIconContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  addFriendBadgeIcon: { marginRight: 4 },
  addFriendBadgeText: {
    fontSize: 12,
    textAlign: "center",
    paddingVertical: 2,
  },
});

const CreatorIcon = () => (
  <MaterialCommunityIcons name="whistle" size={20} color={COLORS.active} />
);

const AddParticipantAsFriendButton = ({ id, displayName }) => {
  const { isLoading, error, makeCall } = useEndpoint(false);

  const onPress = () => {
    makeCall(() => axios.post(`${API_HOST}/addFriend`, { id }));
  };

  return (
    <View style={styles.addFriendBadge}>
      <TouchableOpacity onPress={onPress}>
        <View style={styles.addFriendBadgeIconContainer}>
          <View style={styles.addFriendBadgeIcon}>
            {isLoading ? (
              <ActivityIndicator />
            ) : error ? (
              <MaterialCommunityIcons
                name="alert-circle-outline"
                size={18}
                color={COLORS.error}
              />
            ) : (
              <MaterialIcons name="person-add" size={18} />
            )}
          </View>
          <Text style={styles.addFriendBadgeText}>Friend</Text>
        </View>
        <Text style={styles.addFriendBadgeText} numberOfLines={1}>
          {displayName}
        </Text>
      </TouchableOpacity>
    </View>
  );
};

export const ProgressTrackerAvatar = ({
  uid,
  userDisplayName,
  userPhotoURL,
  habitid,
  isChallengeOwner,
}) => {
  const navigation = useNavigation();
  const history = useHistory();
  const layout = useLayoutContext();
  const { auth } = useGetAuth();
  const { habit } = useGetSpecificHabit(habitid);
  const { friends } = useGetFriends();
  const { COLUMN_WIDTH } = useHabitGridStyles();
  const avatarStyle = [styles.avatar, { height: COLUMN_WIDTH }];

  const canAddFriend = useMemo(
    () => !friends.some(friend => friend.id === uid) && !(uid === auth.uid),
    [auth, friends, uid]
  );

  return (
    <View style={styles.container}>
      <TouchableOpacity
        onPress={() => {
          if (uid === auth.uid) {
            if (layout === Layouts.WIDE) {
              history.push(`/me/details/${habitid}`);
            } else {
              navigation.navigate("HabitScreen", {
                //TODO: change this to users name instead of habit name, but not possible
                //TODO: until refactor title in HabitScreen
                title: habit.title,
                habitid,
              });
            }
          } else {
            if (layout === Layouts.WIDE) {
              history.push(`/friend/${uid}/details/${habitid}`);
            } else {
              navigation.navigate("FriendHabitScreen", {
                title: userDisplayName,
                friendid: uid,
                habitid,
              });
            }
          }
        }}
        style={avatarStyle}
      >
        <KAvatar
          size={50}
          source={{ uri: userPhotoURL }}
          Icon={isChallengeOwner ? CreatorIcon : null}
        />
      </TouchableOpacity>
      <View style={styles.badge}>
        <Text style={styles.badgeText}>
          {habit.streak}
          <Text style={styles.flameText}>🔥</Text>
        </Text>
      </View>
      {canAddFriend && (
        <AddParticipantAsFriendButton id={uid} displayName={userDisplayName} />
      )}
    </View>
  );
};
