import { useReducer } from "react";

import axios from "utils/axios";
import { API_HOST } from "utils/constants";
import { fetchReducer } from "utils/reducers";

export const useFriendService = initialState => {
  const [fetchState, dispatch] = useReducer(fetchReducer, initialState);

  const dispatchFetch = (path, docid) => {
    const payload = {
      id: docid,
    };

    dispatch({
      type: "LOADING",
      payload,
    });

    axios
      .post(`${API_HOST}${path}`, {
        docid,
      })
      .then(() => {
        // Wait a little for the listener to delete
        // otherwise, we stop rendering the loading icon
        // before the entry disappears
        setTimeout(() => {
          dispatch({
            type: "DONE_LOADING",
            payload,
          });
        }, 2000);
      })
      .catch(error =>
        dispatch({
          type: "ERROR",
          payload: { ...payload, error },
        })
      );
  };

  const deleteFriend = (docid: string) => dispatchFetch("/deleteFriend", docid);

  const acceptRequest = (docid: string) =>
    dispatchFetch("/acceptFriend", docid);

  return {
    fetchState,
    deleteFriend,
    acceptRequest,
  };
};
