import React, { useCallback, memo } from "react";
import { StyleSheet } from "react-native";
import { FlatList, TouchableOpacity } from "react-native-gesture-handler";
import { Card } from "react-native-paper";

import { useSelector } from "redux/store";
import { HabitChallenge, Note, NoteMedia } from "types/habits";
import { KImage } from "components/Media/KImage";
import { KAvatar } from "components/UI/KAvatar";
import { Body } from "components/UI/Typography";
import { shallowEqual } from "react-redux";
import { Logger } from "utils/Logger";
import { KCard } from "components";

const IMAGE_LENGTH = 100;
const keyExtractor = item => item.lastUpdated.toString();
const getItemLayout = (_data, index) => ({
  length: IMAGE_LENGTH,
  offset: IMAGE_LENGTH * index,
  index,
});

const MediaCarousel = ({
  media,
  setSelectedNoteMedia,
  setImageViewerIndex,
  setImageViewerIsVisible,
}: {
  media: NoteMedia[];
  setSelectedNoteMedia;
  setImageViewerIndex;
  setImageViewerIsVisible;
}) => {
  const renderItem = useCallback(({ item, index }) => {
    return (
      <TouchableOpacity
        onPress={() => {
          setSelectedNoteMedia(media);
          setImageViewerIndex(index);
          setImageViewerIsVisible(true);
        }}
      >
        <KImage
          local={item.uri}
          remote={item.url}
          type={item.type}
          style={styles.mediaStyle}
        />
      </TouchableOpacity>
    );
  }, []);

  return (
    <FlatList
      data={media}
      renderItem={renderItem}
      keyExtractor={keyExtractor}
      initialNumToRender={5}
      maxToRenderPerBatch={5}
      windowSize={5}
      getItemLayout={getItemLayout}
      horizontal
    />
  );
};

export const ChallengeUpdateCard = memo(
  ({
    uid,
    date,
    habitid,
    mainHabit,
    setSelectedNoteMedia,
    setImageViewerIndex,
    setImageViewerIsVisible,
  }: {
    uid;
    date;
    habitid;
    mainHabit: HabitChallenge;
    setSelectedNoteMedia;
    setImageViewerIndex;
    setImageViewerIsVisible;
  }) => {
    const note: Note = useSelector(
      state => state.notes.habitToNoteMap?.[habitid]?.[date],
      shallowEqual
    );

    const owner = mainHabit?.challengerInfo?.[uid] ?? null;

    if (!note) {
      Logger.log("NO NOTE FOUND", habitid, date);
      return null;
    }

    return (
      <KCard>
        <Card.Title
          title={owner && owner.name}
          left={() =>
            owner &&
            owner.photoURL && (
              <KAvatar source={{ uri: owner.photoURL }} size={40} />
            )
          }
        />
        <Card.Content>
          <Body style={{ margin: 12 }}>{note.note}</Body>
          {note.media && (
            <MediaCarousel
              media={note.media}
              setSelectedNoteMedia={setSelectedNoteMedia}
              setImageViewerIndex={setImageViewerIndex}
              setImageViewerIsVisible={setImageViewerIsVisible}
            />
          )}
        </Card.Content>
      </KCard>
    );
  }
);

const styles = StyleSheet.create({
  content: {
    margin: 10,
  },
  mediaStyle: {
    height: 100,
    width: 100,
  },
});
