import React from "react";
import { Text, StyleSheet, View } from "react-native";

const ErrorToast = ({ message }) => {
  return (
    <View style={styles.container}>
      <Text style={styles.title}>Oh no!</Text>
      <Text style={styles.text}>{message}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: { flex: 1, margin: 20, alignItems: "center" },
  title: { fontSize: 22, fontWeight: "700" },
  text: {
    fontSize: 18,
    textAlign: "center",
  },
});

export const PermissionDeniedToast = () => (
  <ErrorToast message="This might have been deleted or the permissions changed." />
);
