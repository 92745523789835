import { Contact } from "expo-contacts";

export enum FriendStatus {
  Inviting = "INVITING",
  Pending = "PENDING - CHOOSE TO ACCEPT",
  Requested = "REQUESTED - WAITING FOR ACCEPT",
  Accepted = "ACCEPTED",
  AcceptedRequestor = "ACCEPTED - REQUESTOR",
}

export type Friend = {
  id?: string;
  email?: string;
  status: FriendStatus;
  // sbChannelUrl?: string; // DEPRECATED
  name?: string;
  avatar?: string;
  wantNotifications?: NotificationType;
  lastNotified?: firebase.firestore.Timestamp;
  acceptedDate?: firebase.firestore.Timestamp;
  type?: string;
};

export type FriendOfFriendInfo = Pick<
  Friend,
  "avatar" | "id" | "email" | "name"
>;

export type FriendOfFriends = FriendOfFriendInfo & {
  mutualFriends: string[];
};

export type FriendsOfFriendsResponse = {
  friendIdToNewFriends: {
    [currentFriendId: string]: string[];
  };
  newFriendsToFriendIds: {
    [newFriendId: string]: Set<string> | string[];
  };
  friendInfo: {
    [friendid: string]: FriendOfFriendInfo;
  };
};

export enum NotificationType {
  NONE = 0,
  HIGHLIGHTS = 1,
  ALL = 2,
}

// Type of motivation being sent to friends
export enum MotivationType {
  GROUP = "GROUP_HABIT",
  ALL = "ALL",
  FEEDBACK = "FEEDBACK", // Sent to one friend upon tapping notification update from them
}

export type AnnotatedContact = Contact & {
  knownUserEmail?: string;
  id?: string; // SnapHabit user id (vs contact ID)
};

export type UserProfileResponse = {
  user: {
    photoURL: string;
    displayName: string;
    handle: string;
    id: string;
    email?: string;
  };
};
