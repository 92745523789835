import { useDimensions } from "react-native-web-hooks";
import { isMobilePlatform } from "utils/helpers";
import { MOBILE_BREAK_POINT } from "utils/appStyles";

import { createContext, useContext } from "react";

export enum Layouts {
  MOBILE = "MOBILE",
  WIDE = "WIDE",
}

export const useGetLayout = (): Layouts => {
  const { window } = useDimensions();

  if (isMobilePlatform || window.width < MOBILE_BREAK_POINT) {
    return Layouts.MOBILE;
  }
  return Layouts.WIDE;
};

export const LayoutContext = createContext(Layouts.MOBILE);

export const useLayoutContext = () => useContext(LayoutContext);
