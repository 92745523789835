import { MaterialIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack/lib/typescript/src/types";
import React, { useMemo } from "react";
import { FlatList, StyleSheet, TouchableOpacity, Text } from "react-native";

import { Note } from "types/habits";
import { StackProps } from "layouts/MobileLayout";
import { ContentCenteredView } from "components/UI/ContentCenteredView";
import { HorizontalSeparator } from "components/UI/HorizontalSeparator";
import { isWeb, isMobilePlatform } from "utils/helpers";
import { useHistory } from "utils/react-router";
import { useDispatch } from "react-redux";
import { setBottomSheet, BottomSheetTypes } from "redux/slices";

import { NoteItem } from "./NoteItem";

const ListEmptyComponent = ({ ownHabit }) => (
  <ContentCenteredView>
    <Text style={{ margin: 15 }}>
      {ownHabit ? "Add a note by long pressing a day!" : "No notes here!"}
    </Text>
  </ContentCenteredView>
);

const NoteRow = ({ item }) => {
  const { note, habitid, ownHabit, friendid, navigation, history } = item;

  const navigationParams = {
    habitid,
    date: note.date,
    ownHabit,
    friendid,
    dayid: note.id,
  };

  const webNavigate = () =>
    ownHabit
      ? history.push(`/me/notes/${habitid}/${note.date}`)
      : history.push(`/friend/${friendid}/notes/${habitid}/${note.date}`);

  const dispatch = useDispatch();

  return (
    <NoteItem
      habitid={habitid}
      note={note}
      onPress={async () => {
        if (isWeb) {
          webNavigate();
        } else if (isMobilePlatform) {
          navigation.navigate("EditDayNoteScreen", {
            ...navigationParams,
            editable: false,
          });
        }
      }}
      ownHabit={ownHabit}
      contentRight={
        ownHabit && (
          <TouchableOpacity
            style={styles.editButtonContainer}
            onPress={async () => {
              if (isWeb) {
                webNavigate();
              } else if (isMobilePlatform) {
                dispatch(
                  setBottomSheet({
                    type: BottomSheetTypes.NOTE,
                    props: {
                      showDayToggle: true,
                      dayid: note.id,
                      habitid,
                      date: note.date,
                      ownHabit,
                      isEditable: true,
                    },
                  })
                );
              }
            }}
          >
            <MaterialIcons name="edit" size={30} />
          </TouchableOpacity>
        )
      }
    />
  );
};

const renderItem = ({
  item,
}: {
  item: {
    note: Note;
    habitid: string;
    ownHabit: boolean;
    friendid: string;
    navigation: StackNavigationProp<StackProps>;
    history;
  };
}) => <NoteRow item={item} />;

const keyExtractor = item => item.note?.id;

export const NotesList = React.memo(
  ({
    notes,
    ownHabit,
    friendid,
    habitid,
  }: {
    notes: Note[];
    habitid: string;
    ownHabit: boolean;
    friendid: string;
  }) => {
    const navigation = useNavigation<StackNavigationProp<StackProps>>();
    const history = useHistory();

    const items = notes.map(note => ({
      note,
      habitid,
      ownHabit,
      friendid,
      navigation,
      history,
    }));

    const EmptyList = useMemo(
      () => <ListEmptyComponent ownHabit={ownHabit} />,
      [ownHabit]
    );

    return (
      <FlatList
        data={items}
        scrollEnabled={items.length !== 0}
        renderItem={renderItem}
        contentContainerStyle={styles.container}
        ListEmptyComponent={EmptyList}
        ItemSeparatorComponent={HorizontalSeparator}
        keyExtractor={keyExtractor}
      />
    );
  }
);

const styles = StyleSheet.create({
  editButtonContainer: {
    flex: 1,
    alignItems: "center",
  },
  container: { flexGrow: 1 },
});
