import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DayMap } from "types/habits";
import { AppThunk } from "redux/store";
import { API_HOST } from "utils/constants";
import axios from "utils/axios";
import { Logger } from "utils/Logger";

const sharedDaysSlice = createSlice({
  name: "sharedDays",
  initialState: {
    hasLoadedOnce: false,
    isLoading: false,
    error: null,
    habitToDayMap: {},
  },
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setError(state, action: PayloadAction<Error>) {
      Logger.error(action.payload);
      state.error = action.payload;
    },
    setDays(state, action: PayloadAction<[string, DayMap][]>) {
      state.error = null;
      for (let i = 0; i < action.payload.length; i++) {
        const [habitid, days] = action.payload[i];
        state.habitToDayMap[habitid] = days;
      }
      state.hasLoadedOnce = true;
    },
    setHasLoadedOnce(state) {
      state.hasLoadedOnce = true;
    },
  },
});

const { setDays, setLoading, setError } = sharedDaysSlice.actions;

export const { setHasLoadedOnce } = sharedDaysSlice.actions;

export const sharedDaysSliceReducer = sharedDaysSlice.reducer;

export const getSharedDaysSlice = (
  habitIds: string[]
): AppThunk => async dispatch => {
  dispatch(setLoading(true));
  try {
    const start = Date.now();
    const res = await axios.post(`${API_HOST}/getSharedDays`, {
      habitIds,
    });
    Logger.warning(
      `[Get shared Days] Took ${(Date.now() - start) / 1000} for ${
        habitIds.length
      } habits.`
    );
    dispatch(setDays(res.data));
  } catch (err) {
    dispatch(setError(err));
  } finally {
    dispatch(setLoading(false));
  }
};
