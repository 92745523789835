import { LanguageCode } from "types/language";

const stringMaps = {
  addHabitScreen: null,
  buildHabit: null,
  challenge: null,
  chatScreen: null,
  contactScreen: null,
  friendListScreen: null,
  friendSettingScreen: null,
  habitListScreen: null,
  noteEditor: null,
  profileScreen: null,
  routine: null,
  permissions: null,
};

// Lazy load string files
export const getStringMap = (nameSpace: string) => {
  switch (nameSpace) {
    case "addHabitScreen":
      if (!stringMaps.addHabitScreen) {
        stringMaps.addHabitScreen = require("./addHabitScreen").default;
      }
      return stringMaps.addHabitScreen;

    case "buildHabit":
      if (!stringMaps.buildHabit) {
        stringMaps.buildHabit = require("./buildHabit").default;
      }
      return stringMaps.buildHabit;

    case "challenge":
      if (!stringMaps.challenge) {
        stringMaps.challenge = require("./challenge").default;
      }
      return stringMaps.challenge;

    case "chatScreen":
      if (!stringMaps.chatScreen) {
        stringMaps.chatScreen = require("./chatScreen").default;
      }
      return stringMaps.chatScreen;

    case "contactScreen":
      if (!stringMaps.contactScreen) {
        stringMaps.contactScreen = require("./contactScreen").default;
      }
      return stringMaps.contactScreen;

    case "friendListScreen":
      if (!stringMaps.friendListScreen) {
        stringMaps.friendListScreen = require("./friendListScreen").default;
      }
      return stringMaps.friendListScreen;

    case "friendSettingScreen":
      if (!stringMaps.friendSettingScreen) {
        stringMaps.friendSettingScreen = require("./friendSettingScreen").default;
      }
      return stringMaps.friendSettingScreen;

    case "habitListScreen":
      if (!stringMaps.habitListScreen) {
        stringMaps.habitListScreen = require("./habitListScreen").default;
      }
      return stringMaps.habitListScreen;

    case "noteEditor":
      if (!stringMaps.noteEditor) {
        stringMaps.noteEditor = require("./noteEditor").default;
      }
      return stringMaps.noteEditor;

    case "permissions":
      if (!stringMaps.permissions) {
        stringMaps.permissions = require("./permissions").default;
      }
      return stringMaps.permissions;

    case "profileScreen":
      if (!stringMaps.profileScreen) {
        stringMaps.profileScreen = require("./profileScreen").default;
      }
      return stringMaps.profileScreen;

    case "routine":
      if (!stringMaps.routine) {
        stringMaps.routine = require("./routine").default;
      }
      return stringMaps.routine;

    default:
      console.error(`NAMESPACE NOT FOUND: ${nameSpace}`);
      return {};
  }
};

export const languageDisplay: { [key in LanguageCode]: string } = {
  en: "English",
  ja: "日本語",
  "zh-Hans": "简体中文",
  "zh-Hant": "繁體中文",
};
