import { Ionicons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import React, { memo, useCallback } from "react";
import { View, StyleSheet, Text, TouchableOpacity } from "react-native";

import {
  Habit,
  RenderWeek,
  isHabitChallenge,
  HabitChallenge,
  isAChallenge,
  isHabitRoutine,
} from "types/habits";
import { StackProps } from "layouts/MobileLayout";
import { useGetAuth, useGetSpecificHabit } from "redux/selectors";
import { HabitDay } from "components/HabitDetails/HabitDay";
import { SimpleWeek } from "components/HabitDetails/Week";
import { HabitTitle } from "components/UI/ListElements";
import { useNavigateToChallenge } from "hooks/habits/useNavigateToChallenge";
import { Logger } from "utils/Logger";
import { KButton } from "components";
import { COLORS } from "utils/appStyles";
import { renderHabitTitle } from "utils/strings";
import { logJoinGroupHabit, logJoinRoutine } from "utils/analytics";

const GenericHabitListItem = ({
  habit,
  habitid,
  onSelectHabit,
  onLongPress,
  week,
  ownHabit,
  style,
}: {
  habit: Habit;
  habitid: string;
  onSelectHabit;
  onLongPress?: () => void;
  week: RenderWeek;
  ownHabit: boolean;
  style?;
}) => {
  const { streak = 0 } = habit;

  return (
    <TouchableOpacity
      onPress={onSelectHabit}
      onLongPress={onLongPress}
      style={[styles.listItem, style]}
    >
      <View style={styles.itemContent}>
        <View style={styles.titleContainer}>
          <HabitTitle habit={habit} habitid={habitid} ownHabit={ownHabit} />
          {streak > 0 && <Text style={styles.subtitle}>{`${streak} 🔥`}</Text>}
        </View>
        <SimpleWeek week={week} habitid={habitid} ownHabit={ownHabit} />
      </View>
      <View style={styles.iconContainer}>
        <Ionicons name="ios-arrow-forward" size={15} />
      </View>
    </TouchableOpacity>
  );
};

const DefaultHabitListItem = (props: {
  habit;
  habitid;
  onLongPress?: () => void;
  week;
  ownHabit;
  friendid;
}) => {
  const { habit, habitid, friendid } = props;
  const navigation = useNavigation<StackNavigationProp<StackProps>>();
  const selectHabit = useCallback(() => {
    if (props.ownHabit) {
      navigation.navigate("HabitScreen", {
        habitid,
        title: renderHabitTitle(habit),
      });
    } else {
      navigation.navigate("FriendHabitScreen", {
        title: renderHabitTitle(habit),
        friendid,
        habitid,
      });
    }
  }, []);

  return (
    <GenericHabitListItem
      DayComponent={HabitDay}
      {...props}
      habit={habit}
      onSelectHabit={selectHabit}
    />
  );
};

const RoutineHabitListItem = (props: {
  habit;
  habitid;
  onLongPress?: () => void;
  week;
  ownHabit;
  friendid;
}) => {
  const { habit, habitid, friendid } = props;
  const navigation = useNavigation<StackNavigationProp<StackProps>>();
  const selectHabit = useCallback(() => {
    navigation.navigate("HabitRoutineScreen", {
      habitid,
      title: habit?.title,
      friendid,
    });
  }, [friendid, habit?.title, habitid, navigation]);

  return (
    <GenericHabitListItem
      DayComponent={HabitDay}
      {...props}
      habit={habit}
      onSelectHabit={selectHabit}
    />
  );
};

const HLItem = (props: {
  habitid;
  onLongPress?: () => void;
  week;
  ownHabit;
  friendid;
  style?;
}) => {
  const { habitid } = props;
  Logger.log("HabitList Item re-rendering", habitid);
  const { habit } = useGetSpecificHabit(habitid);
  const isChallenge = isAChallenge(habit);

  if (isHabitRoutine(habit)) {
    return <RoutineHabitListItem {...props} habit={habit} />;
  }

  if (isChallenge) {
    return <ChallengeHLI {...props} habit={habit} />;
  }

  return <DefaultHabitListItem {...props} habit={habit} />;
};

const ChallengeHLI = (props: { habit; habitid; week; ownHabit; friendid }) => {
  const { habit, habitid, friendid, ownHabit } = props;
  const { auth } = useGetAuth();
  const navigation = useNavigation();

  const userIsInChallenge =
    isHabitChallenge(habit) && auth.uid in habit.challengerInfo;

  const onSelectHabit = useNavigateToChallenge(
    habitid,
    habit,
    ownHabit,
    friendid
  );

  return (
    <View>
      <GenericHabitListItem
        DayComponent={HabitDay}
        {...props}
        onSelectHabit={onSelectHabit}
      />
      {!ownHabit && isHabitChallenge(habit) && !userIsInChallenge && (
        <KButton
          mode="outlined"
          label="Join Habit"
          color={COLORS.accent}
          icon="flag"
          style={{ marginVertical: 5, borderLeftWidth: 0, borderRightWidth: 0 }}
          labelStyle={{ letterSpacing: 0 }}
          onPress={() => {
            logJoinGroupHabit(habit, "HabitListItem");
            if (isHabitRoutine(habit)) {
              logJoinRoutine(habit, "HabitListItem");
              navigation.navigate("RoutineOverviewScreen", {
                routineId: habit.routineId,
              });
              return;
            }
            navigation.navigate("AddHabitScreen", {
              habitChallenge: habit as HabitChallenge,
              habitChallengeId: habitid,
            });
          }}
        />
      )}
    </View>
  );
};

export const HabitListItem = memo(HLItem);

const styles = StyleSheet.create({
  listItem: {
    padding: 15,
    flexDirection: "row",
  },
  titleContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "baseline",
  },
  iconContainer: {
    justifyContent: "center",
    marginLeft: 15,
  },
  itemContent: {
    flex: 1,
    justifyContent: "space-between",
  },
  subtitle: {
    fontSize: 14,
    marginBottom: 12,
    fontWeight: "500",
  },
});
