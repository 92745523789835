import React, { useState, useEffect, useMemo } from "react";
import { View, StyleSheet } from "react-native";
import { InviteCarousel } from "components/HabitInvites/InviteCarousel";

const styles = StyleSheet.create({
  titleContainer: {
    marginHorizontal: 20,
    alignSelf: "flex-start",
    marginBottom: 10,
  },
});

export const InviteSection = ({
  style,
  title,
  data,
  renderItem,
  keyExtractor,
  ListFooterComponent,
  showEvenIfEmpty = false, // show the section title even if the carousel is empty
}) => {
  // Once the last carousel item disappears, we want the
  // animation to finish before the carousel disappears
  // so don't directly just take action on numDisplayedHabits
  // and instead use a specific state variable for whether to display
  const [shouldShow, setShouldShow] = useState(false);
  const numDisplayedCards = useMemo(() => data.filter(c => c.isShown).length, [
    data,
  ]);

  useEffect(() => {
    let mounted = true;
    if (numDisplayedCards === 0) {
      // TODO - animate the view away
      setTimeout(() => {
        if (mounted) {
          setShouldShow(false);
        }
      }, 750);
    } else {
      setShouldShow(true);
    }

    return () => {
      mounted = false;
    };
  }, [numDisplayedCards]);

  if (!shouldShow && !showEvenIfEmpty) {
    return null;
  }

  return (
    <View style={style}>
      <View style={styles.titleContainer}>{title}</View>
      {shouldShow && (
        <InviteCarousel
          data={data}
          renderItem={renderItem}
          keyExtractor={keyExtractor}
          ListFooterComponent={ListFooterComponent}
        />
      )}
    </View>
  );
};
