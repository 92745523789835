import { createSelector } from "@reduxjs/toolkit";
import { useSelector } from "redux/store";
import { Routine, RoutineContent } from "types/routines";
import { useFirestoreConnect, isLoaded } from "react-redux-firebase";
import { shallowEqual } from "react-redux";
import { useGetOrganization } from "redux/selectors/useGetOrganization";
import { useMemo } from "react";

export const useGetRoutine = (id: string) =>
  useSelector(state => state.firestore.data?.routines?.[id], shallowEqual);

export const useGetRoutineAndContent = (id: string) => {
  const routine: Routine = useGetRoutine(id);

  useFirestoreConnect([
    {
      collection: "routines",
      doc: id,
      subcollections: [{ collection: "content", doc: routine?.contentId }],
      storeAs: `routine-content-${routine?.contentId}`,
    },
  ]);

  const routineContent: RoutineContent[] = useSelector(
    state =>
      state.firestore.data?.[`routine-content-${routine?.contentId}`]?.content
  );

  return {
    isLoaded: isLoaded(routine, routineContent),
    routine,
    routineContent,
  };
};

const routinesSelector = createSelector(
  state => state.firestore?.ordered?.routines as Routine[],
  routines => {
    const r = routines ? [...routines] : [];
    return r.sort((r1, r2) => {
      const published = r2.publishedStatus - r1.publishedStatus;

      if (published === 0) {
        return r1.ordering - r2.ordering;
      }

      return published;
    });
  }
);

export const useGetRoutines = (): Routine[] => {
  const organization = useGetOrganization();

  const selector = useMemo(
    () =>
      createSelector(routinesSelector, r => {
        if (organization.enableAllRoutines) {
          return r;
        }
        if (organization.enabledRoutineIds) {
          const eligible = new Set(organization.enabledRoutineIds || []);

          return r.filter(r => eligible.has(r.id));
        }

        return r;
      }),
    [organization]
  );

  return useSelector(selector);
};
