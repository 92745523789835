import { useSelector } from "redux/store";
import { Logger } from "utils/Logger";
import { DayMap } from "types/habits";

export const useGetDays = ({ habitid, ownHabit }): DayMap => {
  Logger.log("useDays rerunning", "habitid", habitid, "ownHabit", ownHabit);
  return useSelector(state =>
    ownHabit
      ? state.userDays?.[habitid]
      : state.sharedDays.habitToDayMap?.[habitid]
  );
};
