export default {
  addFriend: {
    en: "Add friend",
    ja: "友達を追加",
    "zh-Hans": "添加好友",
    "zh-Hant": "添加好友",
  },
  chat: {
    en: "Chat",
    ja: "チャット",
    "zh-Hans": "消息",
    "zh-Hant": "消息",
  },
  delete: {
    en: "Delete",
    ja: "削除する",
    "zh-Hans": "删除",
    "zh-Hant": "刪除",
  },
  friends: {
    en: "Friends",
    ja: "友達",
    "zh-Hans": "好友",
    "zh-Hant": "好友",
  },
  friendRequestReceived: {
    en: "Friend requests received",
    ja: "友達リクエスト",
    "zh-Hans": "收到好友请求",
    "zh-Hant": "收到好友請求",
  },
  hasNotSharedHabits: {
    en: "Hasn't shared any habits!",
    ja: "ハビットをシェアしていません！",
    "zh-Hans": "没有分享任何习惯",
    "zh-Hant": "沒有分享任何習慣",
  },
  justAFriendlyBot: {
    en:
      "Just a friendly bot. I don't see any of your habits. Chatting me sends messages to our support team, or delete me if you'd like!",
    ja:
      "フレンドリーなボットです。 私はあなたの習慣を何も見えていません。 チャットすると、サポートチームにメッセージが送信します。必要に応じて削除してください。",
    "zh-Hans":
      "只是一个友好的机器人。我看不到你的任何习惯。与我聊天会向我们的支持小组发送消息，或者你如果愿意，可以将我删除",
    "zh-Hant":
      "只是一個友好的機器人。我看不到你的任何習慣。與我聊天會向我們的支持小組發送消息，或者如果你願意，可以將我刪除",
  },
  noOneHere: {
    en: "Ain't no one here. Add someone!",
    ja: "誰もいない。誰か追加してみて！",
    "zh-Hans": "这里一个人也没有。加个好友吧！",
    "zh-Hant": "這裡一個人也沒有。加個好友吧！",
  },
  sharingNumHabitsWithYou: {
    en: (num: number) => {
      switch (num) {
        case 1:
          return `Sharing ${num} habit with you`;
        default:
          return `Sharing ${num} habits with you`;
      }
    },
    ja: (num: number) => `あなたと${num}つのハビットをシェアしている`,
    "zh-Hans": (num: number) => `与你分享${num}个习惯`,
    "zh-Hant": (num: number) => `與你分享${num}個習慣`,
  },
  viewAll: {
    en: "View all",
    ja: "すべてを見る",
    "zh-Hans": "查看全部",
    "zh-Hant": "查看全部",
  },
  waitingOnResponse: {
    en: "Waiting on response",
    ja: "応答を待っています",
    "zh-Hans": "等待回应",
    "zh-Hant": "等待回應",
  },
};
