import React from "react";
import { useHistory } from "utils/react-router";
import { useGetSpecificHabit, useGetRoutine } from "redux/selectors";
import { AddHabitLayout } from "components";
import { PanelRight } from "panels/subpanels/PanelRight";
import { HabitChallenge } from "types/habits";

export const AddHabitPanel = () => {
  const { location } = useHistory<{
    habitid?: string;
    habitChallengeId?: string;
    routineId?: string;
  }>();
  const { habitid, habitChallengeId, routineId } = location?.state ?? {};
  const { habit } = useGetSpecificHabit(habitid);
  const { habit: habitChallenge } = useGetSpecificHabit(habitChallengeId) as {
    isLoaded: boolean;
    habit: HabitChallenge;
  };
  const routine = useGetRoutine(routineId);
  return (
    <PanelRight>
      <AddHabitLayout
        habitid={habitid}
        habit={habit}
        habitChallengeId={habitChallengeId}
        habitChallenge={habitChallenge}
        routineId={routineId}
        routine={routine}
      />
    </PanelRight>
  );
};
