import React, { useState } from "react";
import { ContactsList } from "components/Friends/ContactsList";
import { FriendOfFriends, AnnotatedContact, Friend } from "types/friends";
import { FetchState } from "utils/reducers";
import { AddByUserNameView } from "components/Friends/AddByUserNameView";
import { AddByEmailView } from "components/Friends/AddByEmailView";
import { useAddFriend } from "hooks/friends/useAddFriend";

export const AddFriendView = (props: {
  permissionGranted: boolean;
  fetchError: {
    loading?: boolean;
    error?: any;
  };
  friendsOfFriends: FriendOfFriends[];
  contacts: AnnotatedContact[];
  friends: Friend[];
  fetchState: FetchState;
  friendEmails: Set<string>;
  isLoadingFoF: boolean;
  smsSupported: boolean;
  addFriend: ReturnType<typeof useAddFriend>;
  doShowShareScreen;
  challengeHabitId?: string;
  routeName: string;
}) => {
  const { addFriend, fetchState } = props;
  const [shownScreen, setShownScreen] = useState("contacts");

  switch (shownScreen) {
    case "username":
      return (
        <AddByUserNameView
          setShownScreen={setShownScreen}
          addFriend={addFriend}
          fetchState={fetchState}
        />
      );
    case "email":
      return (
        <AddByEmailView
          setShownScreen={setShownScreen}
          addFriend={addFriend}
          fetchState={fetchState}
        />
      );
    default:
      return <ContactsList {...props} setShownScreen={setShownScreen} />;
  }
};
