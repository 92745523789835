import React from "react";
import { StyleSheet } from "react-native";
import { Paragraph } from "react-native-paper";
import * as StoreReview from "expo-store-review";
import * as Analytics from "expo-firebase-analytics";

import { COLORS } from "utils/appStyles";
import { KButton, KPrompt } from "components";

const Buttons = ({ dismissCard }) => {
  return (
    <>
      <KButton
        label="Sure!"
        mode="text"
        color={COLORS.logoBlue}
        onPress={async () => {
          const canPrompt = await StoreReview.isAvailableAsync();
          Analytics.logEvent("rating_prompt", {
            action: "rated",
          });
          if (canPrompt) {
            StoreReview.requestReview();
          }
          dismissCard();
        }}
      />
      <KButton
        label="No thanks"
        mode="text"
        color={COLORS.darkGray}
        onPress={() => {
          Analytics.logEvent("rating_prompt", {
            action: "dimissed",
          });
          dismissCard();
        }}
      />
    </>
  );
};

export const RatingPromptCard = ({ dismissCard }) => {
  return (
    <KPrompt
      body={
        <Paragraph style={styles.text}>
          Would you mind supporting the developers by rating the app?
        </Paragraph>
      }
      actions={<Buttons dismissCard={dismissCard} />}
    />
  );
};

const styles = StyleSheet.create({
  text: {
    fontSize: 15.5,
    paddingTop: 4,
  },
});
