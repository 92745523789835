import React, { useState, useEffect } from "react";
import {
  View,
  StyleSheet,
  FlatList,
  Text,
  AppState,
  SafeAreaView,
} from "react-native";
import * as Permissions from "expo-permissions";
import * as Haptics from "expo-haptics";

import { Footnote } from "components";
import { KSegmentedControl } from "components/UI/KSegmentedControl";
import { GrantNotificationsMessage } from "components/Notifications/GrantNotificationsMessage";
import { useGetAuth, useGetFriends } from "redux/selectors";
import { FriendStatus, Friend, NotificationType } from "types/friends";
import { toggleNotifications } from "utils/friends";
import { SEGMENTED_CONTROL_OPTIONS } from "utils/notifications";
import { isMobilePlatform } from "utils/helpers";
import { useNotificationPermissions } from "hooks/notifications/useNotificationPermissions";

const NotificationToggle = ({
  uid,
  friendid,
  wantNotifications,
  friendName,
  notificationsGranted,
}) => {
  return (
    <View style={styles.marginVertical} key={friendid}>
      <View style={[styles.center, { flexDirection: "row" }]}>
        <Text style={styles.text}>{friendName}</Text>
      </View>
      <KSegmentedControl
        disabled={!notificationsGranted}
        values={SEGMENTED_CONTROL_OPTIONS}
        initialSelectedName={SEGMENTED_CONTROL_OPTIONS[wantNotifications]}
        onChangeValue={(selected: string) => {
          const newVal = SEGMENTED_CONTROL_OPTIONS.indexOf(
            selected
          ) as NotificationType;

          if (isMobilePlatform) {
            // Haptics only for real device
            Haptics.selectionAsync();
          }

          if (newVal !== -1) {
            toggleNotifications(uid, friendid, newVal);
          }
        }}
        containerStyle={styles.margin}
      />
    </View>
  );
};

const MemoizedToggle = React.memo(NotificationToggle);

export const NotificationSettingsScreen = () => {
  const { friends } = useGetFriends();
  const { auth } = useGetAuth();
  const notificationsGranted = useNotificationPermissions();

  const friendsFiltered: Friend[] = friends.filter(
    friend =>
      friend.status === FriendStatus.Accepted ||
      friend.status === FriendStatus.AcceptedRequestor
  );

  return (
    <SafeAreaView style={styles.container}>
      {!notificationsGranted && <GrantNotificationsMessage />}
      <Footnote style={styles.highlightExplanation}>
        Highlights include notes and occasional habit updates.
      </Footnote>
      <FlatList
        data={friendsFiltered}
        renderItem={({ item }) => {
          return (
            <MemoizedToggle
              notificationsGranted={notificationsGranted}
              friendid={item.id}
              wantNotifications={item.wantNotifications}
              friendName={item.name}
              uid={auth.uid}
            />
          );
        }}
      />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: { flex: 1 },
  text: {
    textAlign: "center",
    margin: 5,
    fontWeight: "500",
  },
  margin: {
    margin: 10,
  },
  marginVertical: {
    marginVertical: 10,
  },
  marginTopMed: {
    marginTop: 20,
  },
  center: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  highlightExplanation: {
    color: "#888888",
    marginHorizontal: 20,
    marginTop: 20,
  },
});
