import React, { memo, useCallback, useReducer } from "react";
import AsyncStorage from "@react-native-community/async-storage";
import { useGetFriend, useGetSpecificHabit } from "redux/selectors";
import { HabitChallenge, isHabitRoutine, Visibility } from "types/habits";
import { ASYNC_STORAGE_INVITES_KEY, API_HOST } from "utils/constants";
import axios from "utils/axios";
import { fetchReducer } from "utils/reducers";
import {
  logJoinRoutine,
  logJoinGroupHabit,
  logPressInviteCardButton,
} from "utils/analytics";
import { ChallengeInviteCardLayout } from "components/Challenges/ChallengeInviteCardLayout";
import { useNavigation, useRoute } from "@react-navigation/native";
import { MainNavigation } from "layouts/MobileLayout";
import { useDimensionsContext, useLayoutContext, Layouts } from "contexts";
import { useHistory } from "utils/react-router";

const InviteCard = ({ habitid, owner, isShown, hideItem }) => {
  const navigation = useNavigation<MainNavigation>();
  const history = useHistory();
  const route = useRoute();
  const { width } = useDimensionsContext();
  const [fetchState, dispatch] = useReducer(fetchReducer, {});
  const layout = useLayoutContext();

  const { habit } = useGetSpecificHabit(habitid) as {
    isLoaded: boolean;
    habit: HabitChallenge;
  };

  const { friend } = useGetFriend(habit?.uid);

  const acceptChallenge = useCallback(() => {
    logJoinGroupHabit(habit, "ChallengeInviteCard");
    logPressInviteCardButton("join");
    if (isHabitRoutine(habit) && route.name !== "RoutineOverviewScreen") {
      logJoinRoutine(habit, "ChallengeInviteCard");
      navigation.navigate("RoutineOverviewScreen", {
        routineId: habit.routineId,
      });
      return;
    }

    dispatch({
      type: "LOADING",
      payload: {
        id: "join",
      },
    });
    axios
      .post(`${API_HOST}/joinChallenge`, {
        habitid,
        owner,
        sharing: {},
        visibility: Visibility.FRIENDS,
      })
      .then(() => {
        dispatch({
          type: "DONE_LOADING",
          payload: {
            id: "join",
          },
        });

        // Don't hide card here! Hide via updateCarouselOnChallengeJoin in ChallengeInvites
      })
      .catch(error => {
        dispatch({
          type: "ERROR",
          payload: {
            id: "join",
            error,
          },
        });
      });
  }, [habit, habitid, navigation, owner, route.name]);

  const dismissChallenge = useCallback(() => {
    logPressInviteCardButton("dismiss");
    dispatch({
      type: "LOADING",
      payload: {
        id: "dismiss",
      },
    });
    AsyncStorage.setItem(
      `${ASYNC_STORAGE_INVITES_KEY}:${habitid}`,
      "true"
    ).then(() => {
      dispatch({
        type: "DONE_LOADING",
        payload: {
          id: "dismiss",
        },
      });
      hideItem(habitid);
    });
  }, [habitid, hideItem]);

  const navigateToHabit = useCallback(() => {
    if (layout === Layouts.WIDE) {
      if (
        isHabitRoutine(habit) &&
        !history.location.pathname.startsWith("routine")
      ) {
        navigation.navigate("RoutineOverviewScreen", {
          routineId: habit.routineId,
        });
      } else {
        history.push(`/challenge/${habitid}`);
      }
    } else {
      if (isHabitRoutine(habit) && route.name !== "RoutineOverviewScreen") {
        navigation.navigate("RoutineOverviewScreen", {
          routineId: habit.routineId,
        });
      } else {
        navigation.navigate("HabitChallengeScreen", {
          habitid,
          title: habit?.title,
          friendid: owner,
        });
      }
    }
  }, [habit, habitid, history, layout, navigation, owner, route]);

  // initial state has no key so presence of the key + no error/loading means success
  const isJoinSuccess =
    "join" in fetchState &&
    !fetchState["join"].loading &&
    !fetchState["join"].error;

  const isDismissSuccess =
    "dismiss" in fetchState &&
    !fetchState["dismiss"].loading &&
    !fetchState["dismiss"].error;

  // If habit is unshared, habit will not exist anymore.
  if (!habit) {
    return null;
  }

  const numParticipants = Object.keys(habit.challengers).length;

  const data = {
    isShown,
    width,
    isJoinSuccess,
    isDismissSuccess,
    fetchState,
    habit,
    numParticipants,
    friend,
  };

  const actions = {
    acceptChallenge,
    dismissChallenge,
    navigateToHabit,
  };

  return <ChallengeInviteCardLayout {...data} {...actions} />;
};

export const ChallengeInviteCard = memo(InviteCard);
