import { useState, useEffect } from "react";
import { AppState } from "react-native";
import * as Application from "expo-application";
import moment from "moment";
import AsyncStorage from "@react-native-community/async-storage";

import {
  ASYNC_STORAGE_APP_OPENS_KEY,
  ASYNC_STORAGE_RATING_PROMPTED_KEY,
  ASYNC_STORAGE_CARD_VIEWS_KEY,
} from "utils/constants";

const APP_OPENS_CARD_SHOW_THRESHOLD = __DEV__ ? 2 : 3;
const DAYS_SINCE_INSTALL_THRESHOLD = 3;

/**
 * Custom hook to check if review gating prompt should be shown on HabitList
 * @return (boolean) indicating if gating card should be shown
 */
export const useGatingCondition = () => {
  // For dev, show card at 2 opens (to test AppState listener)
  // For prod, show card after 3 opens

  const [havePrompted, setHavePrompted] = useState(false);
  useEffect(() => {
    if (!havePrompted) {
      (async () => {
        // clear async storage keys for dev upon reload
        if (__DEV__) {
          await AsyncStorage.removeItem(`${ASYNC_STORAGE_RATING_PROMPTED_KEY}`);
          await AsyncStorage.removeItem(`${ASYNC_STORAGE_CARD_VIEWS_KEY}`);
        }
        AsyncStorage.getItem(
          `${ASYNC_STORAGE_RATING_PROMPTED_KEY}`
        ).then(checkPrompted => setHavePrompted(checkPrompted !== null));
      })();
    }
  }, [havePrompted]);

  const [appOpens, setAppOpens] = useState(0);
  const [installTime, setInstallTime] = useState(moment());
  useEffect(() => {
    async function getAppOpens(newState) {
      if (newState === "active") {
        AsyncStorage.getItem(
          `${ASYNC_STORAGE_APP_OPENS_KEY}`
        ).then(storedAppOpens => setAppOpens(parseInt(storedAppOpens)));

        Application.getInstallationTimeAsync().then(installDate =>
          setInstallTime(moment(installDate))
        );
      }
    }
    // call function initially as well for first time app is opened (when EventListener hasn't been added yet)
    getAppOpens("active");
    AppState.addEventListener("change", getAppOpens);
    return () => {
      AppState.removeEventListener("change", getAppOpens);
    };
  }, []);

  const today = moment();
  const daysSinceInstall = today.diff(installTime, "days");
  if (
    daysSinceInstall > DAYS_SINCE_INSTALL_THRESHOLD &&
    appOpens >= APP_OPENS_CARD_SHOW_THRESHOLD &&
    !havePrompted
  ) {
    return true;
  }
  return false;
};
