import React, { useState, useEffect, useCallback } from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { Ionicons } from "@expo/vector-icons";

import { useGetAuth } from "redux/selectors";
import { updateDay } from "utils/habits";
import { COLORS } from "utils/appStyles";

const Option = ({ icon, text, selected, color, onPress }) => (
  <TouchableOpacity
    onPress={onPress}
    style={[
      styles.option,
      { borderColor: color },
      selected ? styles.selected : styles.unselected,
    ]}
  >
    {icon}
    <Text style={[styles.text, selected ? { color } : {}]}>{text}</Text>
  </TouchableOpacity>
);

export const Toggle = ({ day, absoluteDay }) => {
  const { auth } = useGetAuth();
  const [status, setStatus] = useState(day?.status ?? 0);

  useEffect(() => {
    setStatus(day?.status ?? 0);
  }, [day]);

  const updateStatus = useCallback(
    newStatus => {
      if (newStatus !== status) {
        setStatus(newStatus);
      }
      updateDay(
        { ...day, absoluteDay, habitid: day.habitid },
        auth.uid,
        newStatus
      );
    },
    [auth, day, absoluteDay, status]
  );

  return (
    <View style={styles.container}>
      <Option
        text="No"
        selected={status === 2}
        color={COLORS.fail}
        onPress={() => {
          updateStatus(2);
        }}
        icon={
          <Ionicons
            name="md-close"
            size={20}
            color={COLORS.fail}
            style={{ marginRight: 10 }}
          />
        }
      />
      <Option
        text="Not Yet"
        selected={status === 0}
        color="black"
        onPress={() => {
          updateStatus(0);
        }}
        icon={null}
      />
      <Option
        text="Yes"
        selected={status === 1}
        color={COLORS.success}
        onPress={() => {
          updateStatus(1);
        }}
        icon={
          <Ionicons
            name="md-checkmark"
            size={20}
            color={COLORS.success}
            style={{ marginRight: 10 }}
          />
        }
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#eee",
    borderRadius: 50,
    flexDirection: "row",
    justifyContent: "space-between",
    marginVertical: 20,
  },
  option: {
    alignItems: "center",
    borderRadius: 50,
    borderWidth: 1,
    flexDirection: "row",
    justifyContent: "center",
    paddingVertical: 10,
    width: "33%",
  },
  text: {
    textAlign: "center",
  },
  success: {
    borderColor: COLORS.success,
  },
  notYet: {
    borderColor: "black",
  },
  fail: {
    borderColor: COLORS.fail,
  },
  selected: {
    backgroundColor: "white",
  },
  unselected: {
    borderColor: "transparent",
  },
});
