import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum BottomSheetTypes {
  NOTE = "NOTE",
}

export type BottomSheetNoteProps = {
  dayid: string;
  habitid: string;
  date: string;
  ownHabit: boolean;
  isEditable: boolean;
  showDayToggle: boolean;
};

const bottomSheetSlice = createSlice({
  name: "bottomSheet",
  initialState: null,
  reducers: {
    setBottomSheet: (
      _state,
      action: PayloadAction<{
        type: BottomSheetTypes.NOTE;
        props: BottomSheetNoteProps;
      }>
    ) => action.payload,
    removeBottomSheet: () => null,
  },
});

export const { setBottomSheet, removeBottomSheet } = bottomSheetSlice.actions;

export const bottomSheetSliceReducer = bottomSheetSlice.reducer;
