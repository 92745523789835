import { createSelector } from "@reduxjs/toolkit";
import { isLoaded } from "react-redux-firebase";

import { useSelector } from "redux/store";
import { Friend } from "types/friends";
import { Logger } from "utils/Logger";

const selector = createSelector(
  state => state.firestore.ordered.friends,
  friends => {
    Logger.log("Creating the Friend Selector Again!");
    return {
      isFriendsLoaded: isLoaded(friends),
      friends: (friends || []) as Friend[],
    };
  }
);

const specificFriendSelector = friendid =>
  createSelector(selector, ({ isFriendsLoaded, friends }) => ({
    isFriendsLoaded,
    friend: friends.find(f => f.id === friendid),
  }));

export const useGetFriends = (): {
  isFriendsLoaded: boolean;
  friends: Friend[];
} => {
  Logger.log("useFriends running");
  return useSelector(selector);
};

export const useGetFriend = (friendid: string) => {
  Logger.log("useFriend running, friendid", friendid);
  return useSelector(
    specificFriendSelector(friendid),
    (prev, current) =>
      prev.isFriendsLoaded === current.isFriendsLoaded &&
      prev.friend === current.friend
  );
};
