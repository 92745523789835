import React from "react";
import { StyleSheet } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { Footnote } from "components";
import { iOSColors } from "react-native-typography";

export const KTextButton = ({ label, onPress }) => (
  <TouchableOpacity onPress={onPress}>
    <Footnote style={styles.textButton}>{label}</Footnote>
  </TouchableOpacity>
);

const styles = StyleSheet.create({
  textButton: {
    margin: 20,
    textAlign: "center",
    textDecorationLine: "underline",
    color: iOSColors.gray,
  },
});
