import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "utils/react-router";

import { InviteScreenLayout } from "components";

import axios from "utils/axios";
import { API_HOST } from "utils/constants";
import { USER_HANDLE_REGEX } from "utils/strings";
import { useGetAuth, useGetFriends } from "redux/selectors";
import { UserProfileResponse } from "types/friends";
import { AxiosResponse } from "axios";
import {
  logTryAddFriend,
  logFriendInviteError,
  logFriendInviteSent,
} from "utils/analytics";

const isValidHandle = (userHandle: string) =>
  !!userHandle.match(USER_HANDLE_REGEX);

export const InviteScreen = () => {
  const { userHandle } = useParams();
  const { auth, profile } = useGetAuth();
  const { isSignedUp } = profile;
  const [userIsAdded, setUserIsAdded] = useState(false);
  const { friends } = useGetFriends();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [profileData, setProfileData] = useState<{
    name: string;
    avatar: string;
    email?: string;
    id: string;
  }>({});

  useEffect(() => {
    if (profileData?.id) {
      setUserIsAdded(friends.some(f => f.id === profileData.id));
    }
  }, [friends, profileData]);

  useEffect(
    function getUserHandle() {
      setIsLoading(true);
      setError(null);

      if (!isValidHandle(userHandle)) {
        setError(Error("INVALID_HANDLE"));
        setIsLoading(false);
        return;
      }

      axios
        .get(`${API_HOST}/getProfileByHandle?userHandle=${userHandle}`)
        .then((res: AxiosResponse<UserProfileResponse>) => {
          const user = res?.data?.user;
          console.log("user is", user);
          if (!user) {
            throw new Error("NOT_FOUND");
          }
          const { displayName, photoURL, email, id } = user;

          setProfileData({ name: displayName, avatar: photoURL, email, id });
        })
        .catch(error => setError(error))
        .finally(() => setIsLoading(false));
    },
    [userHandle]
  );

  const resetPage = useCallback(() => {
    setError(false);
  }, []);

  const data = {
    isLoading,
    error,
    profileData,
    auth,
    isSignedUp,
    userIsAdded,
  };

  const actions = {
    addFriend: async () => {
      setIsLoading(true);
      try {
        logTryAddFriend({ id: profileData.id }, "profilelink");
        await axios.post(`${API_HOST}/addFriend`, {
          id: profileData.id,
        });
        logFriendInviteSent();
      } catch (error) {
        console.log(error.response);
        logFriendInviteError({ id: profileData.id }, error);
        if (error?.response?.data?.message) {
          setError(Error(error.response.data.message));
        } else {
          setError(error);
        }
      } finally {
        setIsLoading(false);
      }
    },
    resetPage,
  };

  return <InviteScreenLayout {...data} {...actions} />;
};
