export default {
  notificationPromoTitle: {
    en: "Don't miss a thing",
  },
  notificationPromoSubtitle: {
    en: "Enable notifications for habit reminders and updates from friends",
  },
  enable: {
    en: "Turn on",
  },
  dismiss: {
    en: "Dismiss",
  },
};
