import React from "react";
import { RouteProp } from "@react-navigation/native";
import { StackProps } from "layouts/MobileLayout";
import { HabitChallengeView } from "components/Challenges/HabitChallengeView";

export const HabitChallengeScreen = ({
  route,
}: {
  route: RouteProp<StackProps, "HabitChallengeScreen">;
}) => {
  return <HabitChallengeView habitid={route.params?.habitid ?? ""} />;
};
