import React, { useState, useEffect } from "react";
import { ViewStyle, View, Text } from "react-native";
import { Loading, Footnote, SectionSubtitle } from "components";
import { EmailSignInView } from "components/Login/EmailSignInView";
import { LoginMotivationSection } from "components/Login/LoginMotivationSection";
import { LoginButtons } from "components/Login/LoginButtons";
import { TouchableOpacity } from "react-native-gesture-handler";
import { COLORS } from "utils/appStyles";
import { isWeb } from "utils/helpers";
import { SectionTitle } from "components/UI/Typography";

// https://stackoverflow.com/questions/31569518/how-to-detect-facebook-in-app-browser
const USER_AGENTS = [
  "FBAN",
  "FBAV",
  "Instagram",
  "MicroMessenger", // WeChat? https://stackoverflow.com/questions/25174582/is-it-possible-to-target-the-user-agent-string-for-wechats-built-in-browser-on
];

const OpenInBrowserPrompt = () => {
  useEffect(() => {
    if (isWeb) {
      // Don't necessarily need this since we gate this component
      // on isInEmbeddedView, but prevents infinite tabs opening
      // when testing
      // https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser
      const isChrome =
        !!window.chrome &&
        (!!window.chrome.webstore || !!window.chrome.runtime);

      if (!isChrome) {
        // Try opening in Chrome
        // No way to deep link into Safari so all we can do is render the warning
        window.location.href = `googlechromes://${window.location.href.replace(
          /https?:\/\//i,
          ""
        )}`;
      }
    }
  }, []);

  return (
    <View
      style={{
        backgroundColor: "#fff3cd",
        borderColor: "#ffeeba",
        borderWidth: 1,
        paddingBottom: 12,
        paddingHorizontal: 20,
        marginTop: 12,
        marginBottom: 20,
        marginHorizontal: 12,
        borderRadius: 8,
      }}
    >
      <SectionTitle>Use a Web Browser</SectionTitle>
      <SectionSubtitle>
        This page seems to be open inside an app right now, so log in may not
        work.{" "}
        <Text style={{ fontWeight: "600" }}>
          Open this in a web browser (e.g. Safari, Chrome) instead.
        </Text>
      </SectionSubtitle>
    </View>
  );
};

export const LogInView = ({
  hideMotivation,
  motivationReason,
  style,
  webRedirectTo,
}: {
  hideMotivation?: boolean;
  motivationReason?: string;
  style?: ViewStyle;
  webRedirectTo?: string;
}) => {
  const [isLogin, setIsLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailSelected, setIsEmailSelected] = useState(false);
  const [isInEmbeddedView, setIsInEmbeddedView] = useState(false);

  useEffect(() => {
    if (isWeb) {
      const ua = navigator.userAgent || navigator.vendor || window.opera;
      if (
        USER_AGENTS.some(a => ua.indexOf(a) > -1) ||
        typeof navigator.wxuserAgent !== "undefined"
      ) {
        setIsInEmbeddedView(true);
      }
    }
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  if (isEmailSelected) {
    return (
      <>
        {isInEmbeddedView && <OpenInBrowserPrompt />}
        <EmailSignInView
          setIsEmailSelected={setIsEmailSelected}
          webRedirectTo={webRedirectTo}
        />
      </>
    );
  }

  return (
    <View style={style || { margin: 20 }}>
      {isInEmbeddedView && <OpenInBrowserPrompt />}
      {!hideMotivation && (
        <LoginMotivationSection
          reason={!isLogin && (motivationReason || "Profile")}
        />
      )}
      <LoginButtons
        setIsLoading={setIsLoading}
        setIsEmailSelected={setIsEmailSelected}
      />
      <TouchableOpacity
        onPress={() => {
          setIsLogin(true);
        }}
      >
        {!isLogin && (
          <Footnote
            style={{
              textAlign: "center",
              textDecorationLine: "underline",
              color: COLORS.disabledDark,
            }}
          >
            Already have an account? Click to login.
          </Footnote>
        )}
      </TouchableOpacity>
    </View>
  );
};
