import moment from "moment";
import * as Analytics from "expo-firebase-analytics";

import { Friend } from "types/friends";
import {
  DayStatus,
  Day,
  RenderWeek,
  HabitType,
  Habit,
  HabitChallenge,
  DayInfo,
  SaveHabitData,
  FrequencyType,
  Visibility,
  isAChallengeWithMultipleParticipants,
  DayUpdateParams,
} from "types/habits";
import { formatReminderObject } from "utils/sharedHabitUtils";
import { COLORS } from "styles";
import { Logger } from "./Logger";
import axios from "./axios";
import { API_HOST } from "./constants";
import firebase from "./firebase";
import { getAbsoluteDays } from "./time";

import {
  logAddHabit,
  logDeleteHabit,
  logEditHabit,
  logArchiveHabit,
  logUpdateDay,
  logAddReminder,
} from "./analytics";

export const checkValidHabit = title => {
  if (title === "") {
    return false;
  } else {
    return true;
  }
};

export const isHabitEditable = (habit: Habit) => {
  return !isAChallengeWithMultipleParticipants(habit);
};

const createDefaultShareObject = ({ friends }: { friends: Friend[] }) => {
  const sharing: Habit["sharing"] = {};
  friends.forEach(f => {
    if (!f.type) {
      sharing[f.id] = true;
    }
  });

  return sharing;
};

const logReminderAnalytics = (reminder: Date, isChallenge: boolean): void => {
  const decimalTime = parseFloat(
    moment
      .duration(moment(reminder).format("HH:mm").toString())
      .asHours()
      .toFixed(2)
  );
  Analytics.logEvent("reminder_add", {
    value: decimalTime,
  });
  logAddReminder(decimalTime, isChallenge);
};

export const updateSharing = (
  habitid: string,
  friendid: string,
  value: boolean
) => {
  firebase
    .firestore()
    .collection("habits")
    .doc(habitid)
    .update({ [`sharing.${friendid}`]: value });
};

export const addHabit = async ({
  title,
  emoji,
  description,
  visibility,
  sharing,
  frequencyType,
  frequency,
  schedule,
  uid,
  friends,
  isChallenge,
  hasReminder,
  reminder,
}: SaveHabitData) => {
  if (checkValidHabit(title)) {
    const user = firebase.auth().currentUser;

    const formattedReminder = formatReminderObject(reminder, hasReminder);

    if (hasReminder) {
      logReminderAnalytics(reminder, isChallenge);
    }

    let habit: Omit<Habit, "createdAt"> = {
      uid,
      lastDayUpdate: Date.now(),
      title,
      emoji,
      description,
      visibility,
      sharing:
        visibility === Visibility.FRIENDS
          ? createDefaultShareObject({ friends })
          : sharing,
      frequencyType,
      frequency,
      schedule,
      isChallenge,
      hasReminder,
      reminder: formattedReminder,
      streak: 0,
      isArchived: false,
    };

    if (isChallenge) {
      habit = {
        ...habit,
        type: HabitType.Challenge,
        challengers: {
          [user.uid]: true,
        },
        challengerInfo: {
          [user.uid]: {
            name: user.displayName,
            email: user.email,
            photoURL: user.photoURL,
            isOwner: true,
          },
        },
        isLinkInviteEnabled: true,
      } as HabitChallenge;
    }

    const habitDoc = await firebase
      .firestore()
      .collection("habits")
      .add({
        ...habit,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
    logAddHabit(habit);

    // TODO: Move to cloud function for more reliability in sending notification
    if (isChallenge) {
      axios.post(`${API_HOST}/sendChallengeInvites`, {
        habitid: habitDoc.id,
        isRoutine: false,
      });
    }

    return habitDoc.id;
  }
};

export const editHabit = ({
  habitid,
  title,
  emoji,
  description,
  visibility,
  frequencyType,
  frequency,
  schedule,
  sharing,
  friends,
  isChallenge,
  hasReminder,
  reminder,
  isEditable,
}: SaveHabitData & { isEditable: boolean }) => {
  if (checkValidHabit(title)) {
    const user = firebase.auth().currentUser;

    const formattedReminder = formatReminderObject(reminder, hasReminder);

    let update: Omit<
      Habit,
      "uid" | "createdAt" | "streak" | "lastDayUpdate" | "isArchived"
    > & {
      type?: Habit["type"];
      challengers?:
        | HabitChallenge["challengers"]
        | firebase.firestore.FieldValue;
      challengerInfo?:
        | HabitChallenge["challengerInfo"]
        | firebase.firestore.FieldValue;
      isLinkInviteEnabled?:
        | HabitChallenge["isLinkInviteEnabled"]
        | firebase.firestore.FieldValue;
    } = {
      title,
      emoji,
      description,
      visibility,
      frequencyType,
      frequency,
      schedule,
      sharing,
      isChallenge,
      hasReminder,
      reminder: formattedReminder,
    };

    return firebase
      .firestore()
      .runTransaction(async trx => {
        const habitDoc = await trx.get(
          firebase.firestore().doc(`habits/${habitid}`)
        );

        if (!habitDoc.exists) {
          return;
        }

        const habit = habitDoc.data() as Habit;

        // Throw error if group habit isEditable but has more than one participant (someone joined during editing)
        // Need to check for habit.isChallenge instead of isChallenge otherwise habit.challengers is undefined
        if (isEditable && habit.isChallenge) {
          if (Object.keys((habit as HabitChallenge).challengers).length > 1) {
            throw new Error("HABIT_NOT_EDITABLE");
          }
        }

        //! Only update these fields if the habit is *becoming* a challenge
        if (!habit.isChallenge && isChallenge) {
          update = {
            ...update,
            type: Array.isArray(habit.type)
              ? [...habit.type, HabitType.Challenge]
              : HabitType.Challenge,
            challengers: {
              [user.uid]: true,
            },
            challengerInfo: {
              [user.uid]: {
                name: user.displayName,
                email: user.email,
                photoURL: user.photoURL,
                isOwner: true,
              },
            },
            isLinkInviteEnabled: true,
          };
        }

        //! Only update these fields if habit is *reverting* back to solo habit
        if (habit.isChallenge && !isChallenge) {
          update = {
            ...update,
            challengers: firebase.firestore.FieldValue.delete(),
            challengerInfo: firebase.firestore.FieldValue.delete(),
            isLinkInviteEnabled: firebase.firestore.FieldValue.delete(),
            // remove HabitType.Challenge from type field (if habit.type is "CHALLENGE" push empty array)
            type: Array.isArray(habit.type)
              ? habit.type.filter(t => t !== HabitType.Challenge)
              : habit.type === HabitType.Challenge
              ? []
              : [habit.type],
          };
        }

        // Only log Analytics event if reminder is *being* added
        if (!habit.hasReminder && hasReminder) {
          logReminderAnalytics(reminder, habit.isChallenge);
        }

        if (visibility === Visibility.FRIENDS) {
          const sharing = createDefaultShareObject({
            friends,
          });

          update.sharing = sharing;
        }

        trx.update(habitDoc.ref, update);

        return habit;
      })
      .then(async (habit: Habit) => {
        logEditHabit(habit);
        if (!habit.isChallenge && isChallenge) {
          axios.post(`${API_HOST}/sendChallengeInvites`, {
            habitid,
            isRoutine: false,
          });
        }
      });
  }
};

export const archiveHabit = (habitid: string) => {
  firebase.firestore().collection("habits").doc(habitid).update({
    isArchived: true,
  });
  logArchiveHabit(habitid);
};

export const deleteHabit = async (docid: string) => {
  try {
    await firebase.firestore().collection("habits").doc(docid).delete();
    logDeleteHabit(docid);
    axios
      .post(`${API_HOST}/deleteDays`, { habitid: docid })
      .catch(error => Logger.error(error));
  } catch (err) {
    Logger.error(err);
  }
};

/**
 *
 * @param day day to update
 * @param uid owner
 * @returns dayid - id of the new day doc
 */
export const updateDay = async (
  day: DayUpdateParams,
  uid: string,
  newStatus: DayStatus
) => {
  //only need to save a portion of the day
  //do not need to store displayed text in the DB.
  //displayed text changes based on the view, and is generated at runtime
  const { id, date, habitid, absoluteDay } = day;

  const daysCollection = firebase
    .firestore()
    .collection("habits")
    .doc(habitid)
    .collection("days");

  logUpdateDay({ id, date, habitid, absoluteDay, newStatus });
  if (id) {
    await daysCollection.doc(id).update({
      status: newStatus,
      lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
    });
    return id;
  } else {
    const ref = await daysCollection.add({
      date,
      absoluteDay,
      habitid,
      uid,
      status: newStatus,
      lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
    });
    return ref.id;
  }
};

export const getWeek = (options?): RenderWeek => {
  const { ending = null, format = "ddd" } = options || {};
  const startDay = (ending ? moment(ending) : moment()).subtract(6, "days");
  const day = startDay;
  const week = [];
  for (let i = 0; i < 7; i++) {
    week.push({
      display: day.format(format),
      date: day.format("MM-DD-YYYY"),
      dayOfWeek: day.day(),
      absoluteDay: getAbsoluteDays(day),
    });
    day.add(1, "days");
  }
  return week;
};

export const getDayStatusColor = status => {
  switch (status) {
    case DayStatus.SUCCESS:
      return COLORS.PRIMARY;
    case DayStatus.FAIL:
      return COLORS.fail;
    case DayStatus.SKIP:
      return COLORS.skip;
    default:
      return COLORS.skip;
  }
};

// Legacy habits don't have frequencyType, so
// check the schedule for whether to set to daily or scheduled.
// Schedule might not exist for very old habits!
export const getHabitFrequencyType = (habit: Habit) => {
  if (habit.frequencyType) {
    return habit.frequencyType;
  }

  const isDailyHabit = habit?.schedule?.every(s => s) ?? true;

  return isDailyHabit ? FrequencyType.DAILY : FrequencyType.SCHEDULED;
};
