// The following annotation causes RN to refresh state for fast reload
// Otherwise we get duplicate keys when channels already has content.
//@refresh reset
import React from "react";
import { ChatList } from "components/Chat/ChatList";
import { SafeAreaView, StyleSheet } from "react-native";
import { KHeader } from "components/UI/KHeader";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
  },
});

export const ChatListScreen = () => {
  return (
    <SafeAreaView style={styles.container}>
      <KHeader title="Chat" />
      <ChatList />
    </SafeAreaView>
  );
};
