import { useActionSheet } from "@expo/react-native-action-sheet";
import Constants from "expo-constants";
import React, { useEffect, useState } from "react";
import { Platform, Linking } from "react-native";
import AsyncStorage from "@react-native-community/async-storage";

import { Loading, KButton } from "components";

import { Logger } from "utils/Logger";
import { isMobilePlatform } from "utils/helpers";

const EMAIL_PROVIDERS = {
  "Apple Mail": "message://",
  Gmail: "googlegmail://",
  Outlook: "ms-outlook://",
  Yahoo: "ymail://",
  Superhuman: "superhuman://",
};

// Cache at the build version level -- if user upgrades or reinstalls, check limit is reset
// https://developer.apple.com/documentation/uikit/uiapplication/1622952-canopenurl
const ASYNC_STORAGE_PREFIX = `@Habit:${Constants.nativeAppVersion}:${Constants.nativeBuildVersion}:EmailProviders`;

export const OpenEmailButton = () => {
  const [emailApps, setEmailApps] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { showActionSheetWithOptions } = useActionSheet();

  useEffect(() => {
    Promise.all(
      Object.entries(EMAIL_PROVIDERS).map(async ([provider, scheme]) => {
        const canOpenCached = await AsyncStorage.getItem(
          `${ASYNC_STORAGE_PREFIX}:${provider}`
        );
        let canOpen = false;
        if (canOpenCached) {
          canOpen = canOpenCached === "true";
        } else {
          canOpen = await Linking.canOpenURL(scheme);
          AsyncStorage.setItem(
            `${ASYNC_STORAGE_PREFIX}:${provider}`,
            canOpen ? "true" : "false"
          );
        }
        return {
          provider,
          canOpen,
        };
      })
    )
      .then(results => {
        setEmailApps(results.filter(r => r.canOpen));
        setIsLoading(false);
      })
      .catch(err => {
        Logger.error(err);
        setIsLoading(false);
      });
  }, []);

  if (!isMobilePlatform) {
    return null;
  }

  if (isLoading && Platform.OS === "ios") {
    return <Loading />;
  }

  if (Platform.OS === "ios" && emailApps.length === 0) {
    return null;
  }

  return (
    <KButton
      icon="email"
      label="Open Email App"
      style={{ marginVertical: 10 }}
      onPress={() => {
        if (Platform.OS === "ios") {
          showActionSheetWithOptions(
            {
              options: [
                "Cancel",
                ...emailApps.map(a => a.provider),
                "Your app isn't here? Open it yourself! :P",
              ],
              cancelButtonIndex: 0,
            },
            buttonIndex => {
              if (buttonIndex > 0 && buttonIndex <= emailApps.length) {
                const { provider } = emailApps[buttonIndex - 1];
                if (provider in EMAIL_PROVIDERS) {
                  Linking.openURL(EMAIL_PROVIDERS[provider]);
                }
              }
            }
          );
        } else if (Platform.OS === "android") {
          const IntentLauncher = require("expo-intent-launcher");
          IntentLauncher.startActivityAsync("android.intent.action.MAIN", {
            category: "android.intent.category.APP_EMAIL",
            // Bitmask for FLAG_ACTIVITY_NEW_TASK
            // https://developer.android.com/reference/android/content/Intent#FLAG_ACTIVITY_NEW_TASK
            // Otherwise, the mail app takes over our app so when we deep link, our app receives it
            // but the mail app is a "view" on our activity.
            flags: 0x10000000,
          }).then(res => console.log(res));
        }
      }}
    />
  );
};
