import React from "react";
import { Keyboard, TouchableWithoutFeedback } from "react-native";
import { TextInput } from "react-native-paper";
import { TextInputProps } from "react-native-paper/lib/typescript/src/components/TextInput/TextInput";

import { isMobilePlatform } from "utils/helpers";

export const KTextInput = ({
  mode = "flat",
  disabled = false,
  onChangeText,
  value,
  label,
  placeholder,
  style = {},
  ...rest
}: Partial<TextInputProps>) => {
  const inner = (
    <TextInput
      label={label}
      mode={mode}
      placeholder={placeholder}
      disabled={disabled}
      onChangeText={onChangeText}
      value={value}
      style={[{ margin: 5 }, style]}
      selectionColor="black"
      theme={{
        colors: {
          primary: "black",
          background: "white",
        },
      }}
      {...rest}
    />
  );

  if (isMobilePlatform) {
    return (
      <TouchableWithoutFeedback onPress={() => Keyboard.dismiss()}>
        {inner}
      </TouchableWithoutFeedback>
    );
  } else {
    return inner;
  }
};
