import React, { Component } from "react";
import { Image, ActivityIndicator, View, StyleSheet } from "react-native";

export class LoadableImage extends Component {
  state = { loading: true };
  render() {
    const { source, style } = this.props;
    const smallLoading = (
      <ActivityIndicator size="small" style={styles.overlay} />
    );

    return (
      <View>
        <Image
          style={style}
          source={{ uri: source }}
          onLoadEnd={() => this.setState({ loading: false })}
          onLoadStart={() => this.setState({ loading: true })}
        />
        {this.state.loading && smallLoading}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  overlay: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
});
