import React, { useRef } from "react";
import { View, ScrollView, StyleSheet, SafeAreaView } from "react-native";

import { HabitsFooter } from "components/HabitList/HabitsFooter";
import { Logger } from "utils/Logger";
import { HabitGrid } from "components";
import { HabitTitleRow } from "components/HabitGrid/HabitTitleRow";
import { HabitTitleRowWide } from "components/HabitGrid/HabitTitleRowWide";
import { KAvatar } from "components/UI/KAvatar";
import { useHabitGridStyles } from "hooks/habits/useHabitGridStyles";
import { HabitListChallengeInvites } from "components/Challenges/ChallengeInvites";
import { AddFriendPromo } from "components/Promos/AddFriendPromo";
import { ReviewCard } from "components/RatingPrompt/ReviewCard";
import { GrantNotificationsCard } from "components/Notifications/GrantNotificationsCard";
import { RoutineInvites } from "components/Routines/RoutineInvites";
import { FeedList } from "components/Feed/FeedList";
import { useLayoutContext, Layouts } from "contexts";
import { isMobilePlatform } from "utils/helpers";
import { useGetHabitsCount } from "redux/selectors";
import { COLORS } from "styles";
import { styles as headerStyles } from "components/UI/KHeader";
import { Body } from "components/UI/Typography";

const PADDING_TOP = 15;

export const HabitList = ({
  ownHabit,
  friendAvatar,
  friendId,
  navigation,
}: {
  ownHabit: boolean;
  friendAvatar?: string;
  friendId?: string;
  navigation?: any;
}) => {
  Logger.log("HabitList re-rendering");
  const habitsCount = useGetHabitsCount(friendId);
  const layout = useLayoutContext();
  const { styles: gridStyles, GRID_LEFT_WIDTH } = useHabitGridStyles();
  const scrollViewRef = useRef(null);

  if (habitsCount === undefined) {
    return null;
  }

  const placeholderArray = Array(habitsCount).fill(0);

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView
        style={styles.container}
        scrollEventThrottle={16}
        ref={scrollViewRef}
      >
        <View style={styles.gridSection}>
          <HabitGrid ownHabit={ownHabit} friendId={friendId} />
          <View style={[styles.titles, { width: GRID_LEFT_WIDTH }]}>
            <View style={gridStyles.spacerBlock}>
              {ownHabit ? (
                <Body style={headerStyles.motivationHeader}>SnapHabit</Body>
              ) : friendAvatar ? (
                <KAvatar source={{ uri: friendAvatar }} size={42} />
              ) : null}
            </View>

            {placeholderArray.map((_, index) =>
              layout === Layouts.MOBILE ? (
                <HabitTitleRow
                  key={index}
                  index={index}
                  ownHabit={ownHabit}
                  friendid={friendId}
                />
              ) : (
                <HabitTitleRowWide
                  key={index}
                  index={index}
                  friendid={friendId}
                />
              )
            )}
          </View>
        </View>
        {ownHabit && isMobilePlatform && (
          <HabitsFooter navigation={navigation} />
        )}

        {ownHabit && (
          // Challenge invites
          <HabitListChallengeInvites style={styles.challengeInvites} />
        )}
        {ownHabit && isMobilePlatform && (
          <ReviewCard scrollViewRef={scrollViewRef} />
        )}
        {ownHabit && isMobilePlatform && (
          <GrantNotificationsCard scrollViewRef={scrollViewRef} />
        )}
        {ownHabit && (
          // Journeys
          <RoutineInvites style={styles.invites} />
        )}
        {ownHabit && isMobilePlatform && (
          // Today's Inspiration
          <FeedList />
        )}
        {isMobilePlatform && (
          // SnapHabit is better together
          <AddFriendPromo style={styles.addFriendPromo} />
        )}
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },

  section: {
    borderBottomWidth: 24,
    borderBottomColor: COLORS.GRAY1,
  },
  gridSection: {
    overflow: "hidden",
    paddingTop: PADDING_TOP,
  },
  titles: {
    position: "absolute",
    top: PADDING_TOP,
    left: 0,
    flex: 1,
    backgroundColor: "white",
    zIndex: 1,
  },
  habitInfoContainer: {
    position: "absolute",
    top: 0,
  },
  challengeInvites: {
    marginTop: 10,
    marginBottom: 20,
  },
  invites: {
    marginBottom: 20,
  },
  addFriendPromo: {
    marginTop: 40,
  },
  homescreenMotivationButton: {
    marginTop: 10,
    alignItems: "center",
  },
});
