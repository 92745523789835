import React, { useContext, useEffect, useState } from "react";
import { StreamChat } from "stream-chat";
import { useGetAuth } from "redux/selectors";
import { STREAM_KEY, API_HOST } from "utils/constants";
import axios from "utils/axios";
import { Logger } from "utils/Logger";
import { Chat, ChatContext, ChatContextValue } from "utils/stream";

const chatClient = new StreamChat(STREAM_KEY);

chatClient.logger = (level, message, _extraData) =>
  level === "error" &&
  Logger.warning({
    message,
  });

const ChatUnreadContext = React.createContext(0);

export const ChatProvider = ({ children }) => {
  const { auth, profile } = useGetAuth();
  const { isSignedUp } = profile;
  const [unreadCount, setUnreadCount] = useState<number>(0);

  useEffect(() => {
    if (!isSignedUp) {
      Logger.log("User not signed up, not signing to Stream");
      return;
    }

    Logger.log("Setting up signed in stream user", {
      id: auth.uid,
      name: auth.displayName,
      image: auth.photoURL,
    });

    chatClient
      .setUser(
        {
          id: auth.uid,
          name: auth.displayName,
          image: auth.photoURL,
        },
        async () => {
          const res = await axios.get(`${API_HOST}/getStreamToken`);
          const { token } = res.data;

          return token;
        }
      )
      .then(res => setUnreadCount(res?.me?.total_unread_count ?? 0));

    return () => {
      Logger.log("Stream chat disconnecting");
      chatClient.disconnect();
    };
  }, [auth, isSignedUp]);

  useEffect(() => {
    chatClient.on(event => {
      if (event.total_unread_count !== undefined) {
        setUnreadCount(event.total_unread_count);
      }
    });
  }, []);

  return (
    <Chat client={chatClient}>
      <ChatUnreadContext.Provider value={unreadCount}>
        {children}
      </ChatUnreadContext.Provider>
    </Chat>
  );
};

//@ts-ignore
export const useChatContext = () => useContext<ChatContextValue>(ChatContext);

export const useChatUnreadCount = () => useContext<number>(ChatUnreadContext);
