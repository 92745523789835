import React, { ReactElement } from "react";
import { StyleSheet, View } from "react-native";
import { Body } from "components/UI/Typography";
import { COLORS } from "utils/appStyles";
import { useHabitGridStyles } from "hooks/habits/useHabitGridStyles";

export const KHeader = ({
  title,
  children,
}: {
  title: string;
  children?: ReactElement;
}) => {
  const { styles: gridStyles, GRID_LEFT_WIDTH } = useHabitGridStyles();
  return (
    <View style={styles.container}>
      <View
        style={{
          width: GRID_LEFT_WIDTH,
        }}
      >
        <View style={[gridStyles.headerCell, styles.headerStyle]}>
          <Body style={styles.motivationHeader}>{title}</Body>
        </View>
      </View>
      <View>{children}</View>
    </View>
  );
};

export const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginRight: 8,
  },
  headerStyle: {
    borderBottomWidth: 0,
  },
  motivationHeader: {
    fontFamily: "OpenSans-SemiBold",
    color: COLORS.logoBlue,
    fontSize: 20,
  },
});
