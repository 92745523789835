import { StyleSheet } from "react-native";
import { COLORS } from "styles";

export const SECTION = StyleSheet.create({
  section: {
    borderBottomWidth: 24,
    borderBottomColor: COLORS.GRAY1,
    paddingTop: 32,
    paddingBottom: 40,
  },
});
