import React from "react";
import { SvgXml, XmlProps } from "react-native-svg";
import { Platform, View } from "react-native";

const xml = `
<svg width="37.571" height="25.657" viewBox="0 0 37.571 25.657">
  <g transform="translate(36.965 0.606) rotate(90)">
    <path fill="#d1e7ff" d="M24.239,15.755V6.06L12.12,0,0,6.06v9.7L12.12,9.7Z" transform="translate(0 0)"/>
    <path fill="#b9dcff" d="M15.393,9.439V6.06L3.274,0,0,1.637A5.621,5.621,0,0,0,3.841,4.7,10.03,10.03,0,0,0,5.8,4.729c4.3-.137,4.419,1.871,5.5,3.236A4.2,4.2,0,0,0,15.393,9.439Z" transform="translate(8.846 0)"/>
    <path fill="#b9dcff" d="M24.239,15.755V6.06L12.12,0,0,6.06v9.7L12.12,9.7Z" transform="translate(0 19.391)"/>
    <path fill="#a2d0ff" d="M15.393,9.439V6.06L3.274,0,0,1.637A5.621,5.621,0,0,0,3.841,4.7,10.03,10.03,0,0,0,5.8,4.729c4.3-.137,4.419,1.871,5.5,3.236a4.2,4.2,0,0,0,4.1,1.474Z" transform="translate(8.846 19.391)"/>
    <path fill="#e8f3ff" d="M24.239,15.755V6.06L12.12,0,0,6.06v9.7L12.12,9.7Z" transform="translate(0 9.696)"/>
    <path fill="#d1e7ff" d="M18.771,11.448V6.06L6.651,0,0,3.325c.275.8.045,1.871.544,2.6a2.275,2.275,0,0,0,2.7.495c2.078-.811,3.073-2.854,8.154-.345A9.307,9.307,0,0,1,13.828,7.65c1.5,1.457,2.341,4.074,4.943,3.8Z" transform="translate(5.468 9.696)"/>
  </g>
  <g transform="translate(37.571) rotate(90)">
    <path fill="#000000" d="M16.09,2.966,12.726,1.284,1.212,7.04v4.473a.606.606,0,1,1-1.212,0V6.666a.606.606,0,0,1,.335-.542L12.455.064a.606.606,0,0,1,.542,0l3.636,1.818a.606.606,0,0,1-.542,1.084Z" transform="translate(0 0)"/>
    <path fill="#000000" d="M.606,12.74C.271,12.74,0,12.3,0,11.76V.98C0,.439.271,0,.606,0s.606.439.606.98V11.76C1.212,12.3.941,12.74.606,12.74Z" transform="translate(13.598 1.885) rotate(-65)"/>
    <path fill="#000000" d="M1.212.606V7.272A.606.606,0,1,1,0,7.272V.606a.606.606,0,0,1,1.212,0Z" transform="translate(6.06 9.09)"/>
    <path fill="#000000" d="M24.574,30.841,19.391,28.25v3.261a.606.606,0,0,1-1.212,0V27.644l-5.454-2.727L.877,30.841A.606.606,0,0,1,0,30.3V10.908a.606.606,0,0,1,.335-.542L2.759,9.154A.606.606,0,1,1,3.3,10.238L1.212,11.282v8.341c12-6,11.307-5.685,11.514-5.685s-.426-.285,11.514,5.685V11.282L12.726,5.525,10.573,6.6a.606.606,0,1,1-.542-1.084l2.424-1.212a.606.606,0,0,1,.542,0L24.239,9.927V1.587l-.877-.439A.606.606,0,0,1,23.634,0c.19,0,.157.007,1.483.67a.606.606,0,0,1,.335.542V30.3A.606.606,0,0,1,24.574,30.841ZM1.212,20.978v8.341L12.455,23.7a.606.606,0,0,1,.542,0l11.243,5.621V20.978L12.726,15.221Z" transform="translate(0 5.454)"/>
    <path fill="#000000" d="M.606,0a.606.606,0,0,1,.606.606V2.424A.606.606,0,0,1,0,2.424V.606A.606.606,0,0,1,.606,0Z" transform="translate(12.12 34.541)"/>
  </g>
</svg>
`;

export const RoutineIcon = ({ style, ...rest }: Omit<XmlProps, "xml">) =>
  Platform.OS === "web" ? (
    <View style={style}>
      <svg width="37.571" height="25.657" viewBox="0 0 37.571 25.657" {...rest}>
        <g transform="translate(36.965 0.606) rotate(90)">
          <path
            fill="#d1e7ff"
            d="M24.239,15.755V6.06L12.12,0,0,6.06v9.7L12.12,9.7Z"
            transform="translate(0 0)"
          />
          <path
            fill="#b9dcff"
            d="M15.393,9.439V6.06L3.274,0,0,1.637A5.621,5.621,0,0,0,3.841,4.7,10.03,10.03,0,0,0,5.8,4.729c4.3-.137,4.419,1.871,5.5,3.236A4.2,4.2,0,0,0,15.393,9.439Z"
            transform="translate(8.846 0)"
          />
          <path
            fill="#b9dcff"
            d="M24.239,15.755V6.06L12.12,0,0,6.06v9.7L12.12,9.7Z"
            transform="translate(0 19.391)"
          />
          <path
            fill="#a2d0ff"
            d="M15.393,9.439V6.06L3.274,0,0,1.637A5.621,5.621,0,0,0,3.841,4.7,10.03,10.03,0,0,0,5.8,4.729c4.3-.137,4.419,1.871,5.5,3.236a4.2,4.2,0,0,0,4.1,1.474Z"
            transform="translate(8.846 19.391)"
          />
          <path
            fill="#e8f3ff"
            d="M24.239,15.755V6.06L12.12,0,0,6.06v9.7L12.12,9.7Z"
            transform="translate(0 9.696)"
          />
          <path
            fill="#d1e7ff"
            d="M18.771,11.448V6.06L6.651,0,0,3.325c.275.8.045,1.871.544,2.6a2.275,2.275,0,0,0,2.7.495c2.078-.811,3.073-2.854,8.154-.345A9.307,9.307,0,0,1,13.828,7.65c1.5,1.457,2.341,4.074,4.943,3.8Z"
            transform="translate(5.468 9.696)"
          />
        </g>
        <g transform="translate(37.571) rotate(90)">
          <path
            fill="#000000"
            d="M16.09,2.966,12.726,1.284,1.212,7.04v4.473a.606.606,0,1,1-1.212,0V6.666a.606.606,0,0,1,.335-.542L12.455.064a.606.606,0,0,1,.542,0l3.636,1.818a.606.606,0,0,1-.542,1.084Z"
            transform="translate(0 0)"
          />
          <path
            fill="#000000"
            d="M.606,12.74C.271,12.74,0,12.3,0,11.76V.98C0,.439.271,0,.606,0s.606.439.606.98V11.76C1.212,12.3.941,12.74.606,12.74Z"
            transform="translate(13.598 1.885) rotate(-65)"
          />
          <path
            fill="#000000"
            d="M1.212.606V7.272A.606.606,0,1,1,0,7.272V.606a.606.606,0,0,1,1.212,0Z"
            transform="translate(6.06 9.09)"
          />
          <path
            fill="#000000"
            d="M24.574,30.841,19.391,28.25v3.261a.606.606,0,0,1-1.212,0V27.644l-5.454-2.727L.877,30.841A.606.606,0,0,1,0,30.3V10.908a.606.606,0,0,1,.335-.542L2.759,9.154A.606.606,0,1,1,3.3,10.238L1.212,11.282v8.341c12-6,11.307-5.685,11.514-5.685s-.426-.285,11.514,5.685V11.282L12.726,5.525,10.573,6.6a.606.606,0,1,1-.542-1.084l2.424-1.212a.606.606,0,0,1,.542,0L24.239,9.927V1.587l-.877-.439A.606.606,0,0,1,23.634,0c.19,0,.157.007,1.483.67a.606.606,0,0,1,.335.542V30.3A.606.606,0,0,1,24.574,30.841ZM1.212,20.978v8.341L12.455,23.7a.606.606,0,0,1,.542,0l11.243,5.621V20.978L12.726,15.221Z"
            transform="translate(0 5.454)"
          />
          <path
            fill="#000000"
            d="M.606,0a.606.606,0,0,1,.606.606V2.424A.606.606,0,0,1,0,2.424V.606A.606.606,0,0,1,.606,0Z"
            transform="translate(12.12 34.541)"
          />
        </g>
      </svg>
    </View>
  ) : (
    <SvgXml xml={xml} style={style} {...rest} />
  );
