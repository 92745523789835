import * as ScreenOrientation from "expo-screen-orientation";

export const MOBILE_BREAK_POINT = 576;

const logoBlue = "#1C65A0";

export const COLORS = {
  primary: "white",
  error: "#de3704",
  disabled: "#CCCCCC",
  disabledDark: "#9E9E9E",
  active: "#4da2e8",
  challenge: "#7abaee",
  accent: logoBlue,
  success: "#388e3c",
  fail: "#ef5350",
  skip: "#f1f3f1",
  logoBlue,
  grayBox: "#F5F5F5", //https://material.io/design/color/#tools-for-picking-colors
  darkGray: "#616161",
};

// resets screen orientation back to PORTRAIT_UP (supported on all devices)
export const resetScreenOrientation = async () => {
  await ScreenOrientation.lockAsync(
    ScreenOrientation.OrientationLock.PORTRAIT_UP
  );
};

export const setScreenOrientation = async (
  orientationLock: ScreenOrientation.OrientationLock
) => {
  const supported: boolean = await ScreenOrientation.supportsOrientationLockAsync(
    orientationLock
  );
  if (supported) {
    await ScreenOrientation.lockAsync(orientationLock);
  }
};
