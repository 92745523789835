import React from "react";
import { Image, View, StyleSheet, Linking } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { Route, Switch, Redirect } from "utils/react-router";
import { PanelLeft } from "panels/subpanels/PanelLeft";
import { UserPanel } from "panels/UserPanel";
import { FriendPanel } from "panels/FriendPanel";
import { ChallengePanel } from "panels/ChallengePanel";
import { RoutinePanel } from "panels/RoutinePanel";
import { Body, Subtitle, KButton, BRANCH_LINK } from "components";
import { appIcon } from "assets/images";
import { COLORS } from "utils/appStyles";
import { useGetAuth } from "redux/selectors";
export const WideLayout = () => {
  const { auth } = useGetAuth();

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.bannerContainer}>
        <View style={styles.bannerContent}>
          <Image source={appIcon} style={styles.icon} />
          <View>
            <Subtitle>
              SnapHabit Web is in beta and some features may not work.
            </Subtitle>
            <Body>Get the mobile app for the best SnapHabit experience.</Body>
          </View>
        </View>
        <KButton
          label="Get the App"
          mode="outlined"
          color={COLORS.accent}
          onPress={() => {
            Linking.openURL(BRANCH_LINK);
          }}
          style={styles.button}
        />
      </View>
      <View style={styles.contentContainer}>
        <PanelLeft />
        <View style={styles.container}>
          <Switch>
            <Route exact path="/">
              <Redirect to="/me" />
            </Route>
            <Route path="/me" component={UserPanel} />
            <Route path="/routine/:id" component={RoutinePanel} />
            {!auth.isAnonymous && (
              <>
                <Route path="/friend/:id" component={FriendPanel} />
                <Route path="/challenge/:id" component={ChallengePanel} />
              </>
            )}
          </Switch>
        </View>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  bannerContainer: {
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    height: 80,
    borderBottomWidth: 1,
    borderBottomColor: COLORS.disabled,
  },
  bannerContent: { marginLeft: 12, flexDirection: "row", alignItems: "center" },
  container: {
    flex: 1,
  },
  contentContainer: {
    flexDirection: "row",
    flex: 1,
    backgroundColor: "white",
  },
  icon: { width: 50, height: 50, alignSelf: "center", margin: 8 },
  button: {
    marginLeft: 24,
  },
});
