import { Share } from "react-native";
import moment from "moment";

import {
  SaveHabitData,
  Habit,
  isHabitRoutine,
  isHabitChallenge,
} from "types/habits";
import axios from "utils/axios";
import { API_HOST, WEB_HOST_URL } from "utils/constants";
import { FirestoreUserData } from "types/users";
import { Logger } from "utils/Logger";

export const joinRoutine = async ({
  habitData,
  routineId,
}: {
  habitData: Omit<SaveHabitData, "friends">;
  routineId: string;
  shareProgressWithOwner: boolean;
}) => {
  const reqBody = {
    habitData,
    routineId,
    shareProgressWithOwner: false, // TODO - actually use this field
  };
  const res = await axios.post(`${API_HOST}/joinRoutine`, reqBody);

  if (habitData.isChallenge) {
    // send routine challenge invite notifications
    try {
      axios.post(`${API_HOST}/sendChallengeInvites`, {
        habitid: res.data.habitId,
        isRoutine: true,
      });
    } catch (error) {
      Logger.error(error);
    }
  }
  return res;
};

export const doShareNote = (
  habit: Habit,
  profile: FirestoreUserData,
  truncatedNote = ""
) =>
  Share.share({
    message:
      `${truncatedNote}\nThis was my reflection for ${habit.title} today. ` +
      `To see the full note, add me as a friend on SnapHabit: ${WEB_HOST_URL}/u/${profile.handle}`,
    title: "Join me on SnapHabit",
  });

export const doShare = (
  habit: Habit,
  profile: FirestoreUserData,
  optionalText = ""
) =>
  Share.share({
    message:
      `I'm working on ${habit.title} in SnapHabit. I'm on day ${
        moment().diff(
          isHabitRoutine(habit)
            ? habit.startTime.toDate()
            : habit.createdAt.toDate(),
          "days"
        ) + 1
      } and would love for you to join me. ${
        optionalText ? optionalText + " " : ""
      }` +
      (isHabitChallenge(habit) && habit.isLinkInviteEnabled
        ? `It's a group activity! Click ${WEB_HOST_URL}/join/${habit.id} to join.`
        : `Add me as a friend with this link: ${WEB_HOST_URL}/u/${profile.handle}`),
    title: "Join me on SnapHabit",
  });
