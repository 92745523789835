import React, { useEffect } from "react";
import { View, StyleSheet } from "react-native";

import { HabitList, Loading } from "components";
import { useGetIsHabitsLoaded, useGetHabitsCount } from "redux/selectors";
import { deleteHabit } from "utils/habits";
import { COLORS } from "utils/appStyles";

export const HabitListScreen = ({ route, navigation }) => {
  const isLoaded = useGetIsHabitsLoaded();
  const habitsCount = useGetHabitsCount();
  const shouldDelete = route.params?.shouldDelete ?? false;
  const habitIdToDelete: string = route.params?.habitid ?? null;

  useEffect(() => {
    if (shouldDelete && habitIdToDelete) {
      deleteHabit(habitIdToDelete);
    }
  }, [shouldDelete, habitIdToDelete]);
  if (!isLoaded || habitsCount === undefined) {
    return <Loading />;
  }

  return (
    <View style={styles.container}>
      <HabitList ownHabit navigation={navigation} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
  },
  invites: {
    position: "absolute",
    bottom: 10,
    height: 240,
    zIndex: 1,
    paddingVertical: 8,
    width: "100%",
  },
  fabStyle: {
    backgroundColor: COLORS.logoBlue,
  },
});
