import React, { ReactElement } from "react";
import {
  SegmentedControl,
  Segment,
  SegmentedControlProps,
  SegmentProps,
} from "react-native-resegmented-control";
import { StyleSheet, View, ViewStyle, Platform } from "react-native";
import { Body } from "components/UI/Typography";
import { useDimensionsContext } from "contexts";

export const KSegmentedControl = ({
  values,
  disabled,
  containerStyle,
  renderSegment,
  ...rest
}: {
  values: string[];
  disabled?: boolean;
  containerStyle?: ViewStyle;
  renderSegment?: (string) => ReactElement<SegmentProps>;
} & Omit<SegmentedControlProps, "children">) => (
  <View pointerEvents={disabled ? "none" : "auto"} style={containerStyle}>
    <View>
      <SegmentedControl
        {...rest}
        style={styles.controlContainer}
        sliderStyle={styles.slider}
      >
        {renderSegment
          ? values.map(renderSegment)
          : values.map(v => (
              <Segment
                key={v}
                name={v}
                style={segmentStyle.style}
                content={props => <SegmentContent {...props} name={v} />}
              />
            ))}
      </SegmentedControl>
      {disabled && <View style={styles.disabled} />}
    </View>
  </View>
);

export const SegmentContent = ({ name, IconLeft, ...props }) => {
  const { getScaledFontSize } = useDimensionsContext();
  const fontSize = getScaledFontSize(0.9);
  return (
    <View style={styles.segmentContentContainer}>
      {IconLeft}
      <Body
        {...props}
        style={[
          styles.segmentLabel,
          props.active
            ? { color: props.activeTintColor, fontSize }
            : { color: props.inactiveTintColor, fontSize },
        ]}
      >
        {name}
      </Body>
    </View>
  );
};

export const segmentStyle = StyleSheet.create({
  style: Platform.select({
    // "width: 100%"" on segment content does not make the entire segment tapable on web
    web: { alignItems: "stretch" },
    default: {},
  }),
});

const styles = StyleSheet.create({
  controlContainer: {
    height: 34,
    borderRadius: 5,
    backgroundColor: "#F2F2F2",
    marginHorizontal: 1,
  },
  slider: {
    borderRadius: 5,
    // No elevation here because it moves the slider above the other content on Android
    shadowOffset: { width: 0, height: 2 },
    shadowColor: "#CECECE",
    shadowOpacity: 1,
    shadowRadius: 2,
  },
  segmentContentContainer: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
  },
  segmentLabel: {
    flex: 1,
    textAlign: "center",
    fontFamily: "OpenSans",
  },
  disabled: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: "white",
    opacity: 0.5,
    borderRadius: 7,
  },
});
