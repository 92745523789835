import React from "react";
import { View, StyleSheet } from "react-native";
import { ToggleSetting } from "components";
import { useGetSpecificHabit, useGetAuth } from "redux/selectors";
import { HabitChallenge } from "types/habits";
import { useFirestore } from "react-redux-firebase";
import { WEB_HOST_URL } from "utils/constants";
import { doShareChallengeInviteLink } from "utils/sharing";
import { ShareLink } from "components/Invite/ShareLink";
import { useDispatch } from "react-redux";

const styles = StyleSheet.create({
  container: {
    marginBottom: 12,
  },
  toggle: { borderTopWidth: 0, padding: 0, paddingVertical: 12 },
});

export const LinkInvite = ({ challengeHabitId, routeName }) => {
  const firestore = useFirestore();
  const { habit } = useGetSpecificHabit(challengeHabitId) as {
    isLoaded: boolean;
    habit: HabitChallenge;
  };
  const { auth } = useGetAuth();
  const dispatch = useDispatch();

  const inviteLink = `${WEB_HOST_URL}/join/${challengeHabitId}`;

  const isDisabled = auth.uid !== habit.uid;

  return (
    <View style={styles.container}>
      <ToggleSetting
        style={styles.toggle}
        label="Invite by link"
        caption={
          "Anyone with the link can join this habit." +
          (isDisabled ? " Setting is controlled by the owner." : "")
        }
        value={habit.isLinkInviteEnabled}
        disabled={isDisabled}
        onToggle={val => {
          firestore.doc(`habits/${challengeHabitId}`).update({
            isLinkInviteEnabled: val,
          });
        }}
      />
      {habit.isLinkInviteEnabled && (
        <ShareLink
          type="habit_link_widget"
          link={inviteLink}
          onPress={() => {
            doShareChallengeInviteLink(
              { ...habit, id: challengeHabitId },
              dispatch
            );
          }}
          routeName={routeName}
        />
      )}
    </View>
  );
};
