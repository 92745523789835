import React from "react";
import { Text, StyleSheet } from "react-native";
import { Footnote } from "components";

const styles = StyleSheet.create({
  text: { margin: 20, textAlign: "center" },
  nonCenter: { margin: 10 },
  bold: { fontWeight: "700" },
});

export const ConEmailWarning = () => (
  <Footnote style={styles.text}>
    Did you mean <Text style={styles.bold}>.com?</Text>
  </Footnote>
);
