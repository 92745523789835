export default {
  //TODO: change for other langs
  challenge: {
    en: "Group",
    // ja: "チャレンジ",
    // "zh-Hans": "挑战",
    // "zh-Hant": "挑戰",
  },
  justThem: {
    en: "Just them... why not join?",
    ja: "彼らだけ...参加しない？",
    "zh-Hans": "只有一人...加入？",
    "zh-Hant": "只有一人...加入？",
  },
  justYou: {
    en: "Just you... ask friends to join",
    ja: "あなただけ...友達を誘おう",
    "zh-Hans": "只有你...邀请好友加入",
    "zh-Hant": "只有你...邀請好友加入",
  },
  numParticipants: {
    en: (num: number) => `${num} participants`,
    ja: (num: number) => `${num}人の参加者`,
    "zh-Hans": (num: number) => `${num}名参加者`,
    "zh-Hant": (num: number) => `${num}名參加者`,
  },
  //TODO: change for other langs from challenge to group
  yourChallenge: {
    en: "Your Group",
    // ja: "あなたの挑戦",
    // "zh-Hans": "你的挑战",
    // "zh-Hant": "你的挑戰",
  },
  // Invites
  invitesTitle: {
    en: (num: number) => `Habit Invites (${num})`,
  },
  invitesSubtitle: {
    en: "Achieve your goals together.",
  },
};
