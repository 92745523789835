import React from "react";
import { StyleSheet, View, Linking } from "react-native";
import { KCard } from "components/UI/KCard";
import { TouchableOpacity } from "react-native-gesture-handler";
import { Ionicons, MaterialIcons } from "@expo/vector-icons";
import { useDimensionsContext } from "contexts";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "center",
  },
  itemContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  item: {
    alignSelf: "center",
  },
});

const ITEMS = [
  {
    icon: <Ionicons name="logo-reddit" size={40} style={styles.item} />,
    link: "https://reddit.com/r/snaphabit",
  },
  {
    icon: <MaterialIcons name="mail-outline" size={30} style={styles.item} />,
    link: "mailto:feedback@snaphabit.app?subject=App Feedback",
  },
];

export const FeedbackWidget = () => {
  const { width } = useDimensionsContext();

  return (
    <View style={styles.container}>
      {ITEMS.map(i => (
        <TouchableOpacity key={i.link} onPress={() => Linking.openURL(i.link)}>
          <KCard
            style={{
              width: width / 6,
              maxWidth: 75,
              height: width / 6,
              maxHeight: 75,
              ...styles.itemContainer,
            }}
          >
            {i.icon}
          </KCard>
        </TouchableOpacity>
      ))}
    </View>
  );
};
