import { NoteMedia, DayStatus, Habit } from "types/habits";
export const STREAK_EMOJI = "🔥";
export const SKIP_EMOJI = "❓";
export const CHAT_EMOJI_SUCCESS = "✓";
export const CHAT_EMOJI_FAIL = "✗";
export const EMOJI_SUCCESS = ["👍"]; //["✓", "🆗", "☑️", "✔️", "✅", "👍", "👌"];
export const EMOJI_FAIL = ["❌"]; //["✗", "🙅", "❌", "❎", "👎"];
// https://stackoverflow.com/a/201378
export const EMAIL_REGEX = RegExp(
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
);

export const NAME_REGEX = /^[a-zA-Z\s]+$/;

export const USER_HANDLE_REGEX = /^[a-z0-9]+(-[a-z0-9]+)*$/;

//-3 because ... is length 3
export const truncate = (s: string, to: number = 15) =>
  s && s.length > to ? s.substring(0, to - 3) + "..." : s;

export const firstName = (name = "") => name.replace(/ .*/, "");

export const renderNote = note => note?.replace(/\n/g, " ");

export const arrayToString = a => a.map(e => truncate(e)).join(", ");

export const renderNoteMediaString = ({ media }: { media: NoteMedia[] }) => {
  const imageCount = media ? media.filter(m => m.type === "image").length : 0;

  const videoCount = media ? media.length - imageCount : 0;

  return `${
    imageCount ? `${imageCount} photo${imageCount > 1 ? "s" : ""} | ` : ""
  }${videoCount ? `${videoCount} video${videoCount > 1 ? "s" : ""} | ` : ""}`;
};

export const renderHabitTitle = (h: Habit) => {
  if (typeof h.emoji !== "undefined") {
    return h.emoji + " " + h.title;
  }
  return h.title;
};

const dayAbbreviations = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];

export const getSchedule = (schedule = [], frequencyType, frequency) => {
  //frequency === 7 and weekly habit OR every day is scheduled
  if (
    (frequencyType === "Weekly" && frequency === 7) ||
    //all habits with weekly schedules have truthy values for each schedule value
    (frequencyType !== "Weekly" &&
      //some habits never had schedules, so schedule.length === 0
      (schedule.length === 0 || schedule.every(val => val)))
  ) {
    return "daily";
  } else if (frequencyType === "Weekly") {
    return `${frequency} days/week`;
  }
  //some days are picked
  else {
    return schedule
      .map((val, index) => (val ? dayAbbreviations[index] : null))
      .filter(day => day)
      .join("·");
  }
};

export const dayStatusToEmoji = status =>
  status === DayStatus.SUCCESS
    ? CHAT_EMOJI_SUCCESS
    : status === DayStatus.FAIL
    ? CHAT_EMOJI_FAIL
    : SKIP_EMOJI;

export const sortTitle = (a: Habit, b: Habit) =>
  (a.title + "").localeCompare(b.title + "");

export const pluralize = (num: number) => {
  if (num === 1) {
    return "";
  }

  return "s";
};

export const parseEmail = (email: string = "") => {
  return {
    isEmail: EMAIL_REGEX.test(email.toLowerCase()),
    emailTLD: email.includes(".")
      ? email.substring(email.lastIndexOf("."))
      : null,
  };
};
