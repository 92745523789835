import { Card, Paragraph } from "react-native-paper";
import { StyleSheet } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import React from "react";

import { KAvatar, KButton } from "components";
import { COLORS } from "utils/appStyles";
import { pluralize, renderHabitTitle } from "utils/strings";
import { TouchableOpacity } from "react-native-gesture-handler";
import { AnimatedInviteCard } from "components/HabitInvites/AnimatedInviteCard";

const styles = StyleSheet.create({
  cardContent: { flex: 1, justifyContent: "space-between" },
  italic: {
    fontStyle: "italic",
  },
  arrowIcon: { paddingRight: 16, paddingLeft: 8 },
  description: {
    fontSize: 16,
  },
  titleStyle: {
    fontSize: 18,
  },
});

export const ChallengeInviteCardLayout = ({
  isShown,
  width,
  fetchState,
  isJoinSuccess,
  isDismissSuccess,
  habit,
  numParticipants,
  friend,
  acceptChallenge,
  dismissChallenge,
  navigateToHabit,
}) => {
  return (
    <AnimatedInviteCard isShown={isShown} width={width}>
      <TouchableOpacity onPress={navigateToHabit}>
        <Card.Title
          title={renderHabitTitle(habit)}
          titleStyle={styles.titleStyle}
          subtitle={`${friend.name} | ${numParticipants} participant${pluralize(
            numParticipants
          )}`}
          left={() => <KAvatar source={{ uri: friend.avatar }} size={40} />}
          right={() => (
            <Ionicons
              name="ios-arrow-forward"
              size={15}
              style={styles.arrowIcon}
            />
          )}
        />
      </TouchableOpacity>
      <Card.Content style={styles.cardContent}>
        <Paragraph numberOfLines={1}>{habit.description}</Paragraph>
      </Card.Content>
      <Card.Actions>
        <KButton
          label="Join"
          mode="text"
          icon={
            isJoinSuccess
              ? "check"
              : fetchState?.["join"]?.error
              ? "alert-circle-outline"
              : null
          }
          color={fetchState?.["join"]?.error ? COLORS.error : COLORS.logoBlue}
          onPress={acceptChallenge}
          disabled={fetchState?.["join"]?.loading}
          loading={fetchState?.["join"]?.loading}
        />
        <KButton
          label="Dismiss"
          mode="text"
          color={COLORS.darkGray}
          icon={isDismissSuccess && "check"}
          onPress={dismissChallenge}
          disabled={fetchState?.["dismiss"]?.loading}
          loading={fetchState?.["dismiss"]?.loading}
        />
      </Card.Actions>
    </AnimatedInviteCard>
  );
};
