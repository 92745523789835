import React from "react";
import { Text, View } from "react-native";
import { ViewWithDimensionsContext } from "contexts";
import { KButton, HabitList, ContentCenteredView } from "components";
import { Route, Switch, useHistory, Redirect } from "utils/react-router";
import { HabitDetailsPanel } from "panels/subpanels/HabitDetailsPanel";
import { NotesPanel } from "panels/subpanels/NotesPanel";
import { AddHabitPanel } from "panels/subpanels/AddHabitPanel";
import { AddFriendPanel } from "panels/subpanels/AddFriendPanel";
import { useGetHabitsCount, useGetAuth } from "redux/selectors";

export const UserPanel = () => {
  const habitCount = useGetHabitsCount();
  const history = useHistory();
  const { auth } = useGetAuth();

  if (habitCount === undefined) {
    return null;
  }

  return (
    <View style={{ flex: 1, flexDirection: "row" }}>
      <ViewWithDimensionsContext style={{ flex: 1 }}>
        {habitCount === 0 ? (
          <ContentCenteredView>
            <Text>Ain't nothing here.</Text>
            <Text>Add a habit! That's the point of this app!</Text>
            <KButton
              icon="plus-circle"
              label="Add Habit"
              onPress={() => history.push("/me/addHabit")}
              style={{ margin: 10 }}
            />
          </ContentCenteredView>
        ) : (
          <HabitList ownHabit />
        )}
      </ViewWithDimensionsContext>
      <Switch>
        <Route path="/me/details/:id" component={HabitDetailsPanel} />
        <Route path="/me/notes/:habitid/:date" component={NotesPanel} />
        <Route path="/me/addHabit" component={AddHabitPanel} />
        <Route path="/me/editHabit/:habitid" component={AddHabitPanel} />
        {!auth.isAnonymous && (
          <Route path="/me/addFriend" component={AddFriendPanel} />
        )}
        <Redirect to="/me" />
      </Switch>
    </View>
  );
};
