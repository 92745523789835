import { useSelector } from "redux/store";
import { generateStreaks } from "utils/streaks";
import firebase from "utils/firebase";
import { Logger } from "utils/Logger";
import { useRef, useEffect } from "react";

export const StreaksComputer = () => {
  // Streak Updates, loading
  const days = useSelector(state => state?.firestore.data.days);
  const habits = useSelector(state => state?.firestore.data.habits);

  const oldDays = useRef();
  useEffect(() => {
    //! If a user adds a schedule, we will not recalculate streaks...
    if (days === oldDays.current) {
      return;
    }
    const streaks = generateStreaks(oldDays.current, days, habits);
    const entries = Object.entries(streaks);
    if (entries.length > 0) {
      const batch = firebase.firestore().batch();
      entries.map(([habitId, streak]) => {
        batch.update(firebase.firestore().doc(`habits/${habitId}`), {
          streak,
        });
      });

      batch.commit().catch(error => {
        // Habit could have been deleted, in which case we get this error and it's
        // expected.
        // Running in a transaction and checking existence also causes this error
        // So this seems to be the best we can do
        if (error.code !== "permission-denied") {
          Logger.error(error);
        }
      });
    }

    oldDays.current = days;
  }, [days, habits]);

  return null;
};
