import React from "react";
import {
  View,
  SafeAreaView,
  KeyboardAvoidingView,
  StyleSheet,
  Platform,
} from "react-native";

/*
  Originally refactored from ChatScreen:
  Our own keyboard handling logic. This wasn't needed before for iOS but it seems like GC 0.13 broke --
  when the keyboard when up, the MessageContainer was too small, causing a gap between the input and
  the keyboard (input looked like it was too big).
  See commit 06cbab2365486a8c3d58efe33d8affc6737bc6b2 for the old code.

  Fixes input being hidden by keyboard
  https://github.com/FaridSafi/react-native-gifted-chat/issues/1424
  Added our own solution to get it working on Android.
  Setting keyboardHidesTabBar = true for Android caused input to be too
  high up when keyboard was down.

  ! After switching to materialTabBar and react native 5, we have been able to remove the android fix
*/
export const KKeyboardAvoidingView = ({ render }) => {
  // const [bottomOffset, setBottomOffset] = useState(0);
  // const wrapper = useRef<View>();
  // const handleLayout = useCallback(() => {
  //   if (wrapper.current) {
  //     wrapper.current.measureInWindow(
  //       (_x: number, y: number, _width: number, height: number): void => {
  //         const nextBottomOffset = Dimensions.get("window").height - y - height;
  //         setBottomOffset(nextBottomOffset);
  //       }
  //     );
  //   }
  // }, []);

  //! Scroll view is still jumpy
  return (
    <KeyboardAvoidingView
      style={styles.container}
      behavior={Platform.OS === "ios" ? "padding" : null}
    >
      <SafeAreaView style={styles.container}>
        <View style={styles.inner}>
          {render()}
          <View style={{ flex: 1 }} />
        </View>
      </SafeAreaView>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  inner: {
    flex: 1,
    justifyContent: "flex-end",
  },
});
