import React, { useState, useEffect, memo } from "react";
import { StyleSheet, View } from "react-native";
import AsyncStorage from "@react-native-community/async-storage";
import { RoutineInviteCard } from "components/Routines/RoutineInviteCard";
import { useString } from "hooks";
import { SectionTitle, SectionSubtitle } from "components";
import {
  useGetAuth,
  useGetRoutines,
  useGetHabitsUnfiltered,
} from "redux/selectors";
import { ASYNC_STORAGE_INVITES_KEY } from "utils/constants";
import { isHabitRoutine } from "types/habits";
import { InviteSection } from "components/HabitInvites/InviteSection";
import { useDimensionsContext } from "contexts";
import { FeedbackWidget } from "components/Feedback/FeedbackWidget";
import { RoutineInviteSectionButtons } from "components/Routines/RoutineInviteSectionButtons";
import { SECTION } from "styles";

const keyExtractor = (item, index) => `${index}-${item.routineId}`;

const styles = StyleSheet.create({
  textCenter: { textAlign: "center" },
  card: {
    flex: 1,
    justifyContent: "center",
    margin: 20,
  },
  wrapper: {
    paddingTop: 32,
    paddingBottom: 40,
  },
});
const ListEndCard = () => {
  const s = useString("routine");
  const { width } = useDimensionsContext();
  return (
    <View style={[styles.card, { width: width * 0.9, maxWidth: 300 }]}>
      <SectionTitle style={styles.textCenter}>{s("endCardTitle")}</SectionTitle>
      <SectionSubtitle style={styles.textCenter}>
        {s("endCardSubtitle")}
      </SectionSubtitle>
      <FeedbackWidget />
    </View>
  );
};
const InviteLayout = ({
  style,
  data,
  hideItem,
}: {
  style;
  data: { routineId: string; isShown: boolean }[];
  hideItem;
}) => {
  const s = useString("routine");

  const renderItem = ({ item: { routineId, isShown } }) => {
    return (
      <RoutineInviteCard
        key={routineId}
        id={routineId}
        isShown={isShown}
        hideItem={hideItem}
      />
    );
  };

  return (
    <View style={SECTION.section}>
      <InviteSection
        style={style}
        title={
          <>
            <SectionTitle>{s("invitesTitle")}</SectionTitle>
            <SectionSubtitle>{s("invitesSubtitle")}</SectionSubtitle>
          </>
        }
        data={data}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
        ListFooterComponent={ListEndCard}
        showEvenIfEmpty
      />
      <RoutineInviteSectionButtons />
    </View>
  );
};

// TODO - think about how to refactor to share logic with ChallengeInvites
const RoutineInvitesCarousel = ({ style }) => {
  const { auth } = useGetAuth();
  const routines = useGetRoutines();

  const [isInitialized, setIsInitialized] = useState(false);
  const [eligibleHabits, setEligibleHabits] = useState([]);

  // include archived habits as well
  const habits = useGetHabitsUnfiltered();

  useEffect(
    function initializeCarouselData() {
      let mounted = true;
      const keys = routines.map(h => `${ASYNC_STORAGE_INVITES_KEY}:${h.id}`);

      AsyncStorage.multiGet(keys).then(res => {
        const set = new Set();
        for (let i = 0; i < res.length; i++) {
          const [k, v] = res[i];
          if (v) {
            set.add(k.replace(`${ASYNC_STORAGE_INVITES_KEY}:`, ""));
          }
        }

        const notDeclinedAndNotAlreadyIn = routines
          .filter(
            r =>
              !set.has(r.id) &&
              !habits.find(h => isHabitRoutine(h) && h.routineId === r.id)
          )
          .map(r => ({
            routineId: r.id,
            isShown: true,
          }));

        if (mounted) {
          setEligibleHabits(notDeclinedAndNotAlreadyIn);
          setIsInitialized(true);
        }
      });

      return () => {
        mounted = false;
      };
    },
    [auth.uid, habits, routines]
  );

  if (!isInitialized) {
    return null;
  }

  const hideItem = id => {
    AsyncStorage.setItem(`${ASYNC_STORAGE_INVITES_KEY}:${id}`, "true").then(
      () =>
        setTimeout(
          () =>
            setEligibleHabits(eligible => {
              const ret = [...eligible];
              const thisItem = ret.find(h => h.routineId === id);
              if (thisItem) {
                thisItem.isShown = false;
              }

              return ret;
            }),
          300
        )
    );
  };

  return (
    <InviteLayout style={style} data={eligibleHabits} hideItem={hideItem} />
  );
};

export const RoutineInvites = memo(RoutineInvitesCarousel);
