import { MaterialIcons } from "@expo/vector-icons";
import React, { ReactElement } from "react";
import { ActivityIndicator, StyleSheet, View, Text } from "react-native";

import { COLORS } from "utils/appStyles";

const IconSuccess = () => (
  <MaterialIcons.Button
    name="check"
    style={styles.button}
    size={18}
    disabled
    backgroundColor="#CCCCCC"
  >
    Invited
  </MaterialIcons.Button>
);

const IconError = () => (
  <MaterialIcons.Button
    name="error-outline"
    style={styles.button}
    size={18}
    disabled
    backgroundColor={COLORS.error}
  >
    Error
  </MaterialIcons.Button>
);

const IconLoading = () => (
  <View style={[styles.button, styles.customButton]}>
    <ActivityIndicator color="white" />
    <Text style={{ color: "white", fontWeight: "600", marginLeft: 4 }}>
      Inviting...
    </Text>
  </View>
);

export const InviteButton = ({ icon, label, onPress }) => (
  <MaterialIcons.Button
    style={styles.button}
    size={18}
    name={icon}
    onPress={onPress}
  >
    {label}
  </MaterialIcons.Button>
);

export const AddFriendButton = ({
  fetchState,
  alreadyInvited,
  Button,
}: {
  fetchState;
  alreadyInvited: boolean;
  Button?: ReactElement;
}) => (
  <View style={styles.badgeContainer} collapsable={false}>
    {fetchState ? (
      fetchState.error ? (
        <IconError />
      ) : fetchState.loading ? (
        <IconLoading />
      ) : (
        <IconSuccess />
      )
    ) : alreadyInvited ? (
      <IconSuccess />
    ) : (
      Button
    )}
  </View>
);

const styles = StyleSheet.create({
  customButton: {
    flexDirection: "row",
    backgroundColor: "#CCCCCC",
    borderRadius: 4,
  },
  badgeContainer: { justifyContent: "center" },
  button: {
    alignSelf: "center",
    alignItems: "center",
    alignContent: "center",
    height: 30,
    paddingVertical: 4,
    paddingHorizontal: 10,
    marginBottom: 0,
  },
});
