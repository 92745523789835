import React from "react";
import { View, StyleSheet } from "react-native";
import { isHabitChallenge } from "types/habits";
import { SectionTitle } from "components";
import { LinkInvite } from "components/Challenges/LinkInvite";
import { useString } from "hooks";
const styles = StyleSheet.create({
  container: {
    margin: 20,
  },
});
export const InviteSection = ({ habit, habitid, friendid }) => {
  const s = useString("routine");

  if (!isHabitChallenge(habit)) {
    return null;
  }

  if (friendid && !habit.isLinkInviteEnabled) {
    return null;
  }

  return (
    <View style={styles.container}>
      <SectionTitle>{s("sectionInviteFriends")}</SectionTitle>
      <LinkInvite challengeHabitId={habitid} />
    </View>
  );
};
