import React from "react";
import { Text, View, StyleSheet, Linking } from "react-native";
import { KAvatar, KButton, Loading } from "components";
import { isMobilePlatform } from "utils/helpers";
import { COLORS } from "utils/appStyles";
import { Title, Subtitle, Footnote } from "components/UI/Typography";
import { FirebaseReducer } from "react-redux-firebase";
import { LogInView } from "components/Login/LoginView";
import { BRANCH_LINK } from "components/GetTheApp";
import { AboutTheAppSection } from "components/Invite/InvitesCommon";
import { JoinChallengeResponse } from "types/challenge";
import { pluralize } from "utils/strings";

const GetTheAppButton = () => (
  <KButton
    onPress={() => Linking.openURL(BRANCH_LINK)}
    label="Get SnapHabit"
    color={COLORS.logoBlue}
  />
);

export const JoinChallengeLayout = ({
  isLoading,
  error,
  auth,
  isSignedUp,
  challengeInfo,
  joinChallenge,
  userJoinedChallenge,
  resetPage,
}: {
  isLoading: boolean;
  error: Error;
  isSignedUp: boolean;
  auth: FirebaseReducer.AuthState;
  challengeInfo: JoinChallengeResponse;
  joinChallenge;
  userJoinedChallenge: boolean;
  resetPage;
}) => {
  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    let message = "";
    switch (error.message) {
      case "INVALID_HANDLE":
      case "NOT_FOUND":
        message = "No user found!";
        break;
      case "REQUEST_ERROR":
        message = "Something is broken... :(";
        break;
      default:
        message = error.message;
    }
    return (
      <View style={styles.container}>
        <Title style={styles.title}>Sorry, something went wrong 🙁</Title>
        <Subtitle>{message}</Subtitle>
        {error.message === "REQUEST_ERROR" && (
          <KButton
            onPress={() => {
              if (isMobilePlatform) {
                const Updates = require("expo-updates");
                Updates.reloadAsync();
              } else if (window) {
                window.location.reload();
              }
            }}
            label="Try again"
            color="green"
          />
        )}
        <AboutTheAppSection />
        <GetTheAppButton />
      </View>
    );
  }

  let CallToAction;
  if (userJoinedChallenge) {
    CallToAction = (
      <>
        <Text style={styles.text}>Habit Joined!</Text>
        <Text style={styles.text}>Download the app to start your journey.</Text>
        <GetTheAppButton />
      </>
    );
  } else if (isSignedUp) {
    CallToAction = (
      <>
        <KButton
          onPress={joinChallenge}
          label="Join Habit"
          color={COLORS.logoBlue}
        />
        <Footnote style={{ marginTop: 20, textAlign: "center" }}>
          You're signed in as {auth.displayName}
        </Footnote>
      </>
    );
  } else {
    CallToAction = (
      <LogInView
        hideMotivation
        style={{ width: "100%" }}
        webRedirectTo={window.location.href}
      />
    );
  }

  return (
    <View style={styles.container}>
      <View style={styles.cardHeader}>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <KAvatar
            source={challengeInfo.ownerPhotoUrl}
            size={40}
            style={styles.avatar}
          />
          <Text style={styles.name}>{challengeInfo.ownerName}</Text>
        </View>
        <Text style={styles.text}>wants you to join their group habit</Text>
        <Text style={styles.name}>{`${
          challengeInfo.emoji ? challengeInfo.emoji + " " : ""
        }${challengeInfo.title}`}</Text>
        <Text style={styles.text} numberOfLines={3}>
          {challengeInfo.description}
        </Text>
        <Text>
          {challengeInfo.numberParticipants} participant
          {pluralize(challengeInfo.numberParticipants)}
        </Text>
      </View>
      <View style={{ margin: 10, width: "100%" }}>{CallToAction}</View>
      <AboutTheAppSection />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    marginHorizontal: "auto",
    maxWidth: 400,
  },
  avatar: {
    marginBottom: 10,
    marginRight: 10,
  },
  signedUp: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  cardHeader: {
    justifyContent: "center",
    alignItems: "center",
    margin: 12,
  },
  name: {
    marginBottom: 5,
    fontSize: 30,
  },
  text: {
    marginBottom: 20,
    fontSize: 20,
    textAlign: "center",
  },
  error: {
    fontSize: 10,
    color: "red",
    width: "100%",
  },
  title: {
    textAlign: "center",
  },
  subtitle: {
    textAlign: "center",
    margin: 12,
  },
});
