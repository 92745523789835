import React, { useCallback, useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import DraggableFlatList from "react-native-draggable-flatlist";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";
import * as Haptics from "expo-haptics";

import { Body, MovableSetting } from "components";
import { useString } from "hooks";
import { useGetHabits } from "redux/selectors";
import { isMobilePlatform } from "utils/helpers";
import { renderHabitTitle } from "utils/strings";
import { logReorderHabits } from "utils/analytics";
import { useFirebase } from "react-redux-firebase";

export const HabitOrderingScreen = () => {
  const s = useString("profileScreen");
  const firebase = useFirebase();
  const habits = useGetHabits();
  const [orderedHabits, setOrderedHabits] = useState(habits);

  useEffect(() => {
    setOrderedHabits(habits);
  }, [habits]);

  const renderItem = useCallback(({ item, drag, isActive }) => {
    return (
      <View style={isActive ? styles.hover : {}}>
        <TouchableWithoutFeedback
          delayLongPress={200}
          onLongPress={() => {
            if (isMobilePlatform) {
              Haptics.selectionAsync();
            }
            drag();
          }}
        >
          <MovableSetting label={renderHabitTitle(item)} />
        </TouchableWithoutFeedback>
      </View>
    );
  }, []);

  return (
    <>
      <Body style={styles.text}>
        {isMobilePlatform ? s("dragToReorder") : s("orderYourHabitsFromApp")}
      </Body>
      {isMobilePlatform && (
        <DraggableFlatList
          data={orderedHabits}
          keyExtractor={item => item.id}
          renderItem={renderItem}
          onDragEnd={({ data }) => {
            if (data.length > 0) {
              setOrderedHabits(data);
              const newHabitOrdering = data.map(item => item.id);
              firebase.updateProfile({
                habitOrdering: newHabitOrdering,
              });
              logReorderHabits(data.length);
            }
          }}
        />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  hover: {
    opacity: 0.9,
    shadowRadius: 2,
    shadowColor: "#aaa",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 1,
  },
  text: {
    textAlign: "center",
    marginVertical: 30,
  },
});
