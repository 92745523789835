import React from "react";
import { View, StyleSheet, StyleProp, ViewStyle } from "react-native";

export const ContentCenteredView = ({
  children,
  style,
}: {
  children: React.ReactChild | React.ReactChild[];
  style?: StyleProp<ViewStyle>;
}) => {
  return <View style={[styles.container, style]}>{children}</View>;
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
  },
});
