import React from "react";
import { StyleSheet, TextInput } from "react-native";
import { useDimensionsContext } from "contexts";
import { DayStatus } from "types/habits";

export const TextSaver = ({
  isEditable,
  status,
  text,
  onChangeText,
  onFocus,
}: {
  isEditable: boolean;
  status: DayStatus;
  text: string;
  onFocus: () => void;
  onChangeText: (text: string) => void;
}) => {
  const { getScaledFontSize } = useDimensionsContext();

  return (
    <TextInput
      editable={isEditable}
      value={text}
      style={[styles.text, { fontSize: getScaledFontSize(1.2) }]}
      multiline
      autoCompleteType="off"
      onChangeText={onChangeText}
      placeholder={
        status === 2
          ? "What will you do next time to succeed?"
          : "Type something..."
      }
      onFocus={onFocus}
    />
  );
};

const styles = StyleSheet.create({
  text: {
    borderColor: "#eee",
    borderRadius: 10,
    borderWidth: 1,
    marginVertical: 20,
    minHeight: 100,
    paddingTop: 10,
    paddingHorizontal: 10,
  },
});
