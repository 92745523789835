import moment from "moment";
import Sentry from "./Sentry";
import { LOG_LEVEL, APP_OWNERSHIP } from "./constants";

const log = (...message) => {
  if (LOG_LEVEL === "DEBUG") {
    console.log(`[${moment().format("hh:mm:ss")}]`, ...message);
  }

  if (APP_OWNERSHIP === "standalone") {
    Sentry.addBreadcrumb({
      message: JSON.stringify(message),
      level: Sentry.Severity.Debug,
    });
  }
};

const warning = (...message) => {
  if (__DEV__) {
    console.warn(...message);
  }
  if (APP_OWNERSHIP === "standalone") {
    Sentry.addBreadcrumb({
      message: JSON.stringify(message),
      level: Sentry.Severity.Warning,
    });
  }
};

const error = (...error: Parameters<typeof Sentry.captureException>) => {
  if (__DEV__) {
    console.warn(error);
  }
  if (APP_OWNERSHIP === "standalone") {
    Sentry.captureException(...error);
  }
};

const setUser = user => {
  if (LOG_LEVEL === "DEBUG") {
    console.log("Logger set user called");
    console.log(user);
  }
  Sentry.setUser(
    user
      ? {
          id: user.uid,
          email: user.email,
        }
      : null
  );
};

export const Logger = { log, warning, error, setUser };
