import { useState, useEffect } from "react";
import { Keyboard, KeyboardEvent, Platform } from "react-native";

export const useKeyboardHeight = (): number => {
  const [keyboardHeight, setKeyboardHeight] = useState(0);

  useEffect(() => {
    function onKeyboardDidShow(e: KeyboardEvent): void {
      setKeyboardHeight(e.endCoordinates.height);
    }

    function onKeyboardDidHide(): void {
      setKeyboardHeight(0);
    }

    // Android doesn't have 'will', only 'did'
    const show =
      Platform.OS === "android" ? "keyboardDidShow" : "keyboardWillShow";
    const hide =
      Platform.OS === "android" ? "keyboardDidHide" : "keyboardWillHide";

    Keyboard.addListener(show, onKeyboardDidShow);
    Keyboard.addListener(hide, onKeyboardDidHide);
    return (): void => {
      Keyboard.removeListener(show, onKeyboardDidShow);
      Keyboard.removeListener(hide, onKeyboardDidHide);
    };
  }, []);

  return keyboardHeight;
};
