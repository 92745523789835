import React, { useState, useCallback, useEffect } from "react";
import { StyleSheet } from "react-native";
import AsyncStorage from "@react-native-community/async-storage";
import { Title, Paragraph } from "react-native-paper";
import { MaterialIcons } from "@expo/vector-icons";

import { KButton, KPrompt } from "components";
import { COLORS } from "utils/appStyles";
import { goToPermissionsSettings } from "utils/permissions";
import { ASYNC_STORAGE_GRANT_NOTIFICATION_CARD_KEY } from "utils/constants";
import { logTapGrantNotificationCard } from "utils/analytics";
import { useNotificationPermissions } from "hooks/notifications/useNotificationPermissions";
import { useString } from "hooks";

const NotificationsPrompt = ({ dismissCard }) => {
  const s = useString("permissions");
  return (
    <KPrompt
      body={
        <>
          <Title style={styles.title}>{s(`notificationPromoTitle`)}</Title>
          <Paragraph>{s(`notificationPromoSubtitle`)}</Paragraph>
        </>
      }
      actions={
        <>
          <KButton
            label={s("enable")}
            mode="text"
            color={COLORS.logoBlue}
            onPress={() => {
              logTapGrantNotificationCard("Turn On");
              goToPermissionsSettings();
            }}
          />
          <KButton
            label={s("dismiss")}
            mode="text"
            color={COLORS.darkGray}
            onPress={() => {
              logTapGrantNotificationCard("Dismiss");
              dismissCard();
            }}
          />
        </>
      }
      icon={
        <MaterialIcons
          name="notifications-off"
          size={35}
          color={COLORS.error}
          style={styles.icon}
        />
      }
    />
  );
};

export const GrantNotificationsCard = ({ scrollViewRef }) => {
  const [card, setCard] = useState(null);
  const [hasDismissed, setHasDismissed] = useState(false);
  const notificationsGranted = useNotificationPermissions();

  const dismissCard = useCallback(() => {
    setCard(null);
    scrollViewRef.current.scrollTo({ x: 0, y: 0, animated: true });
    AsyncStorage.setItem(
      `${ASYNC_STORAGE_GRANT_NOTIFICATION_CARD_KEY}`,
      "true"
    );
    setHasDismissed(true);
  }, []);

  useEffect(() => {
    if (!hasDismissed) {
      (async () => {
        AsyncStorage.getItem(
          `${ASYNC_STORAGE_GRANT_NOTIFICATION_CARD_KEY}`
        ).then(checkDismissed => {
          setHasDismissed(checkDismissed !== null);
        });
      })();
    }
  }, [hasDismissed]);

  useEffect(() => {
    if (!notificationsGranted && !hasDismissed) {
      setCard(<NotificationsPrompt dismissCard={dismissCard} />);
    } else {
      setCard(null);
    }
  }, [notificationsGranted, hasDismissed]);

  return card;
};

const styles = StyleSheet.create({
  title: {
    fontSize: 18,
    paddingTop: 4,
  },
  icon: {
    paddingLeft: 8,
    paddingRight: 8,
  },
});
