import React, { useRef, ReactNode } from "react";
import { StyleSheet, StyleProp, ViewStyle, SafeAreaView } from "react-native";
import { ScrollView } from "react-native-gesture-handler";

export const KScrollView = ({
  children,
  style,
  scrollViewStyle,
  contentContainerStyle,
  render,
}: {
  children?: React.ReactChild | React.ReactChild[];
  style?: StyleProp<ViewStyle>;
  scrollViewStyle?: StyleProp<ViewStyle>;
  contentContainerStyle?: StyleProp<ViewStyle>;
  render?: (ref: React.LegacyRef<ScrollView>) => ReactNode;
}) => {
  const ref = useRef();
  return (
    <SafeAreaView style={[styles.container, style]}>
      <ScrollView
        style={[styles.container, scrollViewStyle]}
        contentContainerStyle={contentContainerStyle}
        ref={ref}
        keyboardShouldPersistTaps="handled"
        keyboardDismissMode="on-drag"
      >
        {render ? render(ref.current) : children}
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
