import React, { useMemo } from "react";
import { useNavigation } from "@react-navigation/native";

import { useGetAuth, useGetFriends } from "redux/selectors";
import { KChatChannelList } from "components/Chat/KChatChannelList";
import { useLayoutContext, Layouts } from "contexts";
import { ChannelList } from "utils/stream";
import { useHistory } from "utils/react-router";
import { FriendStatus } from "types/friends";

const sort = { last_message_at: -1 };

export const ChatListCommon = () => {
  const { auth } = useGetAuth();
  const { isFriendsLoaded, friends } = useGetFriends();
  const layout = useLayoutContext();
  const navigation = useNavigation();
  const history = useHistory();
  const query = useMemo(() => {
    const friendIds = friends
      .filter(
        f =>
          f.status === FriendStatus.Accepted ||
          f.status === FriendStatus.AcceptedRequestor
      )
      .map(f => f.id);
    const q = [{ members: { $in: [auth.uid] } }];
    if (friendIds.length > 0) {
      q.push({ members: { $in: friendIds } });
    }
    return q;
  }, [auth.uid, friends]);

  if (!isFriendsLoaded) {
    return null;
  }

  return (
    <ChannelList
      filters={{
        type: "messaging",
        $and: query,
      }}
      sort={sort}
      List={({ setActiveChannel, ...rest }) => {
        //@ts-ignore
        return (
          <KChatChannelList
            {...rest}
            setActiveChannel={channel => {
              const members = channel.state
                ? Object.values(channel.state.members)
                : [];
              const friend = members.find(m => m.user.id !== auth.uid);
              if (layout === Layouts.MOBILE) {
                navigation.navigate("ChatScreen", {
                  friendid: friend.user.id,
                  title: friend.user.name,
                });
              } else {
                if (friend) {
                  history.push(`/friend/${friend.user.id}`);
                }
              }
            }}
          />
        );
      }}
    />
  );
};
