import React from "react";
import { StyleSheet } from "react-native";
import { COLORS } from "utils/appStyles";
import { ViewWithDimensionsContext } from "contexts";

const styles = StyleSheet.create({
  container: {
    flexBasis: "25%",
    minWidth: 350,
    borderLeftWidth: 1,
    borderColor: COLORS.disabled,
  },
});
export const PanelRight = ({ children }) => {
  return (
    <ViewWithDimensionsContext style={styles.container}>
      {children}
    </ViewWithDimensionsContext>
  );
};
