import React from "react";
import { ActivityIndicator } from "react-native";

import { ContentCenteredView } from "components/UI/ContentCenteredView";

export const Loading = () => (
  <ContentCenteredView>
    <ActivityIndicator />
  </ContentCenteredView>
);
