// Library import
import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { StyleSheet, View } from "react-native";
import ReanimatedBottomSheet from "reanimated-bottom-sheet";
import Animated from "react-native-reanimated";

// Redux import
import { useSelector } from "redux/store";
import { useDispatch } from "react-redux";
import { removeBottomSheet, BottomSheetTypes } from "redux/slices";

// Components import
import { BottomSheetNoteEditor } from "components/Notes/BottomSheetNoteEditor";

export enum BOTTOM_SHEET_POSITION {
  FULL_OPEN = 0,
  PART_OPEN,
  CLOSED,
}

export type BottomSheetManipulator = {
  openFull: () => void;
  openPartial: () => void;
  close: () => void;
};

export const BottomSheet = () => {
  const dispatch = useDispatch();
  const data = useSelector(state => state.bottomSheet);
  const { type, props } = data || {};
  const bottomSheetRef = useRef(null);
  const fall = new Animated.Value(2);
  const [open, setOpen] = useState(false);

  const renderHeader = useCallback(() => <View style={styles.header} />, []);

  const setBottomSheetPosition = useMemo(
    () => ({
      openFull: () =>
        bottomSheetRef.current.snapTo(BOTTOM_SHEET_POSITION.FULL_OPEN),
      openPartial: () =>
        bottomSheetRef.current.snapTo(BOTTOM_SHEET_POSITION.PART_OPEN),
      close: () => bottomSheetRef.current.snapTo(BOTTOM_SHEET_POSITION.CLOSED),
    }),
    []
  );

  const renderContent = () => {
    if (!data) {
      return null;
    }

    if (type === BottomSheetTypes.NOTE) {
      return (
        <BottomSheetNoteEditor
          {...props}
          bottomSheetRef={bottomSheetRef}
          setBottomSheetPosition={setBottomSheetPosition}
        />
      );
    }

    return null;
  };

  const renderShadow = () => {
    const animatedShadowOpacity = Animated.interpolate(fall, {
      inputRange: [0, 1],
      outputRange: [0.5, 0],
    });

    return (
      <Animated.View
        pointerEvents={open ? "auto" : "none"}
        style={[
          styles.shadowContainer,
          {
            opacity: animatedShadowOpacity,
          },
        ]}
      />
    );
  };

  useEffect(() => {
    bottomSheetRef.current.snapTo(data && data.type ? 1 : 2);
  }, [data]);

  const onOpenStart = useCallback(() => {
    setOpen(true);
  }, []);

  const onCloseEnd = useCallback(() => {
    setOpen(false);
    dispatch(removeBottomSheet());
  }, [dispatch]);

  return (
    <>
      <ReanimatedBottomSheet
        enabledContentTapInteraction={false}
        ref={bottomSheetRef}
        callbackNode={fall}
        snapPoints={["100%", "85%", 0]}
        initialSnap={2}
        onOpenStart={onOpenStart}
        onCloseEnd={onCloseEnd}
        renderHeader={renderHeader}
        renderContent={renderContent}
      />
      {renderShadow()}
    </>
  );
};

const styles = StyleSheet.create({
  // Header
  header: {
    backgroundColor: "white",
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    paddingBottom: 15,
    paddingHorizontal: 24,
    paddingTop: 25,
  },
  // Shadow
  shadowContainer: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: "#000",
  },
});
