import React, { useEffect, useState } from "react";
import { Habit, isAChallenge, HabitRoutine, DayStatus } from "types/habits";
import { GenericHabitTitleRow } from "components/HabitGrid/GenericHabitTitleRow";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { StackProps } from "layouts/MobileLayout";
import { RoutineIcon } from "icons/RoutineIcon";
import { useGetDays } from "redux/selectors";
import { useTodaysRoutineContentIndex } from "hooks/routines/useTodaysRoutineContentIndex";
import { GroupRoutineIcon } from "icons/GroupRoutineIcon";

const useRoutineHasContentToDo = (habitid: string, habit: HabitRoutine) => {
  const days = useGetDays({ ownHabit: true, habitid });
  const { isLoaded, maxIndex, routineDays } = useTodaysRoutineContentIndex(
    habit
  );
  const [hasContent, setHasContent] = useState(false);

  useEffect(() => {
    if (!isLoaded || maxIndex === null || !routineDays) {
      return;
    }

    let hasStuffToDo = false;

    for (let i = 0; i <= maxIndex; i++) {
      if (
        days?.[routineDays[i].moment.format("MM-DD-YYYY")]?.status !==
        DayStatus.SUCCESS
      ) {
        hasStuffToDo = true;
        break;
      }
    }

    setHasContent(hasStuffToDo);
  }, [days, habit.startTime, isLoaded, maxIndex, routineDays]);

  return hasContent;
};

const RoutineBadge = ({ isChallenge }) => {
  return isChallenge ? (
    <GroupRoutineIcon height={16} width={16} />
  ) : (
    <RoutineIcon height={16} width={16} />
  );
};

const OwnRoutineHabitTitleRow = ({
  isChallenge,
  onPress,
  title,
  habitid,
  habit,
}) => {
  const routineHasContentToDo = useRoutineHasContentToDo(habitid, habit);

  return (
    <GenericHabitTitleRow
      key={habitid}
      title={title}
      onPress={onPress}
      Icon={<RoutineBadge isChallenge={isChallenge} />}
      showBadge={routineHasContentToDo}
    />
  );
};

const FriendRoutineHabitTitleRow = ({
  isChallenge,
  onPress,
  title,
  habitid,
}) => {
  return (
    <GenericHabitTitleRow
      key={habitid}
      title={title}
      onPress={onPress}
      Icon={<RoutineBadge isChallenge={isChallenge} />}
    />
  );
};

export const RoutineHabitTitleRow = ({
  title,
  habitid,
  habit,
  ownHabit,
  friendid,
}: {
  title: string;
  habitid: string;
  habit: Habit;
  ownHabit: boolean;
  friendid: string;
}) => {
  const navigation = useNavigation<StackNavigationProp<StackProps>>();

  const onPress = () =>
    navigation.navigate("HabitRoutineScreen", {
      habitid: habit.id,
      title: habit?.title,
      friendid,
    });

  const isChallenge = isAChallenge(habit);

  const props = {
    title,
    habitid,
    isChallenge,
    onPress,
  };

  if (ownHabit) {
    return <OwnRoutineHabitTitleRow {...props} habit={habit} />;
  }

  return <FriendRoutineHabitTitleRow {...props} />;
};
