import React, { useState, useEffect } from "react";
import { View, SafeAreaView, FlatList, StyleSheet } from "react-native";

import { useGetRoutines } from "redux/selectors";
import { RoutineTag, Routine } from "types/routines";
import { ROUTINE_TAGS_ALL_ARRAY, RTag } from "components/Routines/RoutineTags";
import { SectionSubtitle } from "components/UI/Typography";
import { RoutineInviteCard } from "./RoutineInviteCard";

const styles = StyleSheet.create({
  container: { flex: 1 },
  routineListContainer: {
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 20,
  },
  routineCardContainer: {
    paddingVertical: 10,
  },
  tagsContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    marginTop: 20,
    marginHorizontal: 20,
  },
  tag: {
    margin: 4,
  },
});

const sortTags = (a, b) => a.localeCompare(b);

//only show tags that have live routines
const getEligibleTags = routines => {
  const allTags = routines.map(routine => routine.tags).flat();
  return ROUTINE_TAGS_ALL_ARRAY.filter(tag => allTags.includes(tag));
};

const TagFilter = ({
  selectedTags,
  setSelectedTags,
  eligibleTags,
}: {
  selectedTags: RoutineTag[];
  setSelectedTags: React.Dispatch<React.SetStateAction<RoutineTag[]>>;
  eligibleTags: RoutineTag[];
}) => {
  const unique = new Set(selectedTags); // Tag can be pressed multiple times which adds to array
  const sortedSelectedTags = Array.from(unique).sort(sortTags);

  const remainingTags = eligibleTags
    .filter(t => !sortedSelectedTags.includes(t))
    .sort(sortTags);

  return (
    <View style={styles.tagsContainer}>
      <SectionSubtitle>Filter: </SectionSubtitle>
      {[...sortedSelectedTags, ...remainingTags].map(t => {
        const selected = selectedTags?.includes(t);
        return (
          <RTag
            key={t}
            tag={t}
            selected={selected}
            onPress={() => {
              setSelectedTags(oldTags => {
                return [...oldTags, t];
              });
            }}
            onClose={
              selected // close button shows if this function is set
                ? () => {
                    setSelectedTags(oldTags => {
                      return oldTags.filter(ot => ot !== t);
                    });
                  }
                : null
            }
            style={styles.tag}
          />
        );
      })}
    </View>
  );
};

export const RoutineListView = () => {
  const routines = useGetRoutines();

  const eligibleTags = getEligibleTags(routines);
  const [selectedTags, setSelectedTags] = useState<RoutineTag[]>([]);
  const [displayedRoutines, setDisplayedRoutines] = useState<Routine[]>([]);

  const renderItem = ({ item }) => {
    return (
      <View style={styles.routineCardContainer}>
        <RoutineInviteCard
          id={item.id}
          hideItem={() => null}
          isShown
          showDismissButton={false}
        />
      </View>
    );
  };

  useEffect(
    function filterRoutines() {
      if (selectedTags.length > 0) {
        setDisplayedRoutines(
          routines.filter(r => selectedTags.some(t => r?.tags?.includes(t)))
        );
      } else {
        setDisplayedRoutines(routines);
      }
    },
    [selectedTags, routines]
  );

  return (
    <SafeAreaView style={styles.container}>
      <FlatList
        data={displayedRoutines}
        renderItem={renderItem}
        contentContainerStyle={styles.routineListContainer}
        ListHeaderComponent={
          <TagFilter
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
            eligibleTags={eligibleTags}
          />
        }
      />
    </SafeAreaView>
  );
};
