// https://docs.expo.io/guides/notification-channels/
export enum AndroidChannel {
  CHAT_MESSAGE = "CHAT_MESSAGE",
  REMINDER = "REMINDER",
  HABIT_UPDATE = "HABIT_UPDATE",
  FRIEND_INVITE = "FRIEND_INVITE",
  CHALLENGE_INVITE = "CHALLENGE_INVITE",
  MOTIVATION = "MOTIVATION",
  NOTE_UPDATE = "NOTE_UPDATE",
}
