import React from "react";
import { SvgXml, XmlProps } from "react-native-svg";
import { Platform, View, ViewStyle } from "react-native";

const xml = `
<svg width="23.36" height="16.694" viewBox="0 0 23.36 16.694">
      <g transform="translate(-205 -173.707)">
        <g transform="translate(145.013 175.514)">
          <path
            fill="#b9dcff"
            d="M90.7,20.925v1.218a.248.248,0,0,1-.248.248H81.4a.248.248,0,0,1-.248-.248V20.925a2.314,2.314,0,0,1,1.357-2.107,3.627,3.627,0,0,1-1.187-1.361,3.279,3.279,0,0,1,.225-3.243,3.19,3.19,0,0,0,.535-1.769V11.1a3.1,3.1,0,0,1,3.1-3.1h1.489a3.1,3.1,0,0,1,3.1,3.1,7.169,7.169,0,0,0,.137,2.268c.242.8.562.9.808,1.714a3.281,3.281,0,0,1-1.1,3.517l-.275.219A2.314,2.314,0,0,1,90.7,20.925Z"
            transform="translate(-7.761 -7.752)"
          />
          <path
            fill="#b9dcff"
            d="M95.554,348.888v1.218a.248.248,0,0,1-.248.248H86.249a.248.248,0,0,1-.248-.248v-1.218a2.313,2.313,0,0,1,2.313-2.313,1.284,1.284,0,0,0,.533-.115l.008.017,1.771,1.371a.248.248,0,0,0,.3,0l1.771-1.371.008-.017a1.284,1.284,0,0,0,.533.115A2.313,2.313,0,0,1,95.554,348.888Z"
            transform="translate(-12.61 -335.714)"
          />
          <path
            fill="#d1e7ff"
            d="M146.907,120.191a.248.248,0,0,1,.235.248v1.485a3.071,3.071,0,1,1-6.141,0V120.46a.248.248,0,0,1,.269-.247,6.419,6.419,0,0,0,4.185-1.07.247.247,0,0,1,.377.138A1.158,1.158,0,0,0,146.907,120.191Z"
            transform="translate(-65.904 -115.406)"
          />
          <path
            fill="#b9dcff"
            d="M89.631,18.6l-.275.219a2.307,2.307,0,0,0-.956-.206,1.283,1.283,0,0,1-1.285-1.282v-.212l0-.011a3.071,3.071,0,0,0,1.9-2.838V12.784a.248.248,0,0,0-.235-.248,1.157,1.157,0,0,1-1.075-.909.247.247,0,0,0-.377-.138,6.42,6.42,0,0,1-4.185,1.07.248.248,0,0,0-.269.247v1.464a3.071,3.071,0,0,0,1.9,2.838l0,.011v.212a1.283,1.283,0,0,1-1.285,1.282,2.307,2.307,0,0,0-.956.206l-.275-.219a3.281,3.281,0,0,1-.687-4.385,3.19,3.19,0,0,0,.535-1.769V11.1a3.1,3.1,0,0,1,3.1-3.1h1.489a3.1,3.1,0,0,1,3.1,3.1v1.344a3.192,3.192,0,0,0,.535,1.769A3.281,3.281,0,0,1,89.631,18.6Z"
            transform="translate(-7.77 -7.752)"
          />
          <path
            fill="#a2d0ff"
            d="M203.3,302.151a3.079,3.079,0,0,1-2.427,0,1.922,1.922,0,0,0,.035-.509l0-.011a3.077,3.077,0,0,0,2.348,0l0,.011A1.919,1.919,0,0,0,203.3,302.151Z"
            transform="translate(-123.918 -292.275)"
          />
          <path
            fill="#d1e7ff"
            d="M88.078,8.334a.323.323,0,0,1-.174.611q-.146-.014-.3-.014H86.119a3.1,3.1,0,0,0-3.1,3.1v.377c0,.223-.155.216-.155.4a9.741,9.741,0,0,1-.017,1.605c-.243.716-.536.819-.773,1.6a3.279,3.279,0,0,0-.123,1.292.324.324,0,0,1-.623.152,3.28,3.28,0,0,1,.225-3.243,3.19,3.19,0,0,0,.535-1.769V11.1a3.1,3.1,0,0,1,3.1-3.1h1.489a3.088,3.088,0,0,1,1.4.334Z"
            transform="translate(-7.765 -7.752)"
          />
          <path
            fill="#e8f3ff"
            d="M143.484,158.705a.474.474,0,0,1-.416.843,3.051,3.051,0,0,1-2.066-2.9v-1.464a.248.248,0,0,1,.27-.247c.119.011.249.019.39.024a.473.473,0,0,1,.457.473v.718A3.06,3.06,0,0,0,143.484,158.705Z"
            transform="translate(-65.905 -150.131)"
          />
          <path
            fill="#d1e7ff"
            d="M89.367,346.873a.2.2,0,0,1-.117.354,2.312,2.312,0,0,0-2.318,2.313v.814H86.25a.248.248,0,0,1-.248-.248v-1.218a2.313,2.313,0,0,1,2.313-2.313,1.284,1.284,0,0,0,.533-.115l.008.017Z"
            transform="translate(-12.611 -335.714)"
          />
          <path
            fill="#a2d0ff"
            d="M283.1,346.873a.2.2,0,0,0,.117.354,2.312,2.312,0,0,1,2.318,2.313v.814h.682a.248.248,0,0,0,.248-.248v-1.218a2.313,2.313,0,0,0-2.313-2.313,1.284,1.284,0,0,1-.533-.115l-.008.017Z"
            transform="translate(-203.526 -335.714)"
          />
          <path
            fill="#000000"
            d="M83.192,13.174v1.466a.248.248,0,0,1-.5,0V13.174a2.064,2.064,0,0,0-2.065-2.065,1.523,1.523,0,0,1-1.311-.736.248.248,0,0,1,.424-.258,1.031,1.031,0,0,0,.887.5A2.56,2.56,0,0,1,83.192,13.174Zm-4.14-1.921-.885.685-1.5-1.165A1.52,1.52,0,0,0,77.231,9.7a3.319,3.319,0,0,0,4.254-3.184c0-.019,0-1.737,0-1.756a.248.248,0,0,0-.247-.232,1.021,1.021,0,0,1-.647-.12,1.129,1.129,0,0,1-.469-.9.248.248,0,0,0-.391-.2,8,8,0,0,1-3.518,1.4.248.248,0,0,0,.034.494A8.164,8.164,0,0,0,78,4.761a8.507,8.507,0,0,0,1.683-.832,1.4,1.4,0,0,0,1.3,1.1V6.517a2.822,2.822,0,1,1-5.645,0V4.776a.248.248,0,0,0-.5,0V6.517a3.323,3.323,0,0,0,1.892,3v.065A1.034,1.034,0,0,1,75.7,10.613a2.56,2.56,0,0,0-.916.169l-.16-.128A3.031,3.031,0,0,1,73.993,6.6a3.428,3.428,0,0,0,.577-1.906V3.35A2.857,2.857,0,0,1,77.423.5h1.489A2.857,2.857,0,0,1,81.765,3.35V4.694a3.41,3.41,0,0,0,.574,1.9A3.031,3.031,0,0,1,82.315,10a.248.248,0,1,0,.409.282,3.527,3.527,0,0,0,.028-3.964,2.916,2.916,0,0,1-.49-1.627V3.35A3.353,3.353,0,0,0,78.911,0H77.423a3.354,3.354,0,0,0-3.35,3.35V4.694a2.933,2.933,0,0,1-.494,1.631,3.526,3.526,0,0,0,.723,4.7,2.554,2.554,0,0,0-1.16,2.144v1.466a.248.248,0,0,0,.5,0V13.174A2.064,2.064,0,0,1,75.7,11.109a1.538,1.538,0,0,0,.483-.077l1.829,1.416a.248.248,0,0,0,.3,0l1.037-.8a.248.248,0,1,0-.3-.392Z"
            transform="translate(0 0)"
          />
        </g>
        <g transform="translate(127 175.514)">
          <path
            fill="#a2d0ff"
            d="M95.553,20.918v1.224a.248.248,0,0,1-.248.248H86.248A.248.248,0,0,1,86,22.143V20.918a2.324,2.324,0,0,1,2.326-2.306,1.284,1.284,0,0,0,1.035-.539l.02.007-.014-.016,0,.007a1.439,1.439,0,0,0,.236-.952l0-.011a3.071,3.071,0,0,1-1.9-2.838v-.1a.765.765,0,0,1-.775-.761v-1.55a3.861,3.861,0,1,1,7.723,0v1.5a.806.806,0,0,1-.806.806v.1a3.071,3.071,0,0,1-1.9,2.838l0,.011a1.439,1.439,0,0,0,.236.952l0-.007-.014.016.02-.007a1.284,1.284,0,0,0,1.035.539,2.324,2.324,0,0,1,2.326,2.306Z"
            transform="translate(-7.752 -7.752)"
          />
          <path
            fill="#b9dcff"
            d="M123.723,11.861v1.5a.806.806,0,0,1-.806.806V12.256a1.489,1.489,0,0,0-1.489-1.489h-3.164a1.489,1.489,0,0,0-1.489,1.489v1.916a.765.765,0,0,1-.775-.761v-1.55a3.861,3.861,0,1,1,7.723,0Z"
            transform="translate(-36.821 -7.752)"
          />
          <path
            fill="#b9dcff"
            d="M147.141,100.732a3.071,3.071,0,1,1-6.141,0V98.719a1.489,1.489,0,0,1,1.489-1.489h3.164a1.489,1.489,0,0,1,1.489,1.489Z"
            transform="translate(-61.046 -94.214)"
          />
          <path
            fill="#d1e7ff"
            d="M93.227,333.048a1.285,1.285,0,0,1-1.041-.548l-1.225,1.362a.248.248,0,0,1-.369,0L89.367,332.5a1.284,1.284,0,0,1-1.041.548A2.324,2.324,0,0,0,86,335.354v1.224a.248.248,0,0,0,.248.248H95.3a.248.248,0,0,0,.248-.248v-1.224A2.324,2.324,0,0,0,93.227,333.048Z"
            transform="translate(-7.752 -322.187)"
          />
          <g transform="translate(81.609 10.313)">
            <path
              fill="#c79a83"
              d="M194.37,332.516l-.02-.007v0l0-.007Z"
              transform="translate(-194.35 -332.5)"
            />
            <path
              fill="#c79a83"
              d="M285.02,332.509l-.02.007.014-.016,0,.007Z"
              transform="translate(-282.188 -332.5)"
            />
            <path
              fill="#c79a83"
              d="M194.356,332.5l0,.007v0l.02.007Zm2.824.007,0-.007-.014.016.02-.007Z"
              transform="translate(-194.35 -332.5)"
            />
          </g>
          <path
            fill="#e8f3ff"
            d="M256.559,332.943c0,.008-.31,1.4-.308,1.392a.228.228,0,0,1-.4.1,10.067,10.067,0,0,0-.786-.89l.942-1.047A1.289,1.289,0,0,0,256.559,332.943Z"
            transform="translate(-171.578 -322.186)"
          />
          <path
            fill="#e8f3ff"
            d="M177.467,332.5l.92,1.022-.763.9a.228.228,0,0,1-.4-.1c0-.009-.3-1.376-.306-1.384A1.289,1.289,0,0,0,177.467,332.5Z"
            transform="translate(-95.852 -322.187)"
          />
          <path
            fill="#b9dcff"
            d="M138.127,347.106v3.587H131.39v-3.586c.723-.308.935-.092,1.413-.3l.3,1.354a.248.248,0,0,0,.43.109l.753-.867.289.322a.248.248,0,0,0,.369,0l.289-.322.753.867a.248.248,0,0,0,.43-.109l.3-1.354C137.195,347.016,137.405,346.8,138.127,347.106Z"
            transform="translate(-51.734 -336.054)"
          />
          <path
            fill="#e8f3ff"
            d="M88.981,347.556a2.313,2.313,0,0,0-2.206,2.31v.845h-.527a.248.248,0,0,1-.248-.248v-1.223a2.326,2.326,0,0,1,2.326-2.308,1.286,1.286,0,0,0,.494-.1C88.824,346.838,88.979,347.547,88.981,347.556Z"
            transform="translate(-7.752 -336.073)"
          />
          <path
            fill="#e8f3ff"
            d="M177.487,333.8l.137.624a.228.228,0,0,1-.4-.1c-.119-.542-.3-1.379-.306-1.384a1.289,1.289,0,0,0,.547-.444l.265.295A1.2,1.2,0,0,0,177.487,333.8Z"
            transform="translate(-95.853 -322.187)"
          />
          <path
            fill="#d1e7ff"
            d="M132.963,347.556a2.306,2.306,0,0,0-1.573.721v-1.152c.69-.3.954-.1,1.412-.3C132.805,346.837,132.963,347.554,132.963,347.556Z"
            transform="translate(-51.734 -336.073)"
          />
          <path
            fill="#b9dcff"
            d="M297.88,347.556a2.313,2.313,0,0,1,2.206,2.31v.845h.527a.248.248,0,0,0,.248-.248v-1.223a2.326,2.326,0,0,0-2.326-2.308,1.285,1.285,0,0,1-.494-.1C298.037,346.838,297.882,347.547,297.88,347.556Z"
            transform="translate(-213.06 -336.073)"
          />
          <path
            fill="#a2d0ff"
            d="M297.88,347.556a2.306,2.306,0,0,1,1.573.721v-1.152c-.69-.3-.954-.1-1.412-.3C298.038,346.837,297.88,347.554,297.88,347.556Z"
            transform="translate(-213.06 -336.073)"
          />
          <path
            fill="#a2d0ff"
            d="M202.233,223.929a.239.239,0,0,1,.08.412.9.9,0,0,1-1.035.073.243.243,0,0,0-.242,0,.9.9,0,0,1-1.035-.073.239.239,0,0,1,.08-.412c.3-.1.35-.316.582-.377a.575.575,0,0,1,.395.052.241.241,0,0,0,.2,0,.575.575,0,0,1,.395-.052C201.883,223.613,201.935,223.827,202.233,223.929Z"
            transform="translate(-118.133 -216.604)"
          />
          <path
            fill="#8bc4ff"
            d="M203.3,302.151a3.079,3.079,0,0,1-2.427,0,1.922,1.922,0,0,0,.035-.509l0-.011a3.077,3.077,0,0,0,2.348,0l0,.011A1.922,1.922,0,0,0,203.3,302.151Z"
            transform="translate(-119.059 -292.275)"
          />
          <path
            fill="#d1e7ff"
            d="M142.807,117.365a.545.545,0,0,1-.732.805A3.008,3.008,0,0,1,141,115.837v-2.013a1.5,1.5,0,0,1,.263-.847.367.367,0,0,1,.668.217c0,.008,0,2.015,0,2.022A3.039,3.039,0,0,0,142.807,117.365Z"
            transform="translate(-61.046 -109.32)"
          />
          <path
            fill="#d1e7ff"
            d="M120.884,8.835a3.86,3.86,0,0,0-3.536,2.109.387.387,0,0,1-.084.112,1.484,1.484,0,0,0-.49,1.1v1.164a.388.388,0,0,1-.774.029c0-.03,0-1.58,0-1.55A3.875,3.875,0,0,1,121,8.077a.388.388,0,0,1-.12.759Z"
            transform="translate(-36.819 -7.656)"
          />
          <path fill="#000000" d="M85.488,10.613a1.031,1.031,0,0,1-.887-.5.248.248,0,1,0-.424.258,1.531,1.531,0,0,0,.519.517l-.257,1.167-.62-.714a.248.248,0,1,0-.362-.34l-.436.5-1.1-1.221a1.516,1.516,0,0,0,.166-.582,3.322,3.322,0,0,0,4.254-3.158c0-.005,0-2.034,0-2.039a1.739,1.739,0,0,0-1.737-1.737H81.443A1.739,1.739,0,0,0,79.706,4.5V6.113a.512.512,0,0,1-.279-.454V4.109a3.613,3.613,0,1,1,7.227,0v1.55a.248.248,0,0,0,.5,0V4.109a4.11,4.11,0,0,0-8.219,0v1.55a1.013,1.013,0,0,0,.778.981A3.324,3.324,0,0,0,81.6,9.513v.065a1.035,1.035,0,0,1-1.037,1.034A2.56,2.56,0,0,0,78,13.173v1.466a.248.248,0,0,0,.5,0V13.173a2.059,2.059,0,0,1,.911-1.713v3.179a.248.248,0,0,0,.5,0V11.216a4.858,4.858,0,0,1,.981-.141l.346,1.571a.248.248,0,0,0,.43.109l.893-1.028a3.591,3.591,0,0,0,.323.352.247.247,0,0,0,.178.046c.12-.015.142-.066.435-.4l.9,1.034a.248.248,0,0,0,.43-.109l.346-1.571a1.558,1.558,0,0,0,.324.034,2.064,2.064,0,0,1,2.065,2.065v1.466a.248.248,0,0,0,.5,0V13.173a2.56,2.56,0,0,0-2.561-2.561ZM80.2,6.517V4.5a1.242,1.242,0,0,1,1.241-1.241h3.164A1.242,1.242,0,0,1,85.847,4.5V6.517a2.822,2.822,0,1,1-5.645,0Zm1.408,5.54-.257-1.167a1.539,1.539,0,0,0,.265-.2l.6.668Zm5.031-.038v2.62a.248.248,0,1,1-.5,0v-2.62a.248.248,0,1,1,.5,0ZM83.725,8.184a1.3,1.3,0,0,0,.983-.715.248.248,0,0,0-.251-.351c-.476.05-.478-.305-.874-.41a2.418,2.418,0,0,1-1.116,0c-.4.1-.4.46-.874.41a.248.248,0,0,0-.251.351,1.3,1.3,0,0,0,.983.715,1.186,1.186,0,0,0,.7-.157A1.183,1.183,0,0,0,83.725,8.184ZM82.04,7.556c.494-.176.42-.544.86-.289a.248.248,0,0,0,.248,0c.44-.254.365.112.86.289a.661.661,0,0,1-.83-.022.248.248,0,0,0-.308,0A.66.66,0,0,1,82.04,7.556Z" />
        </g>
        <g transform="translate(132.967 173.707)">
          <path
            fill="#d1e7ff"
            d="M96.743,22.5v1.365a.278.278,0,0,1-.278.278H86.31a.278.278,0,0,1-.278-.278V22.5a2.594,2.594,0,0,1,2.594-2.593,1.44,1.44,0,0,0,1.209-.656,1.635,1.635,0,0,0,.231-1.019l.005-.013a3.443,3.443,0,0,1-2.127-3.182v-.109a.858.858,0,0,1-.869-.853V12.63a4.813,4.813,0,0,1,1.294-3.513c1.49-1.437,3.852-1.319,5.563-.55a.07.07,0,0,1,0,.128l-.446.184a.07.07,0,0,0,.019.134l.217.023a3.51,3.51,0,0,1,2.6,1.612.075.075,0,0,1-.009.086,2.1,2.1,0,0,0-.574,1.445v1.9a.854.854,0,0,1-.853.853H94.83v.109A3.443,3.443,0,0,1,92.7,18.22l.005.013a1.634,1.634,0,0,0,.231,1.019,1.44,1.44,0,0,0,1.209.656A2.593,2.593,0,0,1,96.743,22.5Z"
            transform="translate(-7.721 -7.728)"
          />
          <path
            fill="#b9dcff"
            d="M96.743,334.622v1.365a.278.278,0,0,1-.278.278H86.31a.278.278,0,0,1-.278-.278v-1.365a2.594,2.594,0,0,1,2.594-2.593,1.44,1.44,0,0,0,1.209-.656,2.387,2.387,0,0,0,3.105,0,1.44,1.44,0,0,0,1.209.656A2.594,2.594,0,0,1,96.743,334.622Z"
            transform="translate(-7.721 -319.849)"
          />
          <path
            fill="#b9dcff"
            d="M125.234,10.648a.074.074,0,0,1-.009.086,2.105,2.105,0,0,0-.574,1.445v1.9a.855.855,0,0,1-.9.853V12.782a1.669,1.669,0,0,0-1.669-1.669H118.53a1.669,1.669,0,0,0-1.669,1.669V14.93a.858.858,0,0,1-.869-.853V12.631a4.814,4.814,0,0,1,1.294-3.513c1.49-1.437,3.853-1.319,5.563-.55a.07.07,0,0,1,0,.128l-.446.184a.07.07,0,0,0,.019.133l.217.023A3.509,3.509,0,0,1,125.234,10.648Z"
            transform="translate(-36.637 -7.729)"
          />
          <path
            fill="#d1e7ff"
            d="M147.917,101.219a3.444,3.444,0,1,1-6.885-.109V98.962a1.669,1.669,0,0,1,1.669-1.669h3.547a1.669,1.669,0,0,1,1.669,1.669Z"
            transform="translate(-60.808 -93.91)"
          />
          <path
            fill="#e8f3ff"
            d="M143.7,118.551a.55.55,0,0,1-.5.974,3.443,3.443,0,0,1-2.163-3.2v-2.257a1.662,1.662,0,0,1,.293-.945.411.411,0,0,1,.75.249v2.257a3.418,3.418,0,0,0,1.62,2.919Z"
            transform="translate(-60.808 -109.018)"
          />
          <path
            fill="#b9dcff"
            d="M203.623,302.277a3.452,3.452,0,0,1-2.721,0,2.155,2.155,0,0,0,.039-.571l.005-.013a3.45,3.45,0,0,0,2.632,0l.005.013A2.155,2.155,0,0,0,203.623,302.277Z"
            transform="translate(-118.596 -291.201)"
          />
          <path
            fill="#d1e7ff"
            d="M90.026,332.869a1.414,1.414,0,0,1-.414.064A2.6,2.6,0,0,0,87,335.517v.261a.487.487,0,0,1-.487.487h-.209a.278.278,0,0,1-.278-.278v-1.365a2.594,2.594,0,0,1,2.594-2.593,1.44,1.44,0,0,0,1.209-.656,2.408,2.408,0,0,0,.331.237A.7.7,0,0,1,90.026,332.869Z"
            transform="translate(-7.72 -319.849)"
          />
          <path
            fill="#a2d0ff"
            d="M265.844,332.869a1.414,1.414,0,0,0,.414.064,2.6,2.6,0,0,1,2.607,2.584v.261a.487.487,0,0,0,.487.487h.209a.278.278,0,0,0,.278-.278v-1.365a2.594,2.594,0,0,0-2.594-2.593,1.44,1.44,0,0,1-1.209-.656,2.408,2.408,0,0,1-.331.237A.7.7,0,0,0,265.844,332.869Z"
            transform="translate(-180.817 -319.849)"
          />
          <path
            fill="#d1e7ff"
            d="M122.847,8.695c-.409.168-.467.205-.609.183-2.284-.354-4.288.591-4.968,2.809h0a1.661,1.661,0,0,0-.408,1.093v1.3a.435.435,0,1,1-.869-.01V12.63a4.814,4.814,0,0,1,1.294-3.513c1.49-1.437,3.852-1.319,5.563-.55A.07.07,0,0,1,122.847,8.695Z"
            transform="translate(-36.638 -7.728)"
          />
          <path
            fill="#000000"
            d="M89.3,14.773v1.643a.278.278,0,1,1-.556,0V14.773a2.314,2.314,0,0,0-2.315-2.315,1.708,1.708,0,0,1-1.47-.825.278.278,0,1,1,.475-.289,1.156,1.156,0,0,0,.994.558A2.87,2.87,0,0,1,89.3,14.773Zm-4.855-2.789a.278.278,0,0,1-.213.331,2.663,2.663,0,0,1-2.07-.4,1.687,1.687,0,0,1-1.259.547,2.314,2.314,0,0,0-2.315,2.315v1.643a.278.278,0,1,1-.556,0V14.773A2.87,2.87,0,0,1,80.9,11.9a1.16,1.16,0,0,0,1.163-1.159v-.073a3.727,3.727,0,0,1-2.119-3.222,1.135,1.135,0,0,1-.872-1.1V4.905a5.1,5.1,0,0,1,1.379-3.717C82.047-.346,84.6-.249,86.477.655a.279.279,0,0,1,.059.463,3.755,3.755,0,0,1,2.3,1.653.307.307,0,0,1,.064.219.281.281,0,0,1-.08.176,1.816,1.816,0,0,0-.526,1.283v1.9a.278.278,0,0,1-.556,0V4.45a2.368,2.368,0,0,1,.54-1.512,3.214,3.214,0,0,0-2.307-1.354l-.489-.052A.278.278,0,0,1,85.4,1l.222-.092c-2.9-1.01-5.994.112-5.994,4V6.347a.574.574,0,0,0,.313.509v-1.8a1.95,1.95,0,0,1,1.947-1.947H85.44a1.95,1.95,0,0,1,1.947,1.947V7.331a3.726,3.726,0,0,1-4.77,3.549,1.7,1.7,0,0,1-.146.568,2.107,2.107,0,0,0,1.642.324A.278.278,0,0,1,84.445,11.984Zm-.779-1.51a3.168,3.168,0,0,0,3.165-3.165V5.053A1.393,1.393,0,0,0,85.44,3.662H81.893A1.393,1.393,0,0,0,80.5,5.053V7.309a3.168,3.168,0,0,0,3.165,3.165Zm2.817,4.795h-.835a.278.278,0,1,0,0,.556h.835a.278.278,0,1,0,0-.556Z"
            transform="translate(0 0)"
          />
        </g>
      </g>
    </svg>
`;

export const GroupIcon = ({ style, ...rest }: Omit<XmlProps, "xml">) =>
  Platform.OS === "web" ? (
    <View style={style}>
      <svg width="23.36" height="16.694" viewBox="0 0 23.36 16.694" {...rest}>
        <g transform="translate(-205 -173.707)">
          <g transform="translate(145.013 175.514)">
            <path
              fill="#b9dcff"
              d="M90.7,20.925v1.218a.248.248,0,0,1-.248.248H81.4a.248.248,0,0,1-.248-.248V20.925a2.314,2.314,0,0,1,1.357-2.107,3.627,3.627,0,0,1-1.187-1.361,3.279,3.279,0,0,1,.225-3.243,3.19,3.19,0,0,0,.535-1.769V11.1a3.1,3.1,0,0,1,3.1-3.1h1.489a3.1,3.1,0,0,1,3.1,3.1,7.169,7.169,0,0,0,.137,2.268c.242.8.562.9.808,1.714a3.281,3.281,0,0,1-1.1,3.517l-.275.219A2.314,2.314,0,0,1,90.7,20.925Z"
              transform="translate(-7.761 -7.752)"
            />
            <path
              fill="#b9dcff"
              d="M95.554,348.888v1.218a.248.248,0,0,1-.248.248H86.249a.248.248,0,0,1-.248-.248v-1.218a2.313,2.313,0,0,1,2.313-2.313,1.284,1.284,0,0,0,.533-.115l.008.017,1.771,1.371a.248.248,0,0,0,.3,0l1.771-1.371.008-.017a1.284,1.284,0,0,0,.533.115A2.313,2.313,0,0,1,95.554,348.888Z"
              transform="translate(-12.61 -335.714)"
            />
            <path
              fill="#d1e7ff"
              d="M146.907,120.191a.248.248,0,0,1,.235.248v1.485a3.071,3.071,0,1,1-6.141,0V120.46a.248.248,0,0,1,.269-.247,6.419,6.419,0,0,0,4.185-1.07.247.247,0,0,1,.377.138A1.158,1.158,0,0,0,146.907,120.191Z"
              transform="translate(-65.904 -115.406)"
            />
            <path
              fill="#b9dcff"
              d="M89.631,18.6l-.275.219a2.307,2.307,0,0,0-.956-.206,1.283,1.283,0,0,1-1.285-1.282v-.212l0-.011a3.071,3.071,0,0,0,1.9-2.838V12.784a.248.248,0,0,0-.235-.248,1.157,1.157,0,0,1-1.075-.909.247.247,0,0,0-.377-.138,6.42,6.42,0,0,1-4.185,1.07.248.248,0,0,0-.269.247v1.464a3.071,3.071,0,0,0,1.9,2.838l0,.011v.212a1.283,1.283,0,0,1-1.285,1.282,2.307,2.307,0,0,0-.956.206l-.275-.219a3.281,3.281,0,0,1-.687-4.385,3.19,3.19,0,0,0,.535-1.769V11.1a3.1,3.1,0,0,1,3.1-3.1h1.489a3.1,3.1,0,0,1,3.1,3.1v1.344a3.192,3.192,0,0,0,.535,1.769A3.281,3.281,0,0,1,89.631,18.6Z"
              transform="translate(-7.77 -7.752)"
            />
            <path
              fill="#a2d0ff"
              d="M203.3,302.151a3.079,3.079,0,0,1-2.427,0,1.922,1.922,0,0,0,.035-.509l0-.011a3.077,3.077,0,0,0,2.348,0l0,.011A1.919,1.919,0,0,0,203.3,302.151Z"
              transform="translate(-123.918 -292.275)"
            />
            <path
              fill="#d1e7ff"
              d="M88.078,8.334a.323.323,0,0,1-.174.611q-.146-.014-.3-.014H86.119a3.1,3.1,0,0,0-3.1,3.1v.377c0,.223-.155.216-.155.4a9.741,9.741,0,0,1-.017,1.605c-.243.716-.536.819-.773,1.6a3.279,3.279,0,0,0-.123,1.292.324.324,0,0,1-.623.152,3.28,3.28,0,0,1,.225-3.243,3.19,3.19,0,0,0,.535-1.769V11.1a3.1,3.1,0,0,1,3.1-3.1h1.489a3.088,3.088,0,0,1,1.4.334Z"
              transform="translate(-7.765 -7.752)"
            />
            <path
              fill="#e8f3ff"
              d="M143.484,158.705a.474.474,0,0,1-.416.843,3.051,3.051,0,0,1-2.066-2.9v-1.464a.248.248,0,0,1,.27-.247c.119.011.249.019.39.024a.473.473,0,0,1,.457.473v.718A3.06,3.06,0,0,0,143.484,158.705Z"
              transform="translate(-65.905 -150.131)"
            />
            <path
              fill="#d1e7ff"
              d="M89.367,346.873a.2.2,0,0,1-.117.354,2.312,2.312,0,0,0-2.318,2.313v.814H86.25a.248.248,0,0,1-.248-.248v-1.218a2.313,2.313,0,0,1,2.313-2.313,1.284,1.284,0,0,0,.533-.115l.008.017Z"
              transform="translate(-12.611 -335.714)"
            />
            <path
              fill="#a2d0ff"
              d="M283.1,346.873a.2.2,0,0,0,.117.354,2.312,2.312,0,0,1,2.318,2.313v.814h.682a.248.248,0,0,0,.248-.248v-1.218a2.313,2.313,0,0,0-2.313-2.313,1.284,1.284,0,0,1-.533-.115l-.008.017Z"
              transform="translate(-203.526 -335.714)"
            />
            <path
              fill="#000000"
              d="M83.192,13.174v1.466a.248.248,0,0,1-.5,0V13.174a2.064,2.064,0,0,0-2.065-2.065,1.523,1.523,0,0,1-1.311-.736.248.248,0,0,1,.424-.258,1.031,1.031,0,0,0,.887.5A2.56,2.56,0,0,1,83.192,13.174Zm-4.14-1.921-.885.685-1.5-1.165A1.52,1.52,0,0,0,77.231,9.7a3.319,3.319,0,0,0,4.254-3.184c0-.019,0-1.737,0-1.756a.248.248,0,0,0-.247-.232,1.021,1.021,0,0,1-.647-.12,1.129,1.129,0,0,1-.469-.9.248.248,0,0,0-.391-.2,8,8,0,0,1-3.518,1.4.248.248,0,0,0,.034.494A8.164,8.164,0,0,0,78,4.761a8.507,8.507,0,0,0,1.683-.832,1.4,1.4,0,0,0,1.3,1.1V6.517a2.822,2.822,0,1,1-5.645,0V4.776a.248.248,0,0,0-.5,0V6.517a3.323,3.323,0,0,0,1.892,3v.065A1.034,1.034,0,0,1,75.7,10.613a2.56,2.56,0,0,0-.916.169l-.16-.128A3.031,3.031,0,0,1,73.993,6.6a3.428,3.428,0,0,0,.577-1.906V3.35A2.857,2.857,0,0,1,77.423.5h1.489A2.857,2.857,0,0,1,81.765,3.35V4.694a3.41,3.41,0,0,0,.574,1.9A3.031,3.031,0,0,1,82.315,10a.248.248,0,1,0,.409.282,3.527,3.527,0,0,0,.028-3.964,2.916,2.916,0,0,1-.49-1.627V3.35A3.353,3.353,0,0,0,78.911,0H77.423a3.354,3.354,0,0,0-3.35,3.35V4.694a2.933,2.933,0,0,1-.494,1.631,3.526,3.526,0,0,0,.723,4.7,2.554,2.554,0,0,0-1.16,2.144v1.466a.248.248,0,0,0,.5,0V13.174A2.064,2.064,0,0,1,75.7,11.109a1.538,1.538,0,0,0,.483-.077l1.829,1.416a.248.248,0,0,0,.3,0l1.037-.8a.248.248,0,1,0-.3-.392Z"
              transform="translate(0 0)"
            />
          </g>
          <g transform="translate(127 175.514)">
            <path
              fill="#a2d0ff"
              d="M95.553,20.918v1.224a.248.248,0,0,1-.248.248H86.248A.248.248,0,0,1,86,22.143V20.918a2.324,2.324,0,0,1,2.326-2.306,1.284,1.284,0,0,0,1.035-.539l.02.007-.014-.016,0,.007a1.439,1.439,0,0,0,.236-.952l0-.011a3.071,3.071,0,0,1-1.9-2.838v-.1a.765.765,0,0,1-.775-.761v-1.55a3.861,3.861,0,1,1,7.723,0v1.5a.806.806,0,0,1-.806.806v.1a3.071,3.071,0,0,1-1.9,2.838l0,.011a1.439,1.439,0,0,0,.236.952l0-.007-.014.016.02-.007a1.284,1.284,0,0,0,1.035.539,2.324,2.324,0,0,1,2.326,2.306Z"
              transform="translate(-7.752 -7.752)"
            />
            <path
              fill="#b9dcff"
              d="M123.723,11.861v1.5a.806.806,0,0,1-.806.806V12.256a1.489,1.489,0,0,0-1.489-1.489h-3.164a1.489,1.489,0,0,0-1.489,1.489v1.916a.765.765,0,0,1-.775-.761v-1.55a3.861,3.861,0,1,1,7.723,0Z"
              transform="translate(-36.821 -7.752)"
            />
            <path
              fill="#b9dcff"
              d="M147.141,100.732a3.071,3.071,0,1,1-6.141,0V98.719a1.489,1.489,0,0,1,1.489-1.489h3.164a1.489,1.489,0,0,1,1.489,1.489Z"
              transform="translate(-61.046 -94.214)"
            />
            <path
              fill="#d1e7ff"
              d="M93.227,333.048a1.285,1.285,0,0,1-1.041-.548l-1.225,1.362a.248.248,0,0,1-.369,0L89.367,332.5a1.284,1.284,0,0,1-1.041.548A2.324,2.324,0,0,0,86,335.354v1.224a.248.248,0,0,0,.248.248H95.3a.248.248,0,0,0,.248-.248v-1.224A2.324,2.324,0,0,0,93.227,333.048Z"
              transform="translate(-7.752 -322.187)"
            />
            <g transform="translate(81.609 10.313)">
              <path
                fill="#c79a83"
                d="M194.37,332.516l-.02-.007v0l0-.007Z"
                transform="translate(-194.35 -332.5)"
              />
              <path
                fill="#c79a83"
                d="M285.02,332.509l-.02.007.014-.016,0,.007Z"
                transform="translate(-282.188 -332.5)"
              />
              <path
                fill="#c79a83"
                d="M194.356,332.5l0,.007v0l.02.007Zm2.824.007,0-.007-.014.016.02-.007Z"
                transform="translate(-194.35 -332.5)"
              />
            </g>
            <path
              fill="#e8f3ff"
              d="M256.559,332.943c0,.008-.31,1.4-.308,1.392a.228.228,0,0,1-.4.1,10.067,10.067,0,0,0-.786-.89l.942-1.047A1.289,1.289,0,0,0,256.559,332.943Z"
              transform="translate(-171.578 -322.186)"
            />
            <path
              fill="#e8f3ff"
              d="M177.467,332.5l.92,1.022-.763.9a.228.228,0,0,1-.4-.1c0-.009-.3-1.376-.306-1.384A1.289,1.289,0,0,0,177.467,332.5Z"
              transform="translate(-95.852 -322.187)"
            />
            <path
              fill="#b9dcff"
              d="M138.127,347.106v3.587H131.39v-3.586c.723-.308.935-.092,1.413-.3l.3,1.354a.248.248,0,0,0,.43.109l.753-.867.289.322a.248.248,0,0,0,.369,0l.289-.322.753.867a.248.248,0,0,0,.43-.109l.3-1.354C137.195,347.016,137.405,346.8,138.127,347.106Z"
              transform="translate(-51.734 -336.054)"
            />
            <path
              fill="#e8f3ff"
              d="M88.981,347.556a2.313,2.313,0,0,0-2.206,2.31v.845h-.527a.248.248,0,0,1-.248-.248v-1.223a2.326,2.326,0,0,1,2.326-2.308,1.286,1.286,0,0,0,.494-.1C88.824,346.838,88.979,347.547,88.981,347.556Z"
              transform="translate(-7.752 -336.073)"
            />
            <path
              fill="#e8f3ff"
              d="M177.487,333.8l.137.624a.228.228,0,0,1-.4-.1c-.119-.542-.3-1.379-.306-1.384a1.289,1.289,0,0,0,.547-.444l.265.295A1.2,1.2,0,0,0,177.487,333.8Z"
              transform="translate(-95.853 -322.187)"
            />
            <path
              fill="#d1e7ff"
              d="M132.963,347.556a2.306,2.306,0,0,0-1.573.721v-1.152c.69-.3.954-.1,1.412-.3C132.805,346.837,132.963,347.554,132.963,347.556Z"
              transform="translate(-51.734 -336.073)"
            />
            <path
              fill="#b9dcff"
              d="M297.88,347.556a2.313,2.313,0,0,1,2.206,2.31v.845h.527a.248.248,0,0,0,.248-.248v-1.223a2.326,2.326,0,0,0-2.326-2.308,1.285,1.285,0,0,1-.494-.1C298.037,346.838,297.882,347.547,297.88,347.556Z"
              transform="translate(-213.06 -336.073)"
            />
            <path
              fill="#a2d0ff"
              d="M297.88,347.556a2.306,2.306,0,0,1,1.573.721v-1.152c-.69-.3-.954-.1-1.412-.3C298.038,346.837,297.88,347.554,297.88,347.556Z"
              transform="translate(-213.06 -336.073)"
            />
            <path
              fill="#a2d0ff"
              d="M202.233,223.929a.239.239,0,0,1,.08.412.9.9,0,0,1-1.035.073.243.243,0,0,0-.242,0,.9.9,0,0,1-1.035-.073.239.239,0,0,1,.08-.412c.3-.1.35-.316.582-.377a.575.575,0,0,1,.395.052.241.241,0,0,0,.2,0,.575.575,0,0,1,.395-.052C201.883,223.613,201.935,223.827,202.233,223.929Z"
              transform="translate(-118.133 -216.604)"
            />
            <path
              fill="#8bc4ff"
              d="M203.3,302.151a3.079,3.079,0,0,1-2.427,0,1.922,1.922,0,0,0,.035-.509l0-.011a3.077,3.077,0,0,0,2.348,0l0,.011A1.922,1.922,0,0,0,203.3,302.151Z"
              transform="translate(-119.059 -292.275)"
            />
            <path
              fill="#d1e7ff"
              d="M142.807,117.365a.545.545,0,0,1-.732.805A3.008,3.008,0,0,1,141,115.837v-2.013a1.5,1.5,0,0,1,.263-.847.367.367,0,0,1,.668.217c0,.008,0,2.015,0,2.022A3.039,3.039,0,0,0,142.807,117.365Z"
              transform="translate(-61.046 -109.32)"
            />
            <path
              fill="#d1e7ff"
              d="M120.884,8.835a3.86,3.86,0,0,0-3.536,2.109.387.387,0,0,1-.084.112,1.484,1.484,0,0,0-.49,1.1v1.164a.388.388,0,0,1-.774.029c0-.03,0-1.58,0-1.55A3.875,3.875,0,0,1,121,8.077a.388.388,0,0,1-.12.759Z"
              transform="translate(-36.819 -7.656)"
            />
            <path
              fill="#000000"
              d="M85.488,10.613a1.031,1.031,0,0,1-.887-.5.248.248,0,1,0-.424.258,1.531,1.531,0,0,0,.519.517l-.257,1.167-.62-.714a.248.248,0,1,0-.362-.34l-.436.5-1.1-1.221a1.516,1.516,0,0,0,.166-.582,3.322,3.322,0,0,0,4.254-3.158c0-.005,0-2.034,0-2.039a1.739,1.739,0,0,0-1.737-1.737H81.443A1.739,1.739,0,0,0,79.706,4.5V6.113a.512.512,0,0,1-.279-.454V4.109a3.613,3.613,0,1,1,7.227,0v1.55a.248.248,0,0,0,.5,0V4.109a4.11,4.11,0,0,0-8.219,0v1.55a1.013,1.013,0,0,0,.778.981A3.324,3.324,0,0,0,81.6,9.513v.065a1.035,1.035,0,0,1-1.037,1.034A2.56,2.56,0,0,0,78,13.173v1.466a.248.248,0,0,0,.5,0V13.173a2.059,2.059,0,0,1,.911-1.713v3.179a.248.248,0,0,0,.5,0V11.216a4.858,4.858,0,0,1,.981-.141l.346,1.571a.248.248,0,0,0,.43.109l.893-1.028a3.591,3.591,0,0,0,.323.352.247.247,0,0,0,.178.046c.12-.015.142-.066.435-.4l.9,1.034a.248.248,0,0,0,.43-.109l.346-1.571a1.558,1.558,0,0,0,.324.034,2.064,2.064,0,0,1,2.065,2.065v1.466a.248.248,0,0,0,.5,0V13.173a2.56,2.56,0,0,0-2.561-2.561ZM80.2,6.517V4.5a1.242,1.242,0,0,1,1.241-1.241h3.164A1.242,1.242,0,0,1,85.847,4.5V6.517a2.822,2.822,0,1,1-5.645,0Zm1.408,5.54-.257-1.167a1.539,1.539,0,0,0,.265-.2l.6.668Zm5.031-.038v2.62a.248.248,0,1,1-.5,0v-2.62a.248.248,0,1,1,.5,0ZM83.725,8.184a1.3,1.3,0,0,0,.983-.715.248.248,0,0,0-.251-.351c-.476.05-.478-.305-.874-.41a2.418,2.418,0,0,1-1.116,0c-.4.1-.4.46-.874.41a.248.248,0,0,0-.251.351,1.3,1.3,0,0,0,.983.715,1.186,1.186,0,0,0,.7-.157A1.183,1.183,0,0,0,83.725,8.184ZM82.04,7.556c.494-.176.42-.544.86-.289a.248.248,0,0,0,.248,0c.44-.254.365.112.86.289a.661.661,0,0,1-.83-.022.248.248,0,0,0-.308,0A.66.66,0,0,1,82.04,7.556Z"
            />
          </g>
          <g transform="translate(132.967 173.707)">
            <path
              fill="#d1e7ff"
              d="M96.743,22.5v1.365a.278.278,0,0,1-.278.278H86.31a.278.278,0,0,1-.278-.278V22.5a2.594,2.594,0,0,1,2.594-2.593,1.44,1.44,0,0,0,1.209-.656,1.635,1.635,0,0,0,.231-1.019l.005-.013a3.443,3.443,0,0,1-2.127-3.182v-.109a.858.858,0,0,1-.869-.853V12.63a4.813,4.813,0,0,1,1.294-3.513c1.49-1.437,3.852-1.319,5.563-.55a.07.07,0,0,1,0,.128l-.446.184a.07.07,0,0,0,.019.134l.217.023a3.51,3.51,0,0,1,2.6,1.612.075.075,0,0,1-.009.086,2.1,2.1,0,0,0-.574,1.445v1.9a.854.854,0,0,1-.853.853H94.83v.109A3.443,3.443,0,0,1,92.7,18.22l.005.013a1.634,1.634,0,0,0,.231,1.019,1.44,1.44,0,0,0,1.209.656A2.593,2.593,0,0,1,96.743,22.5Z"
              transform="translate(-7.721 -7.728)"
            />
            <path
              fill="#b9dcff"
              d="M96.743,334.622v1.365a.278.278,0,0,1-.278.278H86.31a.278.278,0,0,1-.278-.278v-1.365a2.594,2.594,0,0,1,2.594-2.593,1.44,1.44,0,0,0,1.209-.656,2.387,2.387,0,0,0,3.105,0,1.44,1.44,0,0,0,1.209.656A2.594,2.594,0,0,1,96.743,334.622Z"
              transform="translate(-7.721 -319.849)"
            />
            <path
              fill="#b9dcff"
              d="M125.234,10.648a.074.074,0,0,1-.009.086,2.105,2.105,0,0,0-.574,1.445v1.9a.855.855,0,0,1-.9.853V12.782a1.669,1.669,0,0,0-1.669-1.669H118.53a1.669,1.669,0,0,0-1.669,1.669V14.93a.858.858,0,0,1-.869-.853V12.631a4.814,4.814,0,0,1,1.294-3.513c1.49-1.437,3.853-1.319,5.563-.55a.07.07,0,0,1,0,.128l-.446.184a.07.07,0,0,0,.019.133l.217.023A3.509,3.509,0,0,1,125.234,10.648Z"
              transform="translate(-36.637 -7.729)"
            />
            <path
              fill="#d1e7ff"
              d="M147.917,101.219a3.444,3.444,0,1,1-6.885-.109V98.962a1.669,1.669,0,0,1,1.669-1.669h3.547a1.669,1.669,0,0,1,1.669,1.669Z"
              transform="translate(-60.808 -93.91)"
            />
            <path
              fill="#e8f3ff"
              d="M143.7,118.551a.55.55,0,0,1-.5.974,3.443,3.443,0,0,1-2.163-3.2v-2.257a1.662,1.662,0,0,1,.293-.945.411.411,0,0,1,.75.249v2.257a3.418,3.418,0,0,0,1.62,2.919Z"
              transform="translate(-60.808 -109.018)"
            />
            <path
              fill="#b9dcff"
              d="M203.623,302.277a3.452,3.452,0,0,1-2.721,0,2.155,2.155,0,0,0,.039-.571l.005-.013a3.45,3.45,0,0,0,2.632,0l.005.013A2.155,2.155,0,0,0,203.623,302.277Z"
              transform="translate(-118.596 -291.201)"
            />
            <path
              fill="#d1e7ff"
              d="M90.026,332.869a1.414,1.414,0,0,1-.414.064A2.6,2.6,0,0,0,87,335.517v.261a.487.487,0,0,1-.487.487h-.209a.278.278,0,0,1-.278-.278v-1.365a2.594,2.594,0,0,1,2.594-2.593,1.44,1.44,0,0,0,1.209-.656,2.408,2.408,0,0,0,.331.237A.7.7,0,0,1,90.026,332.869Z"
              transform="translate(-7.72 -319.849)"
            />
            <path
              fill="#a2d0ff"
              d="M265.844,332.869a1.414,1.414,0,0,0,.414.064,2.6,2.6,0,0,1,2.607,2.584v.261a.487.487,0,0,0,.487.487h.209a.278.278,0,0,0,.278-.278v-1.365a2.594,2.594,0,0,0-2.594-2.593,1.44,1.44,0,0,1-1.209-.656,2.408,2.408,0,0,1-.331.237A.7.7,0,0,0,265.844,332.869Z"
              transform="translate(-180.817 -319.849)"
            />
            <path
              fill="#d1e7ff"
              d="M122.847,8.695c-.409.168-.467.205-.609.183-2.284-.354-4.288.591-4.968,2.809h0a1.661,1.661,0,0,0-.408,1.093v1.3a.435.435,0,1,1-.869-.01V12.63a4.814,4.814,0,0,1,1.294-3.513c1.49-1.437,3.852-1.319,5.563-.55A.07.07,0,0,1,122.847,8.695Z"
              transform="translate(-36.638 -7.728)"
            />
            <path
              fill="#000000"
              d="M89.3,14.773v1.643a.278.278,0,1,1-.556,0V14.773a2.314,2.314,0,0,0-2.315-2.315,1.708,1.708,0,0,1-1.47-.825.278.278,0,1,1,.475-.289,1.156,1.156,0,0,0,.994.558A2.87,2.87,0,0,1,89.3,14.773Zm-4.855-2.789a.278.278,0,0,1-.213.331,2.663,2.663,0,0,1-2.07-.4,1.687,1.687,0,0,1-1.259.547,2.314,2.314,0,0,0-2.315,2.315v1.643a.278.278,0,1,1-.556,0V14.773A2.87,2.87,0,0,1,80.9,11.9a1.16,1.16,0,0,0,1.163-1.159v-.073a3.727,3.727,0,0,1-2.119-3.222,1.135,1.135,0,0,1-.872-1.1V4.905a5.1,5.1,0,0,1,1.379-3.717C82.047-.346,84.6-.249,86.477.655a.279.279,0,0,1,.059.463,3.755,3.755,0,0,1,2.3,1.653.307.307,0,0,1,.064.219.281.281,0,0,1-.08.176,1.816,1.816,0,0,0-.526,1.283v1.9a.278.278,0,0,1-.556,0V4.45a2.368,2.368,0,0,1,.54-1.512,3.214,3.214,0,0,0-2.307-1.354l-.489-.052A.278.278,0,0,1,85.4,1l.222-.092c-2.9-1.01-5.994.112-5.994,4V6.347a.574.574,0,0,0,.313.509v-1.8a1.95,1.95,0,0,1,1.947-1.947H85.44a1.95,1.95,0,0,1,1.947,1.947V7.331a3.726,3.726,0,0,1-4.77,3.549,1.7,1.7,0,0,1-.146.568,2.107,2.107,0,0,0,1.642.324A.278.278,0,0,1,84.445,11.984Zm-.779-1.51a3.168,3.168,0,0,0,3.165-3.165V5.053A1.393,1.393,0,0,0,85.44,3.662H81.893A1.393,1.393,0,0,0,80.5,5.053V7.309a3.168,3.168,0,0,0,3.165,3.165Zm2.817,4.795h-.835a.278.278,0,1,0,0,.556h.835a.278.278,0,1,0,0-.556Z"
              transform="translate(0 0)"
            />
          </g>
        </g>
      </svg>
    </View>
  ) : (
    <SvgXml xml={xml} style={style} {...rest} />
  );
