import React, { useEffect, useState } from "react";
import { View, StyleSheet, Platform } from "react-native";
import * as AppleAuthentication from "expo-apple-authentication";
import { warmUpAsync, coolDownAsync } from "expo-web-browser";
import { KButton } from "components";
import {
  signInWithGoogleAsync,
  signInWithAppleAsync,
  signInWithFacebookAsync,
} from "utils/auth";
import { isMobilePlatform, isWeb } from "utils/helpers";
import { useFirebase } from "react-redux-firebase";
import { envIsDevelopment, envIsBeta } from "utils/constants";
import { Footnote } from "components/UI/Typography";
import { COLORS } from "utils/appStyles";
import { logAuthLogin } from "utils/analytics";

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
  },
  button: {
    marginVertical: 8,
    borderRadius: 6,
    width: "90%",
    maxWidth: 400,
  },
  buttonContent: {
    height: 44,
    padding: 0,
  },
  loginLabel: {
    fontSize: 18,
  },
  textCenter: {
    textAlign: "center",
  },
  moreOptions: {
    alignSelf: "center",
    marginBottom: 8,
  },
});

const LoginButton = (props: { onPress; icon; label; color }) => (
  <KButton
    {...props}
    uppercase={false}
    labelStyle={styles.loginLabel}
    style={styles.button}
    contentStyle={styles.buttonContent}
  />
);

export const SignInWithGoogleButton = ({ setIsLoading, firebase }) => (
  <LoginButton
    icon="google"
    label="Sign in with Google"
    color="#4285F4"
    onPress={async () => {
      logAuthLogin("google");
      setIsLoading(true);
      await signInWithGoogleAsync(firebase);
      setIsLoading(false);
    }}
  />
);

export const SignInWithFacebookButton = ({ setIsLoading, firebase }) => (
  <LoginButton
    icon="facebook"
    label="Sign in with Facebook"
    color="#3b5998"
    onPress={async () => {
      logAuthLogin("facebook");
      setIsLoading(true);
      await signInWithFacebookAsync(firebase);
      setIsLoading(false);
    }}
  />
);

export const SignInWithAppleButton = ({ setIsLoading, firebase }) => (
  <LoginButton
    icon="apple"
    label="Sign in with Apple"
    color="black"
    onPress={async () => {
      logAuthLogin("apple");
      setIsLoading(true);
      await signInWithAppleAsync(firebase);
      setIsLoading(false);
    }}
  />
);

export const LoginButtons = ({ setIsLoading, setIsEmailSelected }) => {
  const firebase = useFirebase();
  const [isAppleAvailable, setIsAppleAvailable] = useState(undefined);
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    AppleAuthentication.isAvailableAsync().then(res => {
      setIsAppleAvailable(res);
    });
  }, []);

  // https://docs.expo.io/guides/authentication/#warming-the-browser
  useEffect(() => {
    if (Platform.OS === "android") {
      warmUpAsync();

      return () => {
        coolDownAsync();
      };
    }
  }, []);

  const shouldShowAppleButton = isMobilePlatform && isAppleAvailable;
  return (
    <View style={styles.container}>
      <SignInWithGoogleButton setIsLoading={setIsLoading} firebase={firebase} />
      {/*
        FB is redirecting to a screen that doesn't exist on Android
        https://github.com/albertcui/kinetic-app/issues/844
      */}
      {Platform.OS !== "android" && !isWeb && (
        <SignInWithFacebookButton
          setIsLoading={setIsLoading}
          firebase={firebase}
        />
      )}
      {shouldShowAppleButton && (
        <SignInWithAppleButton
          setIsLoading={setIsLoading}
          firebase={firebase}
        />
      )}
      {showMore ? (
        <LoginButton
          icon="email"
          label="Sign in with Email"
          color={COLORS.darkGray}
          onPress={() => {
            logAuthLogin("email");
            setIsEmailSelected(true);
          }}
        />
      ) : (
        <KButton
          icon="chevron-down"
          label="More Options"
          mode="text"
          color="black"
          uppercase={false}
          style={styles.moreOptions}
          onPress={() => setShowMore(true)}
        />
      )}
      {shouldShowAppleButton && (envIsDevelopment() || envIsBeta()) && (
        <Footnote style={styles.textCenter}>
          Apple only works in the SnapHabitDevelopment and SnapHabit apps. This
          message does not appear in production.
        </Footnote>
      )}

      {/* {__DEV__ && APP_ENV === "development" && (
        <>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <KButton
              label={`Del ${email.substring(0, 5)}...`}
              onPress={async () => {
                setIsLoading(true);
                try {
                  await axios.post(`${API_HOST}/deleteUserTest`, {
                    email,
                  });
                  setIsLoading(false);
                } catch (error) {
                  console.log(error);
                  // TODO render a toast or something
                  Logger.error(error);
                  setIsLoading(false);
                }
              }}
            />
            <KButton
              label="Delete current user"
              onPress={async () => {
                setIsLoading(true);
                try {
                  console.log(API_HOST);
                  await axios.post(`${API_HOST}/deleteUserTest`, {
                    uid: firebase.auth().currentUser.uid,
                  });
                  setIsLoading(false);
                } catch (error) {
                  console.log(error);
                  // TODO render a toast or something
                  Logger.error(error);
                  setIsLoading(false);
                }
              }}
            />
          </View>
        </>
      )} */}
    </View>
  );
};
