import React, { useState } from "react";
import { StyleSheet } from "react-native";
import { Paragraph } from "react-native-paper";
import * as Analytics from "expo-firebase-analytics";

import { COLORS } from "utils/appStyles";
import { KButton, KPrompt } from "components";
import { FeedbackModal } from "components/Feedback/FeedbackModal";
import { isMobilePlatform } from "utils/helpers";
import { FeedbackSource } from "types/feedback";

const Buttons = props => {
  return (
    <>
      <KButton
        label="Share Feedback"
        mode="text"
        color={COLORS.logoBlue}
        onPress={async () => {
          props.setShouldShowModal(true);
        }}
      />
      <KButton
        label="Dismiss"
        mode="text"
        color={COLORS.darkGray}
        onPress={() => {
          Analytics.logEvent("feedback_prompt", {
            action: "dismissed",
          });
          props.dismissCard();
        }}
      />
    </>
  );
};

export const FeedbackPromptCard = props => {
  const [shouldShowModal, setShouldShowModal] = useState<boolean>(false);
  return (
    <>
      <KPrompt
        body={
          <Paragraph style={styles.text}>
            How can we make your experience better?
          </Paragraph>
        }
        actions={<Buttons {...props} setShouldShowModal={setShouldShowModal} />}
      />
      {isMobilePlatform && shouldShowModal && (
        <FeedbackModal
          {...props}
          setShouldShowModal={setShouldShowModal}
          onSend={() => {
            Analytics.logEvent("feedback_prompt", {
              action: "feedback_sent",
            });
            props.dismissCard();
          }}
          source={FeedbackSource.PROMPT}
        />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  text: {
    fontSize: 16,
    paddingTop: 4,
  },
});
