import { StyleSheet } from "react-native";

export const SHADOWS = StyleSheet.create({
  rightShadow: {
    shadowColor: "#000",
    shadowOffset: { width: 4, height: 9 },
    shadowOpacity: 0.15,
    shadowRadius: 4,
  },
  shadowCard: {
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.15,
    shadowRadius: 4,
  },
});
