import React, { memo } from "react";

import { useGetHabitByIndex, useGetSpecificHabit } from "redux/selectors";
import {
  isHabitChallengeParticipant,
  isHabitChallenge,
  isAChallenge,
  isHabitRoutine,
} from "types/habits";
import { GenericHabitTitleRow } from "components/HabitGrid/GenericHabitTitleRow";
import { useHistory } from "utils/react-router";
import { GroupIcon } from "icons/GroupIcon";
import { RoutineIcon } from "icons/RoutineIcon";
import { GroupRoutineIcon } from "icons/GroupRoutineIcon";

export const HabitTitleRowWide = memo(
  ({ index, friendid }: { index: number; friendid: string }) => {
    const history = useHistory();
    const habit = useGetHabitByIndex(index, friendid);
    const isChallenge = isAChallenge(habit);
    const isRoutine = isHabitRoutine(habit);

    const { habit: mainHabit } = useGetSpecificHabit(
      isHabitChallengeParticipant(habit) ? habit.challengeHabitId : null
    );

    if (!habit) {
      return null;
    }

    let icon = null;

    if (isRoutine && isChallenge) {
      icon = <GroupRoutineIcon height={16} width={16} />;
    } else if (isRoutine) {
      icon = <RoutineIcon height={16} width={16} />;
    } else if (isChallenge) {
      icon = <GroupIcon height={16} width={16} />;
    }

    return (
      <GenericHabitTitleRow
        title={habit.title}
        Icon={icon}
        onPress={() => {
          if (isHabitRoutine(habit)) {
            history.push(
              friendid
                ? `/friend/${friendid}/details/${habit.id}`
                : `/me/details/${habit.id}`
            );
          }
          if (isHabitChallenge(habit)) {
            return history.push(`/challenge/${habit.id}`);
          }

          if (isChallenge && mainHabit) {
            //@ts-ignore -- presence of mainHabit means challengeHabitId exists
            // mainHabit does not have an ID because useGetSpecificHabit does not return it
            return history.push(`/challenge/${habit.challengeHabitId}`);
          }

          history.push(
            friendid
              ? `/friend/${friendid}/details/${habit.id}`
              : `/me/details/${habit.id}`
          );
        }}
      />
    );
  }
);
