import React from "react";
import { View, StyleSheet, ViewStyle } from "react-native";

import { COLORS } from "utils/appStyles";

export const HorizontalSeparator = ({ style }: { style?: ViewStyle }) => (
  <View style={[styles.separator, style]} />
);

const styles = StyleSheet.create({
  separator: {
    borderBottomWidth: 1,
    borderColor: COLORS.disabled,
  },
});
