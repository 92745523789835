import { View, StyleSheet, Platform } from "react-native";
import { Title, Footnote } from "components";
import { Ionicons } from "@expo/vector-icons";
import { useGetDays, useGetHabitByIndex } from "redux/selectors";
import React, { useMemo, useEffect, useState, memo, useCallback } from "react";
import Animated, { Easing } from "react-native-reanimated";
import { TouchableOpacity } from "react-native-gesture-handler";
import { useNavigation } from "@react-navigation/native";
import { MainNavigation } from "layouts/MobileLayout";
import { useHabitGridStyles } from "hooks/habits/useHabitGridStyles";
import moment from "moment";
import { DayStatus, FrequencyType } from "types/habits";
import { pluralize, renderHabitTitle } from "utils/strings";

const getCurrentWeek = () => {
  const weekStart = moment().startOf("week");
  const days = [];
  for (let i = 0; i < 7; i++) {
    days.push(weekStart.clone().add(i, "days").format("MM-DD-YYYY"));
  }

  return days;
};

const FrequencyCounter = memo(
  ({
    habitid,
    ownHabit,
    frequency,
  }: {
    habitid: string;
    ownHabit: boolean;
    frequency: number;
  }) => {
    const days = useGetDays({ habitid, ownHabit });
    const week = useMemo(() => getCurrentWeek(), []);
    const numCompleted = useMemo(() => {
      let num = 0;
      for (let i = 0; i < week.length; i++) {
        if (days?.[week[i]]?.status === DayStatus.SUCCESS) {
          num++;
        }
      }

      return num;
    }, [days, week]);

    return (
      <>
        <Title>{numCompleted}</Title>
        <Footnote style={styles.footnote}>of {frequency} this week</Footnote>
      </>
    );
  }
);

export const HabitInfoRowLayout = ({
  habitid,
  ownHabit,
  frequencyType,
  frequency,
  streak,
  numShared,
  isShown,
  onPress,
}) => {
  const { COLUMN_WIDTH, GRID_LEFT_WIDTH } = useHabitGridStyles();
  const HIDDEN_LOCATION = GRID_LEFT_WIDTH - 4 * COLUMN_WIDTH;
  const [slideAnimation] = useState(new Animated.Value(HIDDEN_LOCATION));

  useEffect(() => {
    Animated.timing(slideAnimation, {
      toValue: isShown ? GRID_LEFT_WIDTH : HIDDEN_LOCATION,
      easing: Easing.linear,
      duration: 200,
    }).start();
  }, [isShown, GRID_LEFT_WIDTH, HIDDEN_LOCATION, slideAnimation]);

  return (
    <Animated.View
      key={habitid}
      style={[
        {
          position: "absolute",
          top: 0,
          width: 4 * COLUMN_WIDTH,
          height: COLUMN_WIDTH,
          left: HIDDEN_LOCATION,
          backgroundColor: "white",
          justifyContent: "center",
        },
        Platform.select({
          default: {
            left: slideAnimation,
          },
          web: {
            left: isShown ? GRID_LEFT_WIDTH : HIDDEN_LOCATION,
          },
        }),
      ]}
    >
      <TouchableOpacity style={styles.container} onPress={onPress}>
        <View style={styles.contentContainer}>
          <View style={styles.habitInfoRowGroup}>
            {frequencyType === FrequencyType.WEEKLY ? (
              <FrequencyCounter {...{ habitid, ownHabit, frequency }} />
            ) : (
              <>
                <Title>{streak || 0}</Title>
                <Footnote style={styles.footnote}>Current Streak</Footnote>
              </>
            )}
          </View>
          {ownHabit && (
            <View style={styles.habitInfoRowGroup}>
              <Title>{numShared}</Title>
              <Footnote style={styles.footnote}>
                Friend{pluralize(numShared)} shared
              </Footnote>
            </View>
          )}
        </View>
        <Ionicons name="ios-arrow-forward" size={15} style={styles.icon} />
      </TouchableOpacity>
    </Animated.View>
  );
};

export const HabitInfoRow = memo(
  ({
    index,
    isShown,
    setIsShown,
    ownHabit,
    friendid,
  }: {
    index: number;
    isShown: boolean;
    setIsShown: React.Dispatch<React.SetStateAction<boolean>>;
    ownHabit: boolean;
    friendid: string;
  }) => {
    const navigation = useNavigation<MainNavigation>();
    const habit = useGetHabitByIndex(index, friendid);
    const { streak, sharing, frequencyType, frequency } = habit;
    const numShared = useMemo(
      () => Object.keys(sharing).filter(friend => sharing[friend]).length,
      [sharing]
    );

    const onPress = useCallback(() => {
      setIsShown(false);
      return navigation.navigate(
        ownHabit ? "HabitScreen" : "FriendHabitScreen",
        {
          habitid: habit.id,
          friendid,
          title: renderHabitTitle(habit),
        }
      );
    }, [setIsShown, navigation, ownHabit, habit, friendid]);

    const props = {
      habitid: habit.id,
      ownHabit,
      frequencyType,
      frequency,
      streak,
      numShared,
      isShown,
      onPress,
    };

    return <HabitInfoRowLayout {...props} />;
  }
);

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 12,
  },
  contentContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    flex: 1,
    marginRight: 4,
  },
  habitInfoRowGroup: {
    flexDirection: "row",
    width: "35%",
    alignItems: "center",
  },
  footnote: {
    marginLeft: 4,
  },
  icon: {
    paddingLeft: 12,
  },
});
