import { useState, useCallback } from "react";

export const useEndpoint = <T>(
  initialLoading: boolean
): {
  isLoading: boolean;
  error: any;
  data: T;
  makeCall;
} => {
  const [isLoading, setIsLoading] = useState(initialLoading);
  const [error, setError] = useState();
  const [data, setData] = useState<T>();

  return {
    isLoading,
    error,
    data,
    makeCall: useCallback(async (endpointCall: () => Promise<{ data: T }>) => {
      try {
        setIsLoading(true);
        setData((await endpointCall()).data);
        setIsLoading(false);
      } catch (error) {
        setError(error);
      }
    }, []),
  };
};
