import React, { ReactNode } from "react";
import { ViewStyle } from "react-native";
import { List } from "react-native-paper";

import { useDimensionsContext } from "contexts";
import { PANEL_LEFT_MIN_WIDTH } from "panels/subpanels/PanelLeft";
import { KAvatar, CoachIcon } from "./KAvatar";

export const FriendItem = ({
  avatar,
  title,
  id,
  subtitle,
  contentLeft,
  contentRight,
  containerStyle,
  onPress,
  showAvatarIcon = false,
}: {
  avatar?: { uri: string };
  title: ReactNode;
  id?: string;
  subtitle?: string | Function;
  contentLeft?: ReactNode;
  contentRight?: ReactNode;
  containerStyle?: ViewStyle;
  contentLeftStyle?: ViewStyle;
  onPress?: () => void;
  showAvatarIcon?: boolean;
}) => {
  const { width } = useDimensionsContext();

  // For web, `PanelLeft` gets collapsed down to 80px.
  return (
    <List.Item
      style={containerStyle}
      title={width <= PANEL_LEFT_MIN_WIDTH ? "" : title}
      description={width <= PANEL_LEFT_MIN_WIDTH ? "" : subtitle}
      descriptionNumberOfLines={1}
      titleNumberOfLines={1}
      onPress={onPress}
      left={() =>
        contentLeft ? (
          contentLeft
        ) : (
          <KAvatar
            size={60}
            source={
              avatar || {
                uri: `https://api.adorable.io/avatars/150/${id}`,
              }
            }
            Icon={showAvatarIcon ? CoachIcon : null}
          />
        )
      }
      right={() => width > PANEL_LEFT_MIN_WIDTH && contentRight}
    />
  );
};
