import React from "react";
import { ViewWithDimensionsContext } from "contexts";
import { MatchProps } from "utils/react-router";
import { HabitChallengeView } from "components/Challenges/HabitChallengeView";

export const ChallengePanel = ({
  match,
}: {
  match: MatchProps<{ id: string }>;
}) => {
  const { id } = match.params;

  return (
    <ViewWithDimensionsContext
      style={{ flex: 1, maxWidth: 800, flexDirection: "row" }}
    >
      <HabitChallengeView habitid={id} />
    </ViewWithDimensionsContext>
  );
};
