import { Ionicons } from "@expo/vector-icons";

import { ImageInfo } from "expo-image-picker/build/ImagePicker.types";
import * as VideoThumbnails from "expo-video-thumbnails";
import React, { useEffect, useState } from "react";
import {
  TouchableOpacity,
  StyleSheet,
  View,
  Image,
  ActivityIndicator,
  StyleProp,
  ImageStyle,
} from "react-native";

import { Logger } from "utils/Logger";

export const IMAGE_HEIGHT = 70;

export const ImageThumbnail = ({
  selectedPhoto,
  onRemovePressed,
  isUploading,
  imageStyle,
}: {
  selectedPhoto: ImageInfo;
  onRemovePressed: () => void;
  isUploading: boolean;
  imageStyle?: StyleProp<ImageStyle>;
}) => {
  const [uri, setUri] = useState(null);

  useEffect(() => {
    if (selectedPhoto?.type === "video") {
      (async () => {
        try {
          const { uri } = await VideoThumbnails.getThumbnailAsync(
            selectedPhoto.uri,
            {
              quality: 0.25,
            }
          );
          setUri(uri);
        } catch (error) {
          //TODO - handle this better. Should probably have a fallback image
          Logger.error(error);
          setUri(null);
        }
      })();
    } else {
      setUri(selectedPhoto.uri);
    }
  }, [selectedPhoto]);

  if (uri === null) {
    return <></>;
  }

  return (
    <View style={[styles.thumbnail, imageStyle]}>
      {isUploading && (
        <View
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            borderWidth: 1,
            justifyContent: "center",
            backgroundColor: "#333333",
            zIndex: 1,
            opacity: 0.5,
          }}
        >
          <ActivityIndicator color="white" size="large" />
        </View>
      )}
      <Image source={{ uri }} style={[styles.thumbnail, imageStyle]} />
      {!isUploading && (
        <TouchableOpacity
          disabled={isUploading}
          style={styles.iconButton}
          onPress={onRemovePressed}
        >
          <Ionicons
            name="md-close-circle"
            size={25}
            //TODO: Shift these colors to common component
            color="white"
          />
        </TouchableOpacity>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
  },
  iconButton: {
    position: "absolute",
    right: 3,
    top: 0,
    zIndex: 1,
  },
  thumbnail: {
    height: IMAGE_HEIGHT,
    width: IMAGE_HEIGHT,
  },
});
