import { createSelector } from "@reduxjs/toolkit";
import { isLoaded } from "react-redux-firebase";

import { Habit } from "types/habits";
import { sortTitle } from "utils/strings";
import { HABIT_LAST_UPDATED_THRESHOLD } from "utils/constants";

//! Habits that haven't been updated in a long time are filtered out
const isEligibleHabit = (habit: Habit, friendId: string) =>
  !habit.isArchived &&
  habit.uid === friendId &&
  habit?.lastDayUpdate > HABIT_LAST_UPDATED_THRESHOLD;

export const friendHabitsCountSelector = (friendId: string) =>
  createSelector(
    state => state.firestore.ordered?.friendHabits,
    state => state.firestore.ordered?.challenges,
    (habits: Habit[] = [], challenges: Habit[] = []) => {
      const ids = new Set();

      habits.forEach(habit => {
        if (isEligibleHabit(habit, friendId)) {
          ids.add(habit.id);
        }
      });
      challenges.forEach(challenge => {
        if (isEligibleHabit(challenge, friendId)) {
          ids.add(challenge.id);
        }
      });

      return ids.size;
    }
  );

export const isFriendHabitsLoadedSelector = createSelector(
  state => state.firestore.ordered?.friendHabits,
  state => state.firestore.ordered?.challenges,
  (habits: Habit[], challenges: Habit[]) => {
    return isLoaded(habits) && isLoaded(challenges);
  }
);

export const friendHabitSelector = (friendId: string) =>
  createSelector(
    state => state.firestore.ordered?.friendHabits,
    state => state.firestore.ordered?.challenges,
    (habits: Habit[] = [], challenges: Habit[] = []) => {
      // Merge lists together, deduping and taking out archived habits
      let deduped = challenges.filter(h => isEligibleHabit(h, friendId));

      for (let i = 0; i < habits.length; i++) {
        const habit = habits[i];
        if (
          isEligibleHabit(habit, friendId) &&
          !deduped.find(h => h.id === habit.id)
        ) {
          deduped = [...deduped, habit];
        }
      }

      // Sort challenges to top
      deduped.sort((a, b) =>
        a.isChallenge === b.isChallenge
          ? sortTitle(a, b)
          : a.isChallenge
          ? -1
          : b.isChallenge
          ? 1
          : sortTitle(a, b)
      );

      return deduped;
    }
  );
