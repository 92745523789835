import axios from "axios";
import * as rax from "retry-axios";

import { Logger } from "./Logger";
//@ts-ignore -- TS does not support RN platform specific module imports
import Sentry from "./Sentry";
import firebase from "./firebase";

rax.attach();

axios.defaults.raxConfig = {
  retry: 3,
  instance: axios,
  onRetryAttempt: err => {
    const cfg = rax.getConfig(err);
    Sentry.addBreadcrumb({
      category: "http",
      message: `Retry attempt #${cfg.currentRetryAttempt}`,
      data: err,
      level: Sentry.Severity.Info,
    });
  },
};

const instance = axios.create();

instance.interceptors.request.use(async config => {
  const user = firebase.auth().currentUser;
  if (user) {
    try {
      // Force reload token in case profile information changed
      // Otherwise token info, which is used in backend, is stale
      const token = await user.getIdToken(true);
      config.headers["Authorization"] = `Bearer ${token}`;
    } catch (err) {
      Logger.warning("Error getting token");
      Logger.error(err);
    }
  }

  return config;
});

/** Axios with Authorization header set with jwt from Firebase */
export default instance;
