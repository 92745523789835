import React, { useEffect, useState, useCallback } from "react";
import {
  SafeAreaView,
  SectionList,
  View,
  Text,
  StyleSheet,
  TextInput,
} from "react-native";
import { useDispatch } from "react-redux";

import {
  AddFriendButton,
  Loading,
  ErrorBanner,
  HorizontalSeparator,
  KButton,
  Subtitle,
} from "components";

import { useString } from "hooks";
import { goToPermissionsSettings } from "utils/permissions";
import { parseEmail } from "utils/strings";
import { isMobilePlatform } from "utils/helpers";
import {
  InviteByUserName,
  InviteByEmail,
  InviteByLink,
  InviteByContacts,
} from "components/Friends/InviteActionButton";
import { COLORS } from "utils/appStyles";
import { FriendOfFriends, AnnotatedContact } from "types/friends";
import { ConEmailWarning } from "components/ConEmailWarning";
import { useAddFriend } from "hooks/friends/useAddFriend";
import { setToast, ToastTypes } from "redux/slices";
import {
  logAddFriendByEmailPress,
  logAddFriendByShareLinkPress,
  logAddFriendByUsernamePress,
} from "utils/analytics";
import { copyInviteLink } from "utils/sharing";
import { useGetAuth } from "redux/selectors";
import { InviteToChallenge } from "components/Friends/InviteToChallenge";
import { ContactRow, ContactItem } from "./ContactRow";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 0,
  },
  note: {
    fontStyle: "italic",
    padding: 8,
  },
  emptyView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  emptyViewText: {
    fontSize: 20,
    margin: 20,
    textAlign: "center",
  },
  permissionAskContainer: {
    marginTop: 100,
  },
  permissionAskText: {
    fontSize: 20,
    textAlign: "center",
    margin: 10,
  },
  permissionAskButton: {
    backgroundColor: "#007AFF",
    borderRadius: 4,
  },
  subtitle: {
    paddingTop: 8,
    paddingBottom: 3,
    paddingLeft: 25,
    textAlign: "left",
    backgroundColor: "#FFFFFF",
    fontSize: 14,
  },
  sectionHeaderContainer: {
    backgroundColor: "white",
  },
});

const keyExtractor = item => item.id;

export const ContactsList = ({
  fetchError,
  permissionGranted,
  friendsOfFriends,
  contacts,
  friends,
  fetchState,
  friendEmails,
  isLoadingFoF,
  addFriend,
  smsSupported,
  doShowShareScreen,
  setShownScreen,
  challengeHabitId,
  routeName,
}: {
  fetchError;
  permissionGranted;
  friendsOfFriends;
  contacts;
  friends;
  fetchState;
  friendEmails;
  isLoadingFoF;
  addFriend: ReturnType<typeof useAddFriend>;
  smsSupported;
  doShowShareScreen;
  setShownScreen;
  challengeHabitId?: string;
  routeName: string;
}) => {
  const s = useString("contactScreen");
  const [fofLimit, setFofLimit] = useState(5);
  const [searchText, setSearchText] = useState("");
  const { isEmail, emailTLD } = parseEmail(searchText);

  // "Filtered" by the search bar, as a sort of grep.
  const [filteredFriendsOfFriends, setFilteredFriendsOfFriends] = useState<
    FriendOfFriends[]
  >([]);
  const [filteredContacts, setFilteredContacts] = useState<AnnotatedContact[]>(
    []
  );
  const dispatch = useDispatch();
  const { profile } = useGetAuth();

  // Update `contacts` and `friendsOfFriends` as user interacts with Search Box.
  useEffect(
    function handleSearch() {
      if (searchText) {
        const s = searchText.toLowerCase();
        setFilteredContacts(
          contacts.filter(
            c =>
              (c.name && c.name.toLowerCase().includes(s)) ||
              (c.emails && c.emails.some(e => e.email.includes(s))) ||
              (c.phoneNumbers && c.phoneNumbers.some(e => e.number.includes(s)))
          )
        );

        setFilteredFriendsOfFriends(
          friendsOfFriends.filter(
            c => c.name && c.name.toLowerCase().includes(s)
          )
        );
      } else {
        setFilteredContacts(contacts);
        setFilteredFriendsOfFriends(friendsOfFriends);
      }
    },
    [searchText, contacts, friendsOfFriends]
  );

  // Takes a contact and transforms it to a common form suitable for
  // rendering using <ContactRow/>
  function toProcessedContact(contact): ContactItem {
    const hasEmail = "emails" in contact;
    const hasPhoneNumber = "phoneNumbers" in contact;
    const existingUser = !!contact.knownUserEmail;

    const subtitle = (() => {
      if (!hasEmail && !hasPhoneNumber) {
        return "";
      }

      if (hasEmail) {
        return (
          contact.emails[0].email +
          (contact.emails.length > 1
            ? ` ${s("andNumMore", contact.emails.length - 1)}`
            : ``)
        );
      }

      return (
        contact.phoneNumbers[0].number +
        (contact.phoneNumbers.length > 1
          ? ` ${s("andNumMore", contact.phoneNumbers.length - 1)}`
          : ``)
      );
    })();

    return {
      avatar: contact.image,
      emails: hasEmail ? contact.emails.map(({ email }) => email) : [],
      existingUser: !!contact.knownUserEmail,
      id: contact.id,
      phoneNumbers: hasPhoneNumber
        ? contact.phoneNumbers.map(({ number }) => number)
        : [],
      primaryEmail: existingUser
        ? contact.knownUserEmail
        : hasEmail
        ? contact.emails[0].email
        : "",
      subtitle,
      title: contact.name,
    };
  }

  // Takes a fof and transforms it to a common form suitable for
  // rendering using <ContactRow/>
  function toProcessedFof(fof: FriendOfFriends): ContactItem {
    return {
      avatar: { uri: fof.avatar },
      emails: [],
      existingUser: true,
      id: fof.id,
      phoneNumbers: [],
      primaryEmail: fof.email,
      subtitle: s("friendOfFriendSubtitle", {
        mutualFriends: fof.mutualFriends,
        friends,
      }),
      friendOfFriendSuggestion: true,
      title: fof.name,
    };
  }

  // Split contacts into two disjoint sets - existingUsers and the rest
  const userContactsSet = new Set<string>();
  const userContacts = [];
  const nonUserContacts = [];
  for (var contact of filteredContacts.map(c => toProcessedContact(c))) {
    // Need to de-dupe the contacts if you have multiple contacts with the same email
    if (!userContactsSet.has(contact.id)) {
      (contact.existingUser ? userContacts : nonUserContacts).push(contact);
      userContactsSet.add(contact.id);
    }
  }

  // Define 3 sections of friends.  But the last two don't show if we don't
  // have permission to contact them. (TODO)
  const sectionSuggestedFriends = {
    title: s("suggestedFriends"),
    key: "fof",
    data: filteredFriendsOfFriends
      .slice(0, fofLimit)
      .map(fof => toProcessedFof(fof)),
  };

  const contactsSections = [
    {
      title: s("contactsOnSnapHabit"),
      key: "userContacts",
      data: userContacts,
    },
    {
      title: s("inviteToSnapHabit"),
      key: "nonUserContacts",
      data: nonUserContacts,
    },
  ];

  // TODO - I don't like the pattern that was previously used to inject the
  // searchBox invite button into contacts, and it's now even more ridiculous
  // that we're adding a fake section altogether.  Is it possible that
  // <SectionList> isn't helping us here, and we should just put 3-4 distinct
  // sections in a column?
  const sectionNoContacts = {
    title: " ",
    key: "noContacts",
    data: [],
  };

  const potentialFriends = [
    sectionSuggestedFriends,
    ...(!permissionGranted ||
    isEmail ||
    userContacts.length + nonUserContacts.length === 0
      ? [sectionNoContacts]
      : contactsSections),
  ];

  const renderSectionHeader = useCallback(
    ({ section }) =>
      section.title &&
      (section.data.length > 0 || (isLoadingFoF && section.key === "fof")) && (
        // show header while loading
        <View style={styles.sectionHeaderContainer}>
          <Subtitle style={styles.subtitle}>{section.title}</Subtitle>
        </View>
      ),
    [isLoadingFoF]
  );

  return (
    <SafeAreaView style={styles.container}>
      <TextInput
        placeholder={
          permissionGranted ? s("searchContacts") : s("emailToInvite")
        }
        autoCapitalize="none"
        onChangeText={text => {
          const trimmed = text.replace(/  /g, " ");
          if (trimmed !== searchText) {
            setSearchText(trimmed);
          }
        }}
        editable={!fetchState[`email:${searchText}`]?.loading}
        value={searchText}
        keyboardType="email-address"
        autoCorrect={false}
        style={{
          fontSize: 20,
          fontFamily: "OpenSans",
          padding: 12,
          borderBottomWidth: 1,
          borderBottomColor: COLORS.disabled,
        }}
      />
      {!!fetchError && (
        <ErrorBanner>
          {fetchError?.error?.response?.data?.error === "DUPE"
            ? s("alreadyAdded")
            : s("errorAddingFriend")}
        </ErrorBanner>
      )}
      <SectionList
        keyboardShouldPersistTaps="handled"
        keyboardDismissMode="on-drag"
        showsVerticalScrollIndicator={false}
        extraData={{ searchText, friendEmails, isLoadingFoF }}
        sections={potentialFriends}
        keyExtractor={keyExtractor}
        ItemSeparatorComponent={HorizontalSeparator}
        stickySectionHeadersEnabled={false}
        ListHeaderComponent={
          <View>
            {isMobilePlatform && !permissionGranted && (
              <InviteByContacts onPress={goToPermissionsSettings} />
            )}
            <InviteByEmail
              onPress={() => {
                logAddFriendByEmailPress();
                setShownScreen("email");
              }}
            />
            <InviteByUserName
              onPress={() => {
                logAddFriendByUsernamePress();
                setShownScreen("username");
              }}
            />
            <InviteByLink
              onPress={() => {
                logAddFriendByShareLinkPress();
                if (isMobilePlatform) {
                  doShowShareScreen();
                } else {
                  copyInviteLink(profile);
                  dispatch(setToast({ type: ToastTypes.CLIPBOARD }));
                }
              }}
              routeName={routeName}
            />
            <HorizontalSeparator />
          </View>
        }
        renderItem={({ item }: { item: ContactItem }) => (
          <ContactRow
            item={item}
            fetchState={fetchState[item.id]}
            searchText={searchText}
            alreadyInvited={item.emails.some(email =>
              friendEmails.has(email.toLowerCase())
            )}
            addFriend={addFriend}
            smsSupported={smsSupported}
          />
        )}
        renderSectionHeader={renderSectionHeader}
        renderSectionFooter={({ section }) => {
          if (section.key === "fof" && isLoadingFoF) {
            return <Loading />;
          } else if (
            section.key === "fof" &&
            fofLimit < filteredFriendsOfFriends.length
          ) {
            return (
              <KButton
                icon="plus"
                label="Show More"
                onPress={() => {
                  setFofLimit(fofLimit + 5);
                }}
              />
            );
          } else if (section.key === "noContacts") {
            return (
              <View style={styles.emptyView}>
                <Text style={styles.emptyViewText}>
                  {isEmail ? s("inviteEmail", searchText) : s("enterAnEmail")}
                </Text>
                {isEmail && emailTLD === ".con" && <ConEmailWarning />}
                <AddFriendButton
                  fetchState={fetchState[`email:${searchText}`]}
                  alreadyInvited={false}
                  Button={
                    <KButton
                      icon="email"
                      label={s("inviteThisEmail")}
                      onPress={() => {
                        addFriend(
                          { email: searchText },
                          { id: `email:${searchText}` }
                        );
                      }}
                      disabled={!isEmail}
                    />
                  }
                />
              </View>
            );
          }
        }}
      />
      <InviteToChallenge
        challengeHabitId={challengeHabitId}
        routeName={routeName}
      />
    </SafeAreaView>
  );
};
