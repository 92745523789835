import React from "react";
import { SvgXml } from "react-native-svg";
import { Platform } from "react-native";

const xml = `
<svg viewBox="0 0 9.837 9.834">
    <path d="M17.369,16.206l3.513-3.513a.823.823,0,0,0-1.164-1.164L16.2,15.041l-3.513-3.513a.823.823,0,1,0-1.164,1.164l3.513,3.513-3.513,3.513a.823.823,0,0,0,1.164,1.164L16.2,17.37l3.513,3.513a.823.823,0,1,0,1.164-1.164Z" transform="translate(-11.285 -11.289)"/>
</svg>
`;

// const xml = `<svg xmlns="http://www.w3.org/2000/svg" width="9.837" height="9.834" viewBox="0 0 9.837 9.834"><defs><style>.a{fill:#ffc107;}</style></defs><path class="a" d="M17.369,16.206l3.513-3.513a.823.823,0,0,0-1.164-1.164L16.2,15.041l-3.513-3.513a.823.823,0,1,0-1.164,1.164l3.513,3.513-3.513,3.513a.823.823,0,0,0,1.164,1.164L16.2,17.37l3.513,3.513a.823.823,0,1,0,1.164-1.164Z" transform="translate(-11.285 -11.289)"/></svg>`;
export const FailIcon = props =>
  Platform.OS === "web" ? (
    <svg viewBox="0 0 9.837 9.834" {...props}>
      <path
        d="M17.369,16.206l3.513-3.513a.823.823,0,0,0-1.164-1.164L16.2,15.041l-3.513-3.513a.823.823,0,1,0-1.164,1.164l3.513,3.513-3.513,3.513a.823.823,0,0,0,1.164,1.164L16.2,17.37l3.513,3.513a.823.823,0,1,0,1.164-1.164Z"
        transform="translate(-11.285 -11.289)"
      />
    </svg>
  ) : (
    <SvgXml xml={xml} {...props} />
  );
