import React, { memo, useState } from "react";
import moment from "moment";
import { getAbsoluteDays } from "utils/time";
import { StyleSheet, View } from "react-native";
import { HabitDay } from "components";
import { COLORS } from "styles";
import { DayInfo } from "types/habits";
import { Footnote, Subtitle } from "components/UI/Typography";
import { useHabitGridStyles } from "hooks/habits/useHabitGridStyles";

export type HabitGridData = {
  id: string;
  ownHabit: boolean;
};

const makeDayInfo = (startAt: number) => {
  const today = moment().startOf("day");
  const monthStart = today.clone().startOf("month");
  const day = moment().subtract(startAt, "days");

  return {
    isGrid: true,
    display: "",
    date: day.format("MM-DD-YYYY"),
    isToday: day.isSame(today, "day"),
    month: day.format("MMM"), // zero indexed
    outOfMonth: day < monthStart,
    dayOfWeek: day.day(),
    dayOfWeekString: day.format("ddd").toUpperCase(),
    absoluteDay: getAbsoluteDays(day),
    moment: day,
  } as DayInfo;
};

const ProgressColumnLayout = ({
  index,
  habitsCount,
  ownHabit,
  friendId,
  habits,
}: {
  index: number;
} & (
  | {
      habitsCount: number;
      ownHabit: boolean;
      friendId?: string;
      habits?: undefined;
    }
  | {
      habits: {
        id: string;
        uid: string;
        ownHabit: boolean;
      }[];
      habitsCount?: undefined;
      ownHabit?: undefined;
      friendId?: undefined;
    }
)) => {
  const { fontSize, styles: gridStyles } = useHabitGridStyles();
  const [dayInfo] = useState(makeDayInfo(index));
  return (
    <View style={gridStyles.column}>
      <View style={gridStyles.headerCell}>
        <View style={gridStyles.day}>
          <Footnote
            style={{
              color: COLORS.GRAY4,
              fontWeight: dayInfo.isToday ? "bold" : "400",
              fontSize: dayInfo.isToday ? fontSize : fontSize * 0.9,
            }}
          >
            {dayInfo.dayOfWeekString}
          </Footnote>
          <Subtitle
            style={{
              color: COLORS.GRAY4,
              lineHeight: dayInfo.isToday ? fontSize * 1.1 : fontSize,
              fontWeight: dayInfo.isToday ? "bold" : "400",
              fontSize: dayInfo.isToday ? fontSize : fontSize * 0.9,
            }}
          >
            {dayInfo.moment.format("D")}
          </Subtitle>
          {dayInfo.outOfMonth && (
            <Footnote
              style={[
                styles.month,
                {
                  fontSize: fontSize * 0.65,
                },
              ]}
            >
              {dayInfo.month}
            </Footnote>
          )}
        </View>
      </View>
      {habits
        ? habits.map(h => (
            <View
              style={gridStyles.dataCell}
              key={`${dayInfo.absoluteDay}-${h.id}`}
            >
              <HabitDay
                ownHabit={h.ownHabit}
                habitid={h.id}
                dayInfo={dayInfo}
                style={gridStyles.day}
              />
            </View>
          ))
        : Array(habitsCount)
            .fill(0)
            .map((_, index) => (
              <View
                style={gridStyles.dataCell}
                key={`${ownHabit ? "me" : friendId}-${
                  dayInfo.absoluteDay
                }-${index}`}
              >
                <HabitDay
                  habitIndex={index}
                  ownHabit={ownHabit}
                  friendId={friendId}
                  dayInfo={dayInfo}
                  style={gridStyles.day}
                />
              </View>
            ))}
    </View>
  );
};

export const ProgressColumn = memo(ProgressColumnLayout);

export const styles = StyleSheet.create({
  headerIcon: {
    position: "absolute",
    bottom: -4,
    left: 0,
    height: 8,
    width: 8,
    borderWidth: 1,
    borderRadius: 8,
    backgroundColor: "white",
    borderColor: COLORS.disabledDark,
  },
  headerText: { fontFamily: "OpenSans" },
  month: { position: "absolute", bottom: 0, color: COLORS.GRAY4 },
});
