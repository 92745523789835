import { Ionicons } from "@expo/vector-icons";
import React from "react";
import { Modal, TouchableOpacity, Image } from "react-native";
import ImageViewer, {
  ImageViewerPropsDefine,
} from "react-native-image-zoom-viewer";
import ProgressBar from "react-native-progress/Bar";
import { SafeAreaView } from "react-native-safe-area-context";

import { KImage } from "./KImage";
import { KVideo } from "./KVideo";

/**
 * Attempts to load image locally and then falls back to remote url
 */
export const KMediaViewer = ({
  isVisible,
  setIsVisible,
  index,
  imageUrls,
  ...rest
}: {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  index?: number;
  /**
   * If url = "", props must be set which will be passed to KVideo/KImage
   * otherwise, will render a react native image
   */
  imageUrls: (
    | {
        url: "";
        height: number;
        width: number;
        props: {
          local: string;
          remote: string;
          type: "image" | "video";
          style: { height: number; width: number };
        };
      }
    | { url: string }
  )[];
} & Partial<ImageViewerPropsDefine>) => {
  return (
    <Modal visible={isVisible}>
      <ImageViewer
        index={index || 0}
        imageUrls={imageUrls}
        renderImage={props =>
          props.type ? (
            props.type === "video" ? (
              <KVideo {...props} />
            ) : (
              <KImage {...props} />
            )
          ) : (
            <Image {...props} />
          )
        }
        onCancel={() => setIsVisible(false)}
        enableSwipeDown
        loadingRender={() => (
          <ProgressBar {...progressBarProps} indeterminate />
        )}
        renderHeader={() => (
          <SafeAreaView
            style={{
              position: "absolute",
              right: 0,
              margin: 20,
              zIndex: 100,
            }}
          >
            <TouchableOpacity
              onPress={() => {
                setIsVisible(false);
              }}
              style={{ zIndex: 1 }}
            >
              <Ionicons name="md-close-circle" size={40} color="white" />
            </TouchableOpacity>
          </SafeAreaView>
        )}
        useNativeDriver
        {...rest}
      />
    </Modal>
  );
};

const progressBarProps = {
  color: "white",
  width: 120,
  useNativeDriver: true,
};
