import React from "react";
import { StyleProp, ViewStyle, TextStyle, StyleSheet } from "react-native";
import { Button } from "react-native-paper";
import { color } from "react-native-reanimated";

export const KButton = ({
  icon = null,
  color = "",
  mode = "contained",
  onPress,
  label,
  disabled = false,
  style = {},
  labelStyle = {},
  loading = false,
  ...rest
}: {
  icon?: string;
  color?: string;
  mode?: "text" | "outlined" | "contained";
  onPress: () => void;
  label: string;
  disabled?: boolean;
  loading?: boolean;
  style?: StyleProp<ViewStyle>;
  labelStyle?: StyleProp<TextStyle>;
  uppercase?: boolean;
  contentStyle?: StyleProp<ViewStyle>;
}) => (
  <Button
    color={color}
    icon={icon}
    mode={mode}
    onPress={onPress}
    style={[styles.buttonStyle, style]}
    labelStyle={[styles.labelStyle, labelStyle]}
    disabled={disabled}
    loading={loading}
    {...rest}
  >
    {label}
  </Button>
);

const styles = StyleSheet.create({
  buttonStyle: {
    borderWidth: 2,
    borderRadius: 32,
    marginRight: 0,
    marginLeft: 0,
  },
  labelStyle: {
    fontSize: 12,
    paddingLeft: 4,
    paddingRight: 4,
  },
});
