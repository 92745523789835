import React, { useMemo } from "react";
import { useFirestoreConnect } from "react-redux-firebase";

import { HabitDetailsView, Loading } from "components";

import { useGetSharedDays, useGetSpecificHabit } from "redux/selectors";

export const FriendHabitScreen = ({ route }) => {
  const { friendid, habitid } = route.params;
  const { habit } = useGetSpecificHabit(habitid);
  const { isSharedDaysLoaded } = useGetSharedDays();

  const query = useMemo(
    () => [
      //?Maybe a better query but Firestore rules don't allow for
      // {
      //   collection: "notes",
      //   doc: habitid,
      //   subcollections: [
      //     { collection: "notesDay", where: ["isPublic", "==", true],  where: ["habitid", "==", auth.uid], },
      //   ],
      //   storeAs: `notes-${habitid}`,
      // },
      {
        collectionGroup: `notesDay`,
        where: [
          [`habitid`, "==", habitid],
          ["isPublic", "==", true],
        ],
        storeAs: `sharedNotes-${habitid}`,
      },
    ],
    []
  );
  useFirestoreConnect(query);

  if (!isSharedDaysLoaded) {
    return <Loading />;
  }

  return (
    <HabitDetailsView
      ownHabit={false}
      habit={habit}
      habitid={habitid}
      friendid={friendid}
    />
  );
};
