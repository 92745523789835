// @refresh reset
import { RouteProp } from "@react-navigation/native";
import React from "react";
import { StackProps, MainNavigation } from "layouts/MobileLayout";
import { Loading } from "components";
import { useChatChannel } from "hooks/chat/useChatChannel";
import { ChatThread } from "components/Chat/ChatThread";

export const ChatThreadScreen = ({
  navigation,
  route,
}: {
  navigation: MainNavigation;
  route: RouteProp<StackProps, "ChatThreadScreen">;
}) => {
  const { thread, channelId } = route.params;

  const channel = useChatChannel({ id: channelId });

  if (!channel) {
    return <Loading />;
  }

  return <ChatThread thread={thread} channel={channel} />;
};
