import React from "react";
import { MessageSimple } from "stream-chat-react";
import { TouchableOpacity, View } from "react-native";

export const NoteMessageLayout = ({
  Header,
  onPress,
  streamProps,
  ownHabit,
}) => {
  return (
    <View>
      <TouchableOpacity onPress={onPress} style={{ zIndex: 1 }}>
        {Header}
      </TouchableOpacity>
      {/* Can't disable editing -- actionsEnabled isn't used in code */}
      <MessageSimple {...streamProps} />
    </View>
  );
};
