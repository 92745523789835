import get from "lodash/get";
import { AxiosError } from "axios";

import { Logger } from "./Logger";

export type FetchState = {
  [id: string]: {
    loading?: boolean;
    error?: AxiosError;
  };
};

export const fetchReducer = (state: FetchState, action): FetchState => {
  const id = get(action, "payload.id");
  const item = get(state, id, {});
  const error = get(action, "payload.error");

  switch (action.type) {
    case "LOADING":
      return { ...state, [id]: { ...item, loading: true } };

    case "ERROR":
      Logger.error(error);
      return { ...state, [id]: { ...item, loading: false, error } };

    case "DONE_LOADING":
      return { ...state, [id]: { ...item, loading: false, error: null } };

    case "RESET_ID":
      return { ...state, [id]: undefined };

    default:
      return state;
  }
};
