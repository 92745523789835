/* eslint-disable import/order */
/* eslint-disable import/first */
// Polyfill atop for Axios / SB / Firebase
import { decode, encode } from "base-64";
if (!global.btoa) {
  global.btoa = encode;
}

if (!global.atob) {
  global.atob = decode;
}

import { ActionSheetProvider } from "@expo/react-native-action-sheet";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import * as Updates from "expo-updates";
import Constants from "expo-constants";
import * as Analytics from "expo-firebase-analytics";
import * as Linking from "expo-linking";
import { logScreen } from "utils/analytics";
import React, { useEffect, useState, useMemo, useRef } from "react";
import {
  YellowBox,
  StatusBar,
  AppState,
  StyleSheet,
  Platform,
} from "react-native";
import AsyncStorage from "@react-native-community/async-storage";
import { DefaultTheme, Provider as PaperProvider } from "react-native-paper";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { enableScreens } from "react-native-screens";
import { createFirestoreInstance } from "redux-firestore";
import {
  ReactReduxFirebaseProvider,
  useFirestoreConnect,
  FirebaseReducer,
  useFirebase,
} from "react-redux-firebase";
import { Provider } from "react-redux";

import { locale } from "expo-localization";
import { LanguageCode } from "types/language";

import {
  BottomSheet,
  ErrorBoundary,
  Loading,
  Toast,
  StreaksComputer,
} from "components";
import { AppInitialization } from "./AppInitialization";
import {
  ChatProvider,
  LanguageContext,
  useDimensionsContext,
  ViewWithDimensionsContext,
  useLanguageContext,
  LayoutContext,
  useGetLayout,
  TimeContextProvider,
} from "contexts";
import { SignupScreen } from "screens/Auth/SignupScreen";
import { UpdateScreen } from "screens/Update/UpdateScreen";
import { Router, Route, Switch } from "utils/react-router";
import { Logger } from "utils/Logger";
import firebase from "utils/firebase";
import { NavigationService } from "utils/NavigationService";
import Sentry from "utils/Sentry";
import {
  APP_ENV,
  SENTRY_DNS,
  LOG_LEVEL,
  FULLSTORY_ID,
  ASYNC_STORAGE_APP_OPENS_KEY,
  envIsDevelopment,
  envIsBeta,
} from "utils/constants";
import { MobileLayout } from "layouts/MobileLayout";
import { createNotificationsListener } from "utils/notifications";
import { COLORS, MOBILE_BREAK_POINT } from "utils/appStyles";
import { store } from "redux/store";
import { useGetAuth, useGetIsAllDataLoaded } from "redux/selectors";
import { isMobilePlatform, isWeb } from "utils/helpers";

import { InviteScreen } from "screens/Invite/InviteScreen";
import { FirestoreUserData } from "types/users";
import { isSignedUp } from "utils/auth";
import { RoutineWebScreen } from "screens/Routines/RoutineWebScreen";
import { PublishedStatus } from "types/routines";
import { JoinChallengeScreen } from "screens/Invite/JoinChallengeScreen";
import FullStory from "react-fullstory";

if (Platform.OS === "ios") {
  enableScreens();
}

Sentry.init({
  dsn: SENTRY_DNS,
  debug: true,
  environment: APP_ENV,
  enableInExpoDevelopment: true,
});

if (!__DEV__ && isMobilePlatform) {
  Sentry.setRelease(Constants.manifest.revisionId);
}

if (isWeb) {
  // Needed to complete auth session on Web
  import("expo-web-browser").then(({ maybeCompleteAuthSession }) =>
    maybeCompleteAuthSession()
  );
}

//@ts-ignore
if (LOG_LEVEL === "DEBUG") {
  const whyDidYouRender = require("@welldone-software/why-did-you-render");
  whyDidYouRender(React, {
    trackAllPureComponents: true,
    trackHooks: true,
    titleColor: "red",
    collapseGroups: true,
    exclude: [/^Icon/],
  });
}

YellowBox.ignoreWarnings([
  "Setting a timer", // SBSyncManager and (maybe) Firestore cause this warning to show up on Android
  "Non-serializable values were found in the navigation state",
]);

const Stack = createStackNavigator();

const theme = {
  ...DefaultTheme,
  roundness: 2,
  colors: {
    ...DefaultTheme.colors,
    primary: COLORS.primary, // Buttons, react navigation using this for back button color, so we need to manually set headerTintColor
    accent: COLORS.accent, // Using this for header icons -- headerTintColor is set to this as well for back
    // Used in react navigation
    card: COLORS.primary, // react navigation uses this for header background
  },
};

const rrfProps = {
  firebase,
  config: {
    userProfile: "users",
    useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
  },
  dispatch: store.dispatch,
  createFirestoreInstance: () =>
    createFirestoreInstance(
      firebase,
      {
        allowMultipleListeners: false,
      },
      store.dispatch
    ),
};

const getActiveRouteName = state => {
  if (!state) {
    return;
  }

  const route = state.routes[state.index];

  if (route.state) {
    // Dive into nested navigators
    return getActiveRouteName(route.state);
  }

  return route.name;
};

const checkForUpdates = async (
  user: FirebaseReducer.AuthState,
  profile: FirestoreUserData,
  setHasUpdate: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    const update = await Updates.checkForUpdateAsync();
    if (update.isAvailable) {
      await Updates.fetchUpdateAsync();
      if (update.manifest?.extra?.UPDATE_SHOULD_PROMPT_BEFORE_SIGN_IN) {
        setHasUpdate(true);
        // Only prompt after user is signed up
      } else if (user && !user.isAnonymous && isSignedUp(profile)) {
        setHasUpdate(true);
      }
    }
  } catch (error) {
    Logger.error(error);
  }
};

const AppLayout = () => {
  const { width } = useDimensionsContext();
  if (!width) {
    return null;
  }

  if (isMobilePlatform || width < MOBILE_BREAK_POINT) {
    return <MobileLayout />;
  }

  const { WideLayout } = require("layouts/WideLayout");

  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen name="SnapHabit">
        {() => <WideLayout width={width} />}
      </Stack.Screen>
    </Stack.Navigator>
  );
};

if (__DEV__ && isMobilePlatform) {
  Analytics.setDebugModeEnabled(true);
}

const App = () => {
  const [hasUpdate, setHasUpdate] = useState(false);

  const routeNameRef = useRef();
  const reduxFirebase = useFirebase();
  const { auth, profile } = useGetAuth();
  const { language, setLanguage } = useLanguageContext();
  const layout = useGetLayout();

  useEffect(() => {
    if (profile.language && profile.language !== language) {
      setLanguage(profile.language);
    }
  }, [language, profile.language, setLanguage]);

  useEffect(
    function handleAppStateChange() {
      AsyncStorage.getItem(`${ASYNC_STORAGE_APP_OPENS_KEY}`).then(value => {
        if (value === null) {
          AsyncStorage.setItem(`${ASYNC_STORAGE_APP_OPENS_KEY}`, "1");
        }
      });

      async function handleStateChange(nextAppState) {
        if (!__DEV__ && nextAppState === "active") {
          checkForUpdates(auth, profile, setHasUpdate);
        }

        if (nextAppState === "active") {
          const storedAppCount = await AsyncStorage.getItem(
            `${ASYNC_STORAGE_APP_OPENS_KEY}`
          );
          AsyncStorage.setItem(
            `${ASYNC_STORAGE_APP_OPENS_KEY}`,
            (parseInt(storedAppCount ?? "0", 10) + 1).toString()
          );
        }
      }

      AppState.addEventListener("change", handleStateChange);

      // Check for update when user value changes
      if (!__DEV__) {
        checkForUpdates(auth, profile, setHasUpdate);
      }
      return function cleanup() {
        AppState.removeEventListener("change", handleStateChange);
      };
    },
    [auth, profile]
  );

  useEffect(
    function setNotificationListener() {
      createNotificationsListener(reduxFirebase);
    },
    [reduxFirebase]
  );

  const mainQuery = useMemo(() => {
    if (auth.uid) {
      const queries = [
        {
          collection: "habits",
          where: ["uid", "==", auth.uid],
        },
        {
          collectionGroup: "days",
          where: ["uid", "==", auth.uid],
          storeAs: "days",
        },
        {
          collection: `users/${auth.uid}/friends`,
          storeAs: "friends",
        },
        //notes was suprisingly fast!
        //as long as querying for one thing, it's fast
        {
          collectionGroup: `notesDay`,
          where: [`uid`, "==", auth.uid],
          storeAs: `notes`,
        },
        //need to query for challenges separately so if you are in a challenge
        //and your friend "unshares" their challenge with you, you can see the data
        //?also so you can see people who aren't your friends in the challenge
        {
          collection: "habits",
          where: [`challengers.${auth.uid}`, "==", true],
          storeAs: "challenges",
        },
        {
          collection: "habits",
          where: [`sharing.${auth.uid}`, "==", true],
          storeAs: "friendHabits",
        },
        {
          collection: "routines",
          where: [
            "publishedStatus",
            ">=",
            envIsDevelopment()
              ? PublishedStatus.DEV
              : envIsBeta()
              ? PublishedStatus.BETA
              : PublishedStatus.PUBLISHED,
          ],
          storeAs: "routines",
        },
      ];

      if (profile && profile.organizationId) {
        return [
          ...queries,
          {
            collection: "organizations",
            doc: profile.organizationId,
          },
        ];
      } else {
        return queries;
      }
    } else {
      return [];
    }
  }, [auth.uid, profile]);

  useFirestoreConnect(mainQuery);

  const isFirebaseDataLoaded = useGetIsAllDataLoaded();
  if (!isFirebaseDataLoaded) {
    return <Loading />;
  }

  const shouldShowSignUp = !auth.isAnonymous && !isSignedUp(profile);

  if (shouldShowSignUp) {
    return <SignupScreen />;
  }

  // Appears to cause crash on web
  // `Got 'undefined' for the navigation state. You must pass a valid state object.`
  const linking = isMobilePlatform
    ? {
        prefixes: [Linking.makeUrl("/")],
        config: {
          initialRouteName: "Home",
          screens: {
            ChatScreen: "chat",
            Home: "*",
          },
        },
      }
    : null;

  //! Anything that uses Navigation service must wait for navigationPromise to resolve
  //! Otherwise, navigation is not guaranteed to be set! See notifications.ts
  return (
    <ViewWithDimensionsContext style={styles.container}>
      {Platform.OS === "web" && !envIsDevelopment() && !__DEV__ && (
        <FullStory org={FULLSTORY_ID} />
      )}
      <LayoutContext.Provider value={layout}>
        <Router>
          {isWeb && console.log(window.location)}
          <Switch>
            <Route path="/u/:userHandle" component={InviteScreen} />
            <Route
              path={["/join/:challengeHabitId", "/join"]}
              component={JoinChallengeScreen}
            />
            {auth.isAnonymous && (
              <Route path="/routine/:id" component={RoutineWebScreen} />
            )}
            <Route>
              <NavigationContainer
                ref={ref => NavigationService.setTopLevelNavigator(ref)}
                onStateChange={state => {
                  const prevScreen = routeNameRef.current;
                  const currentScreen = getActiveRouteName(state);
                  if (prevScreen !== currentScreen) {
                    Analytics.setCurrentScreen(currentScreen);
                    logScreen(currentScreen);
                  }

                  routeNameRef.current = currentScreen;
                }}
                //@ts-ignore
                theme={theme}
                linking={linking}
              >
                {hasUpdate ? (
                  <Stack.Navigator
                    screenOptions={{
                      headerShown: false,
                    }}
                  >
                    <Stack.Screen
                      name="UpdateScreen"
                      component={UpdateScreen}
                    />
                  </Stack.Navigator>
                ) : (
                  <AppLayout />
                )}
              </NavigationContainer>
              {isMobilePlatform && <BottomSheet />}
              <StreaksComputer />
            </Route>
          </Switch>
          <Toast />
        </Router>
      </LayoutContext.Provider>
    </ViewWithDimensionsContext>
  );
};

const defaultLanguage = Object.values(LanguageCode).includes[locale]
  ? (locale as LanguageCode)
  : LanguageCode.EN;

const Root = () => {
  const [language, setLanguage] = useState(defaultLanguage);

  return (
    <ErrorBoundary>
      <StatusBar hidden={false} barStyle="dark-content" />
      <Provider store={store}>
        <ReactReduxFirebaseProvider {...rrfProps}>
          <PaperProvider theme={theme}>
            <ActionSheetProvider>
              <LanguageContext.Provider value={{ language, setLanguage }}>
                <AppInitialization>
                  <ChatProvider>
                    <TimeContextProvider>
                      <SafeAreaProvider>
                        <App />
                      </SafeAreaProvider>
                    </TimeContextProvider>
                  </ChatProvider>
                </AppInitialization>
              </LanguageContext.Provider>
            </ActionSheetProvider>
          </PaperProvider>
        </ReactReduxFirebaseProvider>
      </Provider>
    </ErrorBoundary>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

export default Root;
