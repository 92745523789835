import * as Updates from "expo-updates";
import React from "react";
import { Text, Button } from "react-native";

import { ContentCenteredView } from "components";

export const UpdateScreen = () => {
  return (
    <ContentCenteredView>
      <Text>There's an update! Reload for the newest experience.</Text>
      <Button
        title="Reload"
        onPress={() => {
          Updates.reloadAsync();
        }}
      />
    </ContentCenteredView>
  );
};
