import { NoteMedia, DayStatus, HabitUpdates } from "./habits";

export enum MessageType {
  NOTE_UPDATE = "NOTE_UPDATE",
  UNKNOWN = "UNKNOWN",
  MOTIVATION = "MOTIVATION",
}

export type NoteUpdateData = {
  title: string;
  habitid: string;
  dayid: string;
  note: string;
  media: NoteMedia[];
  status: DayStatus;
  date: string;
};

export type HabitUpdateData = HabitUpdates & { title: string; streak: number };
