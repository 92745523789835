import { FirebaseReducer } from "react-redux-firebase";

// https://stackoverflow.com/a/43843633
export const isGoogleDefaultImage = (profileUrl: string) => {
  return profileUrl?.includes("AAAAAAAAAAI/AAAAAAAAAAA") ?? false;
};

export const isSnapHabitDefaultImage = (profileUrl: string) => {
  return profileUrl?.includes("api.adorable.io") ?? false;
};

export const generateDefaultProfilePictureUrl = (
  user: FirebaseReducer.AuthState
) => `https://api.adorable.io/avatars/200/${user.email || user.uid}`;
