import React from "react";
import { View, Text } from "react-native";
import { HabitList, Loading, ContentCenteredView, ZeroState } from "components";
import { Route, Switch, MatchProps } from "utils/react-router";
import { FriendHabitDetailsPanel } from "panels/subpanels/HabitDetailsPanel";
import { NotesPanel } from "panels/subpanels/NotesPanel";
import {
  useGetFriend,
  useGetHabitsCount,
  useGetSharedDays,
} from "redux/selectors";
import { ChatMessageList } from "components/Chat/ChatMessageList";
import { PanelRight } from "panels/subpanels/PanelRight";
import { useChatChannel } from "hooks/chat/useChatChannel";

export const FriendPanel = ({
  match,
}: {
  match: MatchProps<{ id: string }>;
}) => {
  const { id } = match.params;
  const { isFriendsLoaded, friend } = useGetFriend(id);
  const habitCount = useGetHabitsCount(id);
  const { isSharedDaysLoaded } = useGetSharedDays();
  const channel = useChatChannel({ friendid: id });

  if (!isFriendsLoaded || !isSharedDaysLoaded || habitCount === undefined) {
    return <Loading />;
  }

  // TODO -- better handle channel presence
  if (!friend) {
    return (
      <ContentCenteredView>
        <Text>This friend wasn't found!</Text>
      </ContentCenteredView>
    );
  }

  return (
    <View style={{ flex: 1, flexDirection: "row" }}>
      <ChatMessageList channel={channel} />
      <Switch>
        <Route
          path="/friend/:friendid/details/:id"
          component={FriendHabitDetailsPanel}
        />
        <Route
          path="/friend/:friendid/notes/:habitid/:date"
          component={NotesPanel}
        />
        <Route>
          <PanelRight>
            {habitCount === 0 ? (
              <ZeroState
                header={
                  (friend?.name ?? "Friend") + " has not shared anything yet."
                }
              />
            ) : (
              <HabitList
                ownHabit={false}
                friendId={id}
                friendAvatar={friend.avatar}
              />
            )}
          </PanelRight>
        </Route>
      </Switch>
    </View>
  );
};
