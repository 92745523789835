import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import React, { useEffect, useState, useReducer, useMemo } from "react";
import { FlatList } from "react-native";
import { heightPercentageToDP } from "react-native-responsive-screen";
import { StackProps } from "layouts/MobileLayout";

import {
  AddFriendButton,
  Body,
  ContentCenteredView,
  FriendItem,
  InviteButton,
  Loading,
} from "components";

import { useAddFriend } from "hooks/friends/useAddFriend";
import { useGetFriends } from "redux/selectors";
import { FriendsOfFriendsResponse } from "types/friends";
import { Logger } from "utils/Logger";
import axios from "utils/axios";
import { API_HOST } from "utils/constants";
import { fetchReducer } from "utils/reducers";
import { firstName } from "utils/strings";
import { logTryAddFriend } from "utils/analytics";

const keyExtractor = item => item;

export const FriendsOfThisFriendScreen = ({
  navigation,
  route,
}: {
  navigation: StackNavigationProp<StackProps, "FriendsOfThisFriendScreen">;
  route: RouteProp<StackProps, "FriendsOfThisFriendScreen">;
}) => {
  const { friends: friendsArray } = useGetFriends();
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [friends, setFriends] = useState<FriendsOfFriendsResponse>();
  const [fetchState, dispatch] = useReducer(fetchReducer, {});
  const addFriend = useAddFriend(navigation, route, dispatch);
  const alreadyInvitedFriendIds = useMemo(() => {
    const friendIds = new Set<string>();
    friendsArray.forEach(f => friendIds.add(f.id));
    return friendIds;
  }, [friendsArray]);

  //need to set for the header buttons
  //see App.jsx where set header buttons
  useEffect(() => {
    axios
      .get(`${API_HOST}/getFriendsOfFriends`, {
        params: {
          friendid: route.params.friendid,
        },
      })
      .then(res => {
        setFriends(res.data);
        setIsLoading(false);
      })
      .catch(error => {
        Logger.error(error);
        setHasError(true);
        setIsLoading(false);
      });
  }, [route.params.friendid]);

  if (hasError) {
    return (
      <ContentCenteredView>
        <Body>Woops! Couldn't load friends. Try again later...</Body>
      </ContentCenteredView>
    );
  }

  if (isLoading) {
    return <Loading />;
  }

  // TODO - refactor to reduce renders
  return (
    <FlatList
      data={
        friends && route.params.friendid in friends.friendIdToNewFriends
          ? friends.friendIdToNewFriends[route.params.friendid]
          : []
      }
      extraData={friends.friendInfo}
      keyExtractor={keyExtractor}
      renderItem={({ item }) => {
        if (!(item in friends.friendInfo)) {
          return null;
        }
        const info = friends.friendInfo[item];
        return (
          <FriendItem
            avatar={{ uri: info.avatar }}
            title={info.name}
            id={info.id}
            contentRight={
              <AddFriendButton
                fetchState={fetchState[info.id]}
                alreadyInvited={alreadyInvitedFriendIds.has(info.id)}
                Button={
                  <InviteButton
                    icon="person-add"
                    label="Add friend"
                    onPress={() => {
                      const payload = {
                        id: info.id,
                        name: info.name,
                      };
                      addFriend(payload, payload);
                      logTryAddFriend(
                        payload,
                        "FriendsOfThisFriendScreen",
                        true
                      );
                    }}
                  />
                }
              />
            }
          />
        );
      }}
      ListEmptyComponent={
        <ContentCenteredView>
          <Body style={{ marginTop: heightPercentageToDP("30%") }}>
            {firstName(route.params.title)} doesn't have any friends to show!
          </Body>
        </ContentCenteredView>
      }
    />
  );
};
