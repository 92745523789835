import { Video, VideoProps } from "expo-av";
import React, { useState } from "react";
import { Platform } from "react-native";

const Vid = ({ uri, ...rest }) => (
  <Video source={{ uri }} {...rest} isLooping useNativeControls />
);
/**
 * Attempts to load video locally and then falls back to remote url
 */
export const KVideo = ({
  local,
  remote,
  style,
}: {
  local?: string;
  remote: string;
  style?: VideoProps;
}) => {
  const [error, setError] = useState(false);

  if (!local || Platform.OS === "android") {
    return <Vid uri={remote} style={style} />;
  }

  if (error) {
    return <Vid uri={remote} style={style} />;
  }

  return <Vid uri={local} style={style} onError={() => setError(true)} />;
};
