import React, { memo, useState } from "react";
import { View, StyleSheet } from "react-native";
import { useGetHabitByIndex } from "redux/selectors";
import { isAChallenge, isHabitRoutine } from "types/habits";
import { HabitInfoRow } from "components/HabitGrid/HabitInfoRow";
import { ChallengeHabitTitleRow } from "components/HabitGrid/ChallengeHabitTitleRow";
import { GenericHabitTitleRow } from "components/HabitGrid/GenericHabitTitleRow";
import { RoutineHabitTitleRow } from "components/HabitGrid/RoutineHabitTitleRow";

import { renderHabitTitle } from "utils/strings";

export const HabitTitleRow = memo(
  ({
    index,
    ownHabit,
    friendid,
  }: {
    index: number;
    ownHabit: boolean;
    friendid: string;
  }) => {
    const [showInfo, setShowInfo] = useState(false);
    const habit = useGetHabitByIndex(index, friendid);

    let titleRow;
    const displayTitle = renderHabitTitle(habit).trim();

    if (isHabitRoutine(habit)) {
      titleRow = (
        <RoutineHabitTitleRow
          title={displayTitle}
          habitid={habit.id}
          habit={habit}
          ownHabit={ownHabit}
          friendid={friendid}
        />
      );
    } else if (isAChallenge(habit)) {
      titleRow = (
        <ChallengeHabitTitleRow
          title={displayTitle}
          habitid={habit.id}
          habit={habit}
          ownHabit={ownHabit}
          friendid={friendid}
        />
      );
    } else {
      titleRow = (
        <GenericHabitTitleRow
          title={displayTitle}
          onPress={() => {
            setShowInfo(!showInfo);
          }}
        />
      );
    }

    return (
      <View style={styles.container}>
        <HabitInfoRow
          index={index}
          ownHabit={ownHabit}
          friendid={friendid}
          isShown={showInfo}
          setIsShown={setShowInfo}
        />
        {titleRow}
      </View>
    );
  }
);

const styles = StyleSheet.create({
  container: {
    position: "relative",
  },
});
