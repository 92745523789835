import moment from "moment";
import React, { useRef, useState, useEffect, useMemo } from "react";
import {
  Text,
  StyleSheet,
  ViewStyle,
  LayoutChangeEvent,
  View,
} from "react-native";
import { useLayout, useScaledSize } from "react-native-web-hooks";
import { TouchableOpacity } from "react-native-gesture-handler";

import { useGetDay, useGetAuth } from "redux/selectors";
import { Logger } from "utils/Logger";
import { COLORS } from "styles";
import { updateDay, getDayStatusColor } from "utils/habits";
import { isMobilePlatform, isWeb } from "utils/helpers";
import { useDispatch, shallowEqual } from "react-redux";
import { DayStatus, DayInfo } from "types/habits";
import {
  setToast,
  ToastTypes,
  setBottomSheet,
  BottomSheetTypes,
} from "redux/slices";
import { FailIcon } from "components/HabitGrid/FailIcon";
import { useIsDayScheduled } from "hooks/habits/useIsDayScheduled";
import { useSelector } from "redux/store";
import { useHabitGridStyles } from "hooks/habits/useHabitGridStyles";
import { useHistory } from "utils/react-router";
import { useLayoutContext, Layouts } from "contexts";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { StackProps } from "layouts/MobileLayout";

const Day = ({
  style,
  disabled,
  onPress,
  onLongPress,
  children,
}: {
  style?: ViewStyle[];
  disabled?: boolean;
  onPress?: () => void;
  onLongPress?: () => void;
  children;
}) => {
  // Web doesn't have aspectRatio so measure the view size and set height
  // to width
  const { onLayout, width } = useLayout();
  return (
    <TouchableOpacity
      onPress={onPress}
      onLongPress={onLongPress}
      disabled={disabled}
      style={[
        styles.dayStyle,
        isMobilePlatform ? {} : { height: width },
        ...style,
      ]}
      onLayout={onLayout as (event: LayoutChangeEvent) => void}
    >
      {children}
    </TouchableOpacity>
  );
};

const DAY_UPDATE_WAIT = 750; // ms

const HDay = ({
  dayInfo,
  habitid,
  habitIndex,
  ownHabit,
  friendId,
  style,
}: {
  dayInfo: DayInfo;
  ownHabit: boolean;
  style?: ViewStyle;
} & (
  | {
      // HabitDetailsView uses habitid since we already have it
      // HabitGrid uses habitIndex since allowing user re-ordering
      // depends on it
      habitid: string;
      habitIndex?: undefined;
      friendId?: undefined;
    }
  | { habitIndex: number; friendId?: string; habitid?: undefined }
)) => {
  Logger.log("Day re-render");
  const { auth } = useGetAuth();
  const dispatch = useDispatch();
  const navigation = useNavigation<StackNavigationProp<StackProps>>();
  const history = useHistory();
  const layout = useLayoutContext();
  const day = useGetDay({
    date: dayInfo.date,
    ownHabit,
    habitIndex,
    habitid,
    friendId,
  });
  const [status, setStatus] = useState<DayStatus>(day.status ?? DayStatus.SKIP);
  const prevDayStatus = useRef<DayStatus>(day.status ?? DayStatus.SKIP);
  const statusColor = getDayStatusColor(status);
  const timeout = useRef<NodeJS.Timeout>();
  const isScheduled = useIsDayScheduled(dayInfo.dayOfWeek, day.habitid);
  const hasNote = useSelector(
    state => !!state.notes.habitToNoteMap?.[day.habitid]?.[dayInfo.date],
    shallowEqual
  );
  const { COLUMN_WIDTH } = useHabitGridStyles();

  // When the underlying day doc in Firestore updates its status,
  // We need to update the component's local copy of `status`.
  // This covers case where you tap in month view
  // and then go back to week -- two different HabitDays
  // rendering the same underlying Day doc.
  useEffect(() => {
    if (day.status !== prevDayStatus.current) {
      setStatus(day.status ?? DayStatus.SKIP);
    }
    prevDayStatus.current = day.status ?? DayStatus.SKIP;
  }, [day]);

  const dayStyle = useMemo(() => {
    let backgroundColor = COLORS.skip;
    if (!dayInfo.isGrid && dayInfo.outOfMonth) {
      backgroundColor = COLORS.disabledDark;
      // If we're on habit grid, there won't be text to display
      // If it's fail, don't show red -- we'll show the icon instead
      // TODO - refactor this logic when we don't depend on HabitListItem anymore
    } else if (!dayInfo.display && status === DayStatus.FAIL) {
      backgroundColor = COLORS.skip;
    } else if (status) {
      backgroundColor = statusColor;
    } else if (dayInfo?.disabled || !isScheduled) {
      backgroundColor = COLORS.disabled;
    }

    return [{ backgroundColor }, style];
  }, [statusColor, status, dayInfo, style, isScheduled]);

  if (!dayInfo.isGrid && dayInfo.outOfMonth) {
    return null;
  }

  return (
    <Day
      style={dayStyle}
      onPress={async () => {
        const s: DayStatus = (status + 1) % 3;
        setStatus(s);
        let shownToast = false;
        if (day?.id) {
          dispatch(
            setToast({
              type: ToastTypes.NOTE,
              props: {
                status: s,
                dayid: day.id,
                date: dayInfo.date,
                habitid: day.habitid,
              },
            })
          );
          shownToast = true;
        }
        clearTimeout(timeout.current);
        timeout.current = setTimeout(async () => {
          try {
            const id = await updateDay(
              { ...day, ...dayInfo, habitid: day.habitid },
              auth.uid,
              s
            );
            if (!shownToast) {
              dispatch(
                setToast({
                  type: ToastTypes.NOTE,
                  props: {
                    status: s,
                    dayid: id,
                    date: dayInfo.date,
                    habitid: day.habitid,
                  },
                })
              );
            }
          } catch (err) {
            Logger.warning(
              `Error updating day to ${s}, ${dayInfo.date}, ${day.habitid}`
            );
            Logger.error(err);
            setStatus(day?.status ?? DayStatus.SKIP);
          }
        }, DAY_UPDATE_WAIT);
      }}
      onLongPress={() => {
        if (isMobilePlatform) {
          dispatch(
            setBottomSheet({
              type: BottomSheetTypes.NOTE,
              props: {
                showDayToggle: true,
                dayid: day.id,
                habitid: day.habitid,
                date: dayInfo.date,
                ownHabit,
                isEditable: true,
              },
            })
          );
        } else {
          if (layout === Layouts.MOBILE) {
            navigation.navigate("EditDayNoteScreen", {
              habitid: day.habitid,
              dayid: day.id,
              date: dayInfo.date,
              editable: true,
              ownHabit,
            });
          } else if (isWeb) {
            history.push(`/me/notes/${day.habitid}/${dayInfo.date}`);
          }
        }
      }}
      disabled={dayInfo?.disabled || !ownHabit || !isScheduled}
    >
      {dayInfo.display ? (
        <Text>{dayInfo.display}</Text>
      ) : status === DayStatus.FAIL ? (
        <FailIcon
          fill={COLORS.GRAY3}
          width={COLUMN_WIDTH * 0.2}
          height={COLUMN_WIDTH * 0.2}
        />
      ) : null}
      {hasNote && (
        <View
          style={[
            styles.noteIconStyle,
            {
              backgroundColor:
                status === DayStatus.SKIP || status === DayStatus.FAIL
                  ? COLORS.GRAY3
                  : "white",
            },
          ]}
        />
      )}
    </Day>
  );
};

export const HabitDay = React.memo(HDay);

const SelectedIndicator = () => <View style={styles.selectedIndicator} />;

const SettingsDay = ({ text, isSelected, ...rest }) => {
  const fontSize = useScaledSize(1);
  return (
    <Day {...rest} style={[styles.settingsDay]}>
      <Text
        style={{
          fontSize,
          fontFamily: isSelected ? "OpenSans-SemiBold" : "OpenSans",
        }}
      >
        {text}
      </Text>
      {isSelected && <SelectedIndicator />}
    </Day>
  );
};

export const FrequencyDay = ({ num, frequency, setFrequency, disabled }) => {
  return (
    <SettingsDay
      disabled={disabled}
      onPress={() => setFrequency(num)}
      isSelected={num === frequency}
      text={num}
    />
  );
};

export const ScheduleDay = ({ dayOfWeek, value, setSchedule, disabled }) => {
  return (
    <SettingsDay
      disabled={disabled}
      onPress={() =>
        setSchedule(s => {
          const ret = [...s];
          ret[dayOfWeek] = !ret[dayOfWeek];
          return ret;
        })
      }
      isSelected={value}
      text={moment().day(dayOfWeek).format("ddd")}
    />
  );
};

const styles = StyleSheet.create({
  dayStyle: {
    alignItems: "center",
    aspectRatio: 1, //height will equal width
    backgroundColor: "#fff",
    borderRadius: 12,
    marginBottom: 2,
    width: "100%",
    justifyContent: "center",
  },
  noteIconStyle: {
    position: "absolute",
    bottom: 5,
    left: 5,
    width: 8,
    height: 8,
    borderRadius: 5,
  },
  settingsDay: {
    backgroundColor: "white",
    justifyContent: "flex-start",
  },
  selectedIndicator: {
    margin: 1,
    height: 4,
    width: 4,
    borderRadius: 4,
    backgroundColor: "#EF5350",
  },
});
