import React, { useState } from "react";
import { View, Text, Image, TouchableOpacity, StyleSheet } from "react-native";
import { useFirestoreConnect } from "react-redux-firebase";
import { shallowEqual } from "react-redux";
import { Card, IconButton } from "react-native-paper";
import * as WebBrowser from "expo-web-browser";
import moment from "moment";

import firebase from "utils/firebase";
import { useSelector } from "redux/store";
import { useGetAuth } from "redux/selectors";
import {
  SectionTitle,
  SectionSubtitle,
  KCard,
  cardTypography,
} from "components";
import { FeedContent, FeedCardData } from "types/feed";
import { useTimeContext, useDimensionsContext } from "contexts";
import { COLORS, SHADOWS, SECTION } from "styles";
import { doShareFeedContent } from "utils/sharing";
import { useString } from "hooks";
import { envIsDevelopment } from "utils/constants";
import { logOpenFeedContent, logShareFeedContent } from "utils/analytics";

const styles = StyleSheet.create({
  wrapper: {
    alignSelf: "center",
    width: "90%",
  },
  feedContentWrapper: {
    marginTop: 24,
  },
  titlePadding: {
    marginTop: 6,
  },
  contentPadding: {
    marginVertical: 8,
  },
  card: {
    width: "95%",
    alignSelf: "center",
    ...SHADOWS.shadowCard,
    marginBottom: 24,
  },
  coverImage: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    marginBottom: 10,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  publisherBlock: {
    flexDirection: "row",
    alignItems: "center",
  },
  publisherIcon: {
    height: 20,
    width: 30,
    resizeMode: "contain",
    marginRight: 10,
  },
  publisherName: {
    color: COLORS.darkGray,
    fontSize: 12,
  },
});

const FeedCard = ({
  imageUrl,
  title,
  description,
  publisherBlock,
  onPress,
  onShare,
}: FeedCardData) => {
  const { width, getScaledFontSize } = useDimensionsContext();
  return (
    <KCard style={styles.card}>
      <TouchableOpacity onPress={onPress}>
        <Card.Cover
          source={{ uri: imageUrl }}
          style={[styles.coverImage, { height: width * 0.4 }]}
        />
        <Card.Content>
          <Text
            style={[
              cardTypography(getScaledFontSize(1)).cardTitle,
              styles.titlePadding,
            ]}
            numberOfLines={2}
          >
            {title}
          </Text>
          <Text
            style={[
              cardTypography(getScaledFontSize(1)).cardContent,
              styles.contentPadding,
            ]}
            numberOfLines={2}
          >
            {description}
          </Text>
          <View style={styles.row}>
            {!!publisherBlock && (
              <View style={styles.publisherBlock}>
                <Image
                  source={{ uri: publisherBlock.logoUrl }}
                  style={styles.publisherIcon}
                />
                <Text style={styles.publisherName}>
                  {publisherBlock.publisher}
                </Text>
              </View>
            )}
            <IconButton icon="share" color={COLORS.accent} onPress={onShare} />
          </View>
        </Card.Content>
      </TouchableOpacity>
    </KCard>
  );
};

const FeedDayToggles = ({ startDay, endDay, setStartDay, setEndDay }) => {
  return (
    <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
      <TouchableOpacity
        onPress={() => {
          setEndDay(startDay);
          setStartDay(
            moment(startDay).subtract(1, "days").startOf("day").toDate()
          );
        }}
      >
        <Text>[DEV] Previous day</Text>
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => {
          setStartDay(endDay);
          setEndDay(moment(endDay).add(1, "days").startOf("day").toDate());
        }}
      >
        <Text>[DEV] Next day</Text>
      </TouchableOpacity>
    </View>
  );
};

export const FeedList = () => {
  const { today } = useTimeContext();
  const { profile } = useGetAuth();
  const s = useString("habitListScreen");
  const [startDay, setStartDay] = useState<Date>(
    moment(today.toDate()).startOf("day").toDate()
  );
  const [endDay, setEndDay] = useState<Date>(
    moment(today.toDate()).add(1, "days").startOf("day").toDate()
  );

  useFirestoreConnect([
    {
      collection: "feed",
      where: [
        [
          "date",
          ">=",
          firebase.firestore.Timestamp.fromDate(new Date(startDay)),
        ],
        ["date", "<", firebase.firestore.Timestamp.fromDate(new Date(endDay))],
      ],
    },
  ]);

  const selectedFeedContent = useSelector(
    state => state.firestore.data.feed,
    shallowEqual
  );
  const feedContent: FeedContent[] = selectedFeedContent
    ? Object.values(selectedFeedContent)
    : [];

  if (feedContent.length === 0) {
    return null;
  }

  // Sort cards using the ordering field
  feedContent.sort((c1, c2) => c1.ordering - c2.ordering);

  const category = feedContent[0].category ?? "Building better habits";

  const renderItem = (content: FeedContent) => {
    const {
      title,
      id,
      description,
      contentUrl,
      imageUrl,
      publisherBlock,
    } = content;
    const props = {
      title,
      description,
      imageUrl,
      publisherBlock,
      onPress: () => {
        logOpenFeedContent(content);
        WebBrowser.openBrowserAsync(contentUrl);
      },
      onShare: () => {
        logShareFeedContent(content);
        doShareFeedContent(contentUrl, profile);
      },
    };
    return <FeedCard {...props} key={id} />;
  };

  return (
    <View style={SECTION.section}>
      <View style={styles.wrapper}>
        <SectionTitle>
          {s("dailyFeedTitle")}: {category}
        </SectionTitle>
        <SectionSubtitle>
          {feedContent[0].categoryDescription
            ? feedContent[0].categoryDescription
            : s("dailyFeedSubtitle")}
        </SectionSubtitle>
        <View style={styles.feedContentWrapper}>
          {envIsDevelopment() && (
            <FeedDayToggles
              startDay={startDay}
              setStartDay={setStartDay}
              endDay={endDay}
              setEndDay={setEndDay}
            />
          )}
          {feedContent.length > 0 &&
            feedContent.map((content: FeedContent) => {
              return renderItem(content);
            })}
        </View>
      </View>
    </View>
  );
};
