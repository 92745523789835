import { Ionicons } from "@expo/vector-icons";
import React from "react";
import { View, Text, StyleSheet, TouchableOpacity } from "react-native";

export const DatePicker = props => {
  return (
    <View style={styles.datePicker}>
      <TouchableOpacity onPress={props.dateDown} style={styles.toggle}>
        <Ionicons size={30} name="ios-arrow-dropleft" color="gray" />
      </TouchableOpacity>
      <View style={styles.date}>
        <Text style={styles.dateText}>{props.date} </Text>
      </View>
      <TouchableOpacity onPress={props.dateUp} style={styles.toggle}>
        <Ionicons size={30} name="ios-arrow-dropright" color="gray" />
      </TouchableOpacity>
    </View>
  );
};
const styles = StyleSheet.create({
  datePicker: {
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 12,
    marginBottom: 12,
  },
  date: {
    alignItems: "center",
    width: "40%",
    justifyContent: "center",
  },
  dateText: {
    fontSize: 17,
    color: "#606060",
  },
  toggle: {
    justifyContent: "center",
    alignItems: "center",
  },
});
