import { RoutineTag } from "types/routines";

export default {
  // RoutineScreen
  tabRoutine: {
    en: "Journey",
  },
  tabDetails: {
    en: "Details",
  },
  tabGroup: {
    en: "Group",
  },
  // End Card
  endCardTitle: {
    en: "Looking for more Journeys?",
  },
  endCardSubtitle: {
    en: "Send us your feedback",
  },
  invitesTitle: {
    en: "Journeys",
  },
  invitesSubtitle: {
    en: "Guided content from experts. Turn thought into habit.",
  },
  // ContentDay
  titleDayIndicator: {
    en: "Day",
  },
  sectionReflect: {
    en: "Reflect",
  },
  sectionInviteFriends: {
    en: "It's better as a group",
  },
  reflectPlaceholder: {
    en: "What did you think?",
  },
  reflectFriendPlaceholder: {
    en: "No thoughts here. Guess you have to ask them.",
  },
  greatJob: {
    en: "Thanks for committing today.",
  },
  askFriend: {
    en: "Talk to your friend about this!",
  },
  chat: {
    en: "Chat",
  },
  shareTitle: {
    en: "Thanks for committing",
  },
  // Overview
  labelCreatedBy: {
    en: "Created by",
  },
  attributePerDay: {
    en: "min/day",
  },
  attributeLengthOngoing: {
    en: "Ongoing",
  },
  attributeNumberOfDays: {
    en: "days",
  },
  attributeCommunity: {
    en: "Invite friends & join a community",
  },
  attributeAction: {
    en: "Make action a habit",
  },
  // Overview - Joining
  joinFriends: {
    en: "Join your friends",
  },
  startOwn: {
    en: "Start your own",
  },
  joinButton: {
    en: "Start Journey",
  },
  // CTA to log in
  login: {
    en: "Log in to SnapHabit to start this Journey",
  },
  // Tags
  [RoutineTag.SOCIAL_JUSTICE]: {
    en: "Social Justice",
  },
  [RoutineTag.EXERCISE]: {
    en: "Exercise",
  },
  [RoutineTag.MINDFULNESS]: {
    en: "Mindfulness",
  },
  [RoutineTag.SUSTAINABILITY]: {
    en: "Sustainability",
  },
  [RoutineTag.JOURNALING]: {
    en: "Journaling",
  },
  [RoutineTag.ART]: {
    en: "Art",
  },
};
