import React from "react";
import { View, StyleSheet } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import { COLORS } from "utils/appStyles";
import { useHistory } from "utils/react-router";
import { useGetAuth } from "redux/selectors";
import { FriendItem, Subtitle, KDialog } from "components";
import { signOut, isSignedUp } from "utils/auth";
import { ChatList } from "components/Chat/ChatList";
import { useDimensionsContext, ViewWithDimensionsContext } from "contexts";
import { IconButton } from "react-native-paper";
import { isWeb } from "utils/helpers";
import { useFirebase } from "react-redux-firebase";
import { LogInView } from "components/Login/LoginView";

const styles = StyleSheet.create({
  addFriend: {
    borderTopColor: COLORS.disabled,
    borderTopWidth: 1,
    position: "absolute",
    bottom: 0,
    zIndex: 9999,
    width: "100%",
  },
});

const ProfileHeader = () => {
  const { width } = useDimensionsContext();
  const history = useHistory();
  const { auth } = useGetAuth();
  const firebase = useFirebase();

  const actions = (
    <View
      style={
        width === PANEL_LEFT_MIN_WIDTH
          ? {
              width: "100%",
              flexDirection: "column",
              borderBottomWidth: 1,
              borderBottomColor: COLORS.disabled,
              alignItems: "center",
            }
          : { flexDirection: "row" }
      }
    >
      <IconButton
        icon="home"
        color={COLORS.accent}
        onPress={() => history.push("/me")}
      />
      <IconButton
        icon="plus-circle"
        color={COLORS.accent}
        onPress={() => history.push("/me/addHabit")}
      />
      <IconButton
        icon={() => (
          <Ionicons name="md-log-out" size={24} color={COLORS.disabledDark} />
        )}
        onPress={async () => {
          await signOut(auth.uid, firebase);
          history.push("/me");
        }}
      />
    </View>
  );

  return (
    <>
      <FriendItem
        title={<Subtitle>{auth.displayName}</Subtitle>}
        subtitle={__DEV__ && `[DEV] ${auth.uid}`}
        avatar={{
          uri: auth.photoURL,
        }}
        contentRight={actions}
        containerStyle={{
          borderBottomColor: COLORS.disabled,
          borderBottomWidth: 1,
        }}
      />
      {width === PANEL_LEFT_MIN_WIDTH ? actions : null}
    </>
  );
};

const BASE_STYLES = {
  borderRightWidth: 1,
  borderColor: COLORS.disabled,
};

export const PANEL_LEFT_MIN_WIDTH = 80;

const Login = () => {
  const { width } = useDimensionsContext();

  if (!width) {
    return null;
  }

  if (width === PANEL_LEFT_MIN_WIDTH) {
    return (
      <KDialog
        renderDialogAnchor={setShowDialog => (
          <IconButton
            icon="login"
            onPress={() => setShowDialog(true)}
            color={COLORS.accent}
            style={{ alignSelf: "center" }}
          />
        )}
      >
        <LogInView />
      </KDialog>
    );
  }

  return <LogInView />;
};

export const PanelLeft = () => {
  const { profile } = useGetAuth();
  const history = useHistory();
  const { width } = useDimensionsContext();

  let style = {};
  if (width < 900) {
    style = {
      flexBasis: 74,
      minWidth: PANEL_LEFT_MIN_WIDTH,
      ...BASE_STYLES,
    };
  } else {
    style = {
      flexBasis: "25%",
      minWidth: 300,
      maxWidth: 420,
      ...BASE_STYLES,
    };
  }

  // if user is not anonymous but not signed up yet, show signup screen first
  // if user is not anonymous but is signed up, show ProfileHeader
  // if user isAnonymous, show login screen
  return (
    <ViewWithDimensionsContext style={style}>
      {isSignedUp(profile) ? (
        <>
          <ProfileHeader />
          <ChatList />
          <FriendItem
            title={<Subtitle>Add Friend</Subtitle>}
            contentLeft={
              <IconButton icon="account-plus" size={30} color={COLORS.accent} />
            }
            onPress={() => {
              if (isWeb) {
                history.push("/me/addFriend");
              }
            }}
            containerStyle={styles.addFriend}
          />
        </>
      ) : (
        <Login />
      )}
    </ViewWithDimensionsContext>
  );
};
