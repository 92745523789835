import { useGetAuth } from "redux/selectors/useGetAuth";
import { useSelector } from "redux/store";
import { Organization } from "types/organization";
import { createSelector } from "@reduxjs/toolkit";
import { useMemo } from "react";

export const useGetOrganization = (): Partial<Organization> & {
  id?: string;
  enableProfileLink: boolean;
  enableFriendsTab: boolean;
  enableAddFriendScreen: boolean;
} => {
  const { profile } = useGetAuth();

  return useSelector(
    useMemo(
      () =>
        createSelector(
          state =>
            state.firestore.data?.organizations?.[profile?.organizationId],
          org => ({
            ...(org || {}),
            id: profile.organizationId,
            enableProfileLink: !org?.UXDisableProfileLink,
            enableAddFriendScreen: !org?.UXDisableAddFriend,
            enableFriendsTab: !org?.UXDisableFriendTab,
          })
        ),
      [profile]
    )
  );
};
