import React from "react";
import { View, StyleSheet, Text } from "react-native";
import { RoutineOverviewView } from "components/Routines/RoutineOverviewView";
import { MatchProps } from "utils/react-router";
const styles = StyleSheet.create({});
export const RoutinePanel = ({
  match,
}: {
  match: MatchProps<{ id: string }>;
}) => {
  const { id } = match.params;

  return (
    <View style={{ flex: 1 }}>
      <RoutineOverviewView routineId={id} />
    </View>
  );
};
