import React, { useState, useCallback } from "react";
import { View, StyleSheet } from "react-native";
import { useGetFriends, useGetAuth } from "redux/selectors";
import {
  Loading,
  Footnote,
  FriendItem,
  AddFriendButton,
  InviteButton,
  InputFieldView,
  KTextInput,
  KButton,
} from "components";
import { useEndpoint } from "hooks/useEndpoint";
import axios from "utils/axios";
import { API_HOST } from "utils/constants";
import { UserProfileResponse } from "types/friends";
import { useAddFriend } from "hooks/friends/useAddFriend";
import { logTryAddFriend } from "utils/analytics";
import { AddFriendViewBackButton } from "./AddFriendViewBackButton";

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  text: { textAlign: "center" },
  footnote: {
    textAlign: "center",
    margin: 10,
  },
  profileContainer: {
    marginHorizontal: 4,
    marginVertical: 12,
  },
});

const Profile = ({
  isLoading,
  error,
  profile,
  addFriend,
  fetchState,
}: {
  isLoading;
  error;
  profile;
  addFriend: ReturnType<typeof useAddFriend>;
  fetchState;
}) => {
  const { friends } = useGetFriends();

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <Footnote>Error</Footnote>;
  }

  if (profile) {
    const { photoURL, displayName, handle, id } = profile;

    return (
      <FriendItem
        avatar={{ uri: photoURL }}
        title={displayName}
        subtitle={handle}
        contentRight={
          <AddFriendButton
            fetchState={fetchState[profile.handle]}
            alreadyInvited={friends.some(f => f.id === id)}
            Button={
              <InviteButton
                icon="person-add"
                label="Add friend"
                onPress={() => {
                  addFriend({ id, name: displayName }, { id: profile.handle });
                  logTryAddFriend(
                    { id, name: displayName },
                    "AddByUserNameView"
                  );
                }}
              />
            }
          />
        }
      />
    );
  }

  // undefined means we haven't requested a profile yet
  if (profile !== undefined) {
    return <Footnote>No user found...</Footnote>;
  }

  return null;
};

export const AddByUserNameView = ({
  setShownScreen,
  addFriend,
  fetchState,
}: {
  setShownScreen;
  addFriend: ReturnType<typeof useAddFriend>;
  fetchState;
}) => {
  const { profile } = useGetAuth();
  const [username, setUsername] = useState("");
  const { isLoading, error, data: response, makeCall } = useEndpoint<
    UserProfileResponse
  >(false);
  const foundUserProfile = response?.user;

  const onSubmit = useCallback(() => {
    makeCall(() =>
      axios.get(`${API_HOST}/getProfileByHandle`, {
        params: { userHandle: username.toLowerCase() },
      })
    );
  }, [makeCall, username]);

  return (
    <View style={styles.container}>
      <AddFriendViewBackButton setShownScreen={setShownScreen} />
      <InputFieldView>
        <View style={styles.profileContainer}>
          <Profile
            isLoading={isLoading}
            error={error}
            profile={foundUserProfile}
            addFriend={addFriend}
            fetchState={fetchState}
          />
        </View>
        <KTextInput
          label="Your friend's sweet user name"
          placeholder="sally-sue"
          value={username}
          onChangeText={text => setUsername(text)}
          disabled={isLoading}
          onSubmitEditing={onSubmit}
          autoCapitalize="none"
          autoCompleteType="off"
          autoCorrect={false}
          autoFocus
        />
        <KButton
          label="Find"
          disabled={!username || isLoading}
          onPress={onSubmit}
        />
        <Footnote style={styles.footnote}>
          Tip: Your user name is {profile.handle}
        </Footnote>
      </InputFieldView>
    </View>
  );
};
