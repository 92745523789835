import React, { useContext, ReactNode, useCallback } from "react";
import { View, ViewStyle, LayoutChangeEvent } from "react-native";
import { useLayout } from "react-native-web-hooks";

export const DimensionsContext = React.createContext({
  x: 0,
  y: 0,
  height: 0,
  width: 0,
  getScaledFontSize: (number: number) => number,
});

export const useDimensionsContext = () =>
  useContext<{
    x: number;
    y: number;
    height: number;
    width: number;
    getScaledFontSize: (number: number) => number;
  }>(DimensionsContext);

export const ViewWithDimensionsContext = ({
  children,
  style,
}: {
  children: ReactNode;
  style?: ViewStyle;
}) => {
  const { onLayout, x, y, height, width } = useLayout();
  // Same logic react-native-web-hooks `useScaledSize` uses
  const getScaledFontSize = useCallback(
    (multiple: number) => {
      let size = 16;

      if (width >= 1408) size = 24;
      else if (width >= 1216) size = 22;
      else if (width >= 1024) size = 20;
      else if (width >= 768) size = 18;

      return size * multiple;
    },
    [width]
  );

  return (
    <DimensionsContext.Provider
      value={{ x, y, height, width, getScaledFontSize }}
    >
      <View
        onLayout={onLayout as (event: LayoutChangeEvent) => void}
        style={style}
      >
        {children}
      </View>
    </DimensionsContext.Provider>
  );
};
