import React, { useCallback, useMemo, useState, useEffect } from "react";
import { View, StyleSheet, TextInput, Text, Keyboard } from "react-native";
import { Segment } from "react-native-resegmented-control";
import { IconButton, Portal, Dialog, Menu } from "react-native-paper";
import moment from "moment";

import { FriendShareSection } from "components/Friends/FriendShareSection";
import { ScheduleWeek, FrequencyWeek } from "components/HabitDetails/Week";
import { KDateTimePicker as DateTimePicker } from "components/UI/KDateTimePicker";
import { Footnote, Body } from "components/UI/Typography";

import {
  KSegmentedControl,
  SegmentContent,
  segmentStyle,
} from "components/UI/KSegmentedControl";
import {
  FrequencyType,
  Habit,
  FormHabitData,
  Visibility,
  isHabitRoutine,
} from "types/habits";
import { useString } from "hooks";

import { emojiIndex, ModalPicker } from "emoji-mart-native";
import { Form, Field, FieldRenderProps } from "react-final-form";
import { getHabitFrequencyType } from "utils/habits";
import { Friend } from "types/friends";
import { Condition, Watch } from "components/Form/FinalFormHelpers";
import { Switch, TouchableOpacity } from "react-native-gesture-handler";
import { COLORS } from "utils/appStyles";
import { useDimensionsContext, useTimeContext } from "contexts";
import { KButton } from "components";
import { useNotificationPermissions } from "hooks/notifications/useNotificationPermissions";
import { GrantNotificationsDialog } from "components/Notifications/GrantNotificationsDialog";
import { isMobilePlatform } from "utils/helpers";
import {
  logPickEmoji,
  logOpenInfoModal,
  logViewVisibilityMenu,
} from "utils/analytics";
import { Routine } from "types/routines";
import { envIsProduction } from "utils/constants";

const SCHEDULE_SEGMENTED_CONTROL_OPTIONS = [
  FrequencyType.DAILY,
  FrequencyType.SCHEDULED,
  FrequencyType.WEEKLY,
];

const styles = StyleSheet.create({
  titleContainer: {
    flexDirection: "row",
    alignItems: "flex-start",
  },
  padding: {
    padding: 10,
  },
  fieldMargin: {
    position: "relative",
    marginVertical: 15,
  },
  toggleContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  rowLayout: {
    flexDirection: "row",
    alignItems: "center",
  },
  titleField: {
    flexGrow: 1,
    width: 100,
    fontFamily: "OpenSans",
    padding: 4,
  },
  descriptionField: {
    position: "relative",
    marginVertical: 10,
    fontFamily: "OpenSans",
  },
  helpText: {
    marginHorizontal: 10,
    marginTop: 10,
    fontFamily: "OpenSans",
    fontSize: 13,
  },
  iconStyle: {
    position: "absolute",
    left: 12,
  },
  disabledTextInput: {
    color: COLORS.darkGray,
  },
  timeStampText: {
    marginRight: 10,
    fontFamily: "OpenSans",
    fontSize: 15,
    color: COLORS.darkGray,
  },
  dialog: {
    maxWidth: 400,
    alignSelf: "center",
  },
  menuOption: {
    borderBottomColor: "black",
    borderBottomWidth: 1,
    paddingHorizontal: 4,
  },
});

const DEFAULT_EMOJI = "✅";

const EmojiField = ({
  input,
  title,
  isEditable,
}: FieldRenderProps<Habit["emoji"]>) => {
  const s = useString("buildHabit");
  const { getScaledFontSize } = useDimensionsContext();
  const fontSize = getScaledFontSize(1.7);
  const [showPicker, setShowPicker] = useState(false);
  // boolean for if user has selected an emoji -- if they have, we don't change
  const [selectedEmoji, setSelectedEmoji] = useState<boolean>(!!input.value);

  const closePicker = useCallback(() => {
    setShowPicker(false);
  }, []);

  const openPicker = useCallback(() => {
    if (isMobilePlatform) {
      setShowPicker(true);
    }
  }, []);

  const onEmojiSelected = useCallback(
    emoji => {
      logPickEmoji(emoji.native);
      input.onChange(emoji.native);
      setSelectedEmoji(true);
      setShowPicker(false);
    },
    [input]
  );

  useEffect(() => {
    // if user has actively selected an emoji, always use that first
    // if user did not select an emoji but has entered a title, do emoji search
    if (!selectedEmoji) {
      if (title) {
        const results = emojiIndex.search(title.replace(/[^A-Za-z]+/g, ""));
        if (results?.length) {
          if (input.value !== results[0].native) {
            input.onChange(results[0].native);
          }
        }
      }

      // if use did not select an emoji and title is empty, set default emoji
      else if (input.value !== DEFAULT_EMOJI) {
        input.onChange(DEFAULT_EMOJI);
      }
    }
  }, [input, selectedEmoji, title]);

  // If user clears the title, reset the selected emoji so that auto select
  // is re-enabled
  useEffect(() => {
    if (!title) {
      setSelectedEmoji(false);
    }
  }, [title]);

  return (
    <>
      <TouchableOpacity
        style={{ marginTop: 6, marginRight: 6 }}
        onPress={openPicker}
        disabled={!isEditable}
      >
        {/* Legacy challenges without emoji can't be edited, so don't show emoji */}
        <Text style={{ fontSize }}>
          {input.value || (isEditable && DEFAULT_EMOJI)}
        </Text>
      </TouchableOpacity>
      <ModalPicker
        native
        onSelect={onEmojiSelected}
        isVisible={showPicker}
        showCloseButton
        onPressClose={closePicker}
        showSkinTones={false}
        exclude={["recent"]}
        i18n={{
          search: s("search"),
          notfound: s("noEmojiFound"),
          categories: {
            search: s("searchResult"),
            recent: s("frequentlyUsed"),
            people: s("smileysAndPeople"),
            nature: s("animalsAndNature"),
            foods: s("foodAndDrink"),
            activity: s("activity"),
            places: s("travelAndPlaces"),
            objects: s("objects"),
            symbols: s("symbols"),
            flags: s("flags"),
            custom: s("custom"),
          },
        }}
      />
    </>
  );
};

const TitleField = ({
  input,
  meta,
  isEditable,
  isStartingRoutine,
}: FieldRenderProps<Habit["title"]>) => {
  const s = useString("buildHabit");
  const { getScaledFontSize } = useDimensionsContext();
  const fontSize = getScaledFontSize(1.7);

  return (
    <>
      <TextInput
        editable={isEditable}
        placeholder={
          isStartingRoutine
            ? s("titleRoutinePlaceholder")
            : s("titlePlaceholder")
        }
        placeholderTextColor="#CECECE"
        value={input.value}
        onChangeText={val => input.onChange(val)}
        style={[
          styles.titleField,
          { fontSize, minHeight: fontSize * 1.5, maxHeight: fontSize * 4 },
          !isEditable && styles.disabledTextInput,
        ]}
        multiline
        // only autofocus on add habit screen, when there is no text yet
        autoFocus={!input.value}
      />
      {meta.error && meta.touched && <Footnote>{s("addATitle")}</Footnote>}
    </>
  );
};

const DescriptionField = ({
  input,
  isEditable,
  isStartingRoutine,
}: FieldRenderProps<Habit["description"]>) => {
  const s = useString("buildHabit");
  const { getScaledFontSize } = useDimensionsContext();
  const fontSize = getScaledFontSize(1.2);
  return (
    <TextInput
      editable={isEditable}
      placeholder={
        isStartingRoutine
          ? s("descriptionRoutinePlaceholder")
          : s("descriptionPlaceholder")
      }
      placeholderTextColor="#CECECE"
      value={input.value}
      onChangeText={val => input.onChange(val)}
      style={[
        styles.descriptionField,
        { fontSize, minHeight: fontSize * 1.5, maxHeight: fontSize * 6 },
        !isEditable && styles.disabledTextInput,
      ]}
      multiline
    />
  );
};

const FrequencyTypeField = ({
  input,
  habit,
  isStartingRoutine,
  isEditingRoutine,
  isEditable,
  form,
}: FieldRenderProps<Habit["frequencyType"]>) => {
  const s = useString("buildHabit");
  return (
    <KSegmentedControl
      disabled={isStartingRoutine || isEditingRoutine || !isEditable}
      renderSegment={v => (
        <Segment
          key={v}
          name={v}
          style={segmentStyle.style}
          content={props => <SegmentContent {...props} name={s(v)} />}
        />
      )}
      values={SCHEDULE_SEGMENTED_CONTROL_OPTIONS}
      initialSelectedName={
        habit ? getHabitFrequencyType(habit) : FrequencyType.DAILY
      }
      onChangeValue={(selected: FrequencyType) => {
        if (selected === FrequencyType.DAILY) {
          form.mutators.resetSchedule();
        }
        return input.onChange(selected);
      }}
      containerStyle={styles.fieldMargin}
    />
  );
};

const ScheduleField = ({
  input,
  isEditable,
}: FieldRenderProps<Habit["schedule"]>) => {
  return (
    <ScheduleWeek
      schedule={input.value}
      // originally we were using useState
      // this emulates that behavior
      setSchedule={cb => {
        const newVal = cb(input.value);
        input.onChange(newVal);
      }}
      disabled={!isEditable}
    />
  );
};

const FrequencyField = ({ input, isEditable }: FieldRenderProps<number>) => {
  return (
    <FrequencyWeek
      frequency={input.value}
      setFrequency={input.onChange}
      disabled={!isEditable}
    />
  );
};

const ReminderToggleField = ({
  input,
  notificationsGranted,
}: FieldRenderProps<Habit["hasReminder"]>) => {
  const s = useString("addHabitScreen");
  const { getScaledFontSize } = useDimensionsContext();
  const fontSize = getScaledFontSize(1);
  const [showDialog, setShowDialog] = useState(false);
  const [showNotificationsDialog, setShowNotificationsDialog] = useState(false);

  return (
    <View style={styles.toggleContainer}>
      <Portal>
        <Dialog
          visible={showDialog}
          onDismiss={() => setShowDialog(false)}
          dismissable
        >
          <Dialog.Title>{s("setReminder")}</Dialog.Title>
          <Dialog.Content>
            <Body>{s("reminderInfo")}</Body>
          </Dialog.Content>
          <Dialog.Actions>
            <KButton
              label={s("gotIt")}
              mode="text"
              color="black"
              onPress={() => {
                setShowDialog(false);
              }}
            />
          </Dialog.Actions>
        </Dialog>
      </Portal>
      <GrantNotificationsDialog
        showDialog={showNotificationsDialog}
        setShowDialog={setShowNotificationsDialog}
      />
      <View style={styles.rowLayout}>
        <Text style={{ fontFamily: "OpenSans", fontSize }}>Add a reminder</Text>
        <IconButton
          icon="information"
          size={fontSize}
          color="#CECECE"
          onPress={() => {
            Keyboard.dismiss();
            setShowDialog(true);
            logOpenInfoModal("reminder");
          }}
        />
      </View>
      <View
        onTouchStart={() => {
          if (!notificationsGranted) {
            // catch taps on disabled switch to show dialog
            setShowNotificationsDialog(true);
          }
        }}
      >
        <Switch
          value={notificationsGranted && input.value}
          disabled={!notificationsGranted}
          //@ts-ignore
          onChange={Keyboard.dismiss}
          onValueChange={input.onChange}
          trackColor={{ true: COLORS.accent, false: COLORS.disabled }}
        />
      </View>
    </View>
  );
};

const TimeField = ({
  input,
  frequencyType,
  notificationsGranted,
}: FieldRenderProps<string>) => {
  const [isDateTimePickerVisible, setIsDateTimePickerVisible] = useState(false);
  const timeStamp = moment(input.value).format("hh:mm A");
  const reminderFrequency =
    frequencyType === FrequencyType.SCHEDULED ? `on scheduled days` : "daily";

  if (!notificationsGranted) {
    return null;
  }
  return (
    <View
      style={{
        alignSelf: "flex-start",
      }}
    >
      <TouchableOpacity
        onPress={() => setIsDateTimePickerVisible(true)}
        hitSlop={{ top: 30, bottom: 10, left: 5, right: 5 }}
      >
        {!!input.value && (
          <Text style={styles.timeStampText}>
            {timeStamp}, {reminderFrequency}
          </Text>
        )}
      </TouchableOpacity>

      <DateTimePicker
        mode="time"
        dateTime={input.value}
        setDateTime={input.onChange}
        isDateTimePickerVisible={isDateTimePickerVisible}
        setIsDateTimePickerVisible={setIsDateTimePickerVisible}
      />
    </View>
  );
};

const RoutineStartTimeField = ({
  input,
  isJoiningChallenge,
}: FieldRenderProps<Date>) => {
  const { today } = useTimeContext();
  const [isDateTimePickerVisible, setIsDateTimePickerVisible] = useState(false);
  const { getScaledFontSize } = useDimensionsContext();
  const fontSize = getScaledFontSize(1);

  const m = moment(input.value);
  const dateStamp = m.isSame(today, "day") ? "Today" : m.format("MMM DD");

  return (
    <>
      <DateTimePicker
        mode="date"
        dateTime={input.value}
        setDateTime={input.onChange}
        isDateTimePickerVisible={isDateTimePickerVisible}
        setIsDateTimePickerVisible={setIsDateTimePickerVisible}
        minimumDate={envIsProduction() && new Date()}
      />

      <View style={[styles.toggleContainer, { marginTop: 15 }]}>
        <Text style={{ fontFamily: "OpenSans", fontSize }}>Start date</Text>
        <View>
          <TouchableOpacity
            onPress={() => {
              setIsDateTimePickerVisible(true);
            }}
            disabled={isJoiningChallenge}
            style={styles.menuOption}
          >
            <Text
              style={{
                fontFamily: "OpenSans",
                fontSize,
              }}
            >
              {dateStamp}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </>
  );
};

const ChallengeField = ({
  input,
  isEditable,
}: FieldRenderProps<Habit["isChallenge"]>) => {
  const s = useString("buildHabit");
  const [showDialog, setShowDialog] = useState(false);
  const { getScaledFontSize } = useDimensionsContext();
  const fontSize = getScaledFontSize(1);
  return (
    <View style={[styles.fieldMargin, styles.toggleContainer]}>
      <Portal>
        <Dialog
          style={styles.dialog}
          visible={showDialog}
          onDismiss={() => setShowDialog(false)}
          dismissable
        >
          <Dialog.Title>{s("allowFriendsToJoinDialogTitle")}</Dialog.Title>
          <Dialog.Content>
            <Body>{s("allowFriendsToJoinDialogBody")}</Body>
          </Dialog.Content>
          <Dialog.Actions>
            <KButton
              label={s("gotIt")}
              mode="text"
              color="black"
              onPress={() => {
                setShowDialog(false);
              }}
            />
          </Dialog.Actions>
        </Dialog>
      </Portal>
      <View style={styles.rowLayout}>
        <Text style={{ fontFamily: "OpenSans", fontSize }}>
          {s("allowFriends")}
        </Text>
        <IconButton
          icon="information"
          size={fontSize}
          color="#CECECE"
          onPress={() => {
            Keyboard.dismiss();
            setShowDialog(true);
            logOpenInfoModal("challenge");
          }}
        />
      </View>
      <Switch
        value={input.value}
        //@ts-ignore
        onChange={Keyboard.dismiss}
        onValueChange={input.onChange}
        trackColor={{ true: COLORS.accent, false: COLORS.disabled }}
        disabled={!isEditable}
      />
    </View>
  );
};

const VisibilityField = ({
  input,
  shareOnlyMe,
}: FieldRenderProps<Visibility> & { sharing: Habit["sharing"] }) => {
  const s = useString("buildHabit");
  const [showDialog, setShowDialog] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const { getScaledFontSize } = useDimensionsContext();
  const fontSize = getScaledFontSize(1);

  const closeMenu = () => setMenuVisible(false);

  return (
    <View style={[styles.fieldMargin, styles.toggleContainer]}>
      <Portal>
        <Dialog
          style={styles.dialog}
          visible={showDialog}
          onDismiss={() => setShowDialog(false)}
          dismissable
        >
          <Dialog.Title>{s("visibilityDialogTitle")}</Dialog.Title>
          <Dialog.Content>
            <Body>{s("visibilityDialogBody")}</Body>
          </Dialog.Content>
          <Dialog.Actions>
            <KButton
              label={s("gotIt")}
              mode="text"
              color="black"
              onPress={() => {
                setShowDialog(false);
              }}
            />
          </Dialog.Actions>
        </Dialog>
      </Portal>
      <View style={styles.rowLayout}>
        <Text style={{ fontFamily: "OpenSans", fontSize }}>
          {s("visibility")}
        </Text>
        <IconButton
          icon="information"
          size={fontSize}
          color="#CECECE"
          onPress={() => {
            Keyboard.dismiss();
            setShowDialog(true);
            logOpenInfoModal("visibility");
          }}
        />
      </View>
      <Menu
        visible={menuVisible}
        onDismiss={closeMenu}
        anchor={
          <TouchableOpacity
            onPress={() => {
              logViewVisibilityMenu();
              setMenuVisible(true);
            }}
            style={styles.menuOption}
          >
            <Text
              style={{
                fontFamily: "OpenSans",
                fontSize,
              }}
            >
              {s(input.value)}
            </Text>
          </TouchableOpacity>
        }
      >
        <Menu.Item
          onPress={() => {
            input.onChange(Visibility.FRIENDS);
            closeMenu();
          }}
          title={s(Visibility.FRIENDS)}
        />
        <Menu.Item
          onPress={() => {
            input.onChange(Visibility.SELECT_FRIENDS);
            closeMenu();
          }}
          title={s(Visibility.SELECT_FRIENDS)}
        />
        <Menu.Item
          onPress={() => {
            shareOnlyMe();
            input.onChange(Visibility.ONLY_ME);
            closeMenu();
          }}
          title={s(Visibility.ONLY_ME)}
        />
      </Menu>
    </View>
  );
};

export const HabitForm = ({
  habit,
  routine,
  isEditable,
  isHabitChallenge,
  isStartingRoutine,
  isEditingRoutine,
  isJoiningChallenge,
  friends,
  onSubmit,
  handleSubmitRef,
  setCanSubmit,
}: {
  habit?: Habit;
  routine?: Routine;
  isEditable: boolean;
  isStartingRoutine: boolean;
  isEditingRoutine: boolean;
  isJoiningChallenge: boolean;
  isHabitChallenge: boolean; // Is this a challenge (either HabitChallenge or Participant)
  friends: Friend[];
  onSubmit;
  handleSubmitRef;
  setCanSubmit: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const notificationsGranted = useNotificationPermissions();
  const initialValues = useMemo(
    () => {
      let visibility = Visibility.FRIENDS;

      // Check isJoiningChallenge, Otherwise, we'd use the setting of the
      // challenge you're joining (`habit` is the challenge you're joining)
      // and leaking this value to the invited user
      if (!isJoiningChallenge && habit) {
        visibility = habit.visibility;
      }

      return {
        title: (habit?.title || routine?.name) ?? "",
        emoji: (habit?.emoji || routine?.emoji) ?? "",
        description: (habit?.description || routine?.description) ?? "",
        // Same case as visibility for referencing isJoiningChallenge
        // legacy habits without isChallenge  default to not challenge
        // New habits should all be default challenge
        isChallenge: isJoiningChallenge ? false : habit?.isChallenge ?? !habit,
        frequencyType: habit
          ? getHabitFrequencyType(habit)
          : FrequencyType.DAILY,
        schedule: habit?.schedule ?? (Array(7).fill(true) as Habit["schedule"]),
        frequency: habit?.frequency ?? 7,
        visibility,
        sharing: isJoiningChallenge ? {} : habit?.sharing ?? {},
        // Same case as visibility for referencing isJoiningChallenge
        hasReminder:
          !isJoiningChallenge && habit
            ? habit.hasReminder ?? false
            : notificationsGranted,
        reminder: habit?.hasReminder
          ? moment
              .utc()
              .set({
                hour: parseInt(habit.reminder.time.slice(0, 2), 10),
                minute: parseInt(habit.reminder.time.slice(3, 5), 10),
              })
              .local()
              .toDate()
          : moment.utc().local().toDate(),
        routineStartTime: moment.utc().local().toDate(),
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [habit, routine, isJoiningChallenge, notificationsGranted]
  );

  return (
    <Form
      onSubmit={data => {
        // If SELECT_FRIENDS but not shared with anyone, save it as ONLY_ME
        if (
          data.visibility === Visibility.SELECT_FRIENDS &&
          Object.values(data.sharing).filter(f => f).length === 0
        ) {
          return onSubmit({ ...data, visibility: Visibility.ONLY_ME });
        }

        return onSubmit(data);
      }}
      // by default, form subscribes to everything i.e. when values change
      // causing the entire form to re-render when something changes. Instead,
      // only subscribe to a limited set. If one field depends on another, it
      // needs to explicitly subscribe to it (see Condition component)
      subscription={{ submitting: true, hasValidationErrors: true }}
      initialValues={initialValues}
      validate={values => {
        const errors: Partial<FormHabitData> = {};
        if (!values.title) {
          errors.title = "Required";
        }
        return errors;
      }}
      mutators={{
        resetSchedule: (_args, state, utils) => {
          utils.changeValue(
            state,
            "schedule",
            () => Array(7).fill(true) as Habit["schedule"]
          );
        },
        shareOnlyMe: (_args, state, utils) => {
          utils.changeValue(state, "sharing", () => ({} as Habit["sharing"]));
        },
      }}
      render={props => {
        // This allows us to handle submit outside of this form
        // i.e. using the upper right header icon.
        // https://final-form.org/docs/react-final-form/faq#via-closure
        handleSubmitRef.current = props.handleSubmit;
        setCanSubmit(!props.hasValidationErrors);
        return (
          <View>
            <View style={styles.titleContainer}>
              <Watch
                name="title"
                render={title => (
                  <Field<Habit["emoji"]>
                    name="emoji"
                    isEditable={isEditable}
                    title={title}
                    allowNull={false}
                    render={props => <EmojiField {...props} />}
                  />
                )}
              />
              <Field<Habit["title"]>
                name="title"
                isStartingRoutine={isStartingRoutine}
                isEditable={isEditable}
                allowNull={false}
                render={props => <TitleField {...props} />}
              />
            </View>

            {
              // don't show the description field if its a challenge invite &
              // description is empty
              !(isJoiningChallenge && !habit.description) && (
                <Field<Habit["description"]>
                  name="description"
                  isStartingRoutine={isStartingRoutine}
                  isEditable={isEditable}
                  render={props => <DescriptionField {...props} />}
                />
              )
            }
            <Field<Habit["frequencyType"]>
              name="frequencyType"
              habit={habit}
              form={props.form}
              isStartingRoutine={isStartingRoutine}
              isEditingRoutine={isEditingRoutine}
              isEditable={isEditable}
              render={props => <FrequencyTypeField {...props} />}
            />
            <Condition when="frequencyType" is={FrequencyType.SCHEDULED}>
              <Field<Habit["schedule"]>
                name="schedule"
                isEditable={isEditable}
                render={props => <ScheduleField {...props} />}
              />
            </Condition>
            <Condition when="frequencyType" is={FrequencyType.WEEKLY}>
              <Field<Habit["frequency"]>
                name="frequency"
                isEditable={isEditable}
                render={props => <FrequencyField {...props} />}
              />
            </Condition>
            <Field<Habit["hasReminder"]>
              name="hasReminder"
              render={props => (
                <ReminderToggleField
                  {...props}
                  notificationsGranted={notificationsGranted}
                />
              )}
            />
            <Watch
              name="frequencyType"
              render={frequencyType => (
                <Condition when="hasReminder" is>
                  <Field<Habit["reminder"]>
                    name="reminder"
                    notificationsGranted={notificationsGranted}
                    frequencyType={frequencyType}
                    // @ts-ignore
                    render={props => <TimeField {...props} />}
                  />
                </Condition>
              )}
            />
            {(isJoiningChallenge && isHabitRoutine(habit)) ||
            isStartingRoutine ? (
              <Field<Date>
                name="routineStartTime"
                isJoiningChallenge={isJoiningChallenge}
                render={props => <RoutineStartTimeField {...props} />}
              />
            ) : null}
            {!isHabitChallenge && (
              <Field<Habit["isChallenge"]>
                name="isChallenge"
                isEditable={isEditable}
                render={props => <ChallengeField {...props} />}
              />
            )}
            <Watch
              name="sharing"
              render={() => (
                <Field<Habit["visibility"]>
                  name="visibility"
                  shareOnlyMe={props.form.mutators.shareOnlyMe}
                  render={props => <VisibilityField {...props} />}
                />
              )}
            />
            <Condition when="visibility" is={Visibility.SELECT_FRIENDS}>
              <Field<Habit["sharing"]>
                name="sharing"
                render={({ input }) => {
                  // sharing for ID can be true or false, so need to check if
                  // value is true gave ID a truthy value because of some
                  // limitation on Firestore queries
                  const shareCount = Object.values(input.value).filter(f => f)
                    .length;

                  return (
                    <FriendShareSection
                      shareCount={shareCount}
                      sharing={input.value}
                      onToggle={(key, value) => {
                        const newVal = { ...input.value, [key]: value };
                        input.onChange(newVal);
                      }}
                      friendsArray={friends}
                    />
                  );
                }}
              />
            </Condition>
          </View>
        );
      }}
    />
  );
};
