import moment from "moment";
import React, { useState, useEffect } from "react";
import { Text, StyleSheet, ScrollView, TouchableOpacity } from "react-native";
import { MaterialIcons } from "@expo/vector-icons";
import { Modal, Portal } from "react-native-paper";

import { Note } from "types/habits";
import { ContentCenteredView } from "components/UI/ContentCenteredView";
import { Instructions, Graph } from "./Graph";

const SEPARATOR = "-";

export const NotesGraphs = React.memo(
  ({ notes, ownHabit }: { notes: Note[]; ownHabit: boolean }) => {
    const [graphData, setGraphData] = useState<{
      units: string[];
      data: {
        [unit: string]: {
          date: Date;
          [value: string]: any;
        }[];
      };
    }>();
    const [height, setHeight] = useState(0);
    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
      if (notes) {
        const data = {};
        const graphUnits = [];
        // Iterate backwards to rely on first valid note for units
        const endIndex = notes.length - 1;

        /**
         * Product Behavior:
         * 1) support multiple graphs for a given set of notes and
         * 2) make it easy to create graphs by allowing users to start creating a graph with existing invalid data
         *       (original implementation required valid data beginning from the first note). Multiple graphs don't
         *  need to start on the same note.
         *
         * Engineering Implementation:
         * Create a boolean array of length 7 that tracks if first valid data point in jth graph
         * (at jth index of array) has been found or not. Initially, all values are set to false. Unit of graph is
         * only taken in once first valid note is found
         * Choice of length 7 is an assumption that users woulnd't create more than 7 graphs per habit. Helps prevent
         * iteration over all notes to find largestNumberOfLinesInNotes
         */
        const foundFirstDataPoint = Array(7).fill(false);

        for (let i = endIndex; i >= 0; i--) {
          const n = notes[i];
          const lines = n.note.split("\n");
          if (lines.length > 0) {
            const noteData = {};
            for (let j = 0; j < lines.length; j++) {
              const line = lines[j];

              // Lines starting with "- " are skipped (negative numbers can be inputted by avoiding space)
              if (line === SEPARATOR || line.startsWith(`${SEPARATOR} `)) {
                continue;
              }

              const tokens = line.split(" ");

              // removes and returns first element (the data-point) from array
              const first = tokens.shift();
              const value = parseFloat(first.replace(/,/g, ""));
              const unit = tokens.length > 0 ? tokens.join(" ") : null;

              // Skip line if empty
              if (first.length === 0) {
                continue;
              }

              // Skip line if non-number
              if (isNaN(value)) {
                continue;
              }

              // Data point valid at this stage
              // Change boolean value and set graph units to this first valid data point
              if (!foundFirstDataPoint[j]) {
                foundFirstDataPoint[j] = true;
                graphUnits.push(unit === null ? `graph ${j + 1}` : unit);
                data[unit === null ? `graph ${j + 1}` : unit] = [];
              }

              noteData[graphUnits[j]] = value;
            }

            const date = moment(n.date, "MM-DD-YYY").toDate();

            Object.entries(noteData).forEach(([unit, value]) => {
              data[unit].push({
                date,
                [unit]: value,
              });
            });
          }
        }

        setGraphData({
          units: graphUnits,
          data,
        });
      }
    }, [notes]);

    if (
      graphData &&
      "data" in graphData &&
      Object.keys(graphData.data).length > 0 &&
      notes.length > 0
    ) {
      return (
        <ScrollView
          // Must manually set dimensions for graph
          // https://github.com/FormidableLabs/victory-native/issues/58
          onLayout={({ nativeEvent }) => {
            const { height } = nativeEvent.layout;
            setHeight(height);
          }}
          showsVerticalScrollIndicator={false}
          style={styles.container}
        >
          {ownHabit && (
            <>
              <Portal>
                <Modal
                  visible={isModalVisible}
                  onDismiss={() => setIsModalVisible(false)}
                  dismissable
                  contentContainerStyle={styles.modalContent}
                >
                  <ScrollView>
                    <Instructions />
                  </ScrollView>
                </Modal>
              </Portal>
              <TouchableOpacity
                onPress={() => setIsModalVisible(true)}
                style={styles.infoIconContainer}
              >
                <MaterialIcons name="info-outline" size={20} />
              </TouchableOpacity>
            </>
          )}
          {Object.entries(graphData.data).map(([unit, data]) => {
            return <Graph height={height} unit={unit} data={data} key={unit} />;
          })}
        </ScrollView>
      );
    }

    return (
      <ScrollView showsVerticalScrollIndicator={false} style={styles.container}>
        {(notes.length === 0 ||
          (graphData && Object.keys(graphData.data).length === 0)) &&
          ownHabit && <Instructions />}
        {(!graphData ||
          (graphData &&
            (!("data" in graphData) ||
              Object.keys(graphData.data).length === 0))) &&
          !ownHabit && (
            <ContentCenteredView>
              <Text style={{ margin: 15 }}>No graphs created!</Text>
            </ContentCenteredView>
          )}
      </ScrollView>
    );
  }
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    margin: 4,
  },
  bold: {
    fontWeight: "bold",
  },
  infoIconContainer: {
    position: "absolute",
    right: 10,
    top: 10,
    zIndex: 2,
  },
  modalContent: {
    width: "80%",
    height: "85%",
    maxWidth: 400,
    maxHeight: 750,
    padding: 10,
    alignSelf: "center",
    backgroundColor: "white",
  },
});
