import React from "react";
import { PanelRight } from "panels/subpanels/PanelRight";
import { ContactsListScreen } from "screens/Contacts/ContactsListScreen";

// TODO - refactor ContactsListScreen so that we can use react-router vs react-navigation
export const AddFriendPanel = () => {
  return (
    <PanelRight>
      <ContactsListScreen />
    </PanelRight>
  );
};
