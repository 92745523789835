import { createSelector } from "@reduxjs/toolkit";
import { isLoaded } from "react-redux-firebase";
import { useSelector } from "redux/store";

const otherDocsLoadingSelector = createSelector(
  state => state?.firestore.data.habits,
  state => state?.firestore.data.days,
  state => state?.firestore.data.friends,
  state => state?.firestore.data.notes,
  state => state?.firestore.data.friendHabits,
  state => state?.firestore.data.challenges,
  (...args) => isLoaded(args)
);

export const useGetIsAllDataLoaded = () => {
  return useSelector(otherDocsLoadingSelector, (left, right) => left === right);
};
