import React from "react";
import { RouteProp } from "@react-navigation/native";

import { HabitChallenge } from "types/habits";
import { StackNavigationProp } from "@react-navigation/stack";
import { StackProps } from "layouts/MobileLayout";
import { AddHabitLayout } from "components";
import { useGetRoutine } from "redux/selectors";

export const AddHabitScreen = ({
  route,
}: {
  navigation: StackNavigationProp<StackProps, "AddHabitScreen">;
  route: RouteProp<StackProps, "AddHabitScreen">;
}) => {
  // If editing an existing habit
  const habit = route.params?.habit ?? null;
  const habitid = route.params?.habitid ?? null;
  // If joining a challenge
  const habitChallenge: HabitChallenge = route.params?.habitChallenge ?? null;
  const habitChallengeId: string = route.params?.habitChallengeId ?? null;

  // If joining a routine
  const routineId: string = route.params?.routineId ?? null;
  const routine = useGetRoutine(routineId);

  return (
    <AddHabitLayout
      habit={habit}
      habitid={habitid}
      habitChallenge={habitChallenge}
      habitChallengeId={habitChallengeId}
      routineId={routineId}
      routine={routine}
    />
  );
};
