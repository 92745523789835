import React from "react";
import { Channel as ChannelType } from "stream-chat";
import {
  Channel,
  MessageList,
  Thread,
  Window,
  TypingIndicator,
} from "stream-chat-react";
import { SafeAreaView } from "react-native-safe-area-context";

import "./ChatMessageList.css";
import { KMessage } from "components/Chat/KMessage";
import { KMessageInput } from "components/Chat/KMessageInput";
import { ALLOWED_MESSAGE_ACTIONS } from "utils/constants";

export const ChatMessageList = ({ channel }: { channel: ChannelType }) => {
  // TODO - handle thread properly
  return (
    <SafeAreaView style={{ flex: 1 }} edges={["right", "bottom", "left"]}>
      <Channel channel={channel}>
        <Window hideOnThread>
          <MessageList
            TypingIndicator={TypingIndicator}
            Message={KMessage}
            messageActions={ALLOWED_MESSAGE_ACTIONS}
          />
          <KMessageInput channel={channel} />
        </Window>
        <Thread
          fullWidth
          Message={KMessage}
          MessageInput={KMessageInput}
          additionalMessageInputProps={{ channel }}
          additionalMessageListProps={{
            messageActions: ALLOWED_MESSAGE_ACTIONS,
            TypingIndicator,
          }}
        />
      </Channel>
    </SafeAreaView>
  );
};
