//! Keep in sync with types/habits.ts in routines folder!!

import { ImageInfo } from "expo-image-picker/build/ImagePicker.types";
import moment from "moment";
import { Friend } from "types/friends";

export enum HabitType {
  Challenge = "CHALLENGE",
  ChallengeParticipant = "CHALLENGE_PARTICIPANT",
  Routine = "ROUTINE",
  // Default Habits
  FaceTouching = "FACE_TOUCHING",
  WALK = "WALK",
  CHECK_IN = "CHECK_IN",
}

const DEFAULT_HABIT_TYPES = [
  HabitType.FaceTouching,
  HabitType.WALK,
  HabitType.CHECK_IN,
];

export enum Visibility {
  FRIENDS = "FRIENDS",
  SELECT_FRIENDS = "SELECT_FRIENDS",
  ONLY_ME = "ONLY_ME",
}

//! These values are stored in the database
//! and compared against so don't change them
//! or we have to do a database migration
export enum FrequencyType {
  DAILY = "Daily",
  SCHEDULED = "Scheduled",
  WEEKLY = "Weekly",
}

export const isDefaultHabit = (h: Habit): boolean =>
  h &&
  h.type &&
  (Array.isArray(h.type)
    ? h.type.some(t => !!DEFAULT_HABIT_TYPES.find(type => type === t))
    : !!DEFAULT_HABIT_TYPES.find(type => type === h.type));

export const isAChallenge = (h: Habit): h is Challenge =>
  h && "challengers" in h;

export const isAChallengeWithMultipleParticipants = (
  h: Habit
): h is Challenge => {
  if (h && "challengers" in h) {
    const c = h as Challenge;
    return Object.keys(c.challengers).length > 1;
  }
  return false;
};

//This means the habit is master challenge habit eg. created by challenge owner
export const isHabitChallenge = (h: Habit): h is HabitChallenge =>
  h &&
  h.type &&
  (Array.isArray(h.type)
    ? h.type.some(t => t === HabitType.Challenge)
    : h.type === HabitType.Challenge);

export const isHabitChallengeParticipant = (
  h: Habit
): h is HabitChallengeParticipant =>
  h &&
  h.type &&
  (Array.isArray(h.type)
    ? h.type.some(t => t === HabitType.ChallengeParticipant)
    : h.type === HabitType.ChallengeParticipant);

export const isHabitRoutine = (h: Habit): h is HabitRoutine =>
  h &&
  h.type &&
  (Array.isArray(h.type)
    ? h.type.some(t => t === HabitType.Routine)
    : h.type === HabitType.Routine);

export type Habit = {
  id?: string;
  uid: string;
  emoji: string;
  title: string;
  createdAt: firebase.firestore.Timestamp;
  description: string;
  streak: number;
  sharing: {
    [uid: string]: boolean;
  };
  lastDayUpdate: number;
  frequencyType?: FrequencyType;
  frequency?: 1 | 2 | 3 | 4 | 5 | 6 | 7;
  schedule?: [boolean, boolean, boolean, boolean, boolean, boolean, boolean];
  visibility: Visibility;
  // defaultShare?: boolean; // DEPRECATED Whether to share by default with all friends
  isArchived: boolean;
  // isChallenge is TRUE if it is a HabitChallenge vs. just a HabitChallengeParticipant,
  // unless that habit itself is a challenge, which isn't currently supported.
  isChallenge?: boolean;
  hasReminder?: boolean;
  // e.g. reminder = { time: "14:05"; offset: 330 }
  reminder?: {
    time: string; // time->UTC "HH:mm"; stored as string to allow comparison (can't compare only HH:mm of two Firestore timestamps)
    offset: number; // UTC offset; used to find local day of week for user in case habit is scheduled
  };
  type?: HabitType | HabitType[];
};

export type FormHabitData = Required<
  Pick<
    Habit,
    | "title"
    | "emoji"
    | "description"
    | "sharing"
    | "visibility"
    | "frequencyType"
    | "frequency"
    | "schedule"
    | "isChallenge"
    | "hasReminder"
    // FormHabitData stores 'reminder' as Date object received from TimePicker (the input field)
    // 'reminder' is then converted to an object consisting of time ("HH:mm") and offset while saving habit
  > & { reminder: Date; routineStartTime?: Date }
>;

export type AdditionalSaveHabitData = {
  habitid?: string;
  uid: string;
  friends: Friend[];
};

export type SaveHabitData = FormHabitData & AdditionalSaveHabitData;

export interface Challenge extends Habit {
  challengers: {
    [uid: string]: boolean;
  };
}

export interface HabitChallenge extends Challenge {
  type: HabitType.Challenge;
  isChallenge: true;
  //! Not currently used. Could be used if we did invites (expired field in challengerInfo)
  challengeStartTime?: firebase.firestore.Timestamp;
  challengerInfo: {
    [uid: string]:
      | {
          name: string;
          email: string;
          photoURL?: string;
          habitid?: string; // this field should not be present if isOwner is true
          isOwner?: boolean;
        }
      | {
          name: string;
          email: string;
          photoURL?: string;
          expires: firebase.firestore.Timestamp;
        };
  };
  isLinkInviteEnabled?: boolean;
  joinCode?: string;
}

export interface HabitChallengeParticipant extends Challenge {
  type: [HabitType.ChallengeParticipant, ...HabitType[]];
  challengeOwnerUid: string;
  challengeHabitId: string;
}

export interface HabitRoutine extends Habit {
  routineId: string; // the doc ID of the routine this habit is based on (NOT the routine's uid, the unique identifier for the routine on the data loading side)
  startTime: firebase.firestore.Timestamp; // When this routine should start
  shareProgressWithOwner: boolean; // If true, sharing will have routineOwnerUid = true
}

export enum DayStatus {
  SKIP = 0,
  SUCCESS = 1,
  FAIL = 2,
}

export type Day = {
  id?: string;
  uid: string;
  habitid: string; //doc id for Habit
  date: string; // DD-MM-YYYY
  absoluteDay: number; // Number of this day counting from 01-01-0000
  status: DayStatus;
  lastUpdated: firebase.firestore.Timestamp;
  type?: string;
};

export type DayUpdateParams = Pick<Day, "habitid" | "id" | "date"> &
  Pick<DayInfo, "absoluteDay">;

export type Note = {
  id?: string;
  docid?: string;
  uid: string;
  habitid: string;
  note: string;
  isPublic: boolean;
  date: string;
  absoluteDay: number;
  media: NoteMedia[];
};

export type NoteMedia = {
  lastUpdated: number;
  url: string;
} & ImageInfo;

export type HabitUpdates = {
  owner: string;
  habitId: string;
  updates: {
    [id: string]: Day;
  };
};

export type DayInfo = {
  display: string;
  date: string;
  absoluteDay: number;
  dayOfWeek: number;
  isGrid?: boolean;
  isToday?: boolean;
  month?: string;
  disabled?: boolean; // Set if a day is out of month
  outOfMonth?: boolean;
  dayOfWeekString?: string;
  moment?: moment.Moment;
};

export type RenderWeek = DayInfo[];

export type DayMap = {
  [date: string]: Day;
};

export type NoteMap = {
  [date: string]: Note;
};

export type HabitDayMap = {
  [habitid: string]: DayMap;
};
