// @refresh reset
import { RouteProp } from "@react-navigation/native";
import React, { useCallback } from "react";
import { StackProps, MainNavigation } from "layouts/MobileLayout";

import { ChatMessageList } from "components/Chat/ChatMessageList";
import { Loading } from "components";
import { useChatChannel } from "hooks/chat/useChatChannel";

export const ChatScreen = ({
  navigation,
  route,
}: {
  navigation: MainNavigation;
  route: RouteProp<StackProps, "ChatScreen">;
}) => {
  const channel = useChatChannel(route.params);

  const onThreadSelect = useCallback(
    thread => {
      navigation.navigate("ChatThreadScreen", {
        thread,
        title: route.params.title,
        channelId: channel.id,
      });
    },
    [channel, navigation, route.params]
  );

  if (!channel) {
    return <Loading />;
  }

  return <ChatMessageList channel={channel} onThreadSelect={onThreadSelect} />;
};
