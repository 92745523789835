import React, { useEffect, useLayoutEffect, useState } from "react";
import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { Appbar, useTheme } from "react-native-paper";

import { StackProps } from "layouts/MobileLayout";

import { useGetFriend } from "redux/selectors/useGetFriends";
import { firstName } from "utils/strings";
import { NoteView } from "components/Notes/NoteView";

export const EditDayNoteScreen = ({
  navigation,
  route,
}: {
  navigation: StackNavigationProp<StackProps, "EditDayNoteScreen">;
  route: RouteProp<StackProps, "EditDayNoteScreen">;
}) => {
  const {
    dayid,
    habitid,
    date,
    friendid,
    ownHabit,
    editable,
  }: {
    dayid: string;
    habitid: string;
    date: string;
    friendid?: string;
    ownHabit?: boolean;
    editable?: boolean;
  } = route.params;

  const theme = useTheme();
  const { friend } = useGetFriend(friendid);
  const [isEditable, setEditable] = useState<boolean>(editable ?? false);

  // Set Screen Header Title
  useEffect(() => {
    if (!ownHabit && friend) {
      navigation.setParams({
        title: friend.name
          ? `${firstName(friend.name)}'s note`
          : "Friend's note",
      });
    }
  }, [friend, navigation, ownHabit]);

  useLayoutEffect(() => {
    if (ownHabit && !isEditable) {
      navigation.setOptions({
        headerRight: () => (
          <Appbar.Action
            icon="pencil"
            color={theme.colors.accent}
            onPress={() => setEditable(true)}
          />
        ),
      });
    }
  }, [ownHabit, isEditable, navigation, theme]);

  return (
    <NoteView
      dayid={dayid}
      habitid={habitid}
      date={date}
      ownHabit={ownHabit}
      isEditable={isEditable}
    />
  );
};
