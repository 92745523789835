import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import Constants from "expo-constants";
import React from "react";
import { Text, StyleSheet, View, SafeAreaView } from "react-native";
import { IconButton } from "react-native-paper";

import {
  APP_ENV,
  API_HOST,
  envIsDevelopment,
  envIsBeta,
  DISPLAY_VERSION,
} from "utils/constants";
import { StackProps } from "layouts/MobileLayout";
import { useGetAuth } from "redux/selectors";
import { LogInView } from "components/Login/LoginView";

//! MAKE SURE TO SET UP https://console.firebase.google.com/u/0/project/[project]/authentication/users
export const LoginScreen = ({
  navigation,
  route,
}: {
  navigation: StackNavigationProp<StackProps, "LoginScreen">;
  route: RouteProp<StackProps, "LoginScreen">;
}) => {
  const { auth } = useGetAuth();
  const reason = route.params?.reason ?? null;

  return (
    <SafeAreaView style={styles.contentContainer}>
      {reason && (
        <IconButton
          icon="close"
          onPress={() =>
            navigation.navigate("Home", {
              screen: "Habits",
            })
          }
          style={styles.close}
        />
      )}
      <LogInView motivationReason={reason} />
      <View>
        {__DEV__ || envIsDevelopment() || envIsBeta() ? (
          <Text style={styles.text}>
            Version: {APP_ENV} |{" "}
            {!__DEV__ && `${Constants.manifest.revisionId} | `}
            {API_HOST} | UID: {auth ? auth.uid : "NONE"}
          </Text>
        ) : (
          reason === "Profile" && (
            <Text style={styles.text}>Build: {DISPLAY_VERSION}</Text>
          )
        )}
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  contentContainer: {
    flex: 1,
    margin: 20,
  },
  text: { margin: 20, textAlign: "center" },
  nonCenter: { margin: 10 },
  close: {
    alignSelf: "flex-end",
    zIndex: 10,
  },
  inner: {
    padding: 16,
    flex: 1,
    justifyContent: "flex-end",
  },
});
