import React from "react";
import { TouchableWithoutFeedback, View, Image, Linking } from "react-native";

import { appStoreIcon, playStoreIcon } from "assets/images";
import { isMobilePlatform } from "utils/helpers";

const APP_STORE_URL =
  "https://apps.apple.com/us/app/snaphabit-ai-healthy-habits/id1494552185";
const PLAY_STORE_URL =
  "https://play.google.com/store/apps/details?id=io.gravitech.habit.staging&hl=en_US";

export const BRANCH_LINK = "https://5cgdk.app.link/2Ov6Cpwf37";

export const GetTheApp = ({ containerStyle, iOSStyle, androidStyle }) => (
  <View style={containerStyle}>
    <TouchableWithoutFeedback
      onPress={() => {
        if (isMobilePlatform) {
          Linking.openURL(APP_STORE_URL);
        } else if (window) {
          window.parent.location.href = APP_STORE_URL;
        }
      }}
    >
      <Image source={{ uri: appStoreIcon }} style={iOSStyle} />
    </TouchableWithoutFeedback>
    <TouchableWithoutFeedback
      onPress={() => {
        if (isMobilePlatform) {
          Linking.openURL(PLAY_STORE_URL);
        } else if (window) {
          window.parent.location.href = PLAY_STORE_URL;
        }
      }}
    >
      <Image source={{ uri: playStoreIcon }} style={androidStyle} />
    </TouchableWithoutFeedback>
  </View>
);
