import { useSelector } from "redux/store";
import { isLoaded } from "react-redux-firebase";
import { useEffect, useState, useRef } from "react";

import { Logger } from "utils/Logger";

import { InteractionManager } from "react-native";
import difference from "lodash/difference";
import { HABIT_LAST_UPDATED_THRESHOLD } from "utils/constants";
import { getSharedDaysSlice, setHasLoadedOnce } from "redux/slices";
import { useDispatch } from "react-redux";

// This hook will try to get all shared days if they haven't
// been loaded already
export const useGetSharedDays = () => {
  const dispatch = useDispatch();
  const isSharedDaysLoaded = useSelector(
    state => state.sharedDays.hasLoadedOnce
  );
  const isLoading = useSelector(state => state.sharedDays.isLoading);
  const friendHabits = useSelector(
    state => state?.firestore?.data?.friendHabits
  );
  const challenges = useSelector(state => state?.firestore?.data?.challenges);

  const prevSharedHabitsIds = useRef<string[]>([]);
  const [needsSharedDaysFor, setNeedsSharedDaysFor] = useState<string[]>(
    undefined
  );

  useEffect(() => {
    const habitsLoaded = isLoaded(friendHabits, challenges);
    if (!habitsLoaded) {
      return;
    }

    const sharedHabits = { ...challenges, ...friendHabits };

    const eligibleHabitIds = Object.keys(sharedHabits).filter(
      habitid =>
        !sharedHabits[habitid]?.isArchived &&
        sharedHabits[habitid]?.lastDayUpdate > HABIT_LAST_UPDATED_THRESHOLD
    );

    const newHabitIds = difference(
      eligibleHabitIds,
      prevSharedHabitsIds.current
    );

    if (
      // already requested from a previous component using this hook
      (isSharedDaysLoaded && newHabitIds.length === eligibleHabitIds.length) ||
      newHabitIds.length === 0
    ) {
      setNeedsSharedDaysFor([]);
    } else {
      setNeedsSharedDaysFor(newHabitIds);
    }

    prevSharedHabitsIds.current = eligibleHabitIds;
  }, [friendHabits, challenges]);

  useEffect(() => {
    if (needsSharedDaysFor && needsSharedDaysFor.length > 0) {
      InteractionManager.runAfterInteractions(() => {
        Logger.log("Getting shared days for", needsSharedDaysFor);
        dispatch(getSharedDaysSlice(needsSharedDaysFor));
      });
    } else if (needsSharedDaysFor && needsSharedDaysFor.length === 0) {
      Logger.log("Don't need shared days");
      dispatch(setHasLoadedOnce());
    }
  }, [needsSharedDaysFor]);

  return { isSharedDaysLoaded, isLoading };
};
