import React from "react";
import { Clipboard } from "react-native";
import {
  SettingSection,
  TextSetting,
  OpenMoreSetting,
} from "components/SettingsItems";
import { useString } from "hooks";
import { APP_ENV, API_HOST } from "utils/constants";
import Constants from "expo-constants";
import { useGetAuth, useGetOrganization } from "redux/selectors";

export const DebugSection = () => {
  const s = useString("profileScreen");
  const { auth } = useGetAuth();
  const organization = useGetOrganization();

  return (
    <SettingSection label={s("debugInfo")}>
      <TextSetting label={s("env")} content={APP_ENV} />
      <OpenMoreSetting
        label={s("uid")}
        caption={auth.uid}
        onPress={() => {
          Clipboard.setString(auth.uid);
        }}
      />
      <TextSetting label={s("host")} content={API_HOST} />
      <TextSetting
        label={s("anonUser")}
        content={auth.isAnonymous ? "Yes" : "No"}
      />
      <TextSetting label={s("appName")} content={Constants.manifest.name} />
      <OpenMoreSetting
        label={s("clearAsyncStorage")}
        caption="Clears keys prefixed with @Habit"
        onPress={async () => {
          const AsyncStorage = require("@react-native-community/async-storage")
            .default;
          const keys = await AsyncStorage.getAllKeys();
          AsyncStorage.multiRemove(keys.filter(k => k.startsWith("@Habit")));
        }}
      />
      {organization ? (
        <>
          <TextSetting label="Organization" content={organization.name} />
          <OpenMoreSetting
            label="Organization ID - click to copy"
            caption={organization.id}
            onPress={() => {
              Clipboard.setString(organization.id);
            }}
          />
          <TextSetting
            label="UXDisableProfileLink"
            content={organization.UXDisableProfileLink ? "True" : "False"}
          />
          <TextSetting
            label="UXDisableFriendTab"
            content={organization.UXDisableFriendTab ? "True" : "False"}
          />
          <TextSetting
            label="UXDisableAddFriend"
            content={organization.UXDisableAddFriend ? "True" : "False"}
          />
        </>
      ) : (
        <TextSetting label="Organization" content="None" />
      )}
    </SettingSection>
  );
};
