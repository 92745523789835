export default {
  addFriend: {
    en: "Add friend",
    ja: "友達を追加",
    "zh-Hans": "添加好友",
    "zh-Hant": "添加好友",
  },
  listEmptyStartChat: {
    en: "Tap a friend above to start a chat or add a friend",
  },
  noMessage: {
    en: "No messages... fix that!",
    ja: "メッセージはありません...何かを送ってみて！",
    "zh-Hans": "没有信息... 发点什么吧！",
    "zh-Hant": "沒有信息... 發點什麼吧！",
  },
  noOneHere: {
    en: "Ain't no one here. Add someone!",
    ja: "誰もいない。追加して！",
    "zh-Hans": "这里谁也没有。添加好友！",
    "zh-Hant": "這裡誰也沒有。添加好友！",
  },
  note: {
    en: "Note",
    ja: "ノート",
    "zh-Hans": "备注",
    "zh-Hant": "備註",
  },
  sentImage: {
    en: "Sent an Image",
    ja: "画像",
    "zh-Hans": "图像",
    "zh-Hant": "圖像",
  },
  sentVideo: {
    en: "Sent a Video",
    ja: "動画",
    "zh-Hans": "视频",
    "zh-Hant": "視頻",
  },
  updatedAHabit: {
    en: "Updated a habit!",
    ja: "ハビットをアップデートした！",
    "zh-Hans": "习惯更新！",
    "zh-Hant": "習慣更新！",
  },
  you: {
    en: "You",
    ja: "あなた",
    "zh-Hans": "你",
    "zh-Hant": "你",
  },
};
