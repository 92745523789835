import React from "react";
import { StyleSheet, ViewStyle } from "react-native";
import { Card } from "react-native-paper";

export const KCard = ({
  children,
  style,
}: {
  children: JSX.Element | JSX.Element[];
  style?: ViewStyle;
}) => (
  <Card style={[styles.card, style]} elevation={2}>
    {children}
  </Card>
);

const styles = StyleSheet.create({
  card: {
    marginHorizontal: 20,
    marginVertical: 10,
    borderRadius: 8,
  },
});
