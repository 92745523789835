import React from "react";
import { StyleSheet, ScrollView, SafeAreaView } from "react-native";

import { Body, ToggleSetting } from "components";
import { useString } from "hooks";
import { useSelector } from "redux/store";
import { Habit } from "types/habits";
import { Logger } from "utils/Logger";
import firebase from "utils/firebase";
import { renderHabitTitle } from "utils/strings";

export const ArchiveScreen = () => {
  const s = useString("profileScreen");
  const habits: Habit[] =
    useSelector(state => state.firestore.ordered.habits) || [];

  return (
    <SafeAreaView style={styles.container}>
      <Body style={styles.text}>{s("toggleToArchive")}</Body>
      <ScrollView contentContainerStyle={styles.padding}>
        {habits.map(habit => (
          <ToggleSetting
            key={habit.id}
            label={renderHabitTitle(habit)}
            value={!habit.isArchived}
            onToggle={value => {
              firebase
                .firestore()
                .doc(`habits/${habit.id}`)
                .update({
                  isArchived: !value,
                })
                .catch(err => Logger.error(err));
            }}
          />
        ))}
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  text: {
    textAlign: "center",
    marginVertical: 30,
  },
  padding: {
    paddingBottom: 30,
  },
});
