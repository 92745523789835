import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "utils/react-router";

import { JoinChallengeLayout } from "components/Invite/JoinChallengeLayout";

import axios from "utils/axios";
import { API_HOST } from "utils/constants";
import { useGetAuth, useGetHabits } from "redux/selectors";
import { AxiosResponse } from "axios";
import { JoinChallengeResponse } from "types/challenge";
import { isHabitChallengeParticipant, Visibility } from "types/habits";
import { logJoinGroupHabit } from "utils/analytics";

export const JoinChallengeScreen = () => {
  const { challengeHabitId } = useParams();
  const { auth, profile } = useGetAuth();
  const { isSignedUp } = profile;
  const habits = useGetHabits();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [challengeInfo, setChallengeInfo] = useState<JoinChallengeResponse>();
  const [userJoinedChallenge, setUserJoinedChallenge] = useState(false);

  useEffect(() => {
    if (challengeHabitId) {
      setUserJoinedChallenge(
        habits.some(
          h =>
            isHabitChallengeParticipant(h) &&
            h.challengeHabitId === challengeHabitId
        )
      );
    }
  }, [challengeHabitId, habits]);

  useEffect(
    function getChallengeInfo() {
      setIsLoading(true);
      setError(null);

      axios
        .post(`${API_HOST}/getChallengeInfo`, { challengeHabitId })
        .then((res: AxiosResponse<JoinChallengeResponse>) => {
          setChallengeInfo(res.data);
        })
        .catch(error => setError(error))
        .finally(() => setIsLoading(false));
    },
    // userJoinedChallenge -- if user joins, re-request so that number participants
    // updates
    [challengeHabitId, userJoinedChallenge]
  );

  const resetPage = useCallback(() => {
    setError(false);
  }, []);

  const data = {
    isLoading,
    error,
    challengeInfo,
    auth,
    isSignedUp,
    userJoinedChallenge,
  };

  const actions = {
    joinChallenge: async () => {
      setIsLoading(true);
      try {
        await axios.post(`${API_HOST}/joinChallenge`, {
          habitid: challengeHabitId,
          owner: challengeInfo.ownerUid,
          sharing: {},
          visibility: Visibility.FRIENDS,
        });
        logJoinGroupHabit(
          { habitid: challengeHabitId, owner: challengeInfo.ownerUid },
          "GroupHabitLink"
        );
      } catch (error) {
        console.log(error.response);
        if (error?.response?.data?.message) {
          setError(Error(error.response.data.message));
        } else {
          setError(error);
        }
      } finally {
        setIsLoading(false);
      }
    },
    resetPage,
  };

  return <JoinChallengeLayout {...data} {...actions} />;
};
