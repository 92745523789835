import { StackNavigationProp } from "@react-navigation/stack";
import { useCallback } from "react";
import { Alert } from "react-native";

import axios from "utils/axios";
import { API_HOST } from "utils/constants";
import { StackProps } from "layouts/MobileLayout";
import { useGetHabits } from "redux/selectors";
import { useLayoutContext, Layouts } from "contexts";
import { Visibility } from "types/habits";
import { logFriendInviteSent, logFriendInviteError } from "utils/analytics";

const AsyncAlert = async alertText =>
  new Promise(resolve => {
    Alert.alert(
      alertText,
      "",
      [
        {
          text: "Yes",
          onPress: () => {
            resolve(true);
          },
        },
        {
          text: "No",
          onPress: () => {
            resolve(false);
          },
        },
      ],
      { cancelable: false }
    );
  });

export const useAddFriend = (
  navigation: StackNavigationProp<StackProps>,
  route,
  dispatch
) => {
  const habits = useGetHabits();
  const layout = useLayoutContext();
  const addFriend = useCallback(
    async (
      body:
        | { id: string; name: string; email?: undefined }
        | { email: string; name?: undefined },
      payload: { id: string }
    ) => {
      const toggleSharing = route?.params?.toggleSharing ?? null; // passed from AddHabitScreen
      const habitsToBeShared = habits.some(
        h => h.visibility === Visibility.FRIENDS
      );

      //has a habit, and has no default shared habits
      const userHasEligibleHabitsButNoDefaultShared =
        habits.length > 0 && !habitsToBeShared;

      const shouldPromptToShare =
        userHasEligibleHabitsButNoDefaultShared && toggleSharing;

      dispatch({ type: "LOADING", payload });
      axios
        .post(`${API_HOST}/addFriend`, body)
        .then(async res => {
          dispatch({ type: "DONE_LOADING", payload });
          logFriendInviteSent();
          if (toggleSharing) {
            //set sharing to true for this user
            toggleSharing(res.data.friendid, true);
          }

          const name = (body?.name || body?.email) ?? "";

          if (layout === Layouts.MOBILE) {
            const wantsToShare = shouldPromptToShare
              ? await AsyncAlert(
                  `Would you like to share any habits ${
                    name ? `with ${name}` : ""
                  }?`
                )
              : false;

            if (wantsToShare) {
              navigation.navigate("SharedHabitsToggleScreen", {
                friendid: res.data.friendid,
                title: body.email,
              });
            }
          }
        })
        .catch(error => {
          logFriendInviteError(body, error);
          return dispatch({
            type: "ERROR",
            payload: { ...payload, error },
          });
        });
    },
    [navigation, route, dispatch, layout, habits]
  );

  return addFriend;
};
