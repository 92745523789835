import React, { useEffect } from "react";
import { WebsitePreview, HostedMedia, RoutineContent } from "types/routines";
import { useDimensionsContext } from "contexts";
import { TouchableOpacity, FlatList } from "react-native-gesture-handler";
import { OrientationLock } from "expo-screen-orientation";

import { KCard } from "components";
import { Card } from "react-native-paper";
import { Image, StyleSheet, Text, View, Platform } from "react-native";
import * as WebBrowser from "expo-web-browser";
import { isMobilePlatform } from "utils/helpers";
import { logTapRoutineContent } from "utils/analytics";
import { resetScreenOrientation, setScreenOrientation } from "utils/appStyles";

const isWebsitePreview = (item): item is WebsitePreview => "title" in item;

const isHostedMedia = (item): item is HostedMedia => !isWebsitePreview(item);

const styles = StyleSheet.create({
  cover: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  publisherBlock: {
    marginTop: 24,
    marginBottom: 0,
    flexDirection: "row",
    alignItems: "center",
  },
  publisherIcon: {
    height: 20,
    width: 20,
    resizeMode: "contain",
    marginRight: 4,
  },
});

const MediaItem = ({
  imageUrl,
  title,
  description,
  publisherBlock,
  onPress,
}: {
  imageUrl: string;
  title: string;
  description?: string;
  publisherBlock?: {
    logo: string;
    publisher: string;
  };
  onPress: () => void;
}) => {
  const { width, getScaledFontSize } = useDimensionsContext();
  return (
    <TouchableOpacity onPress={onPress}>
      <KCard
        style={{
          width: width * 0.85,
          marginLeft: 20,
          marginRight: 5,
          marginHorizontal: 0, // Override KCard
          //? Better way to make cards all the same height?
          height: Platform.OS === "web" ? "100%" : null,
        }}
      >
        <Card.Cover source={{ uri: imageUrl }} style={styles.cover} />
        <Card.Content style={{ height: Platform.OS !== "web" && width * 0.4 }}>
          <View style={{ flex: 1 }}>
            <Text
              style={{ fontSize: getScaledFontSize(1.2), marginVertical: 12 }}
              numberOfLines={2}
            >
              {title}
            </Text>
            <Text numberOfLines={2}>{description}</Text>
          </View>
          {!!publisherBlock && (
            <View style={styles.publisherBlock}>
              <Image
                source={{ uri: publisherBlock.logo }}
                style={styles.publisherIcon}
              />
              <Text>{publisherBlock.publisher}</Text>
            </View>
          )}
        </Card.Content>
      </KCard>
    </TouchableOpacity>
  );
};

const keyExtractor = item => item.url || item.id;

export const MediaCarousel = ({
  media,
  urls,
}: {
  media: RoutineContent["media"];
  urls: RoutineContent["urls"];
}) => {
  useEffect(() => {
    // Allow all orientations
    setScreenOrientation(OrientationLock.DEFAULT);

    return resetScreenOrientation;
  }, []);

  const data = [...urls, ...media];

  const renderItem = ({ item }) => {
    let props;

    if (isWebsitePreview(item)) {
      props = {
        imageUrl: item.image ? item.image.url : item.screenshot.url,
        title: item.title,
        description: item.description,
        publisherBlock: {
          logo: item.logo.url,
          publisher: item.publisher,
        },
        onPress: () => {
          logTapRoutineContent("media", item.url);
          WebBrowser.openBrowserAsync(item.url);
        },
      };
    } else if (isHostedMedia(item)) {
      props = {
        imageUrl: item?.thumbnails?.large?.url,
        title: item.filename,
        onPress: () => {},
      };
    }

    return <MediaItem {...props} />;
  };

  if (data.length === 1) {
    return (
      // marginRight offsets the marginLeft 20 for the card
      <View style={{ alignItems: "center", marginRight: 15 }}>
        {renderItem({ item: data[0] })}
      </View>
    );
  }

  return (
    <FlatList
      data={data}
      horizontal
      showsHorizontalScrollIndicator={!isMobilePlatform}
      keyExtractor={keyExtractor}
      renderItem={renderItem}
      // Add margin to the end
      ListFooterComponent={<View style={{ width: 20 }} />}
    />
  );
};
