import { Foundation } from "@expo/vector-icons";
import React, { ComponentType } from "react";
import { View, ViewStyle, StyleProp, StyleSheet } from "react-native";
import { Avatar } from "react-native-paper";
import { COLORS } from "../../utils/appStyles";

const styles = StyleSheet.create({
  icon: {
    position: "absolute",
    bottom: -5,
    right: -5,
  },
});

export const CoachIcon = () => (
  <Foundation name="sheriff-badge" size={20} color={COLORS.active} />
);

export const KAvatar = ({
  source,
  size,
  Icon,
  style,
  avatarStyle,
  iconContainerStyle,
  iconName, // If want to render icon instead of avatar
  iconColor,
}: {
  source;
  size;
  Icon?: ComponentType<StyleProp<ViewStyle>>;
  style?: StyleProp<ViewStyle>;
  avatarStyle?: StyleProp<ViewStyle>;
  iconContainerStyle?: StyleProp<ViewStyle>;
  iconName?: string;
  iconColor?: string;
}) => (
  <View style={[{ width: size, height: size }, style]}>
    {iconName ? (
      <Avatar.Icon
        size={size}
        icon={iconName}
        style={avatarStyle}
        color={iconColor}
      />
    ) : (
      <Avatar.Image size={size} source={source} style={avatarStyle} />
    )}
    {Icon && (
      <View style={[styles.icon, iconContainerStyle]}>
        <Icon />
      </View>
    )}
  </View>
);
