import moment from "moment";

import { Day, DayStatus, Habit } from "types/habits";
import { Logger } from "./Logger";
import { getAbsoluteDays } from "./time";

const getPreviousDayOfWeek = (current: number) => (current - 1 + 7) % 7;

const getNextEligibleDay = (
  currentAbsoluteDay: number,
  currentDayOfWeek: number,
  schedule: Habit["schedule"]
) => {
  let nextAbsoluteDay = currentAbsoluteDay - 1;
  let nextDayOfWeek = getPreviousDayOfWeek(currentDayOfWeek);
  if (schedule) {
    for (let i = 0; i < 7; i++) {
      if (schedule[nextDayOfWeek]) {
        break;
      }
      nextAbsoluteDay--;
      nextDayOfWeek = getPreviousDayOfWeek(nextDayOfWeek);
    }
  }

  return {
    nextAbsoluteDay,
    nextDayOfWeek,
  };
};

export const generateStreaks = (
  oldDays: Day[],
  newDays: Day[],
  habits: Habit[]
) => {
  const changedHabitIds = new Set<string>();

  for (const key in newDays) {
    if (
      oldDays?.[key] === newDays[key] ||
      oldDays?.[key]?.status === newDays[key]?.status
    ) {
      continue;
    } else if (newDays[key] && newDays[key].habitid) {
      changedHabitIds.add(newDays[key].habitid);
    }
  }

  Logger.log("Streak calculator, observed changed habitIds", changedHabitIds);

  const changes = Array.from(changedHabitIds);
  const updates = {};
  for (let j = 0; j < changes.length; j++) {
    const habitid = changes[j];
    const habit = habits?.[habitid] || null; // If habits is empty...
    if (!habit) {
      Logger.log(
        `Day updated but no habit found, habitid: ${habitid}. Habit probably deleted`
      );
      continue;
    } else {
      const habitDays = Object.values(newDays)
        .filter((d: Day) => d?.habitid === habitid)
        .sort((a: Day, b: Day) => b.absoluteDay - a.absoluteDay);
      const now = moment();

      const todayAbsoluteDay = getAbsoluteDays(now);
      const todayDayOfWeek = now.day();
      let streak = 0;
      const { nextAbsoluteDay, nextDayOfWeek } = getNextEligibleDay(
        todayAbsoluteDay,
        todayDayOfWeek,
        habit.schedule
      );
      let currentAbsoluteDay = todayAbsoluteDay;
      let currentDayOfWeek = todayDayOfWeek;
      let firstValidDay = 0;

      Logger.log("Starting streak calculation for", habitid);
      Logger.log("Habit Days are", habitDays);
      for (let i = 0; i < habitDays.length; i++) {
        const { status, absoluteDay } = habitDays[i] as Day;
        Logger.log(
          "Looking at day",
          habitDays[i],
          "\ncurrentAbsoluteDay",
          currentAbsoluteDay,
          "\ncurrentDayOfWeek",
          currentDayOfWeek,
          "\nstreak is at",
          streak
        );

        // In the case that someone is editing into the future
        // iterate until we get to valid start day
        if (absoluteDay > currentAbsoluteDay) {
          firstValidDay++;
          continue;
        }
        // The first eligible start day of the streak is either today
        // or the next non-scheduled day
        if (i === firstValidDay) {
          if (absoluteDay === currentAbsoluteDay && status !== DayStatus.FAIL) {
            Logger.log("Case 1");
            if (status === DayStatus.SUCCESS) {
              streak++;
            }
            const { nextAbsoluteDay, nextDayOfWeek } = getNextEligibleDay(
              currentAbsoluteDay,
              currentDayOfWeek,
              habit.schedule
            );
            currentAbsoluteDay = nextAbsoluteDay;
            currentDayOfWeek = nextDayOfWeek;
            continue;
          } else if (
            status === DayStatus.SUCCESS &&
            absoluteDay === nextAbsoluteDay
          ) {
            Logger.log("Case 2");
            streak++;
            const {
              nextAbsoluteDay: nextNextAbsoluteDay,
              nextDayOfWeek: nextNextDayOfWeek,
            } = getNextEligibleDay(
              nextAbsoluteDay,
              nextDayOfWeek,
              habit.schedule
            );
            currentAbsoluteDay = nextNextAbsoluteDay;
            currentDayOfWeek = nextNextDayOfWeek;
            continue;
          }
          // No start of streak found
          Logger.log("Case 3 - No start of streak found");
          break;
        }
        if (
          status === DayStatus.SUCCESS &&
          absoluteDay === currentAbsoluteDay
        ) {
          streak++;
          const { nextAbsoluteDay, nextDayOfWeek } = getNextEligibleDay(
            currentAbsoluteDay,
            currentDayOfWeek,
            habit.schedule
          );
          currentAbsoluteDay = nextAbsoluteDay;
          currentDayOfWeek = nextDayOfWeek;
          continue;
        } else {
          break;
        }
      }
      Logger.log("Streak is", streak);
      updates[habitid] = streak;
    }
  }

  return updates;
};
