const logoBlue = "#1C65A0";
const logoGreen = "#ACC68E";

export const COLORS = {
  GRAY1: "#F4F4F9",
  GRAY3: "#BABABA",
  GRAY4: "#888888",
  GRAY: "#333333",
  PRIMARY: logoGreen,
  SALMON: "#FA8072",
  ORANGE: "#FDAF17",
  BLUEBERRY: "#585764",
  WHITE: "#FFFFFF",
  PURPLE: "#735A78",

  // old
  error: "#de3704",
  disabled: "#CCCCCC",
  disabledDark: "#9E9E9E",
  active: "#4da2e8",
  challenge: "#7abaee",
  accent: logoGreen,
  success: "#388e3c",
  fail: "#ef5350",
  skip: "#F4F4F9",
  logoBlue,
  grayBox: "#F5F5F5", //https://material.io/design/color/#tools-for-picking-colors
  darkGray: "#616161",
};
