import React from "react";
import { StyleSheet, Platform, View } from "react-native";
import { FlatList } from "react-native-gesture-handler";
import { useDimensionsContext } from "contexts";
const styles = StyleSheet.create({
  center: { alignItems: "center" },
  carousel: {
    overflow: "visible",
  },
  // overflow visible does not work on Android, so need to increase container padding so that card bottom shows
  contentContainer: {
    paddingVertical: 4,
  },
});

export const CARD_WIDTH_PERCENTAGE = 0.85;

export const InviteCarousel = ({
  data,
  renderItem,
  keyExtractor,
  ListFooterComponent,
}) => {
  const { width } = useDimensionsContext();

  // If there is one card when this invite session started,
  // center the card.
  if (data.length === 1) {
    return <View style={styles.center}>{renderItem({ item: data[0] })}</View>;
  }

  return (
    <FlatList
      data={data}
      renderItem={renderItem}
      keyExtractor={keyExtractor}
      horizontal
      showsHorizontalScrollIndicator={Platform.OS === "web"}
      snapToInterval={width * CARD_WIDTH_PERCENTAGE}
      //! This only works in iOS
      snapToAlignment="center"
      decelerationRate="fast"
      contentInset={{
        // Centers the first and last elements
        //! This only works in iOS
        top: 0,
        left: (width * (1 - CARD_WIDTH_PERCENTAGE)) / 2,
        bottom: 0,
        right: (width * (1 - CARD_WIDTH_PERCENTAGE)) / 2,
      }}
      style={styles.carousel}
      contentContainerStyle={styles.contentContainer}
      ListFooterComponent={ListFooterComponent}
    />
  );
};
