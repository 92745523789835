import get from "lodash/get";
import React, { useState, useCallback } from "react";
import { StyleSheet, Alert } from "react-native";

import {
  Body,
  KButton,
  KTextInput,
  Loading,
  Title,
  InputFieldView,
} from "components";

import { signUp } from "utils/signUp";
import { Logger } from "utils/Logger";
import { NAME_REGEX } from "utils/strings";
import { useFirebase } from "react-redux-firebase";
import { useGetAuth } from "redux/selectors";
import { logAuthEnterName } from "utils/analytics";

export const SignupScreen = () => {
  const firebase = useFirebase();
  const { auth } = useGetAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState((auth && auth.displayName) || "");
  const onSubmit = useCallback(async () => {
    const displayName = name.trim();
    // Only allow alphabetical chars and spaces in name. Have not confirmed if
    // SendBird and other software can handle non-alpha chars for name
    if (!NAME_REGEX.test(displayName)) {
      Alert.alert(
        "Name can only include alphabetical characters 😔, please re-enter."
      );
      return;
    }

    try {
      setIsLoading(true);
      await signUp(firebase, displayName);
    } catch (error) {
      Logger.error(error);
    } finally {
      logAuthEnterName(displayName.length);
      setIsLoading(false);
    }
  }, [name, firebase]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <InputFieldView>
      <Title style={styles.text}>Welcome to SnapHabit!</Title>
      <Body style={styles.text}>
        {get(auth, "displayName")
          ? `Adjust your name if you'd like! `
          : `Add a name to continue. `}
        Last thing, we promise.
      </Body>
      <KTextInput
        label="Your name"
        placeholder="Sally Sue"
        value={name}
        onChangeText={text => setName(text)}
        onSubmitEditing={onSubmit}
        autoCapitalize="words"
      />
      <KButton
        label="Start my journey!"
        disabled={!name || name.trim().length < 3}
        onPress={onSubmit}
      />
    </InputFieldView>
  );
};

const styles = StyleSheet.create({
  text: { margin: 20, textAlign: "center" },
});
