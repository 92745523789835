import React, { useState, useRef } from "react";
import {
  View,
  StyleSheet,
  Alert,
  TextInput,
  ActivityIndicator,
} from "react-native";
import { IconButton } from "react-native-paper";
import { useFirebase } from "react-redux-firebase";

import { NAME_REGEX } from "utils/strings";
import { API_HOST } from "utils/constants";
import { Logger } from "utils/Logger";
import axios from "utils/axios";

import { useGetAuth } from "redux/selectors";
import { useEndpoint } from "hooks/useEndpoint";
import { COLORS } from "utils/appStyles";

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
    marginBottom: 30,
    alignSelf: "center",
  },
  name: {
    textAlign: "center",
    fontSize: 24,
    fontWeight: "bold",
  },
  editingName: {
    borderBottomWidth: 1,
  },
  indicator: {
    position: "absolute",
    right: -60,
  },
});

export const DisplayName = () => {
  const firebase = useFirebase();
  const { auth } = useGetAuth();
  const [name, setName] = useState<string>(auth.displayName);
  const [isEditingName, setIsEditingName] = useState(false);
  const inputRef = useRef();
  const { isLoading, makeCall } = useEndpoint(false);

  const handleChangeName = async () => {
    if (!NAME_REGEX.test(name)) {
      Alert.alert(
        "Name can only include alphabetical characters 😔, please re-enter."
      );
      // reset name back to original if new name invalid
      setName(auth.displayName);
      return;
    }

    await firebase.updateAuth({
      displayName: name,
    });
    makeCall(() =>
      axios.post(`${API_HOST}/updateProfile`, {
        name,
      })
    )
      .then(() => setIsEditingName(false))
      .catch(error => Logger.error(error));
  };

  let indicator;

  if (isLoading) {
    indicator = <ActivityIndicator size="small" />;
  } else if (isEditingName) {
    indicator = (
      <IconButton
        icon="check"
        onPress={() => handleChangeName()}
        color={COLORS.disabledDark}
      />
    );
  } else {
    indicator = (
      <IconButton
        icon="pencil"
        onPress={() => {
          setIsEditingName(true);
          if (inputRef.current) {
            // Doesn't seem to focus without timeout...
            setTimeout(() => inputRef.current.focus(), 200);
          }
        }}
        color={COLORS.disabledDark}
      />
    );
  }

  return (
    <View style={styles.container}>
      <TextInput
        ref={inputRef}
        style={[styles.name, isEditingName && styles.editingName]}
        onChangeText={text => setName(text)}
        value={name}
        onEndEditing={handleChangeName}
        returnKeyType="done"
        autoFocus
        editable={isEditingName}
      />
      <View style={styles.indicator}>{indicator}</View>
    </View>
  );
};
