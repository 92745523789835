import { Habit } from "types/habits";
import moment from "moment";

// Takes in reminder as a Date and returns reminder as an object {time: string, offset: number}
export const formatReminderObject = (
  reminder: Date,
  hasReminder: boolean
): Habit["reminder"] => {
  if (hasReminder) {
    const formattedReminder = {
      time: moment.utc(reminder).format("HH:mm"),
      offset: moment.parseZone(reminder).utcOffset(),
    };
    return formattedReminder;
  }

  return {
    time: "",
    offset: 0,
  };
};
