import React, { useMemo, useState, useCallback } from "react";
import { Ionicons } from "@expo/vector-icons";

import { useString } from "hooks";
import { useGetAuth, useGetDay, useGetFriend } from "redux/selectors";
import { getAbsoluteDays } from "utils/time";
import { updateDay } from "utils/habits";
import { DayStatus, HabitRoutine } from "types/habits";
import { StyleSheet, Text, TextInput, View, Keyboard } from "react-native";
import { NoteEditor, RenderNoteProps } from "components/Notes/NoteEditor";
import { RoutineContent, SelectedRoutineDay } from "types/routines";
import { SectionTitle } from "components/UI/Typography";
import { COLORS } from "utils/appStyles";
import { useDimensionsContext } from "contexts";
import { KButton } from "components/UI/KButton";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { StackProps } from "layouts/MobileLayout";
import { isMobilePlatform } from "utils/helpers";
import { logCompleteRoutineDay } from "utils/analytics";
import { RoutineShareModal } from "components/Routines/RoutineShareModal";
import { IconButton } from "react-native-paper";
import { TouchableOpacity } from "react-native-gesture-handler";
import { useDispatch } from "react-redux";
import {
  setBottomSheet,
  BottomSheetTypes,
  BottomSheetNoteProps,
} from "redux/slices";
import { SaveStateIndicator } from "components/Notes/SaveStateIndicator";
import { ImageSaver } from "components/Notes/ImageSaver";

const styles = StyleSheet.create({
  container: { marginHorizontal: 20 },
  input: {
    minHeight: 200,
    borderColor: COLORS.disabled,
    borderWidth: 1,
    borderRadius: 8,
    paddingTop: 12,
    paddingHorizontal: 12,
    textAlignVertical: "top",
  },
  bottomToolbar: {
    flexDirection: "row",
    alignItems: "center",
    position: "absolute",
    bottom: 8,
    right: 8,
  },
  buttonContainer: {
    marginVertical: 24,
  },
  button: {
    maxWidth: "70%",
    alignSelf: "center",
  },
  greatJob: {
    textAlign: "center",
    marginTop: 12,
  },
  flexEnd: {
    position: "absolute",
    right: 0,
  },
  juxtaposedButtonsContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
});

// isEditable is a proxy for ownHabit here
const NoteInput = ({
  isEditable,
  lastSaved,
  saveState,
  onChangeText,
  note,
  onFocus,
  notePlaceholder,
  noteBottomSheetProps,
  dayid, // Used to prevent race condition of two NoteEditors creating two days for the same date
  ...mediaProps
}: RenderNoteProps & {
  noteBottomSheetProps;
  notePlaceholder;
  onFocus;
  dayid;
}) => {
  const sr = useString("routine");
  const dispatch = useDispatch();
  const { getScaledFontSize, height } = useDimensionsContext();

  const expandNote = useCallback(
    () =>
      dispatch(
        setBottomSheet({
          type: BottomSheetTypes.NOTE,
          props: noteBottomSheetProps,
        })
      ),
    [dispatch, noteBottomSheetProps]
  );

  const { media } = mediaProps;

  return (
    <View>
      {!!media?.length && <ImageSaver {...mediaProps} isEditable={false} />}
      <TextInput
        style={[
          styles.input,
          {
            fontSize: getScaledFontSize(1.2),
            paddingBottom: getScaledFontSize(2),
            maxHeight: 0.35 * height,
          },
        ]}
        editable={isEditable}
        value={note}
        onChangeText={onChangeText}
        multiline
        onFocus={onFocus}
        placeholder={
          isEditable
            ? notePlaceholder || sr("reflectPlaceholder")
            : sr("reflectFriendPlaceholder")
        }
      />
      {isEditable && (
        <View style={styles.bottomToolbar}>
          <SaveStateIndicator saveState={saveState} lastSaved={lastSaved} />

          <TouchableOpacity onPress={expandNote} disabled={!dayid}>
            <Ionicons
              name="md-camera"
              color={COLORS.darkGray}
              size={getScaledFontSize(1.2)}
              style={{ marginHorizontal: 12 }}
            />
          </TouchableOpacity>
          <TouchableOpacity onPress={expandNote} disabled={!dayid}>
            <Ionicons
              name="md-expand"
              color={COLORS.darkGray}
              size={getScaledFontSize(1.2)}
              style={{ marginRight: 4 }}
            />
          </TouchableOpacity>
        </View>
      )}
    </View>
  );
};

const ShareButton = ({ style, onPress }) => (
  <IconButton style={style} icon="share" size={40} onPress={onPress} />
);

export const ReflectSection = ({
  habitid,
  habit,
  friendid,
  selectedRoutineDay,
  onFocus,
  notePlaceholder,
  photosForSharing,
}: {
  habitid: string;
  habit: HabitRoutine;
  friendid?: string;
  selectedRoutineDay: SelectedRoutineDay;
  onFocus: () => void;
  notePlaceholder?: string;
  photosForSharing: RoutineContent["photosForSharing"];
}) => {
  const s = useString("routine");
  const navigation = useNavigation<StackNavigationProp<StackProps>>();
  const { auth } = useGetAuth();
  const ownHabit = !friendid;
  const { friend } = useGetFriend(friendid);
  const [shouldShowShareModal, setShouldShowShareModal] = useState(false);

  // memoize these since it's used in the useEffect
  const { date, label, absoluteDay } = useMemo(() => {
    const { moment } = selectedRoutineDay.routineDays[selectedRoutineDay.index];

    return {
      date: moment.format("MM-DD-YYYY"),
      label: moment.format("MMM D"),
      absoluteDay: getAbsoluteDays(moment),
    };
  }, [selectedRoutineDay.index, selectedRoutineDay.routineDays]);

  const habitDay = useGetDay({
    date,
    ownHabit,
    habitid,
  });

  let completeDaySection = null;

  const canShare = ownHabit && photosForSharing?.length > 0;

  if (habitDay.status === DayStatus.SUCCESS) {
    completeDaySection = (
      <>
        <View style={styles.juxtaposedButtonsContainer}>
          <KButton
            style={styles.button}
            mode="text"
            icon="check"
            label={`${label} Done!`}
            disabled
            onPress={() => {}}
          />
          {canShare && (
            <ShareButton
              style={styles.flexEnd}
              onPress={() => setShouldShowShareModal(true)}
            />
          )}
        </View>
        {ownHabit && <Text style={styles.greatJob}>{s("greatJob")}</Text>}
      </>
    );
  } else {
    if (ownHabit) {
      completeDaySection = (
        <KButton
          style={styles.button}
          mode="outlined"
          label="Complete Day"
          onPress={() => {
            Keyboard.dismiss();
            logCompleteRoutineDay(habit, selectedRoutineDay.index);
            updateDay(
              { id: habitDay.id, habitid, date, absoluteDay },
              auth.uid,
              DayStatus.SUCCESS
            );

            setShouldShowShareModal(true);
          }}
          color={COLORS.accent}
        />
      );
    } else {
      completeDaySection = (
        <>
          <KButton
            style={styles.button}
            mode="outlined"
            icon="chat"
            label={s("chat")}
            onPress={() => {
              if (isMobilePlatform) {
                navigation.navigate("ChatScreen", {
                  title: friend.name,
                  friendid,
                });
              }
            }}
            color={COLORS.accent}
          />
          <Text style={styles.greatJob}>{s("askFriend")}</Text>
        </>
      );
    }
  }

  const noteBottomSheetProps: BottomSheetNoteProps = {
    dayid: habitDay.id,
    habitid,
    date,
    ownHabit,
    isEditable: ownHabit,
    showDayToggle: false,
  };

  return (
    <View style={styles.container}>
      <SectionTitle>{s("sectionReflect")}</SectionTitle>
      <NoteEditor
        dayid={habitDay.id}
        habitid={habitid}
        date={date}
        ownHabit={ownHabit}
        isEditable={ownHabit}
        render={props => (
          <NoteInput
            {...props}
            notePlaceholder={notePlaceholder}
            onFocus={onFocus}
            noteBottomSheetProps={noteBottomSheetProps}
            dayid={habitDay.id}
          />
        )}
      />
      <View style={styles.buttonContainer}>{completeDaySection}</View>
      {canShare && shouldShowShareModal && (
        <RoutineShareModal
          routineId={habit.routineId}
          photosForSharing={photosForSharing}
          setIsVisible={setShouldShowShareModal}
        />
      )}
    </View>
  );
};
