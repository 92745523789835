import { useNavigation } from "@react-navigation/native";
import { Habit, isHabitChallengeParticipant } from "types/habits";
import { useGetSpecificHabit } from "redux/selectors";

import { renderHabitTitle } from "utils/strings";

export const useNavigateToChallenge = (
  habitid: string,
  habit: Habit,
  ownHabit: boolean,
  friendid?: string
) => {
  const navigation = useNavigation();

  const { habit: mainHabit } = useGetSpecificHabit(
    isHabitChallengeParticipant(habit) ? habit.challengeHabitId : null
  );

  return () => {
    if (isHabitChallengeParticipant(habit)) {
      // if the main habit is shared with the user,
      // we can render the challenge habit screen
      if (mainHabit) {
        navigation.navigate("HabitChallengeScreen", {
          habitid,
          title: renderHabitTitle(habit),
          friendid,
          ownHabit,
        });
      } else {
        navigation.navigate("FriendHabitScreen", {
          habitid,
          title: renderHabitTitle(habit),
          friendid,
        });
      }
    } else {
      navigation.navigate("HabitChallengeScreen", {
        habitid,
        title: renderHabitTitle(habit),
        friendid,
        ownHabit,
      });
    }
  };
};
