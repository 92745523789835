import React, {
  useRef,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { Platform, ActivityIndicator } from "react-native";
import { ImagePickerResult } from "expo-image-picker/build/ImagePicker.types";

import { useGetDay, useGetAuth } from "redux/selectors";
import { useSelector } from "redux/store";
import { NoteMedia, Note, DayStatus } from "types/habits";
import firebase from "utils/firebase";
import { uploadImage } from "utils/chat";
import { ContentCenteredView } from "components";
import { isLoaded, isEmpty, useFirestore } from "react-redux-firebase";
import { createSelector } from "@reduxjs/toolkit";
import moment from "moment";
import { getAbsoluteDays } from "utils/time";
import { updateDay } from "utils/habits";
import { usePrevious } from "hooks/usePrevious";

export enum SaveState {
  IS_DIRTY = 0,
  IS_SAVING,
  SAVED,
  UNTOUCHED,
}

export type RenderNoteProps = {
  isEditable: boolean;
  saveState: SaveState;
  note: string;
  onChangeText: (text: string) => void;
  media: NoteMedia[];
  onMediaChange: (
    mediaUpdateFunction: (oldMedia: NoteMedia[]) => NoteMedia[]
  ) => void;
  selectedMedia: ImagePickerResult;
  setSelectedMedia: React.Dispatch<React.SetStateAction<ImagePickerResult>>;
  isUploadingMedia: boolean;
  lastSaved: number;
};

export type NoteEditorProps = {
  dayid: string;
  habitid: string;
  date: string; //MM-DD-YYYY
  ownHabit: boolean;
  isEditable: boolean;
  render?: (props: RenderNoteProps) => JSX.Element;
};

export const NoteEditor = ({
  dayid,
  habitid,
  date,
  ownHabit,
  isEditable,
  render,
}: NoteEditorProps) => {
  // Note parameters
  const [isLoading, setIsLoading] = useState(true);
  const [note, setNote] = useState<string>("");
  const [media, setMedia] = useState<NoteMedia[]>([]);
  const [isPublic, setIsPublic] = useState(true); // Not exposed right now, everything is public

  // Media selection and uploading
  const [selectedMedia, setSelectedMedia] = useState<ImagePickerResult>();
  const [isUploadingMedia, setIsUploadingMedia] = useState(false);

  // For note saving
  const timeoutRef = useRef(null);
  const noteToSave = useRef<{
    docPath: string;
    noteData: Note;
  }>();
  const [lastSaved, setLastSaved] = useState<number>();
  const [enableAutoSave, setEnableAutoSave] = useState(false); // so initial load doesn't immediately try to save
  const [saveState, setSaveState] = useState<SaveState>(SaveState.UNTOUCHED);

  // Extra data
  //! Day may not exist if shared days aren't loaded
  //! So do not assume we have a day (unless its your own note)
  const day = useGetDay({ habitid, date, ownHabit });
  const { auth } = useGetAuth();
  const firestore = useFirestore();

  // Notes need to have the same id as the corresponding day
  // so if there's no id, we need to add the day to get it
  const m = moment(date, "MM-DD-YYYY");
  const absoluteDay = getAbsoluteDays(m);

  useEffect(
    function createDay() {
      if (ownHabit && !day.id) {
        updateDay({ habitid, date, absoluteDay }, auth.uid, DayStatus.SKIP);
      }
    },
    [absoluteDay, auth.uid, date, day, habitid, ownHabit]
  );

  // If viewing friend note, we'll always have the dayid
  // But if own note, day may not exist. Read id from day object
  // because dayid prop may be undefined (e.g. if coming from bottom sheet)
  const dayIdToUse = ownHabit ? day.id : dayid;
  const previousDayIdToUse = usePrevious(dayIdToUse);
  useEffect(
    function saveNote() {
      let isMounted = true;

      function save() {
        const { docPath, noteData } = noteToSave.current;
        firebase
          .firestore()
          .doc(docPath)
          .set({
            ...noteData,
            lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
          })
          .then(() => {
            if (isMounted) {
              setLastSaved(Date.now());
              setSaveState(SaveState.SAVED);
            }
          });
      }
      if (saveState === SaveState.IS_SAVING && noteToSave.current) {
        save();
      }

      return function saveOnUnmount() {
        isMounted = false;
      };
    },
    [saveState]
  );

  useEffect(
    function watchSave() {
      const shouldSave = ownHabit;

      function debouncedSave() {
        if (enableAutoSave && shouldSave) {
          setSaveState(SaveState.IS_DIRTY);
          noteToSave.current = {
            docPath: `notes/${habitid}/notesDay/${dayIdToUse}`,
            noteData: {
              note,
              isPublic,
              media,
              date: day.date,
              absoluteDay: day.absoluteDay,
              uid: auth.uid,
              habitid,
            },
          };

          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
          }

          timeoutRef.current = setTimeout(() => {
            setSaveState(SaveState.IS_SAVING);
          }, 500);
        }
      }

      debouncedSave();
    },
    [
      enableAutoSave,
      ownHabit,
      auth,
      day,
      dayIdToUse,
      habitid,
      isPublic,
      media,
      note,
    ]
  );

  // No listener for friend notes, so we query directly for it
  useEffect(
    function loadSharedNote() {
      if (!ownHabit) {
        firestore.get({
          collection: "notes",
          doc: habitid,
          subcollections: [{ collection: "notesDay", doc: dayIdToUse }],
          storeAs: `sharedNote-${dayIdToUse}`,
        });
      }
    },
    [ownHabit, habitid, dayIdToUse, firestore]
  );

  const noteSelector = useMemo(
    () =>
      createSelector(
        state =>
          ownHabit
            ? state.firestore.data?.notes
            : state.firestore.data?.[`sharedNote-${dayIdToUse}`],
        n => {
          return {
            isNoteLoaded: isLoaded(n),
            savedNote: ownHabit ? n?.[dayIdToUse] : n,
          };
        }
      ),
    [ownHabit, dayIdToUse]
  );

  const { isNoteLoaded, savedNote } = useSelector(noteSelector);

  useEffect(
    function reconcileSavedAndLocalState() {
      if (!dayIdToUse || !isNoteLoaded) {
        setIsLoading(true);
      } else if (isEmpty(savedNote)) {
        // It is wrong to reset the state in all cases
        // e.g. if it's a new note, savedNote will be empty so
        // we don't want to override local state. For clarity:
        // 1. First run of useEffect will trigger on note load (isNoteLoaded/savedNote)
        // 2. Add note -- saveState changes to IS_DIRTY
        // 3. useEffect runs again but change hasn't been saved -> we'd wipe it out
        // so we will not have started the save yet
        // So we only want to reset state if day ID changed.
        if (previousDayIdToUse !== dayIdToUse) {
          setIsPublic(true);
          setNote("");
          setMedia([]);
          setEnableAutoSave(false);
        }
        setIsLoading(false);
      } else {
        if (
          saveState === SaveState.UNTOUCHED ||
          saveState === SaveState.SAVED
        ) {
          const { isPublic, note, media } = savedNote;

          setIsPublic(isPublic);
          setNote(note);
          setMedia(media);
          setIsLoading(false);
          setEnableAutoSave(false); // Otherwise, we'll get an infinite loop of saving
        }
      }
    },
    [isNoteLoaded, savedNote, saveState, dayIdToUse, previousDayIdToUse]
  );

  const onChangeText = useCallback((text: string) => {
    setEnableAutoSave(true);
    setNote(text);
  }, []);

  const onMediaChange = useCallback(
    (mediaUpdateFunction: (oldMedia: NoteMedia[]) => NoteMedia[]) => {
      setEnableAutoSave(true);
      setMedia(mediaUpdateFunction);
    },
    []
  );

  useEffect(
    function uploadMedia() {
      if (selectedMedia && selectedMedia.cancelled === false) {
        setIsUploadingMedia(true);
        uploadImage(
          selectedMedia,
          `notes/${auth.uid}/${habitid}/${dayIdToUse}`
        ).then(url => {
          onMediaChange(media => {
            const newItem = { ...selectedMedia };
            delete newItem.cancelled;
            // on Web, the URI is the entire file base64 encoded instead of
            // just a file path. Delete this otherwise we're essentially
            // storing the file in Firestore and will hit the ~1MB limit for
            // a doc!
            if (Platform.OS === "web") {
              if (newItem.uri.startsWith("data:video")) {
                newItem.type = "video";
                newItem.height = 1080; //! PLACEHOLDERS!
                newItem.width = 1920; // TODO: figure out the right values to place here
              } else {
                newItem.type = "image";
                newItem.height = 1080;
                newItem.width = 1920;
              }

              delete newItem.uri;
            }

            const newMedia = [
              ...media,
              {
                lastUpdated: Date.now(),
                ...newItem,
                url,
              },
            ];

            return newMedia;
          });
          setIsUploadingMedia(false);
          setSelectedMedia(null);
        });
      }
    },
    [auth, dayIdToUse, habitid, onMediaChange, selectedMedia]
  );

  if (isLoading) {
    return (
      <ContentCenteredView>
        <ActivityIndicator />
      </ContentCenteredView>
    );
  }

  return render({
    isEditable,
    saveState,
    note,
    onChangeText,
    media,
    onMediaChange,
    selectedMedia,
    setSelectedMedia,
    lastSaved,
    isUploadingMedia,
  });
};
