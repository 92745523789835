import { createSelector } from "@reduxjs/toolkit";
import { isLoaded } from "react-redux-firebase";

import { useSelector } from "redux/store";
import { Note } from "types/habits";
import { Logger } from "utils/Logger";
// : (Object.entries(state.firestore.ordered).find(
//   ([key]) => key.startsWith("sharedDays") && key.includes(habitid)
// ) as [string, Day[]]),
//https://github.com/reduxjs/reselect#accessing-react-props-in-selectors

//To share a selector across multiple instances while passing in props and retaining memoization
//each instance of the component needs its own private copy of the selector
//let’s create a function named makeNotesSelector that returns a new copy each time it is called:
const makeNotesSelector = (habitid: string, ownHabit: boolean) =>
  createSelector(
    state =>
      ownHabit
        ? state.firestore.ordered.notes
        : state.firestore.ordered[`sharedNotes-${habitid}`],
    (notes: Note[]) => {
      // Sort is in place and state is immutable, so need to make a copy!
      const notesNotNull = notes ? [...notes] : [];
      const sortedNotes = ownHabit
        ? notesNotNull
            .filter(note => note.habitid === habitid)
            .sort((a, b) => b.absoluteDay - a.absoluteDay)
        : notesNotNull.sort((a, b) => b.absoluteDay - a.absoluteDay);

      return {
        isLoaded: isLoaded(notes),
        notes: sortedNotes,
      };
    }
  );

export const useGetNotes = ({ habitid, ownHabit }) => {
  Logger.log("useNotes rerunning, habitid", habitid, "ownHabit?", ownHabit);
  return useSelector(makeNotesSelector(habitid, ownHabit));
};
