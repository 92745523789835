import { HabitRoutine } from "types/habits";
import { useGetRoutineAndContent } from "redux/selectors";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTimeContext } from "contexts";

export const useTodaysRoutineContentIndex = (habit: HabitRoutine) => {
  const { today } = useTimeContext();
  const { isLoaded, routineContent, routine } = useGetRoutineAndContent(
    habit?.routineId
  );

  const [maxIndex, setIndex] = useState(null);
  const [routineDays, setRoutineDays] = useState<
    {
      day: number;
      moment: moment.Moment;
    }[]
  >(null);

  useEffect(() => {
    if (routineContent) {
      const startDay = moment(habit.startTime.toDate()).startOf("day");
      const todaysDayOfRoutine = today.diff(startDay, "days", true); // is negative if startDay set in future
      const routineDays = routineContent.map(routineDay => ({
        day: routineDay.day,
        // routineDay.day is not zero indexed (day 1 is start day)
        moment: startDay.clone().add(routineDay.day - 1, "days"),
      }));

      // find the maximum index that we can show
      // if < 0, means we haven't reached the start day yet
      // special handle this in RoutineView
      let index = todaysDayOfRoutine;

      if (index >= 0) {
        index = routineDays.length - 1;
        for (let i = routineDays.length - 1; i >= 0; i--) {
          const { day } = routineDays[i];

          // Same zero index issue here
          if (day - 1 <= todaysDayOfRoutine) {
            index = i;
            break;
          }
        }
      }

      setIndex(index);
      setRoutineDays(routineDays);
    }
  }, [today, routineContent, habit.startTime]);

  return {
    isLoaded,
    routineContent,
    routine,
    maxIndex,
    routineDays,
  };
};
