import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Day, HabitDayMap } from "types/habits";

const userDaysSlice = createSlice({
  name: "userDays",
  initialState: {} as HabitDayMap,
  reducers: {
    setDays(
      state,
      action: PayloadAction<{
        ordered: Day[];
      }>
    ) {
      const { ordered } = action.payload;
      for (let i = 0; i < ordered.length; i++) {
        const day = ordered[i];
        if (day.habitid in state) {
          state[day.habitid][day.date] = day;
        } else {
          state[day.habitid] = {
            [day.date]: day,
          };
        }
      }
    },
    setSingleDay(state, action: PayloadAction<Day>) {
      const { payload: day } = action;
      if (day.habitid in state) {
        state[day.habitid][day.date] = day;
      } else {
        state[day.habitid] = {
          [day.date]: day,
        };
      }
    },
  },
});

export const { setDays, setSingleDay } = userDaysSlice.actions;

export const userDaysSliceReducer = userDaysSlice.reducer;
