import React, { ReactElement } from "react";
import { View, StyleProp, ViewStyle, StyleSheet } from "react-native";
import { Card } from "react-native-paper";
import { SHADOWS } from "styles";

// Prompt takes the form of a card with a body, icon (optional), and actions (buttons)
export const KPrompt = ({
  body,
  actions,
  style,
  icon,
}: {
  body: JSX.Element | JSX.Element[];
  actions: ReactElement | ReactElement[];
  style?: StyleProp<ViewStyle>;
  icon?: JSX.Element;
}) => {
  return (
    <View style={styles.container}>
      <Card style={[styles.card, style]} elevation={2}>
        <View style={styles.row}>
          {icon}
          <View style={styles.content}>
            <Card.Content>{body}</Card.Content>
            <Card.Actions>{actions}</Card.Actions>
          </View>
        </View>
      </Card>
    </View>
  );
};

export const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignSelf: "center",
    width: "90%",
  },
  card: {
    borderRadius: 12,
    ...SHADOWS.shadowCard,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
  content: { flex: 1 },
});
