import React from "react";
import { KButton, Body } from "components";
import { Dialog, Portal } from "react-native-paper";

import { goToPermissionsSettings } from "utils/permissions";

export const GrantNotificationsDialog = ({ showDialog, setShowDialog }) => (
  <Portal>
    <Dialog
      visible={showDialog}
      onDismiss={() => setShowDialog(false)}
      dismissable
    >
      <Dialog.Title>Device notifications disabled</Dialog.Title>
      <Dialog.Content>
        <Body>
          To receive reminders for your habit, you need to allow notifications
          from SnapHabit in your device settings
        </Body>
      </Dialog.Content>
      <Dialog.Actions>
        <KButton
          label="Go to Settings"
          mode="text"
          color="black"
          onPress={() => {
            setShowDialog(false);
            return goToPermissionsSettings();
          }}
        />
      </Dialog.Actions>
    </Dialog>
  </Portal>
);
