import React, { useState } from "react";
import { useGetSpecificHabit } from "redux/selectors";
import { isHabitChallenge } from "types/habits";
import { LinkInvite } from "components/Challenges/LinkInvite";
import { Portal, Dialog } from "react-native-paper";

export const InviteToChallenge = ({
  challengeHabitId,
  routeName,
}: {
  challengeHabitId?: string;
  routeName: string;
}) => {
  const { habit } = useGetSpecificHabit(challengeHabitId);
  const [isVisible, setIsVisible] = useState(true);

  if (!isHabitChallenge(habit)) {
    return null;
  }

  return (
    <Portal>
      <Dialog visible={isVisible} onDismiss={() => setIsVisible(false)}>
        <Dialog.Title>Invite to Join {habit.title}</Dialog.Title>
        <Dialog.Content>
          <LinkInvite
            challengeHabitId={challengeHabitId}
            routeName={routeName}
          />
        </Dialog.Content>
      </Dialog>
    </Portal>
  );
};
