export default {
  anonUser: {
    en: "Anon User?",
    ja: "匿名ユーザー？",
    "zh-Hans": "匿名用户？",
    "zh-Hant": "匿名用戶？",
  },
  addFriends: {
    en: "Add Friends with Invite Link",
  },
  appInfo: {
    en: "App Info",
    ja: "アプリ情報",
    "zh-Hans": "应用信息",
    "zh-Hant": "應用信息",
  },
  appName: {
    en: "App Name",
    ja: "アプリ名",
    "zh-Hans": "应用名",
    "zh-Hant": "應用名",
  },
  appVersion: {
    en: "App Version",
    ja: "アプリバージョン",
    "zh-Hans": "应用版本",
    "zh-Hant": "應用版本",
  },
  archivedHabits: {
    en: "Archived Habits",
    ja: "アーカイブされたハビット",
    "zh-Hans": "习惯存档",
    "zh-Hant": "習慣存檔",
  },
  byUsingOurApp: {
    en: "By using our app, you agree to our Privacy Policy and Terms of Use.",
    ja:
      "このアプリを使用することにより、プライバシーポリシーと利用規約に同意することになります。",
    "zh-Hans": "使用我们的应用程序，即表示您同意我们的隐私政策和使用条款。",
    "zh-Hant": "使用我們的應用程序，即表示您同意我們的隱私政策和使用條款。",
  },
  clearAsyncStorage: {
    en: "Clear Async Storage",
  },
  debugInfo: {
    en: "Debug Info",
  },
  dragToReorder: {
    en: "Drag to reorder your habits",
    ja: "ドラッグしてハビットを並べ替えます",
    "zh-Hans": "拖动以重新排列您的习惯",
    "zh-Hant": "拖動以重新排列您的習慣",
  },
  email: {
    en: "Email",
    ja: "メール",
    "zh-Hans": "邮件地址",
    "zh-Hant": "郵件地址",
  },
  env: {
    en: "Env",
    ja: "環境",
    "zh-Hans": "环境",
    "zh-Hant": "環境",
  },
  general: {
    en: "General",
    ja: "一般",
    "zh-Hans": "常规设置",
    "zh-Hant": "常規設置",
  },
  helpsNewFriendsFindYou: {
    en:
      "Helps new friends find you by showing up in mutual friend lists. Your name and avatar will be visible.",
    ja:
      "新しい友達があなたを見つけやすくなるため、相互の友達リストに表示されます。 あなたの名前とアバターが表示されます。",
    "zh-Hans":
      "通过在共同的朋友列表中显示来帮助新朋友找到您。您的姓名和头像将可见。",
    "zh-Hant":
      "通過在共同的朋友列表中顯示來幫助新朋友找到您。您的姓名和頭像將可見。",
  },
  host: {
    en: "Host",
    ja: "ホスト",
    "zh-Hans": "主机",
    "zh-Hant": "主機",
  },
  language: {
    en: "App Language",
    ja: "言語",
    "zh-Hans": "语言",
    "zh-Hant": "語言",
  },
  faq: {
    en: "Frequently asked questions",
  },
  notifications: {
    en: "Notifications",
    ja: "通知",
    "zh-Hans": "通知",
    "zh-Hant": "通知",
  },
  orderYourHabitsFromApp: {
    en: "Order your habits from the Snap Habit app",
    ja: "Snap Habitアプリから習慣を並べるする",
    "zh-Hans": "通过SnapHabit应用程序排序您的习惯",
    "zh-Hant": "通過SnapHabit應用程序排序您的習慣",
  },
  privacyPolicyAndTermsOfUse: {
    en: "Privacy Policy & Terms of Use",
    ja: "プライバシーポリシーと利用規約",
    "zh-Hans": "隐私政策和使用条款",
    "zh-Hant": "隱私政策和使用條款",
  },
  profileId: {
    en: "User Name",
    ja: "プロファイルID",
    "zh-Hans": "账户ID",
    "zh-Hant": "賬戶ID",
  },
  addByUserName: {
    en: "Other SnapHabit users can find you with this",
  },
  rateApp: {
    en: "Rate the App",
  },
  reorderYourHabits: {
    en: "Reorder Your Habits",
    ja: "ハビットの順位を変更",
    "zh-Hans": "习惯排序",
    "zh-Hant": "習慣排序",
  },
  sendUsFeedback: {
    en: "Send us feedback",
    ja: "フィードバックを送信",
    "zh-Hans": "发送反馈",
    "zh-Hant": "發送反饋",
  },
  signOut: {
    en: "Sign Out",
    ja: "サインアウト",
    "zh-Hans": "登出",
    "zh-Hant": "登出",
  },
  toggleToArchive: {
    en: "Toggle off to archive a habit",
  },
  uid: {
    en: "UID - click to copy to clipboard",
    ja: "UID - クリックしてコピー",
    "zh-Hans": "UID - 单击以复制到剪贴板",
    "zh-Hant": "UID - 單擊以復製到剪貼板",
  },
  updatesFrom: {
    en: (name: string) => `Updates from ${name}`,
    ja: (name: string) => `${name}の更新`,
    "zh-Hans": (name: string) => `来自${name}的更新`,
    "zh-Hant": (name: string) => `來自${name}的更新`,
  },
  visibleToFriendsOfFriends: {
    en: "Visible to Friends of Friends",
    ja: "友達の友達に表示されます",
    "zh-Hans": "对共同朋友可见",
    "zh-Hant": "對共同朋友可見",
  },
};
