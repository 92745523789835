import React from "react";
import { View, StyleSheet, Text, ViewStyle } from "react-native";
import { RoutineTag } from "types/routines";
import { useString } from "hooks";
import { Chip } from "react-native-paper";
const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  chipStyle: {
    margin: 4,
  },
  avatar: {
    textAlign: "center",
    marginTop: 4, // Otherwise looks off-center
  },
});

const ROUTINE_TAG_EMOJI = {
  [RoutineTag.SOCIAL_JUSTICE]: "🧑🏿‍🤝‍🧑🏼",
  [RoutineTag.EXERCISE]: "🤸🏽",
  [RoutineTag.MINDFULNESS]: "🧘",
  [RoutineTag.SUSTAINABILITY]: "♻️",
  [RoutineTag.JOURNALING]: "📝",
  [RoutineTag.ART]: "🎨 ",
};

//#200 row in https://material.io/resources/color/
const ROUTINE_TAG_COLORS = {
  [RoutineTag.SOCIAL_JUSTICE]: "#ce93d8", // purple
  [RoutineTag.EXERCISE]: "#ffab91", // deep orange
  [RoutineTag.MINDFULNESS]: "#80cbc4", // teal
  [RoutineTag.SUSTAINABILITY]: "#c5e1a5", // light green
  [RoutineTag.JOURNALING]: "#e6ee9c", //lime yellow
  [RoutineTag.ART]: "#ef9a9a", //red
};

export const ROUTINE_TAGS_ALL_ARRAY = [
  RoutineTag.EXERCISE,
  RoutineTag.SOCIAL_JUSTICE,
  RoutineTag.MINDFULNESS,
  RoutineTag.SUSTAINABILITY,
  RoutineTag.JOURNALING,
  RoutineTag.ART,
];

export const RTag = ({
  tag,
  style,
  ...chipProps
}: {
  tag: RoutineTag;
  style?: ViewStyle;
  selected?: boolean;
  disabled?: boolean;
  onClose?: () => void;
  onPress?: () => void;
}) => {
  const s = useString("routine");

  return (
    <Chip
      {...chipProps}
      style={[
        style,
        {
          backgroundColor: ROUTINE_TAG_COLORS[tag],
        },
      ]}
      avatar={<Text style={styles.avatar}>{ROUTINE_TAG_EMOJI[tag]}</Text>}
    >
      {s(tag) || tag}
    </Chip>
  );
};

export const RoutineTags = ({ tags = [] }: { tags: RoutineTag[] }) => {
  return (
    <View style={styles.container}>
      {tags.map(t => (
        <RTag key={t} tag={t} style={styles.chipStyle} />
      ))}
    </View>
  );
};
