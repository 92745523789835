import { SelectedRoutineDay } from "types/routines";
import React from "react";
import { FlatList, TouchableOpacity } from "react-native-gesture-handler";
import { View, Text, StyleSheet } from "react-native";
import { useHabitGridStyles } from "hooks/habits/useHabitGridStyles";
import { COLORS } from "utils/appStyles";
import { useTheme } from "react-native-paper";
import { useString } from "hooks";
import { Footnote } from "components/UI/Typography";
import { useGetDay } from "redux/selectors";
import { getDayStatusColor } from "utils/habits";
import { DayStatus } from "types/habits";
import { logSwitchRoutineDay } from "utils/analytics";

const styles = StyleSheet.create({
  flatListContainer: { marginLeft: 20, marginTop: 15 },
  contentContainer: { marginHorizontal: 0 },
});

const Switcher = ({
  onPress,
  habitid,
  friendid,
  moment,
  day,
  isSelected,
  isDisabled,
}) => {
  const { COLUMN_WIDTH } = useHabitGridStyles();
  const theme = useTheme();
  const date = moment.format("MM-DD-YYYY");

  const habitDay = useGetDay({
    date,
    ownHabit: !friendid,
    habitid,
  });

  const backgroundColor = getDayStatusColor(habitDay.status);

  const width = COLUMN_WIDTH * 0.9;

  return (
    <TouchableOpacity onPress={onPress} disabled={isDisabled}>
      <View
        style={{
          width,
          height: width,
          backgroundColor: isDisabled ? COLORS.disabled : backgroundColor,
          borderWidth: isSelected ? 1 : 0,
          borderColor: isSelected ? "black" : theme.colors.disabled,
          justifyContent: "center",
          alignItems: "center",
          marginRight: 8,
        }}
      >
        <Text
          style={{
            textAlign: "center",
            color: habitDay.status === DayStatus.SUCCESS ? "white" : "black",
          }}
        >
          <Footnote
            style={{
              color: habitDay.status === DayStatus.SUCCESS ? "white" : "black",
            }}
          >
            #{day}
          </Footnote>
          {`\n${moment.format("M/D")}`}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

export const DaySwitcher = ({
  habitid,
  friendid,
  selectedRoutineDay,
  setSelectedRoutineDay,
}: {
  habitid: string;
  friendid?: string;
  selectedRoutineDay: SelectedRoutineDay;
  setSelectedRoutineDay: React.Dispatch<
    React.SetStateAction<SelectedRoutineDay>
  >;
}) => {
  const s = useString("routine");

  return (
    <FlatList
      style={styles.flatListContainer}
      contentContainerStyle={styles.contentContainer}
      data={selectedRoutineDay.routineDays}
      keyExtractor={(_item, index) => "" + index}
      renderItem={({ item, index }) => (
        <Switcher
          day={item.day}
          habitid={habitid}
          friendid={friendid}
          moment={item.moment}
          isDisabled={index > selectedRoutineDay.maxIndex}
          isSelected={index === selectedRoutineDay.index}
          onPress={() => {
            // only log taps/switches on other routine days
            index !== selectedRoutineDay.index &&
              logSwitchRoutineDay(index, habitid);
            setSelectedRoutineDay(prevSelected => {
              return {
                ...prevSelected,
                index,
              };
            });
          }}
        />
      )}
      horizontal
    />
  );
};
