import React, { useState } from "react";
import { StyleSheet } from "react-native";
import { Portal, Dialog } from "react-native-paper";

const styles = StyleSheet.create({
  dialog: { maxWidth: 400, alignSelf: "center" },
});

export const KDialog = ({
  children,
  renderDialogContent,
  renderDialogAnchor,
}: {
  children?: React.ReactNode;
  renderDialogContent?: (
    setShowDialog: React.Dispatch<React.SetStateAction<boolean>>
  ) => React.ReactNode;
  renderDialogAnchor: (
    setShowDialog: React.Dispatch<React.SetStateAction<boolean>>
  ) => React.ReactNode;
}) => {
  const [showDialog, setShowDialog] = useState(false);

  return (
    <>
      <Portal>
        <Dialog
          style={styles.dialog}
          visible={showDialog}
          onDismiss={() => setShowDialog(false)}
          dismissable
        >
          {renderDialogContent ? renderDialogContent(setShowDialog) : children}
        </Dialog>
      </Portal>
      {renderDialogAnchor(setShowDialog)}
    </>
  );
};
