import { MaterialIcons } from "@expo/vector-icons";
import * as VideoThumbnails from "expo-video-thumbnails";
import React, { useEffect, useState } from "react";
import { View, StyleSheet, Platform, ImageStyle } from "react-native";
import Image from "react-native-image-progress";
import ProgressBar from "react-native-progress/Bar";

import { videoPlaceholder } from "assets/images";
import { Logger } from "utils/Logger";

/**
 * Attempts to load image locally and then falls back to remote url
 */
export const KImage = ({
  local,
  remote,
  style,
  type,
}: {
  local?: string;
  remote?: string;
  style: ImageStyle;
  type?: "image" | "video";
}) => {
  const [videoThumbnail, setVideoThumbnail] = useState(null);

  useEffect(() => {
    const mounted = true;
    // Seems like there's a crash that 37 might fix
    // https://github.com/expo/expo/pull/6877/files
    if (type === "video") {
      (async () => {
        try {
          if (Platform.OS === "ios") {
            const { uri } = await VideoThumbnails.getThumbnailAsync(local);
            if (mounted) setVideoThumbnail(uri);
          }
        } catch (e) {
          try {
            const { uri } = await VideoThumbnails.getThumbnailAsync(remote);
            if (mounted) setVideoThumbnail(uri);
          } catch (e) {
            Logger.error(e);
          }
        }
      })();
    }
  }, []);

  if (type === "video") {
    return (
      <>
        <View collapsable={false} style={styles.iconBackground} />
        <View style={styles.iconContainer}>
          <MaterialIcons name="play-circle-outline" color="white" size={40} />
        </View>
        {videoThumbnail && Platform.OS === "ios" ? (
          <Image
            source={{ uri: videoThumbnail, cache: "force-cache" }}
            indicator={ProgressBar}
            style={style}
          />
        ) : Platform.OS === "android" || Platform.OS === "web" ? (
          <Image source={videoPlaceholder} style={style} />
        ) : null}
      </>
    );
  }

  if (local) {
    return (
      <Image
        source={{ uri: local, cache: "force-cache" }}
        indicator={ProgressBar}
        style={[style, styles.imageStyle]}
        renderError={() => (
          <Image
            source={{ uri: remote, cache: "force-cache" }}
            indicator={ProgressBar}
            style={[style, styles.imageStyle]}
          />
        )}
      />
    );
  }

  if (remote) {
    return (
      <Image
        source={{ uri: remote, cache: "force-cache" }}
        indicator={ProgressBar}
        style={[style, styles.imageStyle]}
      />
    );
  }

  return null;
};

const styles = StyleSheet.create({
  iconBackground: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: "black",
    opacity: 0.2,
    zIndex: 1,
  },
  iconContainer: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: "center",
    alignItems: "center",
    zIndex: 2,
  },
  // Prevent the progress bar from overflowing image for small images
  imageStyle: {
    overflow: "hidden",
  },
});
