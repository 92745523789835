export default {
  failed: {
    en: "No",
  },
  empty: {
    en: "Not Yet",
  },
  success: {
    en: "Yes",
  },
  autoSaveInstruction: {
    en: "Just type. Notes auto-save.",
  },
  didYouSucceed: {
    en: "Did you succeed?",
  },
  addTodaysImage: {
    en: "Add today's image",
  },
  addTodaysNote: {
    en: "Add today's note",
  },
  howDidItFeel: {
    en: "How did it feel?",
  },
  close: {
    en: "Close",
  },
  privacyNote: {
    en:
      "This note will be shared with your friends. To edit the people who can see your note, please edit your habit’s visibility settings.",
  },
};
