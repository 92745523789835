import React from "react";
import { Text } from "react-native";

export const ErrorBanner = ({ children }) => (
  <Text
    style={{
      padding: 4,
      fontSize: 18,
      textAlign: "center",
      color: "white",
      backgroundColor: "#de3704",
      width: "100%",
    }}
  >
    {children}
  </Text>
);
