import React from "react";
import { Habit } from "types/habits";
import { useNavigateToChallenge } from "hooks/habits/useNavigateToChallenge";
import { GenericHabitTitleRow } from "components/HabitGrid/GenericHabitTitleRow";
import { GroupIcon } from "icons/GroupIcon";

export const ChallengeHabitTitleRow = ({
  title,
  habitid,
  habit,
  ownHabit,
  friendid,
}: {
  title: string;
  habitid: string;
  habit: Habit;
  ownHabit: boolean;
  friendid: string;
}) => {
  const onPress = useNavigateToChallenge(habitid, habit, ownHabit, friendid);

  return (
    <GenericHabitTitleRow
      key={habit.id}
      title={title}
      Icon={<GroupIcon height={16} width={16} />}
      onPress={onPress}
    />
  );
};
