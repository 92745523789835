import React, { useState, useEffect, useCallback } from "react";
import { StyleSheet, AppState, View } from "react-native";
import { Title } from "react-native-paper";
import * as Analytics from "expo-firebase-analytics";
import AsyncStorage from "@react-native-community/async-storage";

import { COLORS, SECTION } from "styles";
import { KButton, KPrompt } from "components";
import { useGatingCondition } from "hooks/useGating";
import {
  ASYNC_STORAGE_RATING_PROMPTED_KEY,
  ASYNC_STORAGE_CARD_VIEWS_KEY,
} from "utils/constants";
import { FeedbackIllustration } from "./FeedbackIllustration";
import { RatingPromptCard } from "./RatingPromptCard";
import { FeedbackPromptCard } from "./FeedbackPromptCard";

enum CardType {
  GATING,
  FEEDBACK,
  RATING,
}

const Buttons = ({ setCardType }) => {
  return (
    <>
      <KButton
        label="Yes!"
        mode="text"
        color={COLORS.logoBlue}
        onPress={() => {
          Analytics.logEvent("review_gating", {
            button: "rating",
          });
          setCardType(CardType.RATING);
        }}
      />
      <KButton
        label="No"
        mode="text"
        color={COLORS.darkGray}
        onPress={() => {
          Analytics.logEvent("review_gating", {
            button: "feedback",
          });
          setCardType(CardType.FEEDBACK);
        }}
      />
    </>
  );
};

const GatingCard = ({ setCardType }) => {
  return (
    <KPrompt
      body={<Title style={styles.title}>Enjoying SnapHabit?</Title>}
      actions={<Buttons setCardType={setCardType} />}
      icon={<FeedbackIllustration />}
    />
  );
};

export const ReviewCard = ({ scrollViewRef }) => {
  // showGating explicitly controls whether to hide the gating to show another prompt,
  // while shouldShowReviewCard checks for conditions to show the review cards
  const [showGating, setShowGating] = useState<boolean>(true);
  const shouldShowReviewCard = useGatingCondition();
  const [cardType, setCardType] = useState<CardType>(CardType.GATING);

  let card = null;

  const dismissCard = useCallback(() => {
    setCardType(null);
    scrollViewRef.current.scrollTo({ x: 0, y: 0, animated: true });
    AsyncStorage.setItem(`${ASYNC_STORAGE_RATING_PROMPTED_KEY}`, "true");
  }, []);

  useEffect(() => {
    setShowGating(shouldShowReviewCard);
  }, [shouldShowReviewCard]);

  // set App opens listener to increment CARD_VIEWS if app opened and card visible
  useEffect(() => {
    function getCardViews(newState) {
      if (newState === "active" && shouldShowReviewCard && showGating) {
        AsyncStorage.getItem(`${ASYNC_STORAGE_CARD_VIEWS_KEY}`).then(
          storedCardViews => {
            storedCardViews === null &&
              Analytics.logEvent("show_review_card", {
                property: "shown",
              });
            // Hide card 4 app opens *after* it is first seen (total 5 views)
            if (parseInt(storedCardViews) >= 3) {
              dismissCard();
            } else {
              AsyncStorage.setItem(
                `${ASYNC_STORAGE_CARD_VIEWS_KEY}`,
                (parseInt(storedCardViews ?? "0") + 1).toString()
              );
            }
          }
        );
      }
    }
    getCardViews("active");
    AppState.addEventListener("change", getCardViews);
    return () => {
      AppState.removeEventListener("change", getCardViews);
    };
  }, [shouldShowReviewCard]);

  if (showGating && cardType === CardType.GATING) {
    card = (
      <View style={SECTION.section}>
        <GatingCard setCardType={setCardType} />
      </View>
    );
  } else if (cardType === CardType.FEEDBACK) {
    card = (
      <View style={SECTION.section}>
        <FeedbackPromptCard dismissCard={dismissCard} />
      </View>
    );
  } else if (cardType === CardType.RATING) {
    card = (
      <View style={SECTION.section}>
        <RatingPromptCard dismissCard={dismissCard} />
      </View>
    );
  }

  return card;
};

export const styles = StyleSheet.create({
  title: {
    fontSize: 18,
    paddingTop: 4,
  },
});
