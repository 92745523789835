import React from "react";
import { Text, View, StyleSheet, Linking } from "react-native";
import { KAvatar, KButton, Loading } from "components";
import { isMobilePlatform } from "utils/helpers";
import { COLORS } from "utils/appStyles";
import { Title, Subtitle, Footnote } from "components/UI/Typography";
import { FirebaseReducer } from "react-redux-firebase";
import { LogInView } from "components/Login/LoginView";
import { BRANCH_LINK } from "components/GetTheApp";
import { AboutTheAppSection } from "components/Invite/InvitesCommon";

const GetTheAppButton = () => (
  <KButton
    onPress={() => Linking.openURL(BRANCH_LINK)}
    label="Get SnapHabit"
    color={COLORS.logoBlue}
  />
);

export const InviteScreenLayout = ({
  isLoading,
  error,
  auth,
  isSignedUp,
  profileData,
  addFriend,
  userIsAdded,
  resetPage,
}: {
  isLoading: boolean;
  error: Error;
  isSignedUp: boolean;
  auth: FirebaseReducer.AuthState;
  profileData;
  addFriend;
  userIsAdded: boolean;
  resetPage;
}) => {
  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    let message = "";
    switch (error.message) {
      case "INVALID_HANDLE":
      case "NOT_FOUND":
        message = "No user found!";
        break;
      case "REQUEST_ERROR":
        message = "Something is broken... :(";
        break;
      default:
        message = error.message;
    }
    return (
      <View style={styles.container}>
        <Title style={styles.title}>Sorry, something went wrong 🙁</Title>
        <Subtitle>{message}</Subtitle>
        {error.message === "REQUEST_ERROR" && (
          <KButton
            onPress={
              profileData
                ? resetPage
                : () => {
                    if (isMobilePlatform) {
                      const Updates = require("expo-updates");
                      Updates.reloadAsync();
                    } else if (window) {
                      window.location.reload();
                    }
                  }
            }
            label="Try again"
            color="green"
          />
        )}
        <AboutTheAppSection />
        <GetTheAppButton />
      </View>
    );
  }

  let CallToAction;
  if (userIsAdded) {
    CallToAction = (
      <>
        <Text style={styles.text}>Friend request sent!</Text>
        <Text style={styles.text}>Download the app to start your journey.</Text>
        <GetTheAppButton />
      </>
    );
  } else if (isSignedUp) {
    CallToAction = (
      <>
        <KButton
          onPress={addFriend}
          label="Add friend"
          color={COLORS.logoBlue}
        />
        <Footnote style={{ marginTop: 20, textAlign: "center" }}>
          You're signed in as {auth.displayName}
        </Footnote>
      </>
    );
  } else {
    CallToAction = (
      <LogInView
        hideMotivation
        style={{ width: "100%" }}
        webRedirectTo={window.location.href}
      />
    );
  }

  return (
    <View style={styles.container}>
      <View style={styles.cardHeader}>
        <KAvatar
          source={profileData?.avatar}
          size={100}
          style={styles.avatar}
        />
        <Text style={styles.name}>{profileData?.name}</Text>
        <Text style={styles.text}>
          is sharing habits on SnapHabit and wants an accountability buddy!
        </Text>
      </View>
      <View style={{ margin: 10, width: "100%" }}>{CallToAction}</View>
      <AboutTheAppSection />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    marginHorizontal: "auto",
    maxWidth: 400,
  },
  avatar: {
    marginBottom: 10,
  },
  signedUp: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  cardHeader: {
    justifyContent: "center",
    alignItems: "center",
    marginHorizontal: 12,
  },
  name: {
    marginBottom: 5,
    fontSize: 40,
    fontWeight: "bold",
    textAlign: "center",
  },
  text: {
    marginBottom: 20,
    fontSize: 20,
    textAlign: "center",
  },
  error: {
    fontSize: 10,
    color: "red",
    width: "100%",
  },
  title: {
    textAlign: "center",
  },
  subtitle: {
    textAlign: "center",
    margin: 12,
  },
  aboutTheAppContainer: {
    marginVertical: 20,
    marginHorizontal: 20,
    flexDirection: "row",
    alignItems: "center",
  },
  logo: { width: 80, height: 80, margin: 20 },
});
