import React, { useState } from "react";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Appbar, Menu, Portal } from "react-native-paper";

import { DeleteHabitDialog } from "components/EditHabit/AddHabitLayout";
import { archiveHabit, isHabitEditable } from "utils/habits";
import { useString } from "hooks";
import { useHistory } from "utils/react-router";

const onEdit = (navigation, habit, habitid) => {
  navigation.navigate("AddHabitScreen", {
    habit,
    habitid,
  });
};

const onArchive = (navigation, habitid) => {
  try {
    archiveHabit(habitid);
    navigation.popToTop();
  } catch (err) {
    console.log(err);
  }
};

const onDelete = (navigation, habit, habitid, history, setDialog) => {
  setDialog(
    <DeleteHabitDialog
      title={habit.title}
      habitid={habitid}
      setDialog={setDialog}
      navigation={navigation}
      history={history}
    />
  );
};

export const EditHabitOverflow = ({ navigation, theme, habit, habitid }) => {
  const [menuVisible, setMenuVisible] = useState<boolean>(false);
  const [dialog, setDialog] = useState(null);
  const s = useString("addHabitScreen");
  const history = useHistory();

  const closeMenu = () => setMenuVisible(false);
  const openMenu = () => setMenuVisible(true);

  const isEditable = isHabitEditable(habit);

  return (
    <>
      <Portal>{dialog}</Portal>
      <Menu
        visible={menuVisible}
        onDismiss={closeMenu}
        anchor={
          <Appbar.Action
            icon="dots-vertical"
            color={theme.colors.accent}
            onPress={openMenu}
          />
        }
      >
        <Menu.Item
          onPress={() => {
            closeMenu();
            onEdit(navigation, habit, habitid);
          }}
          icon="pencil"
          title="Edit"
        />
        <Menu.Item
          onPress={() => {
            closeMenu();
            onArchive(navigation, habitid);
          }}
          icon="archive"
          title={isEditable ? s("archive") : s("disable")}
        />
        {isEditable && (
          <Menu.Item
            onPress={() => {
              closeMenu();
              onDelete(navigation, habit, habitid, history, setDialog);
            }}
            icon="delete"
            title={s("delete")}
          />
        )}
      </Menu>
    </>
  );
};
