// Library import
import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import { FlatList } from "react-native-gesture-handler";

// Components import
import { ImageThumbnail, SelectMediaAction, KMediaViewer } from "components";
import { RenderNoteProps } from "components/Notes/NoteEditor";

// Style import
import { COLORS } from "utils/appStyles";

// Local import
import { SavedImage } from "./SavedImage";

const MAX_MEDIA_COUNT = 9;
const MEDIA_GRID_WIDTH = "32%";
const MEDIA_LIST_WIDTH = 100;

const mediaContainerStyles = StyleSheet.create({
  gridContainer: {
    aspectRatio: 1,
    borderRadius: 15,
    marginBottom: 10,
    overflow: "hidden",
    width: MEDIA_GRID_WIDTH,
  },
  listContainer: {
    aspectRatio: 1,
    borderRadius: 15,
    marginHorizontal: 5,
    marginVertical: 10,
    overflow: "hidden",
    width: MEDIA_LIST_WIDTH,
  },
});

const MediaContainer = ({ children, list }) => (
  <View
    style={
      list
        ? mediaContainerStyles.listContainer
        : mediaContainerStyles.gridContainer
    }
  >
    {children}
  </View>
);

const keyExtractor = item => item.lastUpdated.toString();

export const ImageSaver = ({
  isEditable,
  media,
  onMediaChange,
  isUploadingMedia,
  selectedMedia,
  setSelectedMedia,
  list = false,
}: Partial<RenderNoteProps> & {
  list?: boolean;
}) => {
  const [imageViewerVisible, setImageViewerVisible] = useState<boolean>(false);
  const [imageViewerIndex, setImageViewerIndex] = useState<number>(0);

  const renderItem = (item, index) => (
    <MediaContainer key={keyExtractor(item)} list={list}>
      <SavedImage
        image={item}
        style={styles.image}
        onImagePressed={() => {
          setImageViewerIndex(index);
          setImageViewerVisible(true);
        }}
        onRemovePressed={() => {
          onMediaChange(media => {
            const newMedia = [...media];
            newMedia.splice(index, 1);
            return newMedia;
          });
        }}
        isEditable={isEditable}
      />
    </MediaContainer>
  );

  let AddImage = null;

  if (selectedMedia && selectedMedia.cancelled === false) {
    AddImage = (
      <MediaContainer list={list}>
        <ImageThumbnail
          selectedPhoto={selectedMedia}
          onRemovePressed={() => {
            setSelectedMedia(null);
          }}
          isUploading={isUploadingMedia}
          imageStyle={styles.image}
        />
      </MediaContainer>
    );
  } else if (isEditable && !selectedMedia && media.length < MAX_MEDIA_COUNT) {
    AddImage = (
      <MediaContainer list={list}>
        <View style={styles.selectMedia}>
          <SelectMediaAction
            selectedPhoto={selectedMedia}
            setSelectedPhoto={setSelectedMedia}
            inputActionsStyle={styles.inputActionsStyle}
          />
        </View>
      </MediaContainer>
    );
  }

  return (
    <>
      <KMediaViewer
        isVisible={imageViewerVisible}
        setIsVisible={setImageViewerVisible}
        index={imageViewerIndex}
        imageUrls={media.map(m => ({
          url: "",
          height: m.height,
          width: m.width,
          props: {
            local: m.uri,
            remote: m.url,
            type: m.type,
            style: { height: m.height, width: m.width },
          },
        }))}
      />
      {list ? (
        <FlatList
          horizontal
          data={media}
          renderItem={({ item, index }) => renderItem(item, index)}
          keyExtractor={keyExtractor}
          ListFooterComponent={AddImage}
        />
      ) : (
        <View style={styles.imageContainer}>
          {media.map(renderItem)}
          {AddImage}
          {/* Needed to prevent a gap between Add image and the rest due to space-between*/}
          <View style={{ width: MEDIA_GRID_WIDTH }} />
        </View>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  imageContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    marginVertical: 20,
  },
  image: {
    height: "100%",
    width: "100%",
  },
  selectMedia: {
    alignItems: "center",
    backgroundColor: COLORS.grayBox,
    height: "100%",
    justifyContent: "center",
    width: "100%",
  },
  inputActionsStyle: {
    alignItems: "center",
    height: "100%",
    justifyContent: "center",
    width: "100%",
  },
});
