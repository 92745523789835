import axios from "utils/axios";
import { API_HOST } from "utils/constants";
import { ExtendedAuthInstance } from "react-redux-firebase";

export const signUp = async (
  firebase: ExtendedAuthInstance,
  displayName: string,
  email: string = ""
) => {
  if (email) {
    await firebase.updateEmail(email);
  }

  await firebase.updateAuth({
    displayName,
  });

  await axios.post(`${API_HOST}/signup`, {
    data: "here",
  });

  return firebase.auth().currentUser;
};
