import React, { useState, useCallback } from "react";
import { View, StyleSheet } from "react-native";
import {
  InputFieldView,
  KTextInput,
  KButton,
  AddFriendButton,
} from "components";
import { parseEmail } from "utils/strings";
import { ConEmailWarning } from "components/ConEmailWarning";
import { useAddFriend } from "hooks/friends/useAddFriend";
import { logTryAddFriend } from "utils/analytics";
import { AddFriendViewBackButton } from "./AddFriendViewBackButton";

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  text: { textAlign: "center" },
  footnote: {
    textAlign: "center",
    margin: 10,
  },
  profileContainer: {
    marginHorizontal: 4,
    marginVertical: 12,
  },
});

export const AddByEmailView = ({
  setShownScreen,
  addFriend,
  fetchState,
}: {
  setShownScreen;
  addFriend: ReturnType<typeof useAddFriend>;
  fetchState;
}) => {
  const [email, setEmail] = useState("");
  const { isEmail, emailTLD } = parseEmail(email);
  const onSubmit = useCallback(() => {
    const processedEmail = email.trim();
    addFriend({ email: processedEmail }, { id: `email:${processedEmail}` });
    logTryAddFriend({ email: processedEmail }, "AddByEmailView");
  }, [addFriend, email]);

  return (
    <View style={styles.container}>
      <AddFriendViewBackButton setShownScreen={setShownScreen} />
      <InputFieldView>
        <KTextInput
          label="Your friend's sweet email"
          placeholder="sally.sue@coolmail.com"
          keyboardType="email-address"
          value={email}
          onChangeText={text => setEmail(text)}
          onSubmitEditing={() => {
            if (isEmail) {
              onSubmit();
            }
          }}
          autoCapitalize="none"
          autoCompleteType="off"
          autoCorrect={false}
          autoFocus
        />
        {isEmail && emailTLD === ".con" && <ConEmailWarning />}
        <AddFriendButton
          fetchState={fetchState[`email:${email}`]}
          alreadyInvited={false}
          Button={
            <KButton
              icon="email"
              label="Invite this Email"
              onPress={onSubmit}
              disabled={!isEmail}
            />
          }
        />
      </InputFieldView>
    </View>
  );
};
