import React from "react";
import { View, TouchableOpacity, StyleSheet, Platform } from "react-native";

import { isAChallenge } from "types/habits";
import { ChallengeTitleBadge } from "components/Verticals/Challenge/ChallengeTitleBadge";
import { Body, Footnote } from "components/UI/Typography";

const wrapTouchable = (item, onPress) => (
  <TouchableOpacity onPress={onPress}>{item}</TouchableOpacity>
);
const wrapSeparator = item => (
  <>
    {item}
    <View style={styles.separator} />
  </>
);

export const Item = ({
  children,
  style,
  touchable,
  separator,
  onPress,
  format,
}) => {
  let item = (
    <View style={[styles.item, style, styles[format]]}>{children}</View>
  );
  item = touchable ? wrapTouchable(item, onPress) : item;
  item = separator ? wrapSeparator(item) : item;
  return <>{item}</>;
};

export const HabitTitle = ({ habit, habitid, ownHabit }) => {
  const { emoji = "", title } = habit;
  const display = `${emoji} ${title}`.trim();

  return (
    <View style={styles.habitTitleContainer}>
      {isAChallenge(habit) && (
        <ChallengeTitleBadge habit={habit} ownHabit={ownHabit} />
      )}
      {__DEV__ ? (
        <TouchableOpacity
          onPress={() => {
            const { Clipboard } = require("react-native");
            Clipboard.setString(habitid);
          }}
        >
          <Body numberOfLines={1}>{display}</Body>
          <Footnote>{`[DEV] Tap to copy ID: ${habitid}`}</Footnote>
        </TouchableOpacity>
      ) : (
        <Body numberOfLines={1}>{display}</Body>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  item: {
    justifyContent: "center",
    padding: 10,
  },
  heavyPad: {
    flexGrow: 1,
    paddingVertical: 10,
    paddingHorizontal: 10,
  },
  sectionTitle: {
    textDecorationLine: "underline",
    marginBottom: -10,
  },
  separator: {
    height: 1,
    backgroundColor: "gray",
  },
  leftAndRight: {
    alignItems: "center", // vertical center of item
    flexDirection: "row",
    justifyContent: "space-between",
  },
  startLeft: {
    justifyContent: "flex-start", // start from left
  },
  fullWidth: {
    flexGrow: 1,
  },
  habitTitleContainer: {
    marginBottom: 12,
    ...Platform.select({
      web: {
        flex: 1,
      },
      default: {
        flexGrow: 1,
      },
    }),
  },
  avatar: {
    height: 45,
    width: 45,
    borderRadius: 45,
  },
  border: {
    borderRadius: 4,
    borderWidth: 0.5,
    borderColor: "#d6d7da",
  },
});
