import React from "react";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { RoutinePanel } from "panels/RoutinePanel";

const Stack = createStackNavigator();

export const RoutineWebScreen = ({ match }) => {
  return (
    <NavigationContainer>
      <Stack.Navigator
        screenOptions={{
          headerShown: false,
        }}
      >
        <Stack.Screen name="RoutineWebScreen">
          {() => <RoutinePanel match={match} />}
        </Stack.Screen>
      </Stack.Navigator>
    </NavigationContainer>
  );
};
