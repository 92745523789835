import React, { useState } from "react";
import {
  View,
  Text,
  Keyboard,
  StyleSheet,
  KeyboardAvoidingView,
} from "react-native";
import { KButton, KTextInput } from "components";
import { Portal, Dialog, Checkbox } from "react-native-paper";
import axios from "utils/axios";

import { API_HOST } from "utils/constants";
import { logShareFeedback } from "utils/analytics";
import { COLORS } from "utils/appStyles";
import { FeedbackSource } from "types/feedback";

export const FeedbackModal = ({
  setShouldShowModal,
  source,
  onSend,
  title = "Share feedback",
  placeholder = "Tell us about your experience so far...",
  feedbackPrepend = "",
}: {
  setShouldShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  source: FeedbackSource;
  onSend?: () => void;
  title?: string;
  placeholder?: string;
  feedbackPrepend?: string;
}) => {
  const [feedbackText, setFeedbackText] = useState("");
  const [emailChecked, setEmailChecked] = useState(true);
  const [isDismissSuccess, setIsDismissSuccess] = useState<boolean>(false);

  return (
    <Portal>
      <KeyboardAvoidingView style={styles.container} behavior="height">
        <Dialog
          visible
          onDismiss={() => {
            Keyboard.dismiss();
            setShouldShowModal(false);
          }}
          dismissable
          style={styles.feedbackContainer}
        >
          <Dialog.Title>{title}</Dialog.Title>
          <Dialog.Content>
            <KTextInput
              value={feedbackText}
              placeholder={placeholder}
              onChangeText={text => setFeedbackText(text)}
              disabled={false}
              autoCapitalize="none"
              autoFocus
              multiline
              style={{ maxHeight: 150 }}
            />
          </Dialog.Content>

          <Dialog.Actions>
            <View style={styles.rowLayout}>
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <Checkbox
                  status={emailChecked ? "checked" : "indeterminate"}
                  onPress={() => {
                    setEmailChecked(!emailChecked);
                  }}
                  uncheckedColor="red"
                  color={COLORS.accent}
                />
                <Text style={{ fontSize: 14 }}>Include my email address</Text>
              </View>
              <KButton
                label="Send"
                mode="text"
                icon={isDismissSuccess && "check"}
                color="black"
                disabled={!feedbackText}
                onPress={async () => {
                  try {
                    setIsDismissSuccess(true);
                    await axios.post(`${API_HOST}/sendFeedback`, {
                      emailChecked,
                      feedbackText: feedbackPrepend + feedbackText,
                      source,
                    });
                  } catch (error) {
                    console.log(error);
                  }
                  setShouldShowModal(false);
                  onSend && onSend();
                  logShareFeedback(feedbackText.length, feedbackText, source);
                }}
              />
            </View>
          </Dialog.Actions>
        </Dialog>
      </KeyboardAvoidingView>
    </Portal>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  feedbackContainer: {
    justifyContent: "flex-start",
  },
  rowLayout: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
});
