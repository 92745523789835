export default {
  addFriend: {
    en: "Add Friend",
    ja: "友達を追加",
    "zh-Hans": "添加好友",
    "zh-Hant": "添加好友",
  },
  addHabit: {
    en: "Add Habit",
    ja: "ハビットを追加",
    "zh-Hans": "创建习惯",
    "zh-Hant": "創建習慣",
  },
  archive: {
    en: "Archive",
    ja: "アーカイブ",
    "zh-Hans": "存档",
    "zh-Hant": "存檔",
  },
  archivedHabitsDoNotShow: {
    en:
      "Archived habits don't show on your habit list and friends can't see them. View or un-archive habits from your Profile.",
    ja:
      "アーカイブされたハビットはハビットリストに表示されず、友達はそれらを見ることができません。プロフィールからハビットを表示またはアーカイブ解除してください。",
    "zh-Hans":
      "存档的习惯不会显示在您的习惯列表上，朋友也不会看到它们。在个人资料中查看或取消存档习惯。",
    "zh-Hant":
      "存檔的習慣不會顯示在您的習慣列表上，朋友也不會看到它們。在個人資料中查看或取消存檔習慣。",
  },
  areYouSureDelete: {
    en: (title: string) => `Are you sure you want to delete ${title} ?`,
    ja: (title: string) => `${title} を削除してもよろしいですか？`,
    "zh-Hans": (title: string) => `确定要删除 ${title} 吗?`,
    "zh-Hant": (title: string) => `確定要刪除 ${title} 嗎?`,
  },
  cancel: {
    en: "Cancel",
  },
  confirmDelete: {
    en: "Confirm Deletion",
    ja: "削除を確認",
    "zh-Hans": "确认删除",
    "zh-Hant": "確認刪除",
  },
  confirmInvites: {
    en: "Confirm Invites",
    ja: "招待を確認",
    "zh-Hans": "确认邀请",
    "zh-Hant": "確認邀請",
  },
  confirmSharing: {
    en: "Confirm Sharing",
    ja: "共有を確認",
    "zh-Hans": "确认分享",
    "zh-Hant": "確認分享",
  },
  delete: {
    en: "Delete",
    ja: "削除する",
    "zh-Hans": "删除",
    "zh-Hant": "刪除",
  },
  disable: {
    en: "Disable",
    ja: "無効にする",
    "zh-Hans": "禁用",
    "zh-Hant": "禁用",
  },
  editHabit: {
    en: "Edit Habit",
    ja: "ハビットを編集",
    "zh-Hans": "编辑习惯",
    "zh-Hant": "編輯習慣",
  },
  errorAddingHabit: {
    en: "Error adding habit :( Please try again later.",
    ja: "発生しました :( もう一度やり直してください",
    "zh-Hans": "添加习惯错误 :( 请稍后再试",
    "zh-Hant": "添加習慣錯誤 :( 請稍後再試",
  },
  friends: {
    en: "Friends!",
    ja: "友達！",
    "zh-Hans": "好友！",
    "zh-Hant": "好友！",
  },
  gotIt: {
    en: "Got it",
    ja: "了解",
    "zh-Hans": "知道了",
    "zh-Hant": "知道了",
  },
  join: {
    en: "Join",
    ja: "参加する",
    "zh-Hans": "参加",
    "zh-Hant": "参加",
  },
  //TODO: Replace challenge with group habit in other langs
  joinChallenge: {
    en: "Join Habit",
    ja: "チャレンジに参加",
    "zh-Hans": "加入挑战",
    "zh-Hant": "加入挑戰",
  },
  //TODO: Replace challenge with group habit in other langs
  joiningChallenge: {
    en: "Joining Habit",
    ja: "チャレンジに参加しています",
    "zh-Hans": "正在加入挑战",
    "zh-Hant": "正在加入挑戰",
  },
  no: {
    en: "No",
    ja: "いいえ",
    "zh-Hans": "取消",
    "zh-Hant": "取消",
  },
  noInvite: {
    en:
      "You haven't invited anyone, so no one can join this habit yet. Are you sure?",
    ja:
      "誰も招待していないので、誰もこのチャレンジに参加できません。よろしいですか？",
    "zh-Hans": "您尚未邀请任何人，因此没有人可以参加这个挑战。确定？",
    "zh-Hant": "您尚未邀請任何人，因此沒有人可以參加這個挑戰。確定？",
  },
  notNow: {
    en: "Not Now",
    ja: "今はやめろ",
    "zh-Hans": "暂时不要",
    "zh-Hant": "暫時不要",
  },
  noSharing: {
    en: "You aren't sharing this habit with anyone. Are you sure?",
    ja: "このハビットを誰とも共有していません。よろしいですか？",
    "zh-Hans": "您确定不要与任何人分享这种习惯吗？",
    "zh-Hant": "您確定不要與任何人分享這種習慣嗎？",
  },
  //TODO: replace challenge with habit in other langs
  onlyOwnerCanInvite: {
    en: (ownerName: string) =>
      `Only ${ownerName} can invite others to join the habit. Progress will be shared with participants and friends you choose.`,
    // ja: (ownerName: string) =>
    //   `${ownerName}だけが他の人をチャレンジに招待できます。プログレスは、参加者や選択した友達と共有されます。`,
    // "zh-Hans": (ownerName: string) =>
    //   `只有 ${ownerName} 可以邀请其他人加入挑战。进度将与参与者和您选择的朋友分享。`,
    // "zh-Hant": (ownerName: string) =>
    //   `只有 ${ownerName} 可以邀請其他人加入挑戰。進度將與參與者和您選擇的朋友分享。`,
  },
  reminderInfo: {
    en: "Habits can be marked complete directly from reminder notifications",
  },
  save: {
    en: "Save",
    ja: "セーブ",
    "zh-Hans": "保存",
    "zh-Hant": "保存",
  },
  setReminder: {
    en: "Setting a reminder",
  },
  startJourney: {
    en: "Start Journey",
  },
  //TODO: replace challenge with habit in other langs
  someoneChallenge: {
    en: (name: string) => `${name}'s habit`,
    // ja: (name: string) => `${name}のチャレンジ`,
    // "zh-Hans": (name: string) => `${name}的挑战`,
    // "zh-Hant": (name: string) => `${name}的挑戰`,
  },
  //TODO: replace challenge with habit in other langs
  someoneChallengeNotFriends: {
    en: (name: string) => `${name}'s habit (not friends)`,
    // ja: (name: string) => `${name}のチャレンジ（友達じゃない）`,
    // "zh-Hans": (name: string) => `${name}的挑战 (不再是朋友)`,
    // "zh-Hant": (name: string) => `${name}的挑戰 (不再是朋友)`,
  },
  toReEnable: {
    en: 'To re-enable, visit your "Archived Habits" on the Profile Tab.',
    ja:
      "再度有効にするには、プロファイルタブの「ハビットアーカイブ」にアクセスしてください。",
    "zh-Hans": "要重新开启，请在“个人资料”标签上访问“习惯存档”。",
    "zh-Hant": "要重新開啟，請在“個人資料”標籤上訪問“習慣存檔”。",
  },
  yes: {
    en: "Yes",
    ja: "はい",
    "zh-Hans": "确定",
    "zh-Hant": "確定",
  },
  yesSave: {
    en: "Yes - Save",
    ja: "はい - 保存",
    "zh-Hans": "确定 - 保存",
    "zh-Hant": "確定 - 保存",
  },
  youHaveNoFriends: {
    en:
      "You don't have any friends. Friends can help you build your habits by holding you accountable. Want to add a friend?",
    ja:
      "友達がいません。友達はあなたに責任を負わせることであなたの習慣を築く手助けをしてくれます。友達を追加しますか？",
    "zh-Hans":
      "你没有任何朋友。朋友可以通过监督你来帮助你养成习惯。要添加朋友吗？",
    "zh-Hant":
      "你沒有任何朋友。朋友可以通過監督你來幫助你養成習慣。要添加朋友嗎？",
  },
  youHaveNoFriendsChallenge: {
    en:
      "You don't have any friends so no one can join your habit. Want to add a friend?",
    // ja:
    //   "友達がいないため、誰もチャレンジに参加できません。友達を追加しますか？",
    // "zh-Hans": "您没有任何朋友，因此没有人可以加入您的挑战。要添加朋友吗？",
    // "zh-Hant": "您沒有任何朋友，因此沒有人可以加入您的挑戰。要添加朋友嗎？",
  },
};
