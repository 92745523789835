export default {
  deleteFriend: {
    en: "Delete friend",
    ja: "友達を削除",
    "zh-hans": "删除好友",
    "zh-hant": "刪除好友",
  },
  deleteFriendConfirm: {
    en: (name: string) => `Are you sure you want to remove ${name}?`,
    ja: (name: string) => `${name}を削除してもよろしいですか？`,
    "zh-hans": (name: string) => `您确定要删除${name}吗？`,
    "zh-hant": (name: string) => `您確定要刪除${name}嗎？`,
  },
  habitsSharedWithFriend: {
    en: "Habits shared with friend",
    ja: "友達とシェアしているハビット",
    "zh-hans": "与好友分享的习惯",
    "zh-hant": "與好友分享的習慣",
  },
  manageNotifications: {
    en: "Manage habit update notifications",
    ja: "ハビットアップデートの通知を管理する",
    "zh-hans": "管理习惯更新通知",
    "zh-hant": "管理習慣更新通知",
  },
  no: {
    en: "No",
    ja: "いいえ",
    "zh-Hans": "取消",
    "zh-hant": "取消",
  },
  yes: {
    en: "Yes",
    ja: "はい",
    "zh-Hans": "确定",
    "zh-hant": "確定",
  },
};
