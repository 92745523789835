import React, { useCallback } from "react";
import { StyleSheet, View } from "react-native";
import { useGetAuth, useGetHabitsCount } from "redux/selectors";
import { KButton } from "components";
import { COLORS } from "styles";

// adding habits
import { logPressFabAction } from "utils/analytics";
import { MAX_NUMBER_NON_DEFAULT_UN_AUTHENTICATED_HABITS } from "utils/constants";
import { MotivationButtonWrapper } from "components/Motivation/MotivationButton";
export const HabitsFooter = ({ navigation }) => {
  const { auth } = useGetAuth();
  // add habit
  const habitsCount = useGetHabitsCount();
  const addAction = useCallback(() => {
    logPressFabAction("habit", habitsCount);
    if (
      auth &&
      auth.isAnonymous &&
      habitsCount > MAX_NUMBER_NON_DEFAULT_UN_AUTHENTICATED_HABITS
    ) {
      navigation.navigate("LoginScreen", {
        reason: "addHabit",
      });
    } else {
      navigation.navigate("AddHabitScreen");
    }
  }, [auth, habitsCount, navigation]);

  return (
    <View style={styles.wrapper}>
      <KButton
        label="+ Add Habit"
        mode="outlined"
        color={COLORS.GRAY3}
        onPress={addAction}
        labelStyle={styles.buttonPositivelLabel}
        style={styles.buttonPositive}
      />

      <MotivationButtonWrapper
        customStyle={{ marginLeft: 40, marginTop: 10 }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    marginTop: 20,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonPositivelLabel: {
    fontWeight: "bold",
  },
  buttonPositive: {
    borderColor: COLORS.GRAY3,
  },
});
