import { RouteProp } from "@react-navigation/native";
import React from "react";
import { StackProps } from "layouts/MobileLayout";

import { ToggleSetting } from "components";

import { useGetHabits, useGetSpecificHabit } from "redux/selectors";
import { updateSharing } from "utils/habits";
import { renderHabitTitle } from "utils/strings";
import { ScrollView } from "react-native-gesture-handler";
import { Visibility } from "types/habits";

const HabitSharingToggleItem = ({ friendid, habitid }) => {
  const { habit } = useGetSpecificHabit(habitid);
  const isSharedWithAll = habit.visibility === Visibility.FRIENDS;
  const onlyMe = habit.visibility === Visibility.ONLY_ME;

  return (
    <ToggleSetting
      label={renderHabitTitle(habit)}
      caption={
        isSharedWithAll
          ? "Shared with all friends"
          : onlyMe
          ? "Only Visible to you. Change this by editing the habit."
          : ""
      }
      value={friendid in habit.sharing && habit.sharing[friendid]}
      disabled={isSharedWithAll || onlyMe}
      onToggle={value => updateSharing(habitid, friendid, value)}
    />
  );
};

export const SharedHabitsToggleScreen = ({
  route,
}: {
  route: RouteProp<StackProps, "SharedHabitsToggleScreen">;
}) => {
  const { friendid } = route.params;
  const habits = useGetHabits();

  return (
    <ScrollView>
      {habits.map(habit => (
        <HabitSharingToggleItem
          key={habit.id}
          friendid={friendid}
          habitid={habit.id}
        />
      ))}
    </ScrollView>
  );
};
