import React from "react";
import { View, StyleSheet, Text } from "react-native";
import { StaticRoutine, RoutineType } from "types/routines";
import { COLORS } from "utils/appStyles";
import { KImage, Title, Body, KButton, KAvatar, KScrollView } from "components";
import { useDimensionsContext, useLayoutContext, Layouts } from "contexts";
import { IconButton } from "react-native-paper";
import { Ionicons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { isMobilePlatform, isWeb } from "utils/helpers";
import { useString } from "hooks";
import { HorizontalSeparator } from "components/UI/HorizontalSeparator";
import { MainNavigation } from "layouts/MobileLayout";
import { useGetAuth, useGetRoutine } from "redux/selectors";
import { ChallengeInvites } from "components/Challenges/ChallengeInvites";
import { SectionSubtitle } from "components/UI/Typography";
import { LogInView } from "components/Login/LoginView";
import { useHistory } from "utils/react-router";
import Markdown from "react-native-markdown-display";
import * as WebBrowser from "expo-web-browser";

const styles = StyleSheet.create({
  close: {
    position: "absolute",
    top: 12,
    right: 12,
    zIndex: 10,
    backgroundColor: "white",
  },
  title: {
    position: "absolute",
    bottom: 12,
    zIndex: 10,
    color: "white",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    marginHorizontal: 20,
    padding: 8,
  },
  contentContainer: {
    margin: 20,
  },
  description: {
    marginBottom: 18,
  },
  // Attributes
  attributesContainer: {
    marginVertical: 18,
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    width: "90%",
    maxWidth: 400,
    alignSelf: "center",
  },
  label: {
    fontWeight: "300",
    fontSize: 16,
    marginBottom: 12,
    flex: 1,
  },
  attribute: {
    width: "48%",
    marginVertical: 4,
    flexDirection: "row",
  },
  icon: { marginRight: 12 },
  avatar: {
    marginRight: 12,
  },
  separator: {
    marginBottom: 12,
  },
  // Joining
  startYourOwnSection: {
    marginHorizontal: 20,
  },
  sectionSubtitle: {
    marginVertical: 20,
  },
  button: {
    maxWidth: 200,
    alignSelf: "center",
  },
  marginBottom: { marginBottom: 100 },
});

const Label = ({ children }) => <Text style={styles.label}>{children}</Text>;

const AttributesBlock = ({ type, timePerDay, length }) => {
  const { getScaledFontSize } = useDimensionsContext();
  const s = useString("routine");
  const iconSize = getScaledFontSize(1.4);
  const textStyle = { ...styles.label, fontSize: getScaledFontSize(1.1) };

  return (
    <View style={styles.attributesContainer}>
      {/* minutes per day */}
      <View style={styles.attribute}>
        <Ionicons name="md-time" size={iconSize} style={styles.icon} />
        <Text style={textStyle}>
          {timePerDay} {s("attributePerDay")}
        </Text>
      </View>
      {/* number of days */}
      <View style={styles.attribute}>
        <Ionicons name="md-calendar" size={iconSize} style={styles.icon} />
        <Text style={textStyle}>
          {type === RoutineType.Ongoing
            ? s("attributeLengthOngoing")
            : `${length} ${s("attributeNumberOfDays")}`}
        </Text>
      </View>

      {/* invite friends and join community */}
      <View style={styles.attribute}>
        <Ionicons name="md-chatbubbles" size={iconSize} style={styles.icon} />
        <Text style={textStyle}>{s("attributeCommunity")}</Text>
      </View>
      {/* make action a habit */}
      <View style={styles.attribute}>
        <Ionicons name="ios-heart" size={iconSize} style={styles.icon} />
        <Text style={textStyle}>{s("attributeAction")}</Text>
      </View>
    </View>
  );
};

const JoinSection = ({ routineId }) => {
  const navigation = useNavigation<MainNavigation>();
  const history = useHistory();
  const s = useString("routine");
  const { auth } = useGetAuth();
  const layout = useLayoutContext();

  if (auth.isAnonymous) {
    return (
      <View style={styles.startYourOwnSection}>
        <SectionSubtitle style={styles.sectionSubtitle}>
          {s("login")}
        </SectionSubtitle>
        <LogInView
          hideMotivation
          webRedirectTo={isWeb && window.location.href}
        />
      </View>
    );
  }

  return (
    <>
      <ChallengeInvites
        routineId={routineId}
        renderTitle={() => (
          <SectionSubtitle>{s("joinFriends")}</SectionSubtitle>
        )}
      />
      <View style={styles.startYourOwnSection}>
        <SectionSubtitle style={styles.sectionSubtitle}>
          {s("startOwn")}
        </SectionSubtitle>
        <KButton
          label={s("joinButton")}
          mode="outlined"
          color={COLORS.logoBlue}
          style={styles.button}
          onPress={() => {
            if (layout === Layouts.MOBILE) {
              navigation.navigate("AddHabitScreen", { routineId });
            } else {
              history.push(`/me/addHabit`, {
                routineId,
              });
            }
          }}
        />
      </View>
    </>
  );
};

export const RoutineOverviewView = ({
  routineId,
  profileView,
}: {
  routineId: string;
  profileView?: boolean;
}) => {
  const routine = useGetRoutine(routineId);
  const { height, width, getScaledFontSize } = useDimensionsContext();
  const navigation = useNavigation();
  const s = useString("routine");

  const imageContainerStyle = { width, height: height * 0.2, minHeight: 250 };
  const textStyle = { fontSize: getScaledFontSize(1.2) };
  const iconSize = getScaledFontSize(1.1);

  if (!routine) {
    return null;
  }

  return (
    <KScrollView>
      <View style={imageContainerStyle}>
        {isMobilePlatform && (
          <IconButton
            icon="close"
            color="black"
            size={18}
            onPress={() => {
              navigation.goBack();
            }}
            style={styles.close}
          />
        )}
        <Title style={styles.title}>{routine.name}</Title>
        <KImage remote={routine.photoUrl} style={imageContainerStyle} />
      </View>
      <View style={styles.contentContainer}>
        <Body style={[styles.description, textStyle]}>
          {routine.description}
        </Body>
      </View>
      {!profileView && <JoinSection routineId={routineId} />}
      <View style={styles.contentContainer}>
        <HorizontalSeparator style={styles.separator} />
        <AttributesBlock
          type={routine.type}
          timePerDay={routine.timePerDay}
          length={(routine as StaticRoutine).length}
        />
        <View>
          <Label>{s("labelCreatedBy")}</Label>
          <View style={[styles.attribute, { alignItems: "center" }]}>
            <KAvatar
              source={{ uri: routine.ownerAvatar }}
              size={getScaledFontSize(2)}
              style={styles.avatar}
            />
            <Text style={{ fontSize: iconSize }}>{routine.ownerName}</Text>
          </View>
          <Markdown
            onLinkPress={url => {
              if (isMobilePlatform) {
                WebBrowser.openBrowserAsync(url);
                return false;
              }
              // return true to open with `Linking.openURL
              // return false to handle it yourself
              return true;
            }}
          >
            {routine.ownerDescription}
          </Markdown>
        </View>
      </View>
      {/* Scroll view / safe area view doesn't scroll all the way down sometimes */}
      <View style={styles.marginBottom} />
    </KScrollView>
  );
};
