import React, { memo } from "react";
import { MessageUIComponentProps, MessageSimple } from "utils/stream";
import { logChatMessageReaction, logChatMessagePress } from "utils/analytics";
import { SUPPORTED_MESSAGE_REACTIONS } from "utils/constants";
import { MessageType } from "types/message";
import { NoteMessage } from "components/Chat/NoteMessage";
import { KMessageText } from "components/Chat/KMessageText";

export const KMessage = memo((props: MessageUIComponentProps) => {
  const { message } = props;
  if (message.kCustomType === MessageType.NOTE_UPDATE) {
    return <NoteMessage streamProps={props} data={message.kData} />;
  }

  // Intercepting message actions for tracking analytics
  const handleReaction = (type: string) => {
    logChatMessageReaction(message.text, type, message.user.id);
    props.handleReaction(type);
  };

  return (
    <MessageSimple
      {...props}
      MessageText={KMessageText}
      handleReaction={handleReaction}
      supportedReactions={SUPPORTED_MESSAGE_REACTIONS}
      onPress={thisArg => {
        logChatMessagePress(message);
        thisArg.props.openReactionPicker();
      }}
    />
  );
});
