import React, { memo } from "react";
import { View, Image, ImageSourcePropType, StyleSheet } from "react-native";
import { Title, Body, Subtitle } from "components";
import { isMobilePlatform } from "utils/helpers";

import { chatIcon, groupIcon } from "assets/images";

const FullValueTemplate = memo(({ title }: { title: string }) => {
  return (
    <View>
      <Title style={{ textAlign: "center" }}>{title}</Title>
      <View style={styles.featureList}>
        <Body style={styles.featureItem}>
          🧑🏿‍🤝‍🧑🏻 Add friends to hold you accountable
        </Body>
        <Body style={styles.featureItem}>
          💬 Chat to check on their progress
        </Body>
        <Body style={styles.featureItem}>
          🔄 Back up your habits to the cloud
        </Body>
      </View>
    </View>
  );
});

const ImageValueTemplate = memo(
  ({
    titleText,
    bodyText,
    image,
  }: {
    titleText: string;
    bodyText: string;
    image: ImageSourcePropType;
  }) => (
    <>
      <View style={{ height: 120, marginTop: 20, marginBottom: 25 }}>
        <Image
          source={image}
          resizeMode="contain"
          style={{ flex: 1, height: undefined, width: undefined }}
        />
      </View>
      <View style={{ marginBottom: 10 }}>
        <Subtitle style={{ textAlign: "center" }}>{titleText}</Subtitle>
        <Body style={styles.featureItem}>{bodyText}</Body>
      </View>
    </>
  )
);
const signInMessaging = {
  FriendLogInScreen: {
    titleText: "Friend request waiting",
    bodyText: `${
      isMobilePlatform ? "Sign in" : "Get the app"
    } to share habits & to hold your friends accountable`,
    image: groupIcon,
  },
  ChatLogInScreen: {
    titleText: "Friends provide accountability",
    bodyText: `${
      isMobilePlatform ? "Sign in" : "Get the app"
    } to receive updates about their habits & to chat with friends`,
    image: chatIcon,
  },
  AddFriends: {
    titleText: "Friends provide accountability",
    bodyText: `${
      isMobilePlatform ? "Sign in" : "Get the app"
    } to share your habits with friends & to save your habits to the cloud`,
    image: groupIcon,
  },
  StartRoutine: {
    titleText: "Start Your Journey",
    bodyText: `${
      isMobilePlatform ? "Sign in" : "Get the app"
    } to start journeys, to add unlimited habits, and to save your progress to the cloud`,
    image: groupIcon,
  },
};

export const LoginMotivationSection = ({ reason }) => {
  //these two show up for anonymous users when tapping friend/chat tab
  if (reason === "Friends") {
    return <ImageValueTemplate {...signInMessaging.FriendLogInScreen} />;
  } else if (reason === "Chat") {
    return <ImageValueTemplate {...signInMessaging.ChatLogInScreen} />;
  } else if (reason === "StartRoutine") {
    return <ImageValueTemplate {...signInMessaging.StartRoutine} />;
  }
  //these show up for anon users who try to add > 3 habits or add friends
  else if (reason === "addFriend") {
    return <ImageValueTemplate {...signInMessaging.AddFriends} />;
  } else if (reason === "addHabit") {
    return <FullValueTemplate title="Sign In to Add More Habits" />;
  } else if (reason === "Profile") {
    return <FullValueTemplate title="Get the best of SnapHabit" />;
  }
  //this shows up for users who previously logged in
  else {
    return (
      <>
        <Title style={styles.text}>SnapHabit</Title>
        <Body style={styles.text}>Welcome back!</Body>
      </>
    );
  }
};

const styles = StyleSheet.create({
  text: { margin: 20, textAlign: "center" },
  featureList: {
    marginHorizontal: "5%",
    marginVertical: 15,
  },
  featureItem: {
    marginVertical: 8,
    textAlign: "center",
  },
});
