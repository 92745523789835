import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DayStatus } from "types/habits";

export enum ToastTypes {
  NOTE = "NOTE",
  PERMISSION_DENIED = "PERMISSION_DENIED",
  CLIPBOARD = "CLIPBOARD",
  MOTIVATION = "MOTIVATION",
}

const toastSlice = createSlice({
  name: "toast",
  initialState: null,
  reducers: {
    setToast(
      _state,
      action: PayloadAction<
        | {
            type: ToastTypes.NOTE;
            props: {
              status: DayStatus;
              dayid: string;
              date: string;
              habitid: string;
            };
          }
        | {
            type: ToastTypes.PERMISSION_DENIED;
          }
        | {
            type: ToastTypes.CLIPBOARD;
          }
        | {
            type: ToastTypes.MOTIVATION;
          }
      >
    ) {
      return action.payload;
    },
    removeToast() {
      return null;
    },
  },
});

export const { setToast, removeToast } = toastSlice.actions;

export const toastSliceReducer = toastSlice.reducer;
