import { Ionicons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import React, { memo } from "react";
import {
  TouchableOpacity,
  StyleSheet,
  ActivityIndicator,
  View,
} from "react-native";
import { Card } from "react-native-paper";
import SendBird from "sendbird";

import {
  Body,
  CoachIcon,
  Footnote,
  HabitListItem,
  KAvatar,
  KButton,
  KCard,
} from "components";
import { useString } from "hooks";
import { Logger } from "utils/Logger";
import { StackProps } from "layouts/MobileLayout";
import {
  useGetFriend,
  useGetHabits,
  useGetIsHabitsLoaded,
} from "redux/selectors";
import { Friend } from "types/friends";
import { Habit } from "types/habits";
import { getWeek } from "utils/habits";
import { COLORS } from "utils/appStyles";
import { useChatContext } from "contexts";
import { useChatChannel } from "hooks/chat/useChatChannel";

const week = getWeek().map(day => ({ ...day, display: day.display[0] }));

export const FriendCardLayout = ({
  isLoading,
  friend,
  habits,
  state,
  actions,
  deleteFriend,
}: {
  isLoading: boolean;
  friend: Friend;
  habits: Habit[];
  state;
  actions;
  deleteFriend;
}) => {
  const cardTitle = friend.name + (__DEV__ ? ` (${friend.id})` : "");
  const s = useString("friendListScreen");
  return (
    <KCard>
      <TouchableOpacity onPress={actions.friendScreen}>
        <Card.Title
          title={cardTitle}
          subtitle={
            habits.length > 0 ? s("sharingNumHabitsWithYou", habits.length) : ""
          }
          left={() =>
            !!friend.avatar && (
              <KAvatar
                source={{ uri: friend.avatar }}
                size={45}
                Icon={friend.type ? CoachIcon : null}
              />
            )
          }
          right={() => (
            <View style={styles.iconContainer}>
              <Ionicons name="ios-arrow-forward" size={15} />
            </View>
          )}
        />
      </TouchableOpacity>
      <Card.Content style={styles.cardContent}>
        {isLoading || state?.loading ? (
          <ActivityIndicator />
        ) : habits.length > 0 ? (
          habits
            .sort((h1, h2) => h2.streak - h1.streak)
            .slice(0, 2)
            .map((h, i) => (
              <HabitListItem
                key={h.id}
                habitid={h.id}
                week={week}
                ownHabit={false}
                friendid={friend.id}
              />
            ))
        ) : (
          <Body style={styles.zeroState}>{s("hasNotSharedHabits")}</Body>
        )}
        {friend.type && friend.type === "DEFAULT_COACH" && (
          <Footnote style={styles.coachMargin}>
            {s("justAFriendlyBot")}
          </Footnote>
        )}

        <Card.Actions style={styles.cardActions}>
          {friend.type === "DEFAULT_COACH" ? (
            <KButton
              icon="trash-can-outline"
              label={s("delete")}
              mode="text"
              color="red"
              onPress={() => deleteFriend(friend.id)}
              style={styles.cardAction}
              labelStyle={styles.cardActionLabel}
            />
          ) : (
            <KButton
              icon="account-multiple"
              label={s("friends")}
              mode="text"
              color={COLORS.accent}
              onPress={actions.friendsOfThisFriend}
              style={styles.cardAction}
              labelStyle={styles.cardActionLabel}
            />
          )}
          {habits.length > 2 && (
            <KButton
              label={s("viewAll")}
              mode="text"
              color={COLORS.accent}
              onPress={actions.friendScreen}
              style={styles.cardAction}
              labelStyle={styles.cardActionLabel}
            />
          )}
          <KButton
            label={s("chat")}
            mode="text"
            color={COLORS.accent}
            icon="chat"
            onPress={actions.chatScreen}
            style={styles.cardAction}
            labelStyle={styles.cardActionLabel}
          />
        </Card.Actions>
      </Card.Content>
    </KCard>
  );
};

export const FriendCard = memo(
  ({
    friendid,
    state,
    deleteFriend,
  }: {
    friendid: string;
    state: {
      loading?: boolean;
      error?: any;
    };
    deleteFriend;
  }) => {
    const navigation = useNavigation<StackNavigationProp<StackProps>>();
    const { friend } = useGetFriend(friendid);
    const habits = useGetHabits(friendid);
    const isLoaded = useGetIsHabitsLoaded(friendid);

    const actions = {
      friendScreen: () => {
        navigation.navigate("FriendScreen", {
          friendid,
          title: friend.name,
        });
      },
      friendsOfThisFriend: () => {
        navigation.navigate("FriendsOfThisFriendScreen", {
          friendid,
          title: friend.name,
        });
      },
      chatScreen: () => {
        navigation.navigate("ChatScreen", { title: friend.name, friendid });
      },
    };

    Logger.log("Friend Card re-render", friendid);

    return (
      <FriendCardLayout
        isLoading={!isLoaded}
        habits={habits}
        friend={friend}
        state={state}
        actions={actions}
        deleteFriend={deleteFriend}
      />
    );
  }
);

const styles = StyleSheet.create({
  cardContent: {
    paddingHorizontal: 0,
    paddingBottom: 0,
    borderTopWidth: 1,
    borderTopColor: "#eee",
  },
  cardActions: {
    marginTop: 5,
    borderTopWidth: 1,
    borderTopColor: "#eee",
    padding: 0,
  },
  cardAction: {
    flexGrow: 1,
    flexBasis: 0,
    padding: 5,
  },
  cardActionLabel: {
    letterSpacing: 0,
  },
  iconContainer: {
    marginHorizontal: 15,
  },
  coachMargin: {
    margin: 10,
  },
  zeroState: {
    marginVertical: 10,
    textAlign: "center",
  },
});
