import { useChatContext } from "contexts";
import { useGetAuth } from "redux/selectors";
import { useEffect, useState } from "react";
import { Channel } from "stream-chat";

export const useChatChannel = ({
  id,
  friendid,
}: {
  id?: string;
  friendid?: string;
}) => {
  const { client } = useChatContext();
  const { auth } = useGetAuth();
  const [channel, setChannel] = useState<Channel>();
  useEffect(() => {
    const c = client.channel(
      "messaging",
      id
        ? id
        : {
            members: [auth.uid, friendid],
          }
    );

    //! need the channel id other wise channel does not update....
    // e.g. no typing indicators, message list doesn't show new message
    //! <Channel/> sets watch so no need to call
    c.create().then(res => {
      const { id } = res.channel;

      setChannel(client.channel("messaging", id));
    });
  }, [client, auth, id, friendid]);

  return channel;
};
