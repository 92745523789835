import React from "react";
import {
  View,
  StyleSheet,
  Text,
  Keyboard,
  TouchableWithoutFeedback,
  TouchableOpacity,
  Clipboard,
} from "react-native";
import {
  NoteEditor,
  NoteEditorProps,
  RenderNoteProps,
} from "components/Notes/NoteEditor";
import moment from "moment";
import { Ionicons } from "@expo/vector-icons";

import { Body, Subtitle } from "components";
import { Title } from "react-native-paper";
import { COLORS } from "utils/appStyles";
import { useGetSpecificHabit, useGetDay } from "redux/selectors";
import { useString } from "hooks";
import { BottomSheetManipulator } from "components/BottomSheet/BottomSheet";
import { DayStatus } from "types/habits";
import { getAbsoluteDays } from "utils/time";
import { SaveStateIndicator } from "components/Notes/SaveStateIndicator";
import { ImageSaver } from "./ImageSaver";
import { TextSaver } from "./TextSaver";
import { Toggle } from "./Toggle";

const styles = StyleSheet.create({
  title: {
    marginTop: 10,
  },
  body: {
    backgroundColor: "white",
    minHeight: "100%",
    paddingHorizontal: 24,
    paddingTop: 10,
  },
  saveIndicatorContainer: {
    marginTop: 8,
  },
  privacyNote: {
    color: "#888",
    fontSize: 12,
    marginVertical: 20,
    textAlign: "center",
  },
  closeButton: {
    alignItems: "center",
    alignSelf: "center",
    borderColor: COLORS.fail,
    borderRadius: 50,
    borderWidth: 1,
    flexDirection: "row",
    justifyContent: "center",
    marginBottom: 100,
    marginTop: 30,
    padding: 15,
    width: "50%",
  },
  closeText: {
    color: COLORS.fail,
  },
});

const NoteLayout = ({
  isEditable,
  lastSaved,
  saveState,
  note,
  onChangeText,
  dayStatus,
  setBottomSheetPosition,
  ...mediaProps
}: RenderNoteProps & {
  dayStatus: DayStatus;
  setBottomSheetPosition: BottomSheetManipulator;
}) => {
  const s = useString("noteEditor");

  return (
    <>
      <Subtitle>{s("addTodaysNote")}</Subtitle>
      <View style={styles.saveIndicatorContainer}>
        {isEditable && (
          <SaveStateIndicator saveState={saveState} lastSaved={lastSaved} />
        )}
      </View>
      <TextSaver
        isEditable={isEditable}
        status={dayStatus}
        text={note}
        onFocus={setBottomSheetPosition.openFull}
        onChangeText={onChangeText}
      />
      <Subtitle>{s("addTodaysImage")}</Subtitle>
      <ImageSaver isEditable={isEditable} {...mediaProps} />

      {/* <Subtitle>{s("howDidItFeel")}</Subtitle>
      <EmojiSaver status={day.status} /> */}
    </>
  );
};

export const BottomSheetNoteEditor = ({
  setBottomSheetPosition,
  showDayToggle,
  ...noteEditorProps
}: {
  showDayToggle: boolean;
  setBottomSheetPosition: BottomSheetManipulator;
} & Omit<NoteEditorProps, "render">) => {
  const { habitid, date, ownHabit } = noteEditorProps;
  const s = useString("noteEditor");
  const { habit } = useGetSpecificHabit(habitid);
  const { emoji, title, visibility } = habit;
  const m = moment(date, "MM-DD-YYYY");
  const absoluteDay = getAbsoluteDays(m);
  //! Day may not exist if shared days aren't loaded
  //! So do not assume we have a day (unless its your own note)
  const day = useGetDay({ habitid, date, ownHabit });

  return (
    <TouchableWithoutFeedback onPressIn={Keyboard.dismiss}>
      <View style={styles.body}>
        <Body>{`${m.format("dddd MMMM D, YYYY")}`}</Body>
        <Title style={styles.title}>{`${emoji ?? ""} ${title}`}</Title>
        {__DEV__ && (
          <TouchableOpacity
            onPress={() =>
              Clipboard.setString(`notes/${habitid}/notesDay/${day.id}`)
            }
          >
            <Text>
              [DEV - click to copy] {`notes/${habitid}/notesDay/${day.id}`}
            </Text>
          </TouchableOpacity>
        )}

        {showDayToggle && (
          <>
            <Subtitle>{s("didYouSucceed")}</Subtitle>
            <Toggle day={day} absoluteDay={absoluteDay} />
          </>
        )}
        <NoteEditor
          {...noteEditorProps}
          render={props => (
            <NoteLayout
              {...props}
              dayStatus={day.status}
              setBottomSheetPosition={setBottomSheetPosition}
            />
          )}
        />
        {visibility !== "ONLY_ME" && (
          <Body style={styles.privacyNote}>{s("privacyNote")}</Body>
        )}
        <TouchableOpacity
          style={styles.closeButton}
          onPress={setBottomSheetPosition.close}
        >
          <Ionicons
            name="md-close"
            size={15}
            color={COLORS.fail}
            style={{ marginRight: 10 }}
          />
          <Text style={styles.closeText}>{s("close")}</Text>
        </TouchableOpacity>
      </View>
    </TouchableWithoutFeedback>
  );
};
