//! Anything that uses Navigation service must wait for navigationPromise to resolve
//! Otherwise, navigation is not guaranteed to be set! See notifications.ts
import {
  NavigationContainerRef,
  NavigationState,
  PartialState,
} from "@react-navigation/native";

let _navigator: NavigationContainerRef;
let navigationResolve;
const navigationPromise = new Promise(resolve => {
  navigationResolve = resolve;
});

function setTopLevelNavigator(navigatorRef) {
  _navigator = navigatorRef;
  navigationResolve();
}

function navigate(routeName, params) {
  _navigator.navigate(routeName, params);
}

function replace(routeName, params) {
  _navigator.replace(routeName, params);
}

function getCurrentState() {
  const findCurrentRoute = (
    navState: NavigationState | PartialState<NavigationState>
  ): NavigationState | PartialState<NavigationState> => {
    if ("index" in navState && navState.routes[navState.index].state) {
      return findCurrentRoute(navState.routes[navState.index].state);
    }
    return navState;
  };
  return findCurrentRoute(_navigator.getRootState());
}

// add other navigation functions that you need and export them
export const NavigationService = {
  setTopLevelNavigator,
  navigationPromise,
  navigate,
  replace,
  getCurrentState,
};
