import { isMobilePlatform, isWeb } from "utils/helpers";

export const reloadApp = async () => {
  if (isMobilePlatform) {
    const { reloadAsync } = await import("expo-updates");
    reloadAsync();
  } else if (isWeb) {
    location.reload();
  }
};
