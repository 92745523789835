export {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  match as MatchProps,
  useParams,
  Redirect,
} from "react-router-dom";
