import React, { useMemo } from "react";
import { TouchableOpacity, StyleSheet } from "react-native";
import { PanelRight } from "panels/subpanels/PanelRight";
import { Redirect, MatchProps, useHistory } from "utils/react-router";
import { HabitDetailsView } from "components";
import { useGetSpecificHabit } from "redux/selectors";
import { useFirestoreConnect } from "react-redux-firebase";
import { MaterialIcons } from "@expo/vector-icons";

import { COLORS } from "utils/appStyles";
import { isHabitRoutine } from "types/habits";
import { HabitRoutineView } from "components/Routines/HabitRoutineView";

export const HabitDetailsPanel = ({
  match,
}: {
  match: MatchProps<{ id: string; friendid?: string }>;
}) => {
  const { path, params } = match;
  const { friendid, id } = params;
  const ownHabit = path.startsWith("/me");
  const { habit } = useGetSpecificHabit(id);
  const history = useHistory();

  if (!habit) {
    return <Redirect to="/me" />;
  }

  return (
    <PanelRight>
      <>
        {isHabitRoutine(habit) ? (
          <HabitRoutineView habit={habit} habitid={id} friendid={friendid} />
        ) : (
          <HabitDetailsView
            habitid={id}
            habit={habit}
            friendid={friendid}
            ownHabit={ownHabit}
          />
        )}
        {ownHabit && (
          <TouchableOpacity
            onPress={() => {
              history.push(`/me/editHabit/${id}`);
            }}
            style={styles.editIcon}
          >
            <MaterialIcons name="edit" color={COLORS.logoBlue} size={30} />
          </TouchableOpacity>
        )}
      </>
    </PanelRight>
  );
};

export const FriendHabitDetailsPanel = ({
  match,
}: {
  match: MatchProps<{ id: string; friendid: string }>;
}) => {
  const { id } = match.params;

  const query = useMemo(
    () => [
      {
        collectionGroup: `notesDay`,
        where: [
          [`habitid`, "==", id],
          ["isPublic", "==", true],
        ],
        storeAs: `sharedNotes-${id}`,
      },
    ],
    [id]
  );

  useFirestoreConnect(query);

  return <HabitDetailsPanel match={match} />;
};

const styles = StyleSheet.create({
  editIcon: {
    position: "absolute",
    right: 10,
    top: 10,
  },
});
