import Constants from "expo-constants";
import * as AuthSession from "expo-auth-session";
import * as AppleAuthentication from "expo-apple-authentication";
import * as Crypto from "expo-crypto";
import * as Google from "expo-google-app-auth";

import {
  GOOGLE_AUTH_CLIENT_IDS,
  ASYNC_STORAGE_EMAIL_KEY,
  FACEBOOK_ID,
} from "utils/constants";
import { TokenStatus, FirestoreUserData } from "types/users";
import { reloadApp } from "utils/reload";
import {
  isLoaded,
  isEmpty,
  ExtendedFirebaseInstance,
  ExtendedAuthInstance,
  ExtendedStorageInstance,
  actionTypes,
} from "react-redux-firebase";
import { isMobilePlatform, isWeb } from "utils/helpers";
import { Platform } from "react-native";
import AsyncStorage from "@react-native-community/async-storage";
import { Logger } from "./Logger";
import firebaseSDK from "./firebase";

export enum AUTH_PROVIDERS {
  EMAIL = "emailLink",
  GOOGLE = "google.com",
  FACEBOOK = "facebook.com",
  APPLE = "apple.com",
}

export const isSignedUp = (profile: FirestoreUserData) =>
  isLoaded(profile) && !isEmpty(profile) && profile.isSignedUp;

const useProxy = Platform.select({ web: false, default: __DEV__ });

const doSignIn = async (
  credential: firebase.auth.OAuthCredential,
  firebase: ExtendedFirebaseInstance &
    ExtendedAuthInstance &
    ExtendedStorageInstance
) => {
  if (firebase.auth().currentUser) {
    try {
      const wasAnon = firebase.auth().currentUser.isAnonymous;
      const { user } = await firebase
        .auth()
        .currentUser.linkWithCredential(credential);

      // react-redux-firebase does not have linkWithCredential
      // so we have to dispatch the login ourselves
      // ! turns out there is one but using it to link anon seems to cause a crash
      // ! due to profile getting replaced
      firebase.dispatch({
        type: actionTypes.LOGIN,
        auth: user,
        preserve: {
          // If was anonymous, make sure to keep the profile since it will be the same
          // showing the SignupScreen after login depends on this
          profile: wasAnon,
        },
      });
      return {};
    } catch (error) {
      Logger.warning(error);
      // Already been linked / has signed in before, so just sign in without linking
      if (
        error.code === "auth/email-already-in-use" // magic link but the account already exists
      ) {
        // error.credential does not appear to give us a credential
        //@ts-ignore - looks like type is messed up
        await firebase.login({ credential });
        return {};
      } else if (
        error.code === "auth/credential-already-in-use" // if this provider has already signed in to SH
      ) {
        try {
          // here we get the credential back which we can use to recover from the error
          //@ts-ignore - looks like type is messed up
          await firebase.login({ credential: error.credential });
          return {};
        } catch (error) {
          Logger.log(
            "Error signInWithCredential after auth/email-already-in-use"
          );
          Logger.error(error);
        }
      } else {
        firebase.dispatch({
          type: actionTypes.LOGIN_ERROR,
          authError: error,
        });
      }
    }
  } else {
    //@ts-ignore - looks like type is messed up
    await firebase.login({ credential });
    return {};
  }
};

export const signInWithMagicLink = async (url: string, firebase) => {
  try {
    const email = isMobilePlatform
      ? await AsyncStorage.getItem(ASYNC_STORAGE_EMAIL_KEY)
      : window.localStorage.getItem(ASYNC_STORAGE_EMAIL_KEY);

    const credential = firebaseSDK.auth.EmailAuthProvider.credentialWithLink(
      email,
      url
    );

    await doSignIn(credential, firebase);
  } catch (error) {
    Logger.error(error);
  }
};

export async function signInWithGoogleAsync(
  firebase: ExtendedFirebaseInstance &
    ExtendedAuthInstance &
    ExtendedStorageInstance
) {
  try {
    if (isWeb) {
      return firebase.login({
        provider: "google",
        type: "popup",
        scopes: ["email", "profile"],
      });
    }

    //@ts-ignore -- clientId is not required
    const result = await Google.logInAsync({
      ...GOOGLE_AUTH_CLIENT_IDS,
      scopes: ["profile", "email"],
    });

    Logger.log("Google Sign In");
    Logger.log(result);

    if (result.type === "success") {
      const credential = firebaseSDK.auth.GoogleAuthProvider.credential(
        null, // don't have idToken
        result.accessToken
      );

      return doSignIn(credential, firebase);
    } else {
      return { cancelled: true };
    }
  } catch (error) {
    // User either didn't allow Expo permission or closed the web view
    if (error.message && error.message.startsWith("ERR_APP_AUTH")) {
      return { cancelled: true };
    } else {
      Logger.error(error);
      return {}; // TODO - add more cases here if there are.
    }
  }
}

export const signInWithFacebookAsync = async (
  firebase: ExtendedFirebaseInstance &
    ExtendedAuthInstance &
    ExtendedStorageInstance
) => {
  try {
    const request = new AuthSession.AuthRequest({
      responseType: AuthSession.ResponseType.Token,
      clientId: FACEBOOK_ID,
      scopes: ["public_profile", "email"],
      // For usage in managed apps using the proxy
      // including the Expo app
      redirectUri: AuthSession.makeRedirectUri({
        useProxy,
        // For usage in bare and standalone
        // Use your FBID here. The path MUST be `authorize`.
        native: `fb${FACEBOOK_ID}://authorize`,
      }),
      extraParams: {
        // Use `popup` on web for a better experience
        display: Platform.select({ web: "popup" }),
        // Optionally you can use this to rerequest declined permissions
        auth_type: "rerequest",
      },
    });

    // Prompt for an auth code
    const result = await request.promptAsync(
      {
        authorizationEndpoint: "https://www.facebook.com/v6.0/dialog/oauth",
      },
      { useProxy }
    );

    if (result.type === "success") {
      const { access_token } = result.params;
      const credential = firebaseSDK.auth.FacebookAuthProvider.credential(
        access_token
      );

      return doSignIn(credential, firebase);
    } else {
      return { cancelled: true };
    }
  } catch (error) {
    Logger.error(error);
    return {}; // TODO - add more cases here if there are.
  }
};

// https://medium.com/@dansinger_68758/adding-sign-in-with-apple-to-a-managed-expo-app-using-firebase-authentication-ca331b4de05
export const signInWithAppleAsync = async (
  firebase: ExtendedFirebaseInstance &
    ExtendedAuthInstance &
    ExtendedStorageInstance
) => {
  try {
    const provider = new firebaseSDK.auth.OAuthProvider("apple.com");

    const csrf = Math.random().toString(36).substring(2, 15);
    const nonce = Math.random().toString(36).substring(2, 10);
    const hashedNonce = await Crypto.digestStringAsync(
      Crypto.CryptoDigestAlgorithm.SHA256,
      nonce
    );

    const {
      identityToken,
      email,
      fullName,
    } = await AppleAuthentication.signInAsync({
      requestedScopes: [
        AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
        AppleAuthentication.AppleAuthenticationScope.EMAIL,
      ],
      state: csrf,
      nonce: hashedNonce,
    });
    Logger.log(identityToken, email, fullName);

    const credential = provider.credential({
      idToken: identityToken,
      rawNonce: nonce,
    });

    return doSignIn(credential, firebase);
  } catch (error) {
    if (error.code === "ERR_CANCELED") {
      return { cancelled: true };
    } else {
      Logger.error(error);
      return {}; // TODO - add more cases here if there are.
    }
  }
};

export async function signOut(
  uid,
  firebase: ExtendedFirebaseInstance &
    ExtendedAuthInstance &
    ExtendedStorageInstance
) {
  const { installationId } = Constants;
  firebaseSDK
    .firestore()
    .doc(`users/${uid}`)
    .update({
      [`tokens.${installationId}.status`]: TokenStatus.SignedOut,
    })
    .then(() => {
      console.log("Signing out");
      return firebase.logout();
      // clear redux store by reloading app
    })
    .then(() => reloadApp())
    .catch(err => Logger.error(err));
}
