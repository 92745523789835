import * as SMS from "expo-sms";
import { Logger } from "utils/Logger";
import { Share, Clipboard } from "react-native";
import { FirestoreUserData } from "types/users";
import { HabitChallenge } from "types/habits";
import { WEB_HOST_URL } from "utils/constants";
import { isMobilePlatform } from "utils/helpers";
import { dispatch } from "rxjs/internal/observable/pairs";
import { setToast, ToastTypes } from "redux/slices";

export const USER_PROFILE_URL = `${WEB_HOST_URL}/u/`;

const CHALLENGE_INVITE_URL = `${WEB_HOST_URL}/join/`;

export const SharingHelper = (profile: FirestoreUserData) => {
  const isSMSAvailableAsync = async () => {
    return SMS.isAvailableAsync();
  };

  const getInviteText = () => {
    return (
      `You should try SnapHabit. It's a habit tracking app where we can share progress ` +
      `and goals with each other. Add me as a friend with this link: ${USER_PROFILE_URL}${profile.handle}. ` +
      `It's free!`
    );
  };

  // TODO - wrap state properly in redux to avoid poor man's semaphores?

  let doingSMS = false; // poor man's semaphore.  If going app-wide utility,
  // consider refactoring into redux.
  const doSMSInvite = async phoneNumber => {
    // Prevent multiple attempts to send SMS (e.g. quick double tap of button)
    if (doingSMS) {
      return; // silently ignore multiple attempts.
    }
    doingSMS = true;

    // Log
    Logger.log("sending addFriend SMS to", phoneNumber);

    // Send message via SMS.
    // ignore response - basically useless, on ios only tells if user sent.
    await SMS.sendSMSAsync(phoneNumber, getInviteText());

    // Ready to do more SMS's.
    doingSMS = false;
  };

  // same poor man's semaphore as in doSMSInvite.  Same caveats too.
  let doingShareScreen = false;
  const doShowShareScreen = async () => {
    // Prevent multiple attempts to share (e.g. quick double tap of button)
    if (doingShareScreen) {
      return; // silently ignore multiple attempts.
    }
    doingShareScreen = true;
    // Log
    Logger.log("sending addFriend invite via share sheet.");

    // Do
    Share.share({
      message: getInviteText(),
    }).then(() => {
      // Ready to do more sharing
      // TODO - this seems to fire on Android when bottom sheet appears,
      // not when user shares or exits...
      doingShareScreen = false;
    });
  };

  return {
    isSMSAvailableAsync,
    doSMSInvite,
    doShowShareScreen,
  };
};

export const doShareInviteLink = (profile: FirestoreUserData, dispatch) => {
  const message =
    `You should try SnapHabit. It's a habit tracking app where we can share progress ` +
    `and goals with each other. Add me as a friend with this link: ${USER_PROFILE_URL}${profile.handle}. ` +
    `It's free!`;

  if (isMobilePlatform) {
    Share.share({
      message,
    });
  } else {
    Clipboard.setString(message);
    dispatch(setToast({ type: ToastTypes.CLIPBOARD }));
  }
};

export const copyInviteLink = (profile: FirestoreUserData) => {
  Clipboard.setString(
    `I'm reaching my goals with SnapHabit. ` +
      `Add me as as friend wit this link ${USER_PROFILE_URL}${profile.handle} ` +
      `so we can reach our goals together.`
  );
};

export const doShareFeedContent = (url: string, profile: FirestoreUserData) => {
  const FEED_SHARE_MESSAGE =
    `I saw this on SnapHabit and thought you might enjoy it: ${url}. ` +
    `SnapHabit is a free habit app -- add me as as friend with this link ${USER_PROFILE_URL}${profile.handle}. ` +
    `Let's achieve our goals together!`;

  Share.share({
    message: FEED_SHARE_MESSAGE,
  });
};

export const doShareChallengeInviteLink = (habit: HabitChallenge, dispatch) => {
  const message = `I'm starting a group habit (${habit.title}) and I'd love for you to join. Click ${CHALLENGE_INVITE_URL}${habit.id} to get started.`;
  if (isMobilePlatform) {
    Share.share({
      message,
    });
  } else {
    Clipboard.setString(message);
    dispatch(setToast({ type: ToastTypes.CLIPBOARD }));
  }
};
