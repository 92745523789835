import moment from "moment";

//! Make sure to use .startOf("day")
//! Otherwise, if it is 12PM, moment will round  to the next value
// This is actually Feb 01 0000 instead of Jan
const DAY_ONE = moment([0, 1, 1]).startOf("day");

// Moment truncates instead of rounding when returning,
// which has resulted in bugs before. Need to get the precise
// value by passing true as 3rd param.
export const getAbsoluteDays = (m: moment.Moment) =>
  Math.round(m.clone().startOf("day").diff(DAY_ONE, "days", true));
