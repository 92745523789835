import React, { useState } from "react";
import {
  View,
  Text,
  TouchableWithoutFeedback,
  StyleSheet,
  StyleProp,
  ViewStyle,
} from "react-native";
import Animated, { Easing } from "react-native-reanimated";
import { impactAsync, ImpactFeedbackStyle } from "expo-haptics";
import { useDispatch } from "react-redux";

import { setToast, ToastTypes } from "redux/slices";
import { useGetFriends } from "redux/selectors";
import { FriendStatus, MotivationType } from "types/friends";
import axios from "utils/axios";
import { API_HOST } from "utils/constants";
import { isMobilePlatform } from "utils/helpers";
import { COLORS } from "utils/appStyles";
import { logSendMotivation } from "utils/analytics";

const BUTTON_ICON = "👏";
const BUTTON_RADIUS = 36;
const BUTTON_SHADOW = "#134770"; // dark blue
const BUTTON_OFFSET = 6; // padding/margin offset for interpolation to create 3D animation

const ButtonLayout = ({
  customStyle,
  onButtonPress,
  onButtonUp,
  heightStyle,
  innerStyle,
  presses,
  isDepressed,
}) => {
  return (
    <View style={customStyle}>
      <TouchableWithoutFeedback
        onPressIn={onButtonPress}
        onPressOut={onButtonUp}
      >
        <View style={styles.button}>
          <Animated.View style={[styles.height, heightStyle]}>
            <Animated.View
              style={[
                styles.inner,
                innerStyle,
                isDepressed && { backgroundColor: BUTTON_SHADOW },
              ]}
            >
              <Text style={styles.icon}>{BUTTON_ICON}</Text>
            </Animated.View>
          </Animated.View>
        </View>
      </TouchableWithoutFeedback>
      <Text style={styles.presses}>{presses}</Text>
    </View>
  );
};

// Wrapper for homescreen motivation button to prevent calling useGetFriends on other's habitlists
export const MotivationButtonWrapper = ({ customStyle }) => {
  const { friends } = useGetFriends();
  const acceptedFriends = friends.filter(
    f =>
      (f.status === FriendStatus.Accepted ||
        f.status === FriendStatus.AcceptedRequestor) &&
      !(f.type && f.type === "DEFAULT_COACH")
  );

  if (acceptedFriends.length < 1) {
    return null;
  }

  return (
    <MotivationButton
      motivationType={MotivationType.ALL}
      customStyle={customStyle}
    />
  );
};

export const MotivationButton = ({
  motivationType,
  habitid,
  customStyle,
}: {
  motivationType: MotivationType;
  habitid?: string;
  customStyle?: StyleProp<ViewStyle>;
}) => {
  const [animation] = useState<Animated.Value<number>>(new Animated.Value(0));
  const [timer, setTimer] = useState<NodeJS.Timeout>(null);
  const [presses, setPresses] = useState<number>(0);
  const [isDepressed, setIsDepressed] = useState(false);
  const dispatch = useDispatch();

  //TODO: once move to reanimated 2.0, can use helper function to animate
  //TODO: Replace isDepressed logic with animatedColor
  // const animatedColor = animation.interpolate({
  //   inputRange: [0, 1],
  //   outputRange: [Animated.color(28, 101, 160), Animated.color(28, 101, 160)],
  // });

  const onButtonPress = () => {
    Animated.timing(animation, {
      toValue: 1,
      duration: 200,
      easing: Easing.inOut(Easing.ease),
    }).start();
    setIsDepressed(true);

    setTimeout(() => {
      Animated.timing(animation, {
        toValue: 0,
        duration: 100,
        easing: Easing.inOut(Easing.ease),
      }).start();
      setIsDepressed(false);
    }, 250);

    // show note toast on first press of a new motivation 'batch'
    if (presses === 0) {
      dispatch(setToast({ type: ToastTypes.MOTIVATION }));
    }
    setPresses(presses => presses + 1);

    if (timer !== null) {
      clearTimeout(timer);
      setTimer(null);
    }

    if (isMobilePlatform) {
      // variable strength of Haptics based on number of presses
      impactAsync(ImpactFeedbackStyle.Heavy);
    }
  };

  const tick = async () => {
    await axios.post(`${API_HOST}/sendMotivation`, {
      motivationType,
      habitid,
      presses,
    });
    logSendMotivation(presses, motivationType, habitid);
    clearTimeout(timer);
    setTimer(null);
    // reset presses after sending batched notification, to 'reset the batching'
    setPresses(0);
  };

  const onButtonUp = async () => {
    const timer = setTimeout(tick, 3000);
    setTimer(timer);
  };

  const innerStyle = {
    borderRadius: animation.interpolate({
      inputRange: [0, 1],
      outputRange: [BUTTON_RADIUS, BUTTON_RADIUS],
    }),
  };
  const heightStyle = {
    marginTop: animation.interpolate({
      inputRange: [0, 1],
      outputRange: [-BUTTON_OFFSET, 0],
    }),
    paddingBottom: animation.interpolate({
      inputRange: [0, 1],
      outputRange: [BUTTON_OFFSET, 0],
    }),
  };

  return (
    <ButtonLayout
      customStyle={customStyle}
      onButtonUp={onButtonUp}
      onButtonPress={onButtonPress}
      isDepressed={isDepressed}
      heightStyle={heightStyle}
      innerStyle={innerStyle}
      presses={presses}
    />
  );
};

const styles = StyleSheet.create({
  button: {
    width: BUTTON_RADIUS * 2,
    height: BUTTON_RADIUS * 2,
  },
  height: {
    borderRadius: BUTTON_RADIUS,
    backgroundColor: BUTTON_SHADOW,
  },
  inner: {
    backgroundColor: COLORS.logoBlue,
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  icon: {
    fontSize: 32,
    textAlign: "center",
    alignSelf: "center",
  },
  presses: {
    color: COLORS.darkGray,
    textAlign: "center",
    fontSize: 11,
  },
});
