import { FriendStatus, NotificationType, Friend } from "types/friends";
import firebase from "./firebase";

//invited friends first, then actual friends
//don't included Pending friends as you haven't chosen to friend them...
export const filterSortFriends = (friendsArray: Friend[]) => {
  const invitedFriends = friendsArray
    .filter(
      friend =>
        friend.status === FriendStatus.Requested ||
        friend.status === FriendStatus.Inviting
    )
    .sort((a, b) => (a.name + "").localeCompare(b.name + ""));
  const friends = friendsArray
    .filter(
      friend =>
        (friend.status === FriendStatus.Accepted ||
          friend.status === FriendStatus.AcceptedRequestor) &&
        //don't allow sharing with coach
        !friend.type
    )
    .sort((a, b) => (a.name + "").localeCompare(b.name + ""));
  return invitedFriends.concat(friends);
};

export const toggleNotifications = (
  uid: string,
  friendid: string,
  value: NotificationType
) => {
  firebase.firestore().doc(`users/${uid}/friends/${friendid}`).update({
    wantNotifications: value,
  });
};
