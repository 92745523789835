import React, { useEffect, useRef } from "react";
import { View, StyleSheet, Text, TextInput, Keyboard } from "react-native";
import { RenderNoteProps, SaveState } from "components/Notes/NoteEditor";
import { KKeyboardAvoidingView } from "components";
import moment from "moment";
import { COLORS } from "utils/appStyles";
import { ImageSaver } from "components/Notes/ImageSaver";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  header: { fontSize: 20, textAlign: "center", margin: 4 },
  imageContainer: {
    borderBottomWidth: 1,
  },
  addMedia: {
    height: 75,
    width: 75,
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  saveContainer: {
    backgroundColor: COLORS.disabled,
    borderBottomWidth: 1,
  },
  saveText: {
    textAlign: "center",
    padding: 4,
  },
  input: {
    flex: 1,
    fontSize: 24,
    lineHeight: 24,
    paddingTop: 18,
    paddingBottom: 18,
    paddingHorizontal: 12,
  },
  toolbar: {
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "space-around",
    padding: 4,
    alignItems: "center",
    borderTopWidth: 1,
  },
  inputActionsStyle: {
    flexBasis: "12%",
    alignItems: "center",
  },
  editButton: {
    backgroundColor: "white",
    borderWidth: 1,
  },
  button: {
    width: "15%",
  },
  toggleContainer: {
    width: "40%",
    alignItems: "center",
  },
});

export const DefaultNoteLayout = ({
  isEditable,
  saveState,
  note,
  onChangeText,
  lastSaved,
  ...mediaProps
}: RenderNoteProps) => {
  //! Autofocus issue with stack navigator causing double navigation when
  // autofocus on input or if focused too quickly
  // https://github.com/react-navigation/react-navigation/issues/6778
  const inputRef = useRef(null);
  useEffect(() => {
    let timeout;
    if (isEditable) {
      timeout = setTimeout(() => inputRef.current?.focus(), 400);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [isEditable]);

  return (
    <KKeyboardAvoidingView
      render={() => (
        <>
          <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
            <View style={styles.saveContainer}>
              {isEditable && (
                <Text style={styles.saveText}>
                  {saveState === SaveState.IS_DIRTY
                    ? "Keep going!"
                    : saveState === SaveState.IS_SAVING
                    ? "Saving..."
                    : saveState === SaveState.SAVED && lastSaved
                    ? `Saved at ${moment(lastSaved).format("h:mm")}`
                    : "Just type. Changes auto save."}
                </Text>
              )}
            </View>
            <View style={styles.imageContainer}>
              <ImageSaver {...mediaProps} isEditable={isEditable} list />
            </View>
          </TouchableWithoutFeedback>
          <TextInput
            ref={inputRef}
            // autoFocus={editable} -- for some reason, this causes the toolbar to be hidden
            // instead, use a useEffect
            editable={isEditable}
            value={note}
            style={styles.input}
            multiline
            autoCompleteType="off"
            onChangeText={onChangeText}
            placeholder={isEditable ? "Add note..." : "Nothing here!"}
          />
        </>
      )}
    />
  );
};
