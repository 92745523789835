import React from "react";
import { ScrollView } from "react-native";
import { useFirebase } from "react-redux-firebase";

import { SelectSetting } from "components";

import { languageDisplay } from "assets/i18n";
import { useLanguageContext } from "contexts";
import { LanguageCode } from "types/language";

export const LanguageScreen = () => {
  const firebase = useFirebase();
  const { language, setLanguage } = useLanguageContext();
  const availableLanguages = Object.values(LanguageCode);

  return (
    <ScrollView>
      {availableLanguages.map(languageCode => (
        <SelectSetting
          key={languageCode}
          label={languageDisplay[languageCode]}
          selected={language === languageCode}
          onPress={() => {
            setLanguage(languageCode);
            firebase.updateProfile({
              language: languageCode,
            });
          }}
        />
      ))}
    </ScrollView>
  );
};
