import React from "react";

import { useActionSheet } from "@expo/react-native-action-sheet";
import { Feather } from "@expo/vector-icons";
import Constants from "expo-constants";
import * as ImagePicker from "expo-image-picker";
import { TouchableOpacity } from "react-native";
import { goToPermissionsSettings } from "utils/permissions";

enum Errors {
  NEED_PERMISSION = "NEED_PERMISSION",
}

const takePhoto = async () => {
  const { status } = await ImagePicker.requestCameraPermissionsAsync();

  if (status !== "granted") {
    throw Error(Errors.NEED_PERMISSION);
  }

  const result = await ImagePicker.launchCameraAsync({
    mediaTypes: ImagePicker.MediaTypeOptions.All,
  });

  return result.cancelled ? null : result;
};

/**
 * TODO - custom image selection UI
 * https://facebook.github.io/react-native/docs/cameraroll#__docusaurus
 * https://github.com/polycarpou/expo-camera-roll-select
 * https://github.com/hyakt/expo-multiple-media-imagepicker
 */
const selectFromGallery = async () => {
  if (Constants.platform.ios) {
    const { status } = await ImagePicker.requestCameraRollPermissionsAsync();
    if (status !== "granted") {
      throw Error(Errors.NEED_PERMISSION);
    }
  }
  const result = await ImagePicker.launchImageLibraryAsync({
    mediaTypes: ImagePicker.MediaTypeOptions.All,
    quality: 0.5,
    exif: true,
    base64: false, //if need, may need to prepend with "data:image/jpeg;base64," + result.base64 to use
  });

  return result.cancelled ? null : result;
};

export const SelectMediaAction = ({
  setSelectedPhoto,
  selectedPhoto,
  inputActionsStyle,
}) => {
  const { showActionSheetWithOptions } = useActionSheet();
  const onOpenActionSheet = async () => {
    const options = ["Camera", "Library", "Cancel"];
    const cancelButtonIndex = 2;

    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
      },
      async buttonIndex => {
        try {
          if (buttonIndex === 0) {
            const fileData = await takePhoto();
            setSelectedPhoto(fileData);
          } else if (buttonIndex === 1) {
            const fileData = await selectFromGallery();
            setSelectedPhoto(fileData);
          }
        } catch (error) {
          if (error.message === Errors.NEED_PERMISSION) {
            goToPermissionsSettings();
          }
        }
      }
    );
  };

  return (
    <TouchableOpacity onPress={onOpenActionSheet} style={inputActionsStyle}>
      <Feather
        name="image"
        size={25}
        //TODO: Shift these colors to common component
        color={selectedPhoto ? "#4F8EF7" : "#a9a9a9"}
      />
    </TouchableOpacity>
  );
};
