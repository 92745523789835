import { createSelector } from "@reduxjs/toolkit";
import { isLoaded } from "react-redux-firebase";
import { useMemo } from "react";

import { useSelector, RootState } from "redux/store";
import { Habit } from "types/habits";
import { Logger } from "utils/Logger";
import { sortTitle } from "utils/strings";

import { shallowEqual } from "react-redux";
import {
  friendHabitSelector,
  friendHabitsCountSelector,
  isFriendHabitsLoadedSelector,
} from "./friendHabitsSelector";

const orderedHabitsSelector = state =>
  state.firestore.ordered.habits as Habit[];

const isHabitsLoadedSelector = createSelector(orderedHabitsSelector, habits =>
  isLoaded(habits)
);

export const useGetIsHabitsLoaded = (friendId?: string) => {
  return useSelector(
    friendId ? isFriendHabitsLoadedSelector : isHabitsLoadedSelector
  );
};

const habitsCountSelector = createSelector(
  orderedHabitsSelector,
  habits => habits?.filter(h => !h.isArchived)?.length
);

export const useGetHabitsCount = (friendId?: string) => {
  const selector = useMemo(
    () =>
      friendId ? friendHabitsCountSelector(friendId) : habitsCountSelector,
    [friendId]
  );
  return useSelector(selector);
};

const habitsSelector = createSelector<
  RootState,
  { [key: string]: Habit },
  string[],
  Habit[]
>(
  state => state.firestore.data.habits,
  state => state.firebase.profile.habitOrdering,
  (habits, ordering) => {
    const habitMap = habits || {};
    const habitOrdering = ordering || [];

    // create first part of the ordered habits using the habitOrdering array
    const alreadyIncludedHabitIds = new Set<string>();

    const userOrderedHabitsArray = habitOrdering
      // filter out habit id if not found in the habit map (ie. deleted habits)
      .filter(habitId => habitMap[habitId])
      // map habitId to habit
      .map(habitId => {
        alreadyIncludedHabitIds.add(habitId);
        return { ...habitMap[habitId], id: habitId };
      });

    // create the second part of ordered habits from those not in habitOrdering
    const remainingOrderedHabitsArray = Object.keys(habitMap)
      // filter out habits that are already in the first part and
      // habits that are null in habit map (deleted habits)
      .filter(
        habitId => !alreadyIncludedHabitIds.has(habitId) && habitMap[habitId]
      )
      .map(habitId => ({ ...habitMap[habitId], id: habitId }))
      // sort these remaining habits by title
      .sort((a, b) => sortTitle(a, b));

    // final array is made up of user ordered habits first followed by others
    const orderedHabitsArray = [
      ...userOrderedHabitsArray,
      ...remainingOrderedHabitsArray,
    ];

    return orderedHabitsArray.filter(h => !h.isArchived);
  }
);

//! This runs every time a habit changes
export const useGetHabits = (friendId?: string) => {
  Logger.log("useGetHabits running");
  const selector = useMemo(
    () => (friendId ? friendHabitSelector(friendId) : habitsSelector),
    [friendId]
  );

  return useSelector(selector);
};

export const useGetHabitsUnfiltered = () =>
  useSelector(state => state.firestore.ordered.habits, shallowEqual);

export const habitByIndexSelector = (index: number, friendId?: string) =>
  createSelector(
    friendId ? friendHabitSelector(friendId) : habitsSelector,
    habits => habits[index]
  );

export const useGetHabitByIndex = (index: number, friendId?: string): Habit =>
  useSelector(
    useMemo(() => habitByIndexSelector(index, friendId), [index, friendId])
  );
