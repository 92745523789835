import React from "react";
import { PanelRight } from "panels/subpanels/PanelRight";
import { NoteEditor } from "components/Notes/NoteEditor";
import { MatchProps } from "utils/react-router";
import { useGetDay } from "redux/selectors";
import { DefaultNoteLayout } from "components/Notes/DefaultNoteLayout";

export const NotesPanel = ({
  match,
}: {
  match: MatchProps<{ habitid: string; date: string }>;
}) => {
  const { path, params } = match;
  const { habitid, date } = params;
  const ownHabit = path.startsWith("/me");

  const day = useGetDay({ habitid, date, ownHabit });

  return (
    <PanelRight>
      <NoteEditor
        dayid={day.id}
        habitid={habitid}
        date={date}
        ownHabit={ownHabit}
        isEditable={ownHabit}
        render={props => <DefaultNoteLayout {...props} />}
      />
    </PanelRight>
  );
};
