import { ShareToSocialMedia } from "components/Routines/ShareToSocialMedia";
import React, { useState } from "react";
import { View, StyleSheet } from "react-native";
import { Modal, Portal, IconButton } from "react-native-paper";
import { RoutineContent } from "types/routines";
import { ViewWithDimensionsContext } from "contexts";
import { SectionTitle } from "components/UI/Typography";
import { useString } from "hooks";
import { KImage } from "components/Media/KImage";

const styles = StyleSheet.create({
  modal: { flex: 1 },
  container: {
    width: "90%",
    height: "90%",
    backgroundColor: "white",
    alignSelf: "center",
    alignItems: "center",
    padding: 30,
  },
  close: {
    position: "absolute",
    top: 3,
    right: 3,
    zIndex: 10,
  },
  image: {
    width: "88%",
    height: "78%",
    resizeMode: "contain",
    marginBottom: 3,
  },
  header: { textAlign: "center" },
});

export const RoutineShareModal = ({
  setIsVisible,
  photosForSharing,
  routineId,
}: {
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  photosForSharing: RoutineContent["photosForSharing"];
  routineId: string;
}) => {
  const [selectedPhoto] = useState(photosForSharing[0]);
  const s = useString("routine");
  return (
    <Portal>
      <ViewWithDimensionsContext style={styles.modal}>
        <Modal visible dismissable onDismiss={() => setIsVisible(false)}>
          <View style={styles.container}>
            <IconButton
              icon="close"
              onPress={() => setIsVisible(false)}
              style={styles.close}
            />
            <SectionTitle style={styles.header}>{s("shareTitle")}</SectionTitle>
            <KImage remote={selectedPhoto} style={styles.image} />
            <ShareToSocialMedia
              remoteUrl={selectedPhoto}
              routineId={routineId}
            />
          </View>
        </Modal>
      </ViewWithDimensionsContext>
    </Portal>
  );
};
