import { Visibility } from "types/habits";

export default {
  addATitle: {
    en: "Add a title!",
    ja: "タイトルを追加してください！",
    "zh-Hans": "添加标题",
    "zh-Hant": "添加標題",
  },
  allowFriends: {
    en: "Allow friends to join",
  },
  //TODO: replace word challenge with group in other languages
  allowFriendsToJoinDialogTitle: {
    en: "Group Habits",
    // ja: "チャレンジ",
    // "zh-Hans": "挑战",
    // "zh-Hant": "挑戰",
  },
  allowFriendsToJoinDialogBody: {
    en:
      "Group habits make it easy to share your progress with friends and to hold each other accountable.\n\n" +
      "Only the friends who can view your habit can join. You can always change visibility later.",
  },
  convertToChallengeWarning: {
    en: "Once you make this a group habit, the title can't be changed.",
  },
  createChallengeWarning: {
    en: "Once you start a group habit, the title can't be edited.",
  },
  //! Keyed by the value of FrequencyType which uses a capital
  Daily: {
    en: "Daily",
    ja: "毎日",
    "zh-Hans": "每日",
    "zh-Hant": "每日",
  },
  descriptionRoutinePlaceholder: {
    en: "An optional description",
  },
  descriptionPlaceholder: {
    en: "Describe your habit (optional)",
    ja: "デスクリプション（オプション）",
    "zh-Hans": "描述你的习惯（可选）",
    "zh-Hant": "描述你的習慣（可選）",
  },
  gotIt: {
    en: "Got it",
    ja: "了解",
    "zh-Hans": "知道了",
    "zh-Hant": "知道了",
  },
  invitingFriends: {
    en: "Inviting friends",
    ja: "友達を招待する",
    "zh-Hans": "邀请好友",
    "zh-Hant": "邀請好友",
  },
  //! Keyed by the value of FrequencyType which uses a capital
  Scheduled: {
    en: "Scheduled",
    ja: "スケジュール",
    "zh-Hans": "定期",
    "zh-Hant": "定期",
  },
  sharingProgress: {
    en: "Sharing progress",
    ja: "プログレスの共有",
    "zh-Hans": "分享进度",
    "zh-Hant": "分享進度",
  },
  solo: {
    en: "Solo",
    ja: "自分で",
    "zh-Hans": "自己",
    "zh-Hant": "自己",
  },
  titleRoutinePlaceholder: {
    en: "Your custom title",
  },
  titlePlaceholder: {
    en: "Run for 20 minutes",
    ja: "20分間走る",
    "zh-Hans": "跑步20分钟",
    "zh-Hant": "跑步20分鐘",
  },
  //! Keyed by the value of FrequencyType which uses a capital
  Weekly: {
    en: "Weekly",
    ja: "毎週",
    "zh-Hans": "每周",
    "zh-Hant": "每週",
  },
  // Visibility Field
  visibility: {
    en: "Visibility",
  },
  visibilityDialogTitle: {
    en: "Habit Visibility",
  },
  visibilityDialogBody: {
    en:
      "Visibility controls who can view your habit progress & notes as well as who gets notified of updates. You can always change this later.",
  },
  [Visibility.FRIENDS]: {
    en: "Friends",
  },
  [Visibility.SELECT_FRIENDS]: {
    en: "Selected Friends",
  },
  [Visibility.ONLY_ME]: {
    en: "Only Me",
  },
  // emoji mart i18n below
  search: {
    en: "Search",
    ja: "検索",
    "zh-Hans": "搜索",
    "zh-Hant": "搜索",
  },
  noEmojiFound: {
    en: "No Emoji Found",
    ja: "見つからなかった",
    "zh-Hans": "什么都没找到",
    "zh-Hant": "什麼都沒找到",
  },
  searchResult: {
    en: "Search Result",
    ja: "検索結果",
    "zh-Hans": "搜索结果",
    "zh-Hant": "搜索結果",
  },
  frequentlyUsed: {
    en: "Frequently Used",
    ja: "よく使われる",
    "zh-Hans": "经常使用",
    "zh-Hant": "經常使用",
  },
  smileysAndPeople: {
    en: "Smileys & People",
    ja: "スマイリーと人物",
    "zh-Hans": "笑脸和人",
    "zh-Hant": "笑臉和人",
  },
  animalsAndNature: {
    en: "Animals & Nature",
    ja: "動物と自然",
    "zh-Hans": "动物与自然",
    "zh-Hant": "動物與自然",
  },
  foodAndDrink: {
    en: "Food & Drink",
    ja: "食べ物と飲み物",
    "zh-Hans": "食物和饮料",
    "zh-Hant": "食物和飲料",
  },
  activity: {
    en: "Activity",
    ja: "アクティビティ",
    "zh-Hans": "活动",
    "zh-Hant": "活動",
  },
  travelAndPlaces: {
    en: "Travel & Places",
    ja: "旅行と場所",
    "zh-Hans": "旅游与地方",
    "zh-Hant": "旅遊與地方",
  },
  objects: {
    en: "Objects",
    ja: "物体",
    "zh-Hans": "物体",
    "zh-Hant": "物体",
  },
  symbols: {
    en: "Symbols",
    ja: "記号",
    "zh-Hans": "符号",
    "zh-Hant": "符號",
  },
  flags: {
    en: "Flags",
    ja: "旗",
    "zh-Hans": "旗帜",
    "zh-Hant": "旗幟",
  },
  custom: {
    en: "Custom",
    ja: "カスタム",
    "zh-Hans": "自制",
    "zh-Hant": "自製",
  },
};
