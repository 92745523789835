import { Platform, Linking } from "react-native";

import { envIsProduction } from "./constants";

export const goToPermissionsSettings = () => {
  if (Platform.OS === "ios") {
    Linking.openURL("app-settings:");
  } else if (Platform.OS === "android") {
    const IntentLauncher = require("expo-intent-launcher");
    IntentLauncher.startActivityAsync(
      IntentLauncher.ACTION_APPLICATION_DETAILS_SETTINGS,
      {
        //! Not doing anything for Expo or Dev right now
        data: envIsProduction()
          ? `package:io.gravitech.habit.staging`
          : `package:io.gravitech.habit.beta`,
      }
    ).then(res => console.log(res));
  }
};
