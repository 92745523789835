import React from "react";
import { Text, Button } from "react-native";

import { Logger } from "utils/Logger";
import Sentry from "utils/Sentry";
import { ContentCenteredView } from "components";
import { reloadApp } from "utils/reload";

export class ErrorBoundary extends React.Component<
  object,
  { hasError: boolean }
> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  // https://github.com/expo/sentry-expo/issues/74#issuecomment-575814689
  componentDidCatch(error, errorInfo) {
    console.log(error);
    console.log(errorInfo);
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      Logger.error(error);
    });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <ContentCenteredView>
          <Text>
            Something went wrong! We'll fix it! Reload the app for now...
          </Text>
          <Button
            title="Reload"
            onPress={() => {
              reloadApp();
            }}
          />
        </ContentCenteredView>
      );
    }

    return this.props.children;
  }
}
