import React from "react";

import { SettingSection, ToggleSetting } from "components/SettingsItems";

export const HabitFriendList = ({ sharing, onToggle, sortedFriends }) => {
  //this is leading to some unnessary rerenders - but dependency needs to be the sharing value
  //useCallback in previous state was not helpin

  return (
    <>
      {sortedFriends.map(friend => (
        <ToggleSetting
          key={friend.id}
          label={friend.name || friend.email}
          value={sharing[friend.id]}
          onToggle={value => onToggle(friend.id, value)}
        />
      ))}
    </>
  );
};
