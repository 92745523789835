import React from "react";
import { SvgXml, XmlProps } from "react-native-svg";
import { Platform, View } from "react-native";

const xml = `
<svg
  width="43.377"
  height="50.592"
  viewBox="0 0 43.377 50.592"
>
  <g transform="translate(-263.413 -285.443)">
    <g transform="translate(263.413 285.443)">
      <g transform="translate(24.139 3.355)">
        <path
          fill="#b9dcff"
          d="M99.023,32v2.261a.461.461,0,0,1-.461.461H81.746a.461.461,0,0,1-.461-.461V32a4.3,4.3,0,0,1,2.52-3.912,6.735,6.735,0,0,1-2.2-2.527,6.089,6.089,0,0,1,.417-6.022,5.923,5.923,0,0,0,.994-3.285v-2.5A5.759,5.759,0,0,1,88.772,8h2.764A5.759,5.759,0,0,1,97.3,13.759c0,2.643-.065,3.16.254,4.211.449,1.486,1.043,1.67,1.5,3.182a6.093,6.093,0,0,1-2.037,6.53l-.51.406A4.3,4.3,0,0,1,99.023,32Z"
          transform="translate(-80.536 -7.539)"
        />
        <path
          fill="#b9dcff"
          d="M103.739,350.968v2.261a.461.461,0,0,1-.461.461H86.462a.461.461,0,0,1-.461-.461v-2.261a4.3,4.3,0,0,1,4.295-4.295,2.383,2.383,0,0,0,.989-.214l.014.031,3.289,2.546a.461.461,0,0,0,.564,0l3.289-2.546.014-.031a2.383,2.383,0,0,0,.989.214A4.3,4.3,0,0,1,103.739,350.968Z"
          transform="translate(-85.252 -326.507)"
        />
        <path
          fill="#d1e7ff"
          d="M151.968,121.125a.461.461,0,0,1,.436.46v2.758a5.7,5.7,0,1,1-11.4,0v-2.718a.46.46,0,0,1,.5-.459,11.92,11.92,0,0,0,7.77-1.987.459.459,0,0,1,.7.257A2.149,2.149,0,0,0,151.968,121.125Z"
          transform="translate(-137.084 -112.241)"
        />
        <path
          fill="#b9dcff"
          d="M97.022,27.683l-.51.406a4.284,4.284,0,0,0-1.774-.382,2.383,2.383,0,0,1-2.386-2.381v-.393l-.009-.021a5.7,5.7,0,0,0,3.522-5.27V16.883a.461.461,0,0,0-.436-.46,2.148,2.148,0,0,1-2-1.689.459.459,0,0,0-.7-.257,11.921,11.921,0,0,1-7.77,1.987.46.46,0,0,0-.5.459v2.718a5.7,5.7,0,0,0,3.522,5.27l-.009.021v.393a2.383,2.383,0,0,1-2.386,2.381,4.284,4.284,0,0,0-1.774.382l-.51-.406a6.093,6.093,0,0,1-1.276-8.143,5.923,5.923,0,0,0,.994-3.285v-2.5A5.759,5.759,0,0,1,88.78,8h2.764A5.759,5.759,0,0,1,97.3,13.759v2.5A5.927,5.927,0,0,0,98.3,19.54,6.093,6.093,0,0,1,97.022,27.683Z"
          transform="translate(-80.544 -7.539)"
        />
        <path
          fill="#a2d0ff"
          d="M205.379,302.6a5.718,5.718,0,0,1-4.507,0,3.575,3.575,0,0,0,.065-.946l.009-.021a5.713,5.713,0,0,0,4.36,0l.009.021A3.566,3.566,0,0,0,205.379,302.6Z"
          transform="translate(-193.507 -284.259)"
        />
        <path
          fill="#d1e7ff"
          d="M94.142,8.62a.6.6,0,0,1-.324,1.134q-.272-.026-.55-.026H90.5a5.759,5.759,0,0,0-5.759,5.759v.7c0,.414-.288.4-.288.736a18.1,18.1,0,0,1-.032,2.981c-.452,1.329-.994,1.521-1.435,2.976a6.088,6.088,0,0,0-.228,2.4.6.6,0,0,1-1.156.282,6.09,6.09,0,0,1,.417-6.022,5.924,5.924,0,0,0,.994-3.285v-2.5A5.759,5.759,0,0,1,88.776,8H91.54A5.735,5.735,0,0,1,94.142,8.62Z"
          transform="translate(-80.539 -7.539)"
        />
        <path
          fill="#e8f3ff"
          d="M145.611,161.935a.879.879,0,0,1-.772,1.565A5.665,5.665,0,0,1,141,158.115V155.4a.46.46,0,0,1,.5-.459c.221.02.463.036.725.044a.878.878,0,0,1,.848.879v1.333A5.681,5.681,0,0,0,145.611,161.935Z"
          transform="translate(-137.085 -146.013)"
        />
        <path
          fill="#d1e7ff"
          d="M92.25,347.227a.366.366,0,0,1-.217.657,4.294,4.294,0,0,0-4.3,4.294v1.512H86.463a.461.461,0,0,1-.461-.461v-2.261a4.3,4.3,0,0,1,4.295-4.295,2.382,2.382,0,0,0,.989-.214l.014.031Z"
          transform="translate(-85.252 -326.507)"
        />
        <path
          fill="#a2d0ff"
          d="M283.17,347.227a.366.366,0,0,0,.217.657,4.294,4.294,0,0,1,4.3,4.294v1.512h1.267a.461.461,0,0,0,.461-.461v-2.261a4.3,4.3,0,0,0-4.294-4.295,2.382,2.382,0,0,1-.989-.214l-.014.031Z"
          transform="translate(-270.931 -326.507)"
        />
        <path
          fill="#000000"
          d="M91.936,24.462v2.722a.461.461,0,0,1-.921,0V24.462a3.832,3.832,0,0,0-3.834-3.834,2.829,2.829,0,0,1-2.434-1.367.461.461,0,1,1,.787-.479,1.914,1.914,0,0,0,1.647.924A4.753,4.753,0,0,1,91.936,24.462Zm-7.687-3.568-1.643,1.272L79.812,20a2.823,2.823,0,0,0,1.056-1.989,6.163,6.163,0,0,0,7.9-5.913c0-.036,0-3.226,0-3.262a.461.461,0,0,0-.459-.432,1.9,1.9,0,0,1-1.2-.222,2.1,2.1,0,0,1-.871-1.676.461.461,0,0,0-.727-.379,14.848,14.848,0,0,1-6.533,2.592.461.461,0,0,0,.063.917,15.159,15.159,0,0,0,3.265-.8A15.8,15.8,0,0,0,85.428,7.3a2.6,2.6,0,0,0,2.419,2.047V12.1a5.241,5.241,0,1,1-10.482,0V8.869a.461.461,0,0,0-.921,0V12.1a6.17,6.17,0,0,0,3.513,5.563v.122a1.921,1.921,0,0,1-1.925,1.92,4.753,4.753,0,0,0-1.7.313l-.3-.237a5.627,5.627,0,0,1-1.18-7.527,6.365,6.365,0,0,0,1.071-3.54V6.22a5.3,5.3,0,0,1,5.3-5.3h2.764a5.3,5.3,0,0,1,5.3,5.3v2.5a6.333,6.333,0,0,0,1.066,3.532,5.628,5.628,0,0,1-.045,6.325.461.461,0,1,0,.759.523,6.549,6.549,0,0,0,.052-7.36,5.414,5.414,0,0,1-.911-3.02V6.22A6.227,6.227,0,0,0,83.988,0H81.224A6.227,6.227,0,0,0,75,6.22v2.5a5.446,5.446,0,0,1-.917,3.029A6.547,6.547,0,0,0,75.43,20.48a4.742,4.742,0,0,0-2.155,3.982v2.722a.461.461,0,0,0,.921,0V24.462a3.832,3.832,0,0,1,3.834-3.834,2.857,2.857,0,0,0,.9-.144l3.4,2.629a.461.461,0,0,0,.564,0l1.925-1.49a.461.461,0,1,0-.564-.729Z"
          transform="translate(-72.987 0)"
        />
      </g>
      <g transform="translate(0 3.355)">
        <path
          fill="#a2d0ff"
          d="M103.738,31.988v2.274a.461.461,0,0,1-.461.461H86.461A.461.461,0,0,1,86,34.262V31.988a4.315,4.315,0,0,1,4.318-4.282,2.384,2.384,0,0,0,1.922-1l.037.013-.026-.029-.009.012a2.673,2.673,0,0,0,.438-1.769l.009-.021a5.7,5.7,0,0,1-3.522-5.27v-.18a1.421,1.421,0,0,1-1.44-1.413V15.17a7.17,7.17,0,0,1,14.34,0v2.793a1.5,1.5,0,0,1-1.5,1.5v.18a5.7,5.7,0,0,1-3.522,5.27l.009.021A2.671,2.671,0,0,0,97.5,26.7l-.009-.012-.026.029L97.5,26.7a2.384,2.384,0,0,0,1.922,1,4.315,4.315,0,0,1,4.318,4.282Z"
          transform="translate(-85.539 -7.539)"
        />
        <path
          fill="#b9dcff"
          d="M130.34,15.17v2.793a1.5,1.5,0,0,1-1.5,1.5V15.9a2.764,2.764,0,0,0-2.764-2.764H120.2A2.764,2.764,0,0,0,117.44,15.9v3.557A1.421,1.421,0,0,1,116,18.047V15.17a7.17,7.17,0,1,1,14.34,0Z"
          transform="translate(-113.812 -7.539)"
        />
        <path
          fill="#b9dcff"
          d="M152.4,103.732a5.7,5.7,0,0,1-11.4,0V99.994a2.764,2.764,0,0,1,2.764-2.764h5.874a2.764,2.764,0,0,1,2.764,2.764Z"
          transform="translate(-137.372 -91.63)"
        />
        <path
          fill="#d1e7ff"
          d="M99.42,333.518a2.385,2.385,0,0,1-1.933-1.018l-2.275,2.528a.461.461,0,0,1-.685,0L92.252,332.5a2.385,2.385,0,0,1-1.933,1.018A4.315,4.315,0,0,0,86,337.8v2.274a.46.46,0,0,0,.461.461h16.817a.46.46,0,0,0,.461-.461V337.8A4.315,4.315,0,0,0,99.42,333.518Z"
          transform="translate(-85.539 -313.351)"
        />
        <g transform="translate(6.701 19.149)">
          <path
            fill="#c79a83"
            d="M194.388,332.529l-.038-.013,0,0,.009-.012Z"
            transform="translate(-194.35 -332.5)"
          />
          <path
            fill="#c79a83"
            d="M285.038,332.516l-.037.013.026-.029.009.012Z"
            transform="translate(-279.779 -332.5)"
          />
          <path
            fill="#c79a83"
            d="M194.362,332.5l-.009.012,0,0,.038.013Zm5.244.012-.009-.012-.026.029.037-.013Z"
            transform="translate(-194.35 -332.5)"
          />
        </g>
        <path
          fill="#e8f3ff"
          d="M257.835,333.325c-.007.015-.576,2.6-.572,2.585a.424.424,0,0,1-.734.187,18.689,18.689,0,0,0-1.459-1.652l1.75-1.944A2.394,2.394,0,0,0,257.835,333.325Z"
          transform="translate(-244.872 -313.35)"
        />
        <path
          fill="#e8f3ff"
          d="M177.935,332.5l1.708,1.9-1.417,1.679a.424.424,0,0,1-.738-.182c0-.017-.562-2.555-.569-2.57A2.393,2.393,0,0,0,177.935,332.5Z"
          transform="translate(-171.223 -313.351)"
        />
        <path
          fill="#b9dcff"
          d="M143.9,347.36v6.66H131.39v-6.659c1.342-.572,1.737-.17,2.624-.551l.554,2.514a.461.461,0,0,0,.8.2l1.4-1.611.538.6a.461.461,0,0,0,.685,0l.537-.6,1.4,1.611a.461.461,0,0,0,.8-.2l.554-2.514C142.168,347.193,142.559,346.788,143.9,347.36Z"
          transform="translate(-128.315 -326.836)"
        />
        <path
          fill="#e8f3ff"
          d="M91.536,348.179a4.3,4.3,0,0,0-4.1,4.29v1.57h-.979a.461.461,0,0,1-.461-.461v-2.27a4.319,4.319,0,0,1,4.32-4.285,2.387,2.387,0,0,0,.916-.192C91.243,346.845,91.532,348.162,91.536,348.179Z"
          transform="translate(-85.539 -326.855)"
        />
        <path
          fill="#e8f3ff"
          d="M177.973,334.919l.255,1.158a.424.424,0,0,1-.738-.182c-.221-1.006-.564-2.56-.569-2.57a2.392,2.392,0,0,0,1.015-.825l.492.547A2.224,2.224,0,0,0,177.973,334.919Z"
          transform="translate(-171.224 -313.351)"
        />
        <path
          fill="#d1e7ff"
          d="M134.312,348.179a4.283,4.283,0,0,0-2.922,1.34V347.38c1.282-.549,1.771-.181,2.622-.55C134.018,346.843,134.312,348.175,134.312,348.179Z"
          transform="translate(-128.315 -326.856)"
        />
        <path
          fill="#b9dcff"
          d="M297.88,348.179a4.3,4.3,0,0,1,4.1,4.29v1.57h.979a.461.461,0,0,0,.461-.461v-2.27a4.319,4.319,0,0,0-4.32-4.285,2.386,2.386,0,0,1-.916-.192C298.172,346.845,297.884,348.162,297.88,348.179Z"
          transform="translate(-285.217 -326.855)"
        />
        <path
          fill="#a2d0ff"
          d="M297.88,348.179a4.283,4.283,0,0,1,2.922,1.34V347.38c-1.282-.549-1.771-.181-2.622-.55C298.174,346.843,297.88,348.175,297.88,348.179Z"
          transform="translate(-285.217 -326.856)"
        />
        <path
          fill="#a2d0ff"
          d="M204.221,224.266a.445.445,0,0,1,.149.765,1.664,1.664,0,0,1-1.922.136.451.451,0,0,0-.45,0,1.664,1.664,0,0,1-1.922-.136.445.445,0,0,1,.149-.765c.553-.19.651-.587,1.08-.7a1.066,1.066,0,0,1,.733.1.447.447,0,0,0,.371,0,1.067,1.067,0,0,1,.733-.1C203.57,223.678,203.668,224.075,204.221,224.266Z"
          transform="translate(-192.893 -210.663)"
        />
        <path
          fill="#8bc4ff"
          d="M205.377,302.6a5.718,5.718,0,0,1-4.507,0,3.57,3.57,0,0,0,.065-.946l.009-.021a5.713,5.713,0,0,0,4.36,0l.009.021A3.566,3.566,0,0,0,205.377,302.6Z"
          transform="translate(-193.794 -284.259)"
        />
        <path
          fill="#d1e7ff"
          d="M144.356,121.261a1.011,1.011,0,0,1-1.359,1.5,5.585,5.585,0,0,1-2-4.332v-3.738a2.794,2.794,0,0,1,.488-1.573.682.682,0,0,1,1.24.4v3.755A5.644,5.644,0,0,0,144.356,121.261Z"
          transform="translate(-137.372 -106.322)"
        />
        <path
          fill="#d1e7ff"
          d="M125.071,9.636a7.168,7.168,0,0,0-6.566,3.916.717.717,0,0,1-.156.208,2.756,2.756,0,0,0-.911,2.05v2.161a.72.72,0,0,1-1.438.054c0-.056,0-2.934,0-2.879a7.2,7.2,0,0,1,9.3-6.919.72.72,0,0,1-.223,1.409Z"
          transform="translate(-113.809 -7.446)"
        />
        <path
          fill="#000000"
          d="M91.9,19.706a1.914,1.914,0,0,1-1.647-.924.461.461,0,1,0-.787.479,2.843,2.843,0,0,0,.964.96l-.477,2.167-1.151-1.326a.461.461,0,1,0-.671-.632l-.81.932-2.041-2.267a2.816,2.816,0,0,0,.309-1.081,6.169,6.169,0,0,0,7.9-5.864c0-.01,0-3.776,0-3.786a3.229,3.229,0,0,0-3.225-3.225H84.393a3.229,3.229,0,0,0-3.225,3.225v2.987a.951.951,0,0,1-.518-.843V7.631a6.709,6.709,0,0,1,13.419,0v2.877a.461.461,0,0,0,.921,0V7.631a7.631,7.631,0,1,0-15.262,0v2.877a1.88,1.88,0,0,0,1.444,1.821,6.172,6.172,0,0,0,3.509,5.335v.121a1.922,1.922,0,0,1-1.925,1.92A4.753,4.753,0,0,0,78,24.462v2.722a.461.461,0,0,0,.922,0V24.462a3.824,3.824,0,0,1,1.692-3.181v5.9a.461.461,0,0,0,.921,0V20.826a9.021,9.021,0,0,1,1.822-.262L84,23.481a.461.461,0,0,0,.8.2l1.658-1.909a6.676,6.676,0,0,0,.6.653.459.459,0,0,0,.331.086c.224-.028.263-.123.807-.749l1.667,1.919a.461.461,0,0,0,.8-.2l.643-2.918a2.893,2.893,0,0,0,.6.064,3.832,3.832,0,0,1,3.834,3.834v2.722a.461.461,0,0,0,.921,0V24.462A4.753,4.753,0,0,0,91.9,19.706Zm-9.815-7.6V8.364a2.306,2.306,0,0,1,2.3-2.3h5.874a2.306,2.306,0,0,1,2.3,2.3V12.1a5.241,5.241,0,1,1-10.482,0ZM84.7,22.388l-.478-2.168a2.859,2.859,0,0,0,.492-.377l1.117,1.241Zm9.343-.07v4.865a.461.461,0,1,1-.921,0V22.318a.461.461,0,1,1,.921,0ZM88.63,15.2a2.406,2.406,0,0,0,1.825-1.328.461.461,0,0,0-.467-.652c-.884.093-.887-.566-1.622-.761a1.532,1.532,0,0,0-1.036.121,1.532,1.532,0,0,0-1.036-.121c-.737.195-.736.854-1.622.761a.461.461,0,0,0-.466.652A2.406,2.406,0,0,0,86.03,15.2a2.2,2.2,0,0,0,1.3-.292A2.2,2.2,0,0,0,88.63,15.2ZM85.5,14.031c.918-.327.78-1.01,1.6-.537a.461.461,0,0,0,.461,0c.817-.472.677.208,1.6.537a1.227,1.227,0,0,1-1.542-.04.461.461,0,0,0-.573,0A1.226,1.226,0,0,1,85.5,14.031Z"
          transform="translate(-78 0)"
        />
      </g>
      <g transform="translate(11.141 0)">
        <path
          fill="#d1e7ff"
          d="M105.921,34.921v2.535a.516.516,0,0,1-.517.517H86.549a.516.516,0,0,1-.517-.517V34.921a4.816,4.816,0,0,1,4.817-4.815,2.674,2.674,0,0,0,2.244-1.218A3.035,3.035,0,0,0,93.523,27l.01-.024a6.393,6.393,0,0,1-3.949-5.908v-.2a1.593,1.593,0,0,1-1.614-1.585V16.592a8.938,8.938,0,0,1,2.4-6.524C93.139,7.4,97.525,7.619,100.7,9.046a.13.13,0,0,1,0,.238l-.828.341a.129.129,0,0,0,.036.248l.4.043a6.518,6.518,0,0,1,4.825,2.993.139.139,0,0,1-.017.16,3.907,3.907,0,0,0-1.067,2.684v3.524a1.585,1.585,0,0,1-1.585,1.585h-.094v.2a6.393,6.393,0,0,1-3.949,5.908l.01.024a3.034,3.034,0,0,0,.429,1.893,2.674,2.674,0,0,0,2.245,1.218A4.816,4.816,0,0,1,105.921,34.921Z"
          transform="translate(-85.516 -7.49)"
        />
        <path
          fill="#b9dcff"
          d="M105.921,337.406v2.535a.517.517,0,0,1-.517.517H86.549a.517.517,0,0,1-.517-.517v-2.535a4.816,4.816,0,0,1,4.816-4.815,2.673,2.673,0,0,0,2.245-1.218,4.432,4.432,0,0,0,5.766,0,2.673,2.673,0,0,0,2.245,1.218A4.816,4.816,0,0,1,105.921,337.406Z"
          transform="translate(-85.516 -309.975)"
        />
        <path
          fill="#b9dcff"
          d="M133.155,12.91a.137.137,0,0,1-.017.159,3.909,3.909,0,0,0-1.067,2.684v3.524a1.588,1.588,0,0,1-1.679,1.585V16.872a3.1,3.1,0,0,0-3.1-3.1h-6.587a3.1,3.1,0,0,0-3.1,3.1v3.989a1.593,1.593,0,0,1-1.614-1.585V16.593a8.938,8.938,0,0,1,2.4-6.524c2.767-2.668,7.154-2.449,10.33-1.022a.129.129,0,0,1,0,.238l-.829.341a.129.129,0,0,0,.035.248l.4.043A6.515,6.515,0,0,1,133.155,12.91Z"
          transform="translate(-113.539 -7.49)"
        />
        <path
          fill="#d1e7ff"
          d="M153.818,104.583a6.394,6.394,0,1,1-12.786-.2v-3.989a3.1,3.1,0,0,1,3.1-3.1h6.586a3.1,3.1,0,0,1,3.1,3.1Z"
          transform="translate(-136.964 -91.01)"
        />
        <path
          fill="#e8f3ff"
          d="M145.977,123.353a1.021,1.021,0,0,1-.928,1.808,6.394,6.394,0,0,1-4.017-5.936v-4.191a3.086,3.086,0,0,1,.544-1.754.763.763,0,0,1,1.393.463v4.191A6.346,6.346,0,0,0,145.977,123.353Z"
          transform="translate(-136.964 -105.653)"
        />
        <path
          fill="#b9dcff"
          d="M205.956,302.777a6.41,6.41,0,0,1-5.054,0,4,4,0,0,0,.073-1.06l.01-.024a6.406,6.406,0,0,0,4.888,0l.01.024A4,4,0,0,0,205.956,302.777Z"
          transform="translate(-192.968 -282.212)"
        />
        <path
          fill="#d1e7ff"
          d="M93.449,334.151a2.628,2.628,0,0,1-.769.119,4.829,4.829,0,0,0-4.841,4.8v.485a.9.9,0,0,1-.9.9h-.387a.517.517,0,0,1-.517-.517v-2.535a4.816,4.816,0,0,1,4.816-4.815,2.673,2.673,0,0,0,2.245-1.218,4.465,4.465,0,0,0,.614.441A1.29,1.29,0,0,1,93.449,334.151Z"
          transform="translate(-85.515 -309.975)"
        />
        <path
          fill="#a2d0ff"
          d="M266.254,334.151a2.628,2.628,0,0,0,.769.119,4.829,4.829,0,0,1,4.841,4.8v.485a.9.9,0,0,0,.9.9h.387a.517.517,0,0,0,.517-.517v-2.535a4.816,4.816,0,0,0-4.816-4.815,2.674,2.674,0,0,1-2.245-1.218,4.459,4.459,0,0,1-.614.441A1.29,1.29,0,0,0,266.254,334.151Z"
          transform="translate(-253.268 -309.975)"
        />
        <path
          fill="#d1e7ff"
          d="M128.722,9.284c-.759.312-.867.381-1.131.341-4.241-.657-7.961,1.1-9.225,5.217h0a3.084,3.084,0,0,0-.758,2.03v2.423a.807.807,0,1,1-1.614-.019V16.592a8.938,8.938,0,0,1,2.4-6.524c2.768-2.668,7.153-2.449,10.33-1.022A.13.13,0,0,1,128.722,9.284Z"
          transform="translate(-113.54 -7.49)"
        />
        <path
          fill="#000000"
          d="M98.955,27.431v3.052a.517.517,0,1,1-1.033,0V27.431a4.3,4.3,0,0,0-4.3-4.3A3.172,3.172,0,0,1,90.894,21.6a.517.517,0,1,1,.882-.537A2.146,2.146,0,0,0,93.623,22.1a5.329,5.329,0,0,1,5.332,5.332ZM89.94,22.253a.517.517,0,0,1-.395.615,4.944,4.944,0,0,1-3.843-.752,3.133,3.133,0,0,1-2.337,1.017,4.3,4.3,0,0,0-4.3,4.3v3.052a.517.517,0,0,1-1.033,0V27.431A5.329,5.329,0,0,1,83.365,22.1a2.154,2.154,0,0,0,2.159-2.153V19.81a6.92,6.92,0,0,1-3.934-5.982,2.108,2.108,0,0,1-1.619-2.042V9.108a9.463,9.463,0,0,1,2.56-6.9c2.956-2.849,7.7-2.668,11.182-.99a.518.518,0,0,1,.109.86,6.972,6.972,0,0,1,4.267,3.07.57.57,0,0,1,.119.406.522.522,0,0,1-.148.328,3.373,3.373,0,0,0-.976,2.382v3.524a.517.517,0,0,1-1.033,0V8.263a4.4,4.4,0,0,1,1-2.808A5.967,5.967,0,0,0,92.769,2.94l-.908-.1a.517.517,0,0,1-.142-.991l.413-.17C86.755-.194,81,1.89,81,9.1v2.684a1.066,1.066,0,0,0,.581.945V9.382A3.62,3.62,0,0,1,85.2,5.766h6.586A3.62,3.62,0,0,1,95.4,9.382v4.23a6.919,6.919,0,0,1-8.858,6.59,3.158,3.158,0,0,1-.27,1.055,3.912,3.912,0,0,0,3.05.6A.517.517,0,0,1,89.94,22.253Zm-1.446-2.8a5.883,5.883,0,0,0,5.876-5.876V9.382A2.586,2.586,0,0,0,91.787,6.8H85.2a2.586,2.586,0,0,0-2.583,2.583v4.19A5.883,5.883,0,0,0,88.494,19.449Zm5.23,8.9h-1.55a.517.517,0,1,0,0,1.033h1.55a.517.517,0,0,0,0-1.033Z"
          transform="translate(-78.033 0)"
        />
      </g>
    </g>
    <path
      fill="#fff"
      d="M1231.5-164.588l-5.187-9.05h-34.379l4.691,9.823Z"
      transform="translate(-927.308 481.798)"
    />
    <g transform="translate(266.765 310.378)">
      <g transform="translate(36.965 0.606) rotate(90)">
        <path
          fill="#d1e7ff"
          d="M24.239,15.755V6.06L12.12,0,0,6.06v9.7L12.12,9.7Z"
          transform="translate(0 0)"
        />
        <path
          fill="#b9dcff"
          d="M15.393,9.439V6.06L3.274,0,0,1.637A5.621,5.621,0,0,0,3.841,4.7,10.03,10.03,0,0,0,5.8,4.729c4.3-.137,4.419,1.871,5.5,3.236A4.2,4.2,0,0,0,15.393,9.439Z"
          transform="translate(8.846 0)"
        />
        <path
          fill="#b9dcff"
          d="M24.239,15.755V6.06L12.12,0,0,6.06v9.7L12.12,9.7Z"
          transform="translate(0 19.391)"
        />
        <path
          fill="#a2d0ff"
          d="M15.393,9.439V6.06L3.274,0,0,1.637A5.621,5.621,0,0,0,3.841,4.7,10.03,10.03,0,0,0,5.8,4.729c4.3-.137,4.419,1.871,5.5,3.236a4.2,4.2,0,0,0,4.1,1.474Z"
          transform="translate(8.846 19.391)"
        />
        <path
          fill="#e8f3ff"
          d="M24.239,15.755V6.06L12.12,0,0,6.06v9.7L12.12,9.7Z"
          transform="translate(0 9.696)"
        />
        <path
          fill="#d1e7ff"
          d="M18.771,11.448V6.06L6.651,0,0,3.325c.275.8.045,1.871.544,2.6a2.275,2.275,0,0,0,2.7.495c2.078-.811,3.073-2.854,8.154-.345A9.307,9.307,0,0,1,13.828,7.65c1.5,1.457,2.341,4.074,4.943,3.8Z"
          transform="translate(5.468 9.696)"
        />
      </g>
      <g transform="translate(37.571) rotate(90)">
        <path
          fill="#000000"
          d="M16.09,2.966,12.726,1.284,1.212,7.04v4.473a.606.606,0,1,1-1.212,0V6.666a.606.606,0,0,1,.335-.542L12.455.064a.606.606,0,0,1,.542,0l3.636,1.818a.606.606,0,0,1-.542,1.084Z"
          transform="translate(0 0)"
        />
        <path
          fill="#000000"
          d="M.606,12.74C.271,12.74,0,12.3,0,11.76V.98C0,.439.271,0,.606,0s.606.439.606.98V11.76C1.212,12.3.941,12.74.606,12.74Z"
          transform="translate(13.598 1.885) rotate(-65)"
        />
        <path
          fill="#000000"
          d="M1.212.606V7.272A.606.606,0,1,1,0,7.272V.606a.606.606,0,0,1,1.212,0Z"
          transform="translate(6.06 9.09)"
        />
        <path
          fill="#000000"
          d="M24.574,30.841,19.391,28.25v3.261a.606.606,0,0,1-1.212,0V27.644l-5.454-2.727L.877,30.841A.606.606,0,0,1,0,30.3V10.908a.606.606,0,0,1,.335-.542L2.759,9.154A.606.606,0,1,1,3.3,10.238L1.212,11.282v8.341c12-6,11.307-5.685,11.514-5.685s-.426-.285,11.514,5.685V11.282L12.726,5.525,10.573,6.6a.606.606,0,1,1-.542-1.084l2.424-1.212a.606.606,0,0,1,.542,0L24.239,9.927V1.587l-.877-.439A.606.606,0,0,1,23.634,0c.19,0,.157.007,1.483.67a.606.606,0,0,1,.335.542V30.3A.606.606,0,0,1,24.574,30.841ZM1.212,20.978v8.341L12.455,23.7a.606.606,0,0,1,.542,0l11.243,5.621V20.978L12.726,15.221Z"
          transform="translate(0 5.454)"
        />
        <path
          fill="#000000"
          d="M.606,0a.606.606,0,0,1,.606.606V2.424A.606.606,0,0,1,0,2.424V.606A.606.606,0,0,1,.606,0Z"
          transform="translate(12.12 34.541)"
        />
      </g>
    </g>
  </g>
</svg>
`;

export const GroupRoutineIcon = ({ style, ...rest }: Omit<XmlProps, "xml">) =>
  Platform.OS === "web" ? (
    <View style={style}>
      <svg width="43.377" height="50.592" viewBox="0 0 43.377 50.592" {...rest}>
        <g transform="translate(-263.413 -285.443)">
          <g transform="translate(263.413 285.443)">
            <g transform="translate(24.139 3.355)">
              <path
                fill="#b9dcff"
                d="M99.023,32v2.261a.461.461,0,0,1-.461.461H81.746a.461.461,0,0,1-.461-.461V32a4.3,4.3,0,0,1,2.52-3.912,6.735,6.735,0,0,1-2.2-2.527,6.089,6.089,0,0,1,.417-6.022,5.923,5.923,0,0,0,.994-3.285v-2.5A5.759,5.759,0,0,1,88.772,8h2.764A5.759,5.759,0,0,1,97.3,13.759c0,2.643-.065,3.16.254,4.211.449,1.486,1.043,1.67,1.5,3.182a6.093,6.093,0,0,1-2.037,6.53l-.51.406A4.3,4.3,0,0,1,99.023,32Z"
                transform="translate(-80.536 -7.539)"
              />
              <path
                fill="#b9dcff"
                d="M103.739,350.968v2.261a.461.461,0,0,1-.461.461H86.462a.461.461,0,0,1-.461-.461v-2.261a4.3,4.3,0,0,1,4.295-4.295,2.383,2.383,0,0,0,.989-.214l.014.031,3.289,2.546a.461.461,0,0,0,.564,0l3.289-2.546.014-.031a2.383,2.383,0,0,0,.989.214A4.3,4.3,0,0,1,103.739,350.968Z"
                transform="translate(-85.252 -326.507)"
              />
              <path
                fill="#d1e7ff"
                d="M151.968,121.125a.461.461,0,0,1,.436.46v2.758a5.7,5.7,0,1,1-11.4,0v-2.718a.46.46,0,0,1,.5-.459,11.92,11.92,0,0,0,7.77-1.987.459.459,0,0,1,.7.257A2.149,2.149,0,0,0,151.968,121.125Z"
                transform="translate(-137.084 -112.241)"
              />
              <path
                fill="#b9dcff"
                d="M97.022,27.683l-.51.406a4.284,4.284,0,0,0-1.774-.382,2.383,2.383,0,0,1-2.386-2.381v-.393l-.009-.021a5.7,5.7,0,0,0,3.522-5.27V16.883a.461.461,0,0,0-.436-.46,2.148,2.148,0,0,1-2-1.689.459.459,0,0,0-.7-.257,11.921,11.921,0,0,1-7.77,1.987.46.46,0,0,0-.5.459v2.718a5.7,5.7,0,0,0,3.522,5.27l-.009.021v.393a2.383,2.383,0,0,1-2.386,2.381,4.284,4.284,0,0,0-1.774.382l-.51-.406a6.093,6.093,0,0,1-1.276-8.143,5.923,5.923,0,0,0,.994-3.285v-2.5A5.759,5.759,0,0,1,88.78,8h2.764A5.759,5.759,0,0,1,97.3,13.759v2.5A5.927,5.927,0,0,0,98.3,19.54,6.093,6.093,0,0,1,97.022,27.683Z"
                transform="translate(-80.544 -7.539)"
              />
              <path
                fill="#a2d0ff"
                d="M205.379,302.6a5.718,5.718,0,0,1-4.507,0,3.575,3.575,0,0,0,.065-.946l.009-.021a5.713,5.713,0,0,0,4.36,0l.009.021A3.566,3.566,0,0,0,205.379,302.6Z"
                transform="translate(-193.507 -284.259)"
              />
              <path
                fill="#d1e7ff"
                d="M94.142,8.62a.6.6,0,0,1-.324,1.134q-.272-.026-.55-.026H90.5a5.759,5.759,0,0,0-5.759,5.759v.7c0,.414-.288.4-.288.736a18.1,18.1,0,0,1-.032,2.981c-.452,1.329-.994,1.521-1.435,2.976a6.088,6.088,0,0,0-.228,2.4.6.6,0,0,1-1.156.282,6.09,6.09,0,0,1,.417-6.022,5.924,5.924,0,0,0,.994-3.285v-2.5A5.759,5.759,0,0,1,88.776,8H91.54A5.735,5.735,0,0,1,94.142,8.62Z"
                transform="translate(-80.539 -7.539)"
              />
              <path
                fill="#e8f3ff"
                d="M145.611,161.935a.879.879,0,0,1-.772,1.565A5.665,5.665,0,0,1,141,158.115V155.4a.46.46,0,0,1,.5-.459c.221.02.463.036.725.044a.878.878,0,0,1,.848.879v1.333A5.681,5.681,0,0,0,145.611,161.935Z"
                transform="translate(-137.085 -146.013)"
              />
              <path
                fill="#d1e7ff"
                d="M92.25,347.227a.366.366,0,0,1-.217.657,4.294,4.294,0,0,0-4.3,4.294v1.512H86.463a.461.461,0,0,1-.461-.461v-2.261a4.3,4.3,0,0,1,4.295-4.295,2.382,2.382,0,0,0,.989-.214l.014.031Z"
                transform="translate(-85.252 -326.507)"
              />
              <path
                fill="#a2d0ff"
                d="M283.17,347.227a.366.366,0,0,0,.217.657,4.294,4.294,0,0,1,4.3,4.294v1.512h1.267a.461.461,0,0,0,.461-.461v-2.261a4.3,4.3,0,0,0-4.294-4.295,2.382,2.382,0,0,1-.989-.214l-.014.031Z"
                transform="translate(-270.931 -326.507)"
              />
              <path
                fill="#000000"
                d="M91.936,24.462v2.722a.461.461,0,0,1-.921,0V24.462a3.832,3.832,0,0,0-3.834-3.834,2.829,2.829,0,0,1-2.434-1.367.461.461,0,1,1,.787-.479,1.914,1.914,0,0,0,1.647.924A4.753,4.753,0,0,1,91.936,24.462Zm-7.687-3.568-1.643,1.272L79.812,20a2.823,2.823,0,0,0,1.056-1.989,6.163,6.163,0,0,0,7.9-5.913c0-.036,0-3.226,0-3.262a.461.461,0,0,0-.459-.432,1.9,1.9,0,0,1-1.2-.222,2.1,2.1,0,0,1-.871-1.676.461.461,0,0,0-.727-.379,14.848,14.848,0,0,1-6.533,2.592.461.461,0,0,0,.063.917,15.159,15.159,0,0,0,3.265-.8A15.8,15.8,0,0,0,85.428,7.3a2.6,2.6,0,0,0,2.419,2.047V12.1a5.241,5.241,0,1,1-10.482,0V8.869a.461.461,0,0,0-.921,0V12.1a6.17,6.17,0,0,0,3.513,5.563v.122a1.921,1.921,0,0,1-1.925,1.92,4.753,4.753,0,0,0-1.7.313l-.3-.237a5.627,5.627,0,0,1-1.18-7.527,6.365,6.365,0,0,0,1.071-3.54V6.22a5.3,5.3,0,0,1,5.3-5.3h2.764a5.3,5.3,0,0,1,5.3,5.3v2.5a6.333,6.333,0,0,0,1.066,3.532,5.628,5.628,0,0,1-.045,6.325.461.461,0,1,0,.759.523,6.549,6.549,0,0,0,.052-7.36,5.414,5.414,0,0,1-.911-3.02V6.22A6.227,6.227,0,0,0,83.988,0H81.224A6.227,6.227,0,0,0,75,6.22v2.5a5.446,5.446,0,0,1-.917,3.029A6.547,6.547,0,0,0,75.43,20.48a4.742,4.742,0,0,0-2.155,3.982v2.722a.461.461,0,0,0,.921,0V24.462a3.832,3.832,0,0,1,3.834-3.834,2.857,2.857,0,0,0,.9-.144l3.4,2.629a.461.461,0,0,0,.564,0l1.925-1.49a.461.461,0,1,0-.564-.729Z"
                transform="translate(-72.987 0)"
              />
            </g>
            <g transform="translate(0 3.355)">
              <path
                fill="#a2d0ff"
                d="M103.738,31.988v2.274a.461.461,0,0,1-.461.461H86.461A.461.461,0,0,1,86,34.262V31.988a4.315,4.315,0,0,1,4.318-4.282,2.384,2.384,0,0,0,1.922-1l.037.013-.026-.029-.009.012a2.673,2.673,0,0,0,.438-1.769l.009-.021a5.7,5.7,0,0,1-3.522-5.27v-.18a1.421,1.421,0,0,1-1.44-1.413V15.17a7.17,7.17,0,0,1,14.34,0v2.793a1.5,1.5,0,0,1-1.5,1.5v.18a5.7,5.7,0,0,1-3.522,5.27l.009.021A2.671,2.671,0,0,0,97.5,26.7l-.009-.012-.026.029L97.5,26.7a2.384,2.384,0,0,0,1.922,1,4.315,4.315,0,0,1,4.318,4.282Z"
                transform="translate(-85.539 -7.539)"
              />
              <path
                fill="#b9dcff"
                d="M130.34,15.17v2.793a1.5,1.5,0,0,1-1.5,1.5V15.9a2.764,2.764,0,0,0-2.764-2.764H120.2A2.764,2.764,0,0,0,117.44,15.9v3.557A1.421,1.421,0,0,1,116,18.047V15.17a7.17,7.17,0,1,1,14.34,0Z"
                transform="translate(-113.812 -7.539)"
              />
              <path
                fill="#b9dcff"
                d="M152.4,103.732a5.7,5.7,0,0,1-11.4,0V99.994a2.764,2.764,0,0,1,2.764-2.764h5.874a2.764,2.764,0,0,1,2.764,2.764Z"
                transform="translate(-137.372 -91.63)"
              />
              <path
                fill="#d1e7ff"
                d="M99.42,333.518a2.385,2.385,0,0,1-1.933-1.018l-2.275,2.528a.461.461,0,0,1-.685,0L92.252,332.5a2.385,2.385,0,0,1-1.933,1.018A4.315,4.315,0,0,0,86,337.8v2.274a.46.46,0,0,0,.461.461h16.817a.46.46,0,0,0,.461-.461V337.8A4.315,4.315,0,0,0,99.42,333.518Z"
                transform="translate(-85.539 -313.351)"
              />
              <g transform="translate(6.701 19.149)">
                <path
                  fill="#c79a83"
                  d="M194.388,332.529l-.038-.013,0,0,.009-.012Z"
                  transform="translate(-194.35 -332.5)"
                />
                <path
                  fill="#c79a83"
                  d="M285.038,332.516l-.037.013.026-.029.009.012Z"
                  transform="translate(-279.779 -332.5)"
                />
                <path
                  fill="#c79a83"
                  d="M194.362,332.5l-.009.012,0,0,.038.013Zm5.244.012-.009-.012-.026.029.037-.013Z"
                  transform="translate(-194.35 -332.5)"
                />
              </g>
              <path
                fill="#e8f3ff"
                d="M257.835,333.325c-.007.015-.576,2.6-.572,2.585a.424.424,0,0,1-.734.187,18.689,18.689,0,0,0-1.459-1.652l1.75-1.944A2.394,2.394,0,0,0,257.835,333.325Z"
                transform="translate(-244.872 -313.35)"
              />
              <path
                fill="#e8f3ff"
                d="M177.935,332.5l1.708,1.9-1.417,1.679a.424.424,0,0,1-.738-.182c0-.017-.562-2.555-.569-2.57A2.393,2.393,0,0,0,177.935,332.5Z"
                transform="translate(-171.223 -313.351)"
              />
              <path
                fill="#b9dcff"
                d="M143.9,347.36v6.66H131.39v-6.659c1.342-.572,1.737-.17,2.624-.551l.554,2.514a.461.461,0,0,0,.8.2l1.4-1.611.538.6a.461.461,0,0,0,.685,0l.537-.6,1.4,1.611a.461.461,0,0,0,.8-.2l.554-2.514C142.168,347.193,142.559,346.788,143.9,347.36Z"
                transform="translate(-128.315 -326.836)"
              />
              <path
                fill="#e8f3ff"
                d="M91.536,348.179a4.3,4.3,0,0,0-4.1,4.29v1.57h-.979a.461.461,0,0,1-.461-.461v-2.27a4.319,4.319,0,0,1,4.32-4.285,2.387,2.387,0,0,0,.916-.192C91.243,346.845,91.532,348.162,91.536,348.179Z"
                transform="translate(-85.539 -326.855)"
              />
              <path
                fill="#e8f3ff"
                d="M177.973,334.919l.255,1.158a.424.424,0,0,1-.738-.182c-.221-1.006-.564-2.56-.569-2.57a2.392,2.392,0,0,0,1.015-.825l.492.547A2.224,2.224,0,0,0,177.973,334.919Z"
                transform="translate(-171.224 -313.351)"
              />
              <path
                fill="#d1e7ff"
                d="M134.312,348.179a4.283,4.283,0,0,0-2.922,1.34V347.38c1.282-.549,1.771-.181,2.622-.55C134.018,346.843,134.312,348.175,134.312,348.179Z"
                transform="translate(-128.315 -326.856)"
              />
              <path
                fill="#b9dcff"
                d="M297.88,348.179a4.3,4.3,0,0,1,4.1,4.29v1.57h.979a.461.461,0,0,0,.461-.461v-2.27a4.319,4.319,0,0,0-4.32-4.285,2.386,2.386,0,0,1-.916-.192C298.172,346.845,297.884,348.162,297.88,348.179Z"
                transform="translate(-285.217 -326.855)"
              />
              <path
                fill="#a2d0ff"
                d="M297.88,348.179a4.283,4.283,0,0,1,2.922,1.34V347.38c-1.282-.549-1.771-.181-2.622-.55C298.174,346.843,297.88,348.175,297.88,348.179Z"
                transform="translate(-285.217 -326.856)"
              />
              <path
                fill="#a2d0ff"
                d="M204.221,224.266a.445.445,0,0,1,.149.765,1.664,1.664,0,0,1-1.922.136.451.451,0,0,0-.45,0,1.664,1.664,0,0,1-1.922-.136.445.445,0,0,1,.149-.765c.553-.19.651-.587,1.08-.7a1.066,1.066,0,0,1,.733.1.447.447,0,0,0,.371,0,1.067,1.067,0,0,1,.733-.1C203.57,223.678,203.668,224.075,204.221,224.266Z"
                transform="translate(-192.893 -210.663)"
              />
              <path
                fill="#8bc4ff"
                d="M205.377,302.6a5.718,5.718,0,0,1-4.507,0,3.57,3.57,0,0,0,.065-.946l.009-.021a5.713,5.713,0,0,0,4.36,0l.009.021A3.566,3.566,0,0,0,205.377,302.6Z"
                transform="translate(-193.794 -284.259)"
              />
              <path
                fill="#d1e7ff"
                d="M144.356,121.261a1.011,1.011,0,0,1-1.359,1.5,5.585,5.585,0,0,1-2-4.332v-3.738a2.794,2.794,0,0,1,.488-1.573.682.682,0,0,1,1.24.4v3.755A5.644,5.644,0,0,0,144.356,121.261Z"
                transform="translate(-137.372 -106.322)"
              />
              <path
                fill="#d1e7ff"
                d="M125.071,9.636a7.168,7.168,0,0,0-6.566,3.916.717.717,0,0,1-.156.208,2.756,2.756,0,0,0-.911,2.05v2.161a.72.72,0,0,1-1.438.054c0-.056,0-2.934,0-2.879a7.2,7.2,0,0,1,9.3-6.919.72.72,0,0,1-.223,1.409Z"
                transform="translate(-113.809 -7.446)"
              />
              <path
                fill="#000000"
                d="M91.9,19.706a1.914,1.914,0,0,1-1.647-.924.461.461,0,1,0-.787.479,2.843,2.843,0,0,0,.964.96l-.477,2.167-1.151-1.326a.461.461,0,1,0-.671-.632l-.81.932-2.041-2.267a2.816,2.816,0,0,0,.309-1.081,6.169,6.169,0,0,0,7.9-5.864c0-.01,0-3.776,0-3.786a3.229,3.229,0,0,0-3.225-3.225H84.393a3.229,3.229,0,0,0-3.225,3.225v2.987a.951.951,0,0,1-.518-.843V7.631a6.709,6.709,0,0,1,13.419,0v2.877a.461.461,0,0,0,.921,0V7.631a7.631,7.631,0,1,0-15.262,0v2.877a1.88,1.88,0,0,0,1.444,1.821,6.172,6.172,0,0,0,3.509,5.335v.121a1.922,1.922,0,0,1-1.925,1.92A4.753,4.753,0,0,0,78,24.462v2.722a.461.461,0,0,0,.922,0V24.462a3.824,3.824,0,0,1,1.692-3.181v5.9a.461.461,0,0,0,.921,0V20.826a9.021,9.021,0,0,1,1.822-.262L84,23.481a.461.461,0,0,0,.8.2l1.658-1.909a6.676,6.676,0,0,0,.6.653.459.459,0,0,0,.331.086c.224-.028.263-.123.807-.749l1.667,1.919a.461.461,0,0,0,.8-.2l.643-2.918a2.893,2.893,0,0,0,.6.064,3.832,3.832,0,0,1,3.834,3.834v2.722a.461.461,0,0,0,.921,0V24.462A4.753,4.753,0,0,0,91.9,19.706Zm-9.815-7.6V8.364a2.306,2.306,0,0,1,2.3-2.3h5.874a2.306,2.306,0,0,1,2.3,2.3V12.1a5.241,5.241,0,1,1-10.482,0ZM84.7,22.388l-.478-2.168a2.859,2.859,0,0,0,.492-.377l1.117,1.241Zm9.343-.07v4.865a.461.461,0,1,1-.921,0V22.318a.461.461,0,1,1,.921,0ZM88.63,15.2a2.406,2.406,0,0,0,1.825-1.328.461.461,0,0,0-.467-.652c-.884.093-.887-.566-1.622-.761a1.532,1.532,0,0,0-1.036.121,1.532,1.532,0,0,0-1.036-.121c-.737.195-.736.854-1.622.761a.461.461,0,0,0-.466.652A2.406,2.406,0,0,0,86.03,15.2a2.2,2.2,0,0,0,1.3-.292A2.2,2.2,0,0,0,88.63,15.2ZM85.5,14.031c.918-.327.78-1.01,1.6-.537a.461.461,0,0,0,.461,0c.817-.472.677.208,1.6.537a1.227,1.227,0,0,1-1.542-.04.461.461,0,0,0-.573,0A1.226,1.226,0,0,1,85.5,14.031Z"
                transform="translate(-78 0)"
              />
            </g>
            <g transform="translate(11.141 0)">
              <path
                fill="#d1e7ff"
                d="M105.921,34.921v2.535a.516.516,0,0,1-.517.517H86.549a.516.516,0,0,1-.517-.517V34.921a4.816,4.816,0,0,1,4.817-4.815,2.674,2.674,0,0,0,2.244-1.218A3.035,3.035,0,0,0,93.523,27l.01-.024a6.393,6.393,0,0,1-3.949-5.908v-.2a1.593,1.593,0,0,1-1.614-1.585V16.592a8.938,8.938,0,0,1,2.4-6.524C93.139,7.4,97.525,7.619,100.7,9.046a.13.13,0,0,1,0,.238l-.828.341a.129.129,0,0,0,.036.248l.4.043a6.518,6.518,0,0,1,4.825,2.993.139.139,0,0,1-.017.16,3.907,3.907,0,0,0-1.067,2.684v3.524a1.585,1.585,0,0,1-1.585,1.585h-.094v.2a6.393,6.393,0,0,1-3.949,5.908l.01.024a3.034,3.034,0,0,0,.429,1.893,2.674,2.674,0,0,0,2.245,1.218A4.816,4.816,0,0,1,105.921,34.921Z"
                transform="translate(-85.516 -7.49)"
              />
              <path
                fill="#b9dcff"
                d="M105.921,337.406v2.535a.517.517,0,0,1-.517.517H86.549a.517.517,0,0,1-.517-.517v-2.535a4.816,4.816,0,0,1,4.816-4.815,2.673,2.673,0,0,0,2.245-1.218,4.432,4.432,0,0,0,5.766,0,2.673,2.673,0,0,0,2.245,1.218A4.816,4.816,0,0,1,105.921,337.406Z"
                transform="translate(-85.516 -309.975)"
              />
              <path
                fill="#b9dcff"
                d="M133.155,12.91a.137.137,0,0,1-.017.159,3.909,3.909,0,0,0-1.067,2.684v3.524a1.588,1.588,0,0,1-1.679,1.585V16.872a3.1,3.1,0,0,0-3.1-3.1h-6.587a3.1,3.1,0,0,0-3.1,3.1v3.989a1.593,1.593,0,0,1-1.614-1.585V16.593a8.938,8.938,0,0,1,2.4-6.524c2.767-2.668,7.154-2.449,10.33-1.022a.129.129,0,0,1,0,.238l-.829.341a.129.129,0,0,0,.035.248l.4.043A6.515,6.515,0,0,1,133.155,12.91Z"
                transform="translate(-113.539 -7.49)"
              />
              <path
                fill="#d1e7ff"
                d="M153.818,104.583a6.394,6.394,0,1,1-12.786-.2v-3.989a3.1,3.1,0,0,1,3.1-3.1h6.586a3.1,3.1,0,0,1,3.1,3.1Z"
                transform="translate(-136.964 -91.01)"
              />
              <path
                fill="#e8f3ff"
                d="M145.977,123.353a1.021,1.021,0,0,1-.928,1.808,6.394,6.394,0,0,1-4.017-5.936v-4.191a3.086,3.086,0,0,1,.544-1.754.763.763,0,0,1,1.393.463v4.191A6.346,6.346,0,0,0,145.977,123.353Z"
                transform="translate(-136.964 -105.653)"
              />
              <path
                fill="#b9dcff"
                d="M205.956,302.777a6.41,6.41,0,0,1-5.054,0,4,4,0,0,0,.073-1.06l.01-.024a6.406,6.406,0,0,0,4.888,0l.01.024A4,4,0,0,0,205.956,302.777Z"
                transform="translate(-192.968 -282.212)"
              />
              <path
                fill="#d1e7ff"
                d="M93.449,334.151a2.628,2.628,0,0,1-.769.119,4.829,4.829,0,0,0-4.841,4.8v.485a.9.9,0,0,1-.9.9h-.387a.517.517,0,0,1-.517-.517v-2.535a4.816,4.816,0,0,1,4.816-4.815,2.673,2.673,0,0,0,2.245-1.218,4.465,4.465,0,0,0,.614.441A1.29,1.29,0,0,1,93.449,334.151Z"
                transform="translate(-85.515 -309.975)"
              />
              <path
                fill="#a2d0ff"
                d="M266.254,334.151a2.628,2.628,0,0,0,.769.119,4.829,4.829,0,0,1,4.841,4.8v.485a.9.9,0,0,0,.9.9h.387a.517.517,0,0,0,.517-.517v-2.535a4.816,4.816,0,0,0-4.816-4.815,2.674,2.674,0,0,1-2.245-1.218,4.459,4.459,0,0,1-.614.441A1.29,1.29,0,0,0,266.254,334.151Z"
                transform="translate(-253.268 -309.975)"
              />
              <path
                fill="#d1e7ff"
                d="M128.722,9.284c-.759.312-.867.381-1.131.341-4.241-.657-7.961,1.1-9.225,5.217h0a3.084,3.084,0,0,0-.758,2.03v2.423a.807.807,0,1,1-1.614-.019V16.592a8.938,8.938,0,0,1,2.4-6.524c2.768-2.668,7.153-2.449,10.33-1.022A.13.13,0,0,1,128.722,9.284Z"
                transform="translate(-113.54 -7.49)"
              />
              <path
                fill="#000000"
                d="M98.955,27.431v3.052a.517.517,0,1,1-1.033,0V27.431a4.3,4.3,0,0,0-4.3-4.3A3.172,3.172,0,0,1,90.894,21.6a.517.517,0,1,1,.882-.537A2.146,2.146,0,0,0,93.623,22.1a5.329,5.329,0,0,1,5.332,5.332ZM89.94,22.253a.517.517,0,0,1-.395.615,4.944,4.944,0,0,1-3.843-.752,3.133,3.133,0,0,1-2.337,1.017,4.3,4.3,0,0,0-4.3,4.3v3.052a.517.517,0,0,1-1.033,0V27.431A5.329,5.329,0,0,1,83.365,22.1a2.154,2.154,0,0,0,2.159-2.153V19.81a6.92,6.92,0,0,1-3.934-5.982,2.108,2.108,0,0,1-1.619-2.042V9.108a9.463,9.463,0,0,1,2.56-6.9c2.956-2.849,7.7-2.668,11.182-.99a.518.518,0,0,1,.109.86,6.972,6.972,0,0,1,4.267,3.07.57.57,0,0,1,.119.406.522.522,0,0,1-.148.328,3.373,3.373,0,0,0-.976,2.382v3.524a.517.517,0,0,1-1.033,0V8.263a4.4,4.4,0,0,1,1-2.808A5.967,5.967,0,0,0,92.769,2.94l-.908-.1a.517.517,0,0,1-.142-.991l.413-.17C86.755-.194,81,1.89,81,9.1v2.684a1.066,1.066,0,0,0,.581.945V9.382A3.62,3.62,0,0,1,85.2,5.766h6.586A3.62,3.62,0,0,1,95.4,9.382v4.23a6.919,6.919,0,0,1-8.858,6.59,3.158,3.158,0,0,1-.27,1.055,3.912,3.912,0,0,0,3.05.6A.517.517,0,0,1,89.94,22.253Zm-1.446-2.8a5.883,5.883,0,0,0,5.876-5.876V9.382A2.586,2.586,0,0,0,91.787,6.8H85.2a2.586,2.586,0,0,0-2.583,2.583v4.19A5.883,5.883,0,0,0,88.494,19.449Zm5.23,8.9h-1.55a.517.517,0,1,0,0,1.033h1.55a.517.517,0,0,0,0-1.033Z"
                transform="translate(-78.033 0)"
              />
            </g>
          </g>
          <path
            fill="#fff"
            d="M1231.5-164.588l-5.187-9.05h-34.379l4.691,9.823Z"
            transform="translate(-927.308 481.798)"
          />
          <g transform="translate(266.765 310.378)">
            <g transform="translate(36.965 0.606) rotate(90)">
              <path
                fill="#d1e7ff"
                d="M24.239,15.755V6.06L12.12,0,0,6.06v9.7L12.12,9.7Z"
                transform="translate(0 0)"
              />
              <path
                fill="#b9dcff"
                d="M15.393,9.439V6.06L3.274,0,0,1.637A5.621,5.621,0,0,0,3.841,4.7,10.03,10.03,0,0,0,5.8,4.729c4.3-.137,4.419,1.871,5.5,3.236A4.2,4.2,0,0,0,15.393,9.439Z"
                transform="translate(8.846 0)"
              />
              <path
                fill="#b9dcff"
                d="M24.239,15.755V6.06L12.12,0,0,6.06v9.7L12.12,9.7Z"
                transform="translate(0 19.391)"
              />
              <path
                fill="#a2d0ff"
                d="M15.393,9.439V6.06L3.274,0,0,1.637A5.621,5.621,0,0,0,3.841,4.7,10.03,10.03,0,0,0,5.8,4.729c4.3-.137,4.419,1.871,5.5,3.236a4.2,4.2,0,0,0,4.1,1.474Z"
                transform="translate(8.846 19.391)"
              />
              <path
                fill="#e8f3ff"
                d="M24.239,15.755V6.06L12.12,0,0,6.06v9.7L12.12,9.7Z"
                transform="translate(0 9.696)"
              />
              <path
                fill="#d1e7ff"
                d="M18.771,11.448V6.06L6.651,0,0,3.325c.275.8.045,1.871.544,2.6a2.275,2.275,0,0,0,2.7.495c2.078-.811,3.073-2.854,8.154-.345A9.307,9.307,0,0,1,13.828,7.65c1.5,1.457,2.341,4.074,4.943,3.8Z"
                transform="translate(5.468 9.696)"
              />
            </g>
            <g transform="translate(37.571) rotate(90)">
              <path
                fill="#000000"
                d="M16.09,2.966,12.726,1.284,1.212,7.04v4.473a.606.606,0,1,1-1.212,0V6.666a.606.606,0,0,1,.335-.542L12.455.064a.606.606,0,0,1,.542,0l3.636,1.818a.606.606,0,0,1-.542,1.084Z"
                transform="translate(0 0)"
              />
              <path
                fill="#000000"
                d="M.606,12.74C.271,12.74,0,12.3,0,11.76V.98C0,.439.271,0,.606,0s.606.439.606.98V11.76C1.212,12.3.941,12.74.606,12.74Z"
                transform="translate(13.598 1.885) rotate(-65)"
              />
              <path
                fill="#000000"
                d="M1.212.606V7.272A.606.606,0,1,1,0,7.272V.606a.606.606,0,0,1,1.212,0Z"
                transform="translate(6.06 9.09)"
              />
              <path
                fill="#000000"
                d="M24.574,30.841,19.391,28.25v3.261a.606.606,0,0,1-1.212,0V27.644l-5.454-2.727L.877,30.841A.606.606,0,0,1,0,30.3V10.908a.606.606,0,0,1,.335-.542L2.759,9.154A.606.606,0,1,1,3.3,10.238L1.212,11.282v8.341c12-6,11.307-5.685,11.514-5.685s-.426-.285,11.514,5.685V11.282L12.726,5.525,10.573,6.6a.606.606,0,1,1-.542-1.084l2.424-1.212a.606.606,0,0,1,.542,0L24.239,9.927V1.587l-.877-.439A.606.606,0,0,1,23.634,0c.19,0,.157.007,1.483.67a.606.606,0,0,1,.335.542V30.3A.606.606,0,0,1,24.574,30.841ZM1.212,20.978v8.341L12.455,23.7a.606.606,0,0,1,.542,0l11.243,5.621V20.978L12.726,15.221Z"
                transform="translate(0 5.454)"
              />
              <path
                fill="#000000"
                d="M.606,0a.606.606,0,0,1,.606.606V2.424A.606.606,0,0,1,0,2.424V.606A.606.606,0,0,1,.606,0Z"
                transform="translate(12.12 34.541)"
              />
            </g>
          </g>
        </g>
      </svg>
    </View>
  ) : (
    <SvgXml xml={xml} style={style} {...rest} />
  );
