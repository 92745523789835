import React from "react";
import { StyleSheet } from "react-native";
import { IconButton } from "react-native-paper";
import { MaterialIcons } from "@expo/vector-icons";
import { COLORS } from "utils/appStyles";

const styles = StyleSheet.create({
  button: {
    backgroundColor: "white",
    overflow: "visible",
    borderRadius: 50,
    elevation: 2,
    shadowOffset: { width: 0, height: 2 },
    shadowColor: "#CECECE",
    shadowOpacity: 1,
    shadowRadius: 2,
  },
});

export const AddFriendIconButton = ({ size, onPress }) => {
  return (
    <IconButton
      style={styles.button}
      icon={() => (
        <MaterialIcons name="person-add" size={size} color={COLORS.accent} />
      )}
      size={size}
      onPress={onPress}
    />
  );
};
