import React, { ReactElement, ComponentType, memo } from "react";
import { View, Text, StyleSheet, StyleProp, ViewStyle } from "react-native";

import { RenderWeek, DayInfo } from "types/habits";
import { useGetDay } from "redux/selectors";
import { getDayStatusColor } from "utils/habits";
import { COLORS } from "utils/appStyles";
import { ScheduleDay, FrequencyDay } from "./HabitDay";

export const DayContainer = ({
  children,
  style = {},
}: {
  children: ReactElement | ReactElement[];
  style?: StyleProp<ViewStyle>;
}) => <View style={[styles.dayContainer, style]}>{children}</View>;

export const WeekContainer = ({
  children,
  style = {},
}: {
  children: ReactElement | ReactElement[];
  style?: StyleProp<ViewStyle>;
}) => <View style={[styles.dateView, style]}>{children}</View>;

const SimpleDay = ({ index, dayInfo, habitid, ownHabit }) => {
  const detail = useGetDay({ date: dayInfo.date, habitid, ownHabit });
  const statusColor = detail ? getDayStatusColor(detail.status) : COLORS.skip;

  return (
    <View
      style={[
        simpleWeekStyle.day,
        { backgroundColor: statusColor },
        index === 0 ? {} : { borderLeftColor: "#fff", borderLeftWidth: 1 },
      ]}
    >
      <Text
        style={[
          simpleWeekStyle.text,
          { color: detail.status ? "#fff" : "#000" },
        ]}
      >
        {dayInfo.display}
      </Text>
    </View>
  );
};

export const SimpleWeek = ({ habitid, ownHabit, week }) => {
  return (
    <View style={simpleWeekStyle.week}>
      {week.map((dayInfo, i) => (
        <SimpleDay
          key={i}
          index={i}
          dayInfo={dayInfo}
          habitid={habitid}
          ownHabit={ownHabit}
        />
      ))}
    </View>
  );
};

const simpleWeekStyle = StyleSheet.create({
  week: {
    display: "flex",
    flexDirection: "row",
    borderRadius: 10,
    overflow: "hidden",
  },
  day: {
    flex: 1,
    paddingVertical: 2,
  },
  text: {
    textAlign: "center",
    fontSize: 10,
  },
});

export const Week = memo(
  ({
    week,
    habitid,
    ownHabit,
    friendid,
    style = {},
    DayComponent,
    view,
    weekNum,
  }: {
    week: RenderWeek;
    habitid: string;
    style?: StyleProp<ViewStyle>;
    ownHabit: boolean;
    friendid?: string;
    DayComponent: ComponentType<{
      ownHabit: boolean;
      view: "week" | "month";
      dayInfo: DayInfo;
      habitid: string;
      friendid?: string;
    }>;
  } & (
    | {
        view: "month";
        weekNum: number;
      }
    | {
        view: "week";
        weekNum?: number;
      }
  )) => {
    const weekRow = week.map(dayInfo => {
      return (
        <DayContainer key={dayInfo.date}>
          {weekNum === 0 && view === "month" && (
            <Text style={styles.today}>{dayInfo.dayOfWeekString}</Text>
          )}
          <DayComponent
            dayInfo={dayInfo}
            ownHabit={ownHabit}
            view={view}
            habitid={habitid}
            friendid={friendid}
          />
          {dayInfo.date === week[6].date && view === "week" && (
            <Text style={styles.today}>TODAY</Text>
          )}
        </DayContainer>
      );
    });

    return <WeekContainer style={style}>{weekRow}</WeekContainer>;
  }
);

export const ScheduleWeek = ({ schedule, setSchedule, disabled }) => (
  <View style={styles.dateView}>
    {schedule.map((value, i) => (
      <DayContainer key={i}>
        <ScheduleDay
          dayOfWeek={i}
          value={value}
          setSchedule={setSchedule}
          disabled={disabled}
        />
      </DayContainer>
    ))}
  </View>
);

export const FrequencyWeek = ({ frequency, setFrequency, disabled }) => (
  <View style={styles.dateView}>
    {Array(7)
      .fill(true)
      .map((_value, i) => (
        <DayContainer key={i + 1}>
          <FrequencyDay
            num={i + 1}
            frequency={frequency}
            setFrequency={setFrequency}
            disabled={disabled}
          />
        </DayContainer>
      ))}
  </View>
);

const styles = StyleSheet.create({
  dayContainer: {
    width: "13%",
  },
  dateView: {
    justifyContent: "space-around",
    flexDirection: "row",
  },
  today: {
    textAlign: "center",
    fontSize: 10,
  },
});
