import { createSelector } from "@reduxjs/toolkit";
import { isLoaded } from "react-redux-firebase";

import { useSelector } from "redux/store";
import {
  Habit,
  isAChallenge,
  isHabitChallenge,
  HabitChallenge,
} from "types/habits";
import { Logger } from "utils/Logger";
import { isEqual } from "lodash";
import { useMemo } from "react";

const habitSelector = habitid =>
  createSelector(
    state => state.firestore.data?.habits,
    state => state.firestore.data?.friendHabits,
    state => state.firestore.data?.challenges,
    (habits, friendHabits, challenges) => {
      return {
        isLoaded: isLoaded(habits, friendHabits, challenges),
        habit: (habits?.[habitid] ??
          friendHabits?.[habitid] ??
          challenges?.[habitid] ??
          null) as Habit,
      };
    }
  );

export const useGetSpecificHabit = habitid => {
  const selector = habitSelector(habitid);

  Logger.log("useSpecificHabit for habitid", habitid, "re-running");

  return useSelector(selector, (prev, current) => {
    if (prev.isLoaded === current.isLoaded && prev.habit === current.habit) {
      return true;
    }

    if (prev.isLoaded !== current.isLoaded) {
      return false;
    }

    const prevHabit = prev.habit;
    const currentHabit = current.habit;

    return (
      prevHabit.emoji === currentHabit.emoji &&
      prevHabit.title === currentHabit.title &&
      prevHabit.streak === currentHabit.streak &&
      prevHabit.description === currentHabit.description &&
      prevHabit.isChallenge === currentHabit.isChallenge &&
      prevHabit.frequencyType === currentHabit.frequencyType &&
      prevHabit.frequency === currentHabit.frequency &&
      prevHabit.visibility === currentHabit.visibility &&
      prevHabit.hasReminder === currentHabit.hasReminder &&
      prevHabit.reminder.time === currentHabit.reminder.time &&
      //! redux-firestore overrides the entire document
      //! so we can't do a quick sharing === sharing check
      //! (or for other complex types)
      //! this might have performance issues on heavily shared habits...
      isEqual(prevHabit.sharing, currentHabit.sharing) &&
      isEqual(prevHabit.schedule, currentHabit.schedule) &&
      (isAChallenge(prevHabit) && isAChallenge(currentHabit)
        ? isEqual(prevHabit.challengers, currentHabit.challengers)
        : true) &&
      (isHabitChallenge(prevHabit)
        ? prevHabit.isLinkInviteEnabled ===
          (currentHabit as HabitChallenge).isLinkInviteEnabled
        : true)
    );
  });
};

// Only memoized for schedule
export const useSpecificHabitMemoizedSchedule = habitid =>
  useSelector(
    useMemo(() => habitSelector(habitid), [habitid]),
    (left, right) => isEqual(left?.habit?.schedule, right?.habit?.schedule)
  );
