import React, { useCallback, useState, memo } from "react";
import { FlatList } from "react-native-gesture-handler";
import { useHabitGridStyles } from "hooks/habits/useHabitGridStyles";
import { useGetHabitsCount } from "redux/selectors";
import { Platform, View, StyleSheet } from "react-native";
import { COLORS } from "styles";
import { ProgressColumn } from "./ProgressColumn";

// Grid is used in PanelRight but most people probably won't scroll at all
const NUM_DAYS_TO_ADD = Platform.OS === "web" ? 5 : 15;

const ChallengeHabitGridKeyExtractor = (_, index) => "" + index;

export const ChallengeHabitGrid = memo(
  ({
    habits,
  }: {
    habits: {
      id: string;
      uid: string;
      ownHabit: boolean;
    }[];
  }) => {
    const renderItem = useCallback(
      ({ index }: { index: number }) => {
        return <ProgressColumn index={index} habits={habits} />;
      },
      [habits]
    );

    return (
      <HabitGridLayout
        renderItem={renderItem}
        keyExtractor={ChallengeHabitGridKeyExtractor}
      />
    );
  }
);

export const HabitGrid = memo(
  ({ ownHabit, friendId }: { ownHabit: boolean; friendId?: string }) => {
    const habitsCount = useGetHabitsCount(friendId);

    const renderItem = useCallback(
      ({ index }: { index: number }) => {
        return (
          <ProgressColumn
            index={index}
            habitsCount={habitsCount}
            ownHabit={ownHabit}
            friendId={friendId}
          />
        );
      },
      [ownHabit, habitsCount, friendId]
    );

    const keyExtractor = useCallback(
      (_, index) => `${ownHabit ? "me" : friendId}-${index}`,
      [ownHabit, friendId]
    );

    return (
      <HabitGridLayout renderItem={renderItem} keyExtractor={keyExtractor} />
    );
  }
);

const HabitGridLayout = ({ renderItem, keyExtractor }) => {
  const [listDays, setListDays] = useState(NUM_DAYS_TO_ADD);
  const { COLUMN_WIDTH } = useHabitGridStyles();

  const onEndReached = useCallback(() => {
    setListDays(listDays => listDays + NUM_DAYS_TO_ADD);
  }, []);

  const getItemLayout = useCallback(
    (_data, index) => ({
      length: COLUMN_WIDTH,
      offset: COLUMN_WIDTH * index,
      index,
    }),
    [COLUMN_WIDTH]
  );

  return (
    <FlatList
      data={Array(listDays).fill(0)}
      horizontal
      inverted
      showsHorizontalScrollIndicator={Platform.OS === "web"}
      renderItem={renderItem}
      onEndReached={onEndReached}
      getItemLayout={getItemLayout}
      keyExtractor={keyExtractor}
      windowSize={10}
      initialNumToRender={10}
    />
  );
};
