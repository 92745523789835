import { useState, useEffect } from "react";
import { AppState } from "react-native";
import * as Permissions from "expo-permissions";

export const useNotificationPermissions = () => {
  const [notificationsGranted, setNotificationsGranted] = useState<boolean>(
    false
  );

  useEffect(() => {
    // Check for and set notification permissions granted status
    async function isNotificationsEnabled() {
      let { status } = await Permissions.getAsync(Permissions.NOTIFICATIONS);
      if (status !== "granted") {
        // if never asked for notifications permission before (e.g. anon user), this will show native permissions dialog
        // else, askAsync is blocked (status undetermined) and we revert to our grantNotifications dialog/message
        const newPermission = await Permissions.askAsync(
          Permissions.NOTIFICATIONS
        );
        status = newPermission.status;
      }
      setNotificationsGranted(status === "granted");
    }

    // Callback for AppState change, calls isNotificationsDisabled if app is foregrounded
    const handleChange = newState => {
      if (newState === "active") {
        isNotificationsEnabled();
      }
    };

    isNotificationsEnabled();

    AppState.addEventListener("change", handleChange);
    return () => {
      AppState.removeEventListener("change", handleChange);
    };
  }, []);

  return notificationsGranted;
};
