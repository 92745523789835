import React, { createContext, useContext, useState, useEffect } from "react";
import moment from "moment";
import { AppState } from "react-native";

const TimeContext = createContext({
  today: moment().startOf("day") as Readonly<moment.Moment>,
});

export const TimeContextProvider = ({ children }) => {
  const [today, setToday] = useState(moment().startOf("day"));

  useEffect(() => {
    async function handleStateChange(nextAppState) {
      if (nextAppState === "active") {
        setToday(oldDate => {
          const newDate = moment().startOf("day");
          if (oldDate.diff(newDate, "day") !== 0) {
            return newDate;
          }
          return oldDate;
        });
      }
    }

    AppState.addEventListener("change", handleStateChange);

    return () => AppState.removeEventListener("change", handleStateChange);
  }, []);

  return (
    <TimeContext.Provider
      value={{
        today,
      }}
    >
      {children}
    </TimeContext.Provider>
  );
};

export const useTimeContext = () => useContext(TimeContext);
