import React from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
import { Ionicons } from "@expo/vector-icons";

import { KImage } from "components";

export const SavedImage = ({
  image,
  onImagePressed,
  onRemovePressed,
  style,
  isEditable,
}) => {
  return (
    <TouchableOpacity
      onPress={onImagePressed}
      style={[styles.container, style]}
    >
      <KImage
        local={image.uri}
        remote={image.url}
        type={image.type}
        style={styles.image}
      />
      {isEditable && (
        <TouchableOpacity style={styles.iconButton} onPress={onRemovePressed}>
          <Ionicons name="md-close-circle" size={30} color="white" />
        </TouchableOpacity>
      )}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 150,
    position: "relative",
    width: 150,
  },
  image: {
    height: "100%",
    width: "100%",
  },
  iconButton: {
    position: "absolute",
    right: 3,
    top: 0,
    zIndex: 1,
  },
});
