import React, { memo } from "react";
import { Text } from "react-native";
import { FriendItem } from "components/UI/FriendItem";
import { useGetFriend } from "redux/selectors";
import { useString } from "hooks";
import { useHistory } from "utils/react-router";

const Item = ({ id }: { id: string }) => {
  const { friend } = useGetFriend(id);
  const s = useString("chatScreen");
  const history = useHistory();

  return (
    <FriendItem
      avatar={{
        uri: friend.avatar,
      }}
      id={id}
      title={<Text>{friend.name}</Text>}
      subtitle={s("noMessage")}
      containerStyle={{
        paddingVertical: 10,
      }}
      onPress={() => {
        // This only renders if layout is wide
        history.push(`/friend/${id}`);
      }}
    />
  );
};

export const NoChatItem = memo(Item);
