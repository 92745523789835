import { AnnotatedContact } from "mobile/src/types/friends";

// TODO - include email regex?
function oneEmailValid(c) {
  return (
    c.emails &&
    c.emails.length > 0 &&
    // We only want contacts with names
    // Android sets contact name to email if no name
    // so we need to filter out this case
    !c.emails.some(e => e.email === c.name)
  );
}

export const filterContacts = (
  contacts: AnnotatedContact[],
  includeContactsWithPhoneNumbers: boolean
) => {
  return contacts
    .filter(c => {
      if (!c.name) {
        return false;
      }
      if (
        includeContactsWithPhoneNumbers &&
        c.phoneNumbers &&
        c.phoneNumbers.length > 0
      ) {
        return true;
      }
      return oneEmailValid(c);
    })
    .sort((a, b) => a.name.localeCompare(b.name));
};
