import React, { useMemo } from "react";
import { StyleSheet } from "react-native";

import { getAbsoluteDays } from "utils/time";
import moment from "moment";
import { Week } from "./Week";

const getWeeks = (month: moment.Moment) => {
  const monthStart = month.clone().startOf("month");
  const monthEnd = month.clone().endOf("month");

  //start with first day of week, even if outside month
  const day = monthStart.clone().startOf("week").startOf("day");
  const weeks = [];
  let weekNum = 0;

  //if still more days in month, print a new week
  while (day < monthEnd) {
    weeks[weekNum] = [];
    for (let i = 0; i < 7; i++) {
      const inMonth = day >= monthStart && day < monthEnd;
      weeks[weekNum].push({
        date: day.format("MM-DD-YYYY"),
        display: day.format("D"),
        absoluteDay: getAbsoluteDays(day),
        dayOfWeek: day.day(),
        dayOfWeekString: day.format("ddd"),
        disabled: !inMonth,
        outOfMonth: !inMonth,
      });
      day.add(1, "days");
    }
    weekNum++;
  }

  return weeks;
};

export const Month = ({
  month,
  habit,
  habitid,
  ownHabit,
  friendid,
  DayComponent,
}: {
  month: moment.Moment;
  habit;
  habitid;
  ownHabit;
  friendid;
  DayComponent;
}) => {
  const weeks = useMemo(() => getWeeks(month), [month]);
  const monthString = useMemo(() => month.format("M"), [month]);
  return (
    <>
      {weeks.map((week, val) => (
        <Week
          week={week}
          weekNum={val}
          key={monthString + val}
          habitid={habitid}
          style={styles.week}
          view="month"
          ownHabit={ownHabit}
          friendid={friendid}
          DayComponent={DayComponent}
        />
      ))}
    </>
  );
};

const styles = StyleSheet.create({
  week: {
    marginBottom: 5,
  },
});
