import React, { useRef } from "react";
import { View, StyleSheet, Platform, Text } from "react-native";
import * as Linking from "expo-linking";
import * as MediaLibrary from "expo-media-library";
import * as FileSystem from "expo-file-system";
import * as Sharing from "expo-sharing";
import { IconButton } from "react-native-paper";
import { COLORS } from "utils/appStyles";
import { useDimensionsContext } from "contexts";
import { logShareRoutineDayFinished } from "utils/analytics";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
  },
  buttonContainer: {
    alignItems: "center",
    width: 100,
  },
  button: {
    backgroundColor: "white",
    overflow: "visible",
    borderRadius: 50,
    elevation: 2,
    shadowOffset: { width: 0, height: 2 },
    shadowColor: "#CECECE",
    shadowOpacity: 1,
    shadowRadius: 2,
  },
  text: {
    fontFamily: "OpenSans",
    textAlign: "center",
  },
});

const doAndroidShareToFacebookOrInstagramStories = async (
  uri: string,
  activityName:
    | "com.instagram.share.ADD_TO_STORY"
    | "com.facebook.stories.ADD_TO_STORY"
) => {
  // This allows the file to be shared to other apps
  const contentUri = await FileSystem.getContentUriAsync(uri);
  // https://developers.facebook.com/docs/instagram/sharing-to-stories/#sharing-a-background-asset
  const IntentLauncher = require("expo-intent-launcher");
  await IntentLauncher.startActivityAsync(activityName, {
    data: contentUri,
    type: "image/jpeg",
    flags: 0x00000001, //https://developer.android.com/reference/android/content/Intent#FLAG_GRANT_READ_URI_PERMISSION
    extra: {
      content_url: "https://snaphabit.app",
    },
  });
};

export const ShareToSocialMedia = ({ remoteUrl, routineId }) => {
  const localUri = useRef<string>();
  const { getScaledFontSize } = useDimensionsContext();
  const saveFileLocallyBeforeSharing = async () => {
    if (!localUri.current) {
      const { uri } = await FileSystem.createDownloadResumable(
        remoteUrl,
        `${FileSystem.cacheDirectory}${Math.random() * 99999}.jpeg`
      ).downloadAsync();

      localUri.current = uri;
    }
  };

  const BUTTONS = [
    {
      icon: "instagram",
      display: "Instagram",
      onPress: async () => {
        logShareRoutineDayFinished("instagram", routineId);
        await saveFileLocallyBeforeSharing();

        if (Platform.OS === "ios") {
          await MediaLibrary.saveToLibraryAsync(localUri.current);
          const instagramURL = `instagram://library?AssetPath=${localUri.current}`;
          Linking.openURL(instagramURL);
        } else if (Platform.OS === "android") {
          doAndroidShareToFacebookOrInstagramStories(
            localUri.current,
            "com.instagram.share.ADD_TO_STORY"
          );
        }
      },
    },
    ...(Platform.OS === "android"
      ? [
          {
            icon: "facebook",
            display: "Facebook",
            onPress: async () => {
              logShareRoutineDayFinished("facebook", routineId);
              await saveFileLocallyBeforeSharing();
              doAndroidShareToFacebookOrInstagramStories(
                localUri.current,
                "com.facebook.stories.ADD_TO_STORY"
              );
            },
          },
        ]
      : []),
    {
      icon: "share",
      display: "More Options",
      onPress: async () => {
        logShareRoutineDayFinished("other", routineId);
        await saveFileLocallyBeforeSharing();
        Sharing.shareAsync(localUri.current);
      },
    },
  ];

  return (
    <View style={styles.container}>
      {BUTTONS.map(b => (
        <View style={styles.buttonContainer} key={b.icon}>
          <IconButton
            icon={b.icon}
            color={COLORS.accent}
            size={getScaledFontSize(2.5)}
            style={styles.button}
            onPress={b.onPress}
          />
          <Text style={styles.text}>{b.display}</Text>
        </View>
      ))}
    </View>
  );
};
