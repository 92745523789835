import React from "react";
import { IconButton } from "react-native-paper";
import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  close: {
    alignSelf: "flex-end",
    zIndex: 10,
  },
});

export const AddFriendViewBackButton = ({ setShownScreen }) => (
  <IconButton
    icon="close"
    onPress={() => setShownScreen("contacts")}
    style={styles.close}
  />
);
