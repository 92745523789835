import moment from "moment";
import React from "react";
import { Text, View, StyleSheet } from "react-native";

import { useSelector } from "redux/store";
import { DayStatus } from "types/habits";
import { NavigationService } from "utils/NavigationService";
import { COLORS } from "utils/appStyles";
import { KButton } from "components/UI/KButton";
import { useDispatch } from "react-redux";
import { removeToast, setBottomSheet, BottomSheetTypes } from "redux/slices";
import { isMobilePlatform, isWeb } from "utils/helpers";
import { useHistory } from "utils/react-router";
import { renderHabitTitle } from "utils/strings";
import { Layouts, useLayoutContext } from "contexts";

export const NoteToast = ({
  status,
  dayid,
  date,
  habitid,
}: {
  status: number;
  dayid: string;
  date: string;
  habitid: string;
}) => {
  const history = useHistory();
  const habit = useSelector(state => state.firestore.data.habits[habitid]);
  const dispatch = useDispatch();
  const layout = useLayoutContext();

  return (
    <View style={styles.container}>
      <View style={styles.contentLeft}>
        <Text style={styles.header}>
          {status === DayStatus.SUCCESS ? "Great job!" : "Why did you skip?"}
        </Text>
        <Text>Documenting your progress helps build habits.</Text>
      </View>
      <View style={styles.contentRight}>
        <Text style={styles.date}>
          {moment(date, "MM-DD-YYYY").format("MMMM D")}
        </Text>
        <Text numberOfLines={1}>{renderHabitTitle(habit)}</Text>
        <KButton
          label="Add a note"
          icon="pencil"
          mode="outlined"
          color={COLORS.accent}
          onPress={async () => {
            dispatch(removeToast());
            if (isMobilePlatform) {
              dispatch(
                setBottomSheet({
                  type: BottomSheetTypes.NOTE,
                  props: {
                    habitid,
                    date,
                    dayid,
                    ownHabit: true,
                    isEditable: true,
                    showDayToggle: true,
                  },
                })
              );
            } else if (isWeb) {
              if (layout === Layouts.MOBILE) {
                await NavigationService.navigationPromise;
                NavigationService.navigate("EditDayNoteScreen", {
                  habitid,
                  date,
                  dayid,
                  editable: true,
                  ownHabit: true,
                });
              } else {
                history.push(`/me/notes/${habitid}/${date}`);
              }
            }
          }}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    margin: 20,
    alignSelf: "stretch",
    alignItems: "stretch",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  contentLeft: {
    width: "54%",
    justifyContent: "space-evenly",
  },
  contentRight: {
    width: "44%",
    justifyContent: "space-around",
  },
  header: {
    fontSize: 25,
    fontWeight: "700",
    marginBottom: 8,
  },
  date: {
    fontWeight: "700",
  },
});
