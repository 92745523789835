import React, { useEffect, useState, useRef, useCallback } from "react";
import { StyleSheet, Platform } from "react-native";
import Animated, { Easing } from "react-native-reanimated";
import { useSelector } from "redux/store";
import { useDispatch } from "react-redux";
import { removeToast, ToastTypes } from "redux/slices";
import { PermissionDeniedToast } from "components";
import { isMobilePlatform } from "utils/helpers";
import { Snackbar } from "react-native-paper";
import { MOBILE_BREAK_POINT } from "utils/appStyles";
import { useDimensions } from "react-native-web-hooks";
import { NoteToast } from "./NoteToast";
import { ClipboardToast } from "./ClipboardToast";
import { MotivationToast } from "./MotivationToast";

const TOAST_TIME = 2000; //in ms
export const TOAST_HEIGHT = 140;

export const Toast = () => {
  const { window } = useDimensions();
  const { width } = window;
  const dispatch = useDispatch();
  const toastData = useSelector(state => state.toast);
  const [slideAnimation] = useState(new Animated.Value(-TOAST_HEIGHT));
  // Toggle display so that the page doesn't scroll on web when toast is hidden
  const [display, setDisplay] = useState<"none" | "flex">("none");
  const timeoutRef = useRef<NodeJS.Timeout>(null);

  const showToast = useCallback(() => {
    setDisplay("flex");
    Animated.timing(slideAnimation, {
      toValue: 0,
      easing: Easing.linear,
      duration: 250,
    }).start();
  }, [slideAnimation]);

  const hideToast = useCallback(() => {
    Animated.timing(slideAnimation, {
      toValue: -TOAST_HEIGHT,
      easing: Easing.linear,
      duration: 200,
    }).start(() => {
      setDisplay("none");
      dispatch(removeToast());
    });
  }, [dispatch, slideAnimation]);

  // If toast content changes, reset timer for closing toast
  // toast auto closes after TOAST_TIME elapses
  // Otherwise, close toast ahead of time by setting toast to null
  useEffect(() => {
    if (toastData) {
      showToast();

      if (isMobilePlatform) {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
          hideToast();
        }, TOAST_TIME);
      }
    } else {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }

      hideToast();
    }
  }, [dispatch, hideToast, showToast, toastData]);

  if (Platform.OS === "web") {
    return (
      <Snackbar
        visible={display === "flex"}
        onDismiss={() => {
          setDisplay("none");
          dispatch(removeToast());
        }}
        duration={TOAST_TIME}
        theme={{
          colors: {
            onSurface: "white",
            surface: "black",
          },
        }}
        style={{
          maxWidth: MOBILE_BREAK_POINT,
          width: "90%",
          alignSelf: width > MOBILE_BREAK_POINT ? "flex-end" : "center",
        }}
      >
        {toastData?.type === ToastTypes.NOTE ? (
          <NoteToast {...toastData.props} />
        ) : toastData?.type === ToastTypes.PERMISSION_DENIED ? (
          <PermissionDeniedToast />
        ) : toastData?.type === ToastTypes.CLIPBOARD ? (
          <ClipboardToast />
        ) : null}
      </Snackbar>
    );
  }

  return (
    <Animated.View
      style={[
        styles.container,
        {
          display,
        },
        Platform.select({
          default: {
            bottom: slideAnimation,
          },
          web: {
            bottom: toastData ? 0 : -TOAST_HEIGHT,
          },
        }),
      ]}
    >
      {toastData?.type === ToastTypes.NOTE ? (
        <NoteToast {...toastData.props} />
      ) : toastData?.type === ToastTypes.PERMISSION_DENIED ? (
        <PermissionDeniedToast />
      ) : toastData?.type === ToastTypes.MOTIVATION ? (
        <MotivationToast />
      ) : null}
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    bottom: -TOAST_HEIGHT,
    height: TOAST_HEIGHT,
    backgroundColor: "white",
    width: "100%",
    borderTopWidth: 1,
    alignItems: "center",
    zIndex: 1,
  },
});
