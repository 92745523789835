import React, { useState } from "react";
import moment from "moment";
import { StyleSheet, Platform } from "react-native";
import DateTimePicker from "@react-native-community/datetimepicker";
import { Modal, Portal } from "react-native-paper";

import { KButton } from "components";

const NativeDatePicker = ({
  mode,
  currentDateTime,
  setCurrentDateTime,
  setDateTime,
  isDateTimePickerVisible,
  setIsDateTimePickerVisible,
  minimumDate,
}: {
  mode: "date" | "time";
  currentDateTime: Date;
  setCurrentDateTime: React.Dispatch<React.SetStateAction<Date>>;
  setDateTime: React.Dispatch<React.SetStateAction<Date>>;
  isDateTimePickerVisible: boolean;
  setIsDateTimePickerVisible: React.Dispatch<React.SetStateAction<boolean>>;
  minimumDate?: Date;
}) => {
  if (!isDateTimePickerVisible) {
    return null;
  }

  return (
    <DateTimePicker
      testID="dateTimePicker"
      value={currentDateTime}
      mode={mode}
      is24Hour
      display="default"
      onChange={(event, selectedDate) => {
        const currentDate =
          selectedDate || new Date(moment.utc().local().format());
        // onChange for Android is fired upon pressing 'Ok', not when spinner value changes
        if (Platform.OS === "android") {
          setIsDateTimePickerVisible(false);
          return setDateTime(currentDate);
        } else {
          return setCurrentDateTime(currentDate);
        }
      }}
      minimumDate={minimumDate}
    />
  );
};

export const KDateTimePicker = props => {
  const [currentDateTime, setCurrentDateTime] = useState(props.dateTime);

  if (Platform.OS === "ios") {
    return (
      <Portal>
        <Modal
          visible={props.isDateTimePickerVisible}
          dismissable
          onDismiss={() => {
            props.setIsDateTimePickerVisible(false);
            return props.setDateTime(currentDateTime);
          }}
          contentContainerStyle={styles.modalContent}
        >
          <KButton
            label="Done"
            mode="text"
            color="black"
            onPress={() => {
              props.setIsDateTimePickerVisible(false);
              return props.setDateTime(currentDateTime);
            }}
            style={styles.dismissButton}
          />
          <NativeDatePicker
            {...props}
            mode={props.mode}
            currentDateTime={currentDateTime}
            setCurrentDateTime={setCurrentDateTime}
          />
        </Modal>
      </Portal>
    );
  }

  return (
    <NativeDatePicker
      {...props}
      mode={props.mode}
      currentDateTime={currentDateTime}
      setCurrentDateTime={setCurrentDateTime}
    />
  );
};

const styles = StyleSheet.create({
  modalContent: {
    width: "90%",
    maxWidth: 400,
    maxHeight: 750,
    paddingVertical: 15,
    alignSelf: "center",
    backgroundColor: "white",
  },
  dismissButton: {
    marginLeft: "auto",
    marginRight: 10,
    marginTop: 0,
    width: "30%",
  },
});
