import React from "react";
import {
  Text,
  Switch,
  StyleSheet,
  ActivityIndicator,
  Keyboard,
  View,
  TouchableWithoutFeedback,
  ViewStyle,
} from "react-native";
import { Card, Avatar } from "react-native-paper";
import { Ionicons } from "@expo/vector-icons";

import { useGetFriend } from "redux/selectors";
import { COLORS } from "utils/appStyles";
import { KButton, KCard } from "components";
import { Item } from "./UI/ListElements";
import { LoadableImage } from "./UI/LoadableImage";
import { MainTextInput } from "./UI/Text";

export const ReceivedRequest = ({ friendid, onAccept, onDecline, state }) => {
  const { friend } = useGetFriend(friendid);
  return (
    <KCard>
      <Card.Title
        title={friend.name}
        left={() =>
          friend.avatar && (
            <Avatar.Image size={50} source={{ uri: friend.avatar }} />
          )
        }
      />
      {state?.loading ? (
        <Card.Content>
          <ActivityIndicator />
        </Card.Content>
      ) : (
        <Card.Actions style={styles.cardActions}>
          <KButton
            label="Delete"
            onPress={onDecline}
            mode="text"
            color="black"
          />
          <KButton
            label="Accept"
            onPress={onAccept}
            mode="text"
            color={COLORS.active}
          />
        </Card.Actions>
      )}
    </KCard>
  );
};

export const SentRequest = ({ friendid, deleteRequest, state }) => {
  const { friend } = useGetFriend(friendid);
  return (
    <KCard>
      <Card.Title title={friend.email || friend.name} />
      {state?.loading ? (
        <Card.Content>
          <ActivityIndicator />
        </Card.Content>
      ) : (
        <Card.Actions style={styles.cardActions}>
          <KButton
            label="Delete"
            onPress={deleteRequest}
            mode="text"
            color="black"
          />
        </Card.Actions>
      )}
    </KCard>
  );
};

export const InputSetting = props => {
  return (
    <Item {...props}>
      <MainTextInput {...props} />
    </Item>
  );
};

const styles = StyleSheet.create({
  pendingButtons: {
    width: "50%",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  largeMarginItem: {
    marginTop: 30,
    marginBottom: 30,
  },
  cardActions: {
    justifyContent: "flex-end",
  },
});

export const SettingSection = ({
  children,
  label = "",
  style = {},
  cardStyle = {},
}) => {
  return (
    <View style={[settingStyles.section, style]}>
      {!!label && <Text style={settingStyles.sectionLabel}>{label}</Text>}
      <KCard style={cardStyle}>
        {React.Children.toArray(children).map((child, i) => {
          if (i !== 0) return child;
          return React.cloneElement(child, {
            style: { borderTopWidth: 0 },
          });
        })}
      </KCard>
    </View>
  );
};

export const Setting = ({
  label,
  caption,
  onPress,
  rightElement,
  style = {},
}: {
  label: string;
  caption?: string;
  onPress?: () => void;
  rightElement: JSX.Element;
  style?: object;
}) => {
  const content = (
    <View style={[settingStyles.item, style]}>
      <View style={settingStyles.texts}>
        <Text style={settingStyles.itemLabel}>{label}</Text>
        {!!caption && <Text style={settingStyles.itemCaption}>{caption}</Text>}
      </View>
      <View style={settingStyles.rightElement}>{rightElement}</View>
    </View>
  );

  if (onPress) {
    return (
      <TouchableWithoutFeedback onPress={onPress}>
        {content}
      </TouchableWithoutFeedback>
    );
  }
  return content;
};

export const TextSetting = ({
  label,
  caption,
  content,
  ...props
}: {
  label: string;
  caption?: string;
  content: string;
}) => (
  <Setting
    {...props}
    label={label}
    caption={caption}
    rightElement={<Text>{content}</Text>}
  />
);

export const SelectSetting = ({
  label,
  caption,
  selected,
  onPress,
  ...props
}: {
  label: string;
  caption?: string;
  selected: boolean;
  onPress: () => void;
}) => (
  <Setting
    {...props}
    label={label}
    caption={caption}
    onPress={onPress}
    rightElement={
      <Ionicons
        name="md-checkmark"
        size={16}
        color={selected ? COLORS.accent : "white"}
      />
    }
  />
);

export const MovableSetting = ({
  label,
  caption,
  ...props
}: {
  label: string;
  caption?: string;
}) => (
  <Setting
    {...props}
    label={label}
    caption={caption}
    rightElement={<Ionicons name="md-menu" size={25} color={COLORS.darkGray} />}
  />
);

export const ToggleSetting = ({
  disabled,
  label,
  caption,
  onToggle,
  value,
  ...props
}: {
  label: string;
  caption?: string;
  disabled?: boolean;
  onToggle: (val: boolean) => void;
  value: boolean;
  style?: ViewStyle;
}) => (
  <Setting
    {...props}
    label={label}
    caption={caption}
    rightElement={
      <Switch
        value={value}
        onChange={Keyboard.dismiss}
        onValueChange={onToggle}
        disabled={disabled}
        trackColor={{ true: COLORS.accent, false: COLORS.disabled }}
        style={{ minWidth: 40 }}
      />
    }
  />
);

export const ButtonSetting = ({
  color,
  backgroundColor,
  label,
  onPress,
  icon,
}: {
  color?: string;
  backgroundColor?: string;
  label: string;
  onPress: () => void;
  icon?: string;
}) => (
  <TouchableWithoutFeedback onPress={onPress}>
    <View
      style={[settingStyles.item, settingStyles.button, { backgroundColor }]}
    >
      {icon && (
        <Ionicons name={icon} style={{ color, marginRight: 10 }} size={16} />
      )}
      <Text style={[settingStyles.buttonLabel, { color }]}>{label}</Text>
    </View>
  </TouchableWithoutFeedback>
);

export const OpenMoreSetting = ({
  label,
  caption,
  onPress,
  ...props
}: {
  label: string;
  caption?: string;
  onPress: () => void;
}) => (
  <Setting
    {...props}
    label={label}
    caption={caption}
    onPress={onPress}
    rightElement={<Ionicons name="ios-arrow-forward" size={16} />}
  />
);

export const ImageSetting = ({
  label,
  caption,
  isLoading,
  source,
  ...props
}: {
  label: string;
  caption?: string;
  isLoading: boolean;
  source: string;
}) => {
  let rightElement = null;

  if (isLoading) {
    rightElement = <ActivityIndicator size="small" />;
  } else if (!source) {
    //image currently uploading
    rightElement = <Text>Add an image</Text>;
  } else {
    rightElement = (
      <LoadableImage source={source} style={settingStyles.loadableImage} />
    );
  }

  return (
    <Setting
      {...props}
      label={label}
      caption={caption}
      rightElement={rightElement}
    />
  );
};

const settingStyles = StyleSheet.create({
  section: {
    marginBottom: 20,
  },
  sectionLabel: {
    paddingHorizontal: 20,
    fontSize: 18,
    fontWeight: "600",
    marginBottom: 5,
    color: "#333",
  },
  item: {
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 15,
    borderTopWidth: 1,
    borderTopColor: "#eee",
  },
  texts: {
    display: "flex",
    flexDirection: "column",
    paddingRight: 10,
    flex: 1,
  },
  itemLabel: {
    fontWeight: "500",
  },
  itemCaption: {
    color: "#888888",
    marginTop: 5,
    fontSize: 12,
  },
  button: {
    justifyContent: "center",
    borderRadius: 10,
    borderTopWidth: 0,
  },
  buttonLabel: {
    fontWeight: "600",
  },
  rightElement: {
    minWidth: 50,
    display: "flex",
    alignItems: "flex-end",
  },
  loadableImage: {
    height: 40,
    width: 40,
  },
});
