import React, { memo } from "react";
import { MaterialIcons } from "@expo/vector-icons";
import { IconButton } from "react-native-paper";
import { doShare } from "utils/routines";
import { COLORS } from "utils/appStyles";
import { useDimensionsContext } from "contexts";
import { useGetAuth, useGetSpecificHabit } from "redux/selectors";
import { ViewStyle } from "react-native";
import { logShareButtonPress } from "utils/analytics";
import { isMobilePlatform, isWeb } from "utils/helpers";
import { setToast, ToastTypes } from "redux/slices";
import { useDispatch } from "react-redux";
import { copyInviteLink } from "utils/sharing";

export const ShareButton = memo(
  ({
    habitid,
    style,
    source,
  }: {
    habitid: string;
    style?: ViewStyle;
    source: string;
  }) => {
    const dispatch = useDispatch();
    const { getScaledFontSize } = useDimensionsContext();
    const { profile } = useGetAuth();
    const { habit } = useGetSpecificHabit(habitid);

    return (
      <IconButton
        style={style}
        icon={() => (
          <MaterialIcons
            name="person-add"
            size={getScaledFontSize(2)}
            color={COLORS.accent}
          />
        )}
        size={getScaledFontSize(2)}
        onPress={() => {
          logShareButtonPress("avatar", "HabitChallengeView");
          if (isMobilePlatform) {
            doShare({ ...habit, id: habitid }, profile);
          } else if (isWeb) {
            copyInviteLink(profile);
            dispatch(setToast({ type: ToastTypes.CLIPBOARD }));
          }
        }}
      />
    );
  }
);
