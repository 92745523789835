import { Friend } from "types/friends";

const getNamesArray = (mutualFriends: string[], friends: Friend[]) =>
  mutualFriends
    .map(f => friends.find(friend => friend.id === f))
    .filter(f => f)
    .map(f => f.name);

export default {
  addFriend: {
    en: "Add Friend",
    ja: "友達を追加",
    "zh-Hans": "添加好友",
    "zh-Hant": "添加好友",
  },
  alreadyAdded: {
    en: "You've already added this friend before!",
    ja: "この友達をもう追加しました！",
    "zh-Hans": "您已经添加了此朋友",
    "zh-Hant": "您已經添加了此朋友",
  },
  andNumMore: {
    en: (num: number) => `and ${num} more`,
    ja: (num: number) => `とその他${num}`,
    "zh-Hans": (num: number) => `还有${num}个`,
    "zh-Hant": (num: number) => `還有${num}個`,
  },
  contactsOnSnapHabit: {
    en: "Contacts on SnapHabit",
    ja: "SnapHabitのユーザー",
    "zh-Hans": "SnapHabit上的联系人",
    "zh-Hant": "SnapHabit上的聯繫人",
  },
  emailToInvite: {
    en: "Enter an email to invite",
    ja: "招待するメール",
    "zh-Hans": "电邮邀请",
    "zh-Hant": "電郵邀請",
  },
  enterAnEmail: {
    en: "Enter an email to invite a friend",
    ja: "メールを入力して友達を招待する",
    "zh-Hans": "输入邮箱邀请朋友",
    "zh-Hant": "輸入郵箱邀請朋友",
  },
  errorAddingFriend: {
    en: "Error adding friend. Try again later.",
    ja: "友達の追加中にエラーが発生しました。 もう一度試してみてください",
    "zh-Hans": "添加好友时出错。请稍后再试。",
    "zh-Hant": "添加好友時出錯。請稍後再試。",
  },
  friendOfFriendSubtitle: {
    en: ({
      mutualFriends,
      friends,
    }: {
      mutualFriends: string[];
      friends: Friend[];
    }) => {
      if (!mutualFriends?.length) return "";
      const names = getNamesArray(mutualFriends, friends);
      if (names.length === 0) return "";
      if (names.length === 1) return `Friends with ${names[0]}`;
      if (names.length === 2) return `Friends with ${names[0]} and ${names[1]}`;
      return `Friends with ${names.join(", ")}`;
    },
    ja: ({
      mutualFriends,
      friends,
    }: {
      mutualFriends: string[];
      friends: Friend[];
    }) => {
      if (!mutualFriends?.length) return "";
      const names = getNamesArray(mutualFriends, friends);
      if (names.length === 0) return "";
      if (names.length === 1) return `${names[0]}と友達`;
      if (names.length === 2) return `${names[0]}と${names[1]}と友達`;
      return `${names.join(", ")}と友達`;
    },
    "zh-Hans": ({
      mutualFriends,
      friends,
    }: {
      mutualFriends: string[];
      friends: Friend[];
    }) => {
      if (!mutualFriends?.length) return "";
      const names = getNamesArray(mutualFriends, friends);
      if (names.length === 0) return "";
      if (names.length === 1) return `与${names[0]}是好友`;
      if (names.length === 2) return `与${names[0]}和${names[1]}是好友`;
      return `与${names.join(", ")}是好友`;
    },
    "zh-Hant": ({
      mutualFriends,
      friends,
    }: {
      mutualFriends: string[];
      friends: Friend[];
    }) => {
      if (!mutualFriends?.length) return "";
      const names = getNamesArray(mutualFriends, friends);
      if (names.length === 0) return "";
      if (names.length === 1) return `與${names[0]}是好友`;
      if (names.length === 2) return `與${names[0]}和${names[1]}是好友`;
      return `與${names.join(", ")}是好友`;
    },
  },
  findFriendsFromContacts: {
    en: "Find friends from contacts",
    ja: "連絡先から友達を探す",
    "zh-Hans": "从通讯录中查找好友",
    "zh-Hant": "從通訊錄中查找好友",
  },
  inviteEmail: {
    en: (email: string) => `Invite ${email}`,
    ja: (email: string) => `${email}を招待する`,
    "zh-Hans": (email: string) => `邀请${email}`,
    "zh-Hant": (email: string) => `邀請${email}`,
  },
  inviteThisEmail: {
    en: "Invite this Email",
    ja: "このメールを招待",
    "zh-Hans": "邀请此电子邮件",
    "zh-Hant": "邀請此電子郵",
  },
  inviteToApp: {
    en: "Invite to App",
    ja: "アプリに招待",
    "zh-Hans": "邀请加入",
    "zh-Hant": "邀請加入",
  },
  inviteToSnapHabit: {
    en: "Invite to SnapHabit",
    ja: "SnapHabitに招待",
    "zh-Hans": "邀请使用SnapHabit",
    "zh-Hant": "邀請使用SnapHabit",
  },
  //TODO: Add non english
  searchContacts: {
    en: "🔍 Search or enter an email",
    ja: "🔍 アドレス帳を検索",
    "zh-Hans": "🔍 搜索通讯录",
    "zh-Hant": "🔍 搜索通訊錄",
  },
  shareLinkToDownload: {
    en: "Share link to download",
    ja: "ダウンロードリンクをシェアする",
    "zh-Hans": "分享下载链接",
    "zh-Hant": "分享下載鏈接",
  },
  suggestedFriends: {
    en: "Suggested Friends",
    ja: "友達の友達",
    "zh-Hans": "推荐好友",
    "zh-Hant": "推薦好友",
  },
};
