import React from "react";
import { View, StyleSheet, Text } from "react-native";
import { Body, KButton, Footnote } from "components";
import { MaterialIcons } from "@expo/vector-icons";

import { COLORS } from "utils/appStyles";
import { goToPermissionsSettings } from "utils/permissions";

export const GrantNotificationsMessage = () => {
  return (
    <>
      <View style={styles.rowFlex}>
        <View style={styles.leftColumn}>
          <MaterialIcons name="warning" size={24} color={COLORS.error} />
        </View>
        <View style={styles.rightColumn}>
          <Body style={styles.warningHeader}>
            Warning: Notifications disabled
          </Body>
          <Footnote style={styles.warningExplanation}>
            Notifications are disabled for SnapHabit in your device settings, so
            you will not receive any of the notifications below.
          </Footnote>
        </View>
      </View>
      <View style={styles.center}>
        <KButton
          label="Go to Settings"
          onPress={goToPermissionsSettings}
          style={styles.button}
        />
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  warningHeader: {
    fontWeight: "500",
    marginVertical: 10,
  },
  warningExplanation: {
    color: COLORS.darkGray,
  },
  leftColumn: {
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
  rightColumn: {
    flex: 4,
  },
  rowFlex: {
    flexDirection: "row",
    marginHorizontal: 15,
  },
  button: {
    width: "60%",
    marginVertical: 15,
  },
  center: {
    alignItems: "center",
  },
});
