import React from "react";
import { StyleSheet, View, Platform } from "react-native";
import * as WebBrowser from "expo-web-browser";
import Markdown from "react-native-markdown-display";
import { RoutineContent } from "types/routines";
import { useDimensionsContext } from "contexts";
import { isMobilePlatform } from "utils/helpers";
import { SectionTitle } from "components/UI/Typography";
import { logTapRoutineContent } from "utils/analytics";

const styles = StyleSheet.create({
  container: {
    marginTop: 12,
    marginHorizontal: 20,
  },
  title: {
    marginVertical: 20,
    fontWeight: "300",
  },
});

const rules = {
  heading2: (node, children) => (
    <SectionTitle key={node.key}>{children}</SectionTitle>
  ),
};

export const RoutineDayMainContent = ({
  body,
}: {
  body: RoutineContent["body"];
}) => {
  const { getScaledFontSize } = useDimensionsContext();

  return (
    <View style={styles.container}>
      <Markdown
        style={{
          body: {
            fontSize: getScaledFontSize(1),
            lineHeight: getScaledFontSize(1.5),
          },
          image: Platform.select({
            default: {
              resizeMode: "contain",
              aspectRatio: 1,
            },
            web: {
              width: "100%",
              height: 300,
            },
          }),
        }}
        rules={rules}
        onLinkPress={url => {
          logTapRoutineContent("url", url);
          if (isMobilePlatform) {
            WebBrowser.openBrowserAsync(url);
            return false;
          }
          // return true to open with `Linking.openURL
          // return false to handle it yourself
          return true;
        }}
      >
        {body}
      </Markdown>
    </View>
  );
};
