/* eslint-disable no-undef */
import React from "react";
import { Text, StyleSheet, Platform, View } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { FontAwesome5, MaterialIcons, Ionicons } from "@expo/vector-icons";

import { useDimensionsContext } from "contexts";
import { COLORS } from "utils/appStyles";
import { ShareLink } from "components/Invite/ShareLink";
import { USER_PROFILE_URL, doShareInviteLink } from "utils/sharing";
import { useGetAuth } from "redux/selectors";
import { useDispatch } from "react-redux";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginHorizontal: 20,
    marginVertical: 15,
  },
  label: {
    fontFamily: "OpenSans",
    color: COLORS.accent,
    flexBasis: "85%",
  },
  icon: {
    flexBasis: "15%",
  },
  linkContainer: {
    marginHorizontal: 8,
    marginVertical: 12,
  },
});

const InviteActionButton = ({ label, Icon, iconName, onPress }) => {
  const { getScaledFontSize } = useDimensionsContext();
  const fontSize = getScaledFontSize(1.1);
  const iconSize = getScaledFontSize(1.65);
  return (
    <TouchableOpacity style={styles.container} onPress={onPress}>
      <Icon
        name={iconName}
        size={iconSize}
        color={COLORS.accent}
        style={styles.icon}
      />
      <Text style={[styles.label, { fontSize }]}>{label}</Text>
    </TouchableOpacity>
  );
};

export const InviteByUserName = ({ onPress }) => (
  <InviteActionButton
    label="Find by username"
    Icon={FontAwesome5}
    iconName="user-tag"
    onPress={onPress}
  />
);

export const InviteByEmail = ({ onPress }) => (
  <InviteActionButton
    label="Invite by email"
    Icon={MaterialIcons}
    iconName="email"
    onPress={onPress}
  />
);

export const InviteByLink = ({ onPress, routeName }) => {
  const { profile } = useGetAuth();
  const dispatch = useDispatch();

  return (
    <>
      <InviteActionButton
        label="Share my invite link"
        Icon={Ionicons}
        iconName={Platform.select({
          default: "ios-share-alt",
          android: "md-share",
        })}
        onPress={onPress}
      />
      <View style={styles.linkContainer}>
        <ShareLink
          link={`${USER_PROFILE_URL}${profile.handle}`}
          onPress={() => {
            doShareInviteLink(profile, dispatch);
          }}
          type="profile_link_widget"
          routeName={routeName}
        />
      </View>
    </>
  );
};

export const InviteByContacts = ({ onPress }) => (
  <InviteActionButton
    label="By contacts"
    Icon={Ionicons}
    iconName={Platform.select({
      default: "ios-contacts",
      android: "md-contacts",
    })}
    onPress={onPress}
  />
);
