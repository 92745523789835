import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import React, { useEffect } from "react";
import { StyleSheet, View } from "react-native";
import { StackProps } from "layouts/MobileLayout";

import { HabitList, Loading, ZeroState } from "components";

import {
  useGetFriend,
  useGetHabits,
  useGetSharedDays,
  useGetIsHabitsLoaded,
} from "redux/selectors";

export const FriendScreen = ({
  navigation,
  route,
}: {
  navigation: StackNavigationProp<StackProps, "FriendScreen">;
  route: RouteProp<StackProps, "FriendScreen">;
}) => {
  const id = route.params.friendid;
  const { isFriendsLoaded, friend } = useGetFriend(id);
  const habits = useGetHabits(id);
  const isLoaded = useGetIsHabitsLoaded(id);
  const { isSharedDaysLoaded } = useGetSharedDays();
  //need to set for the header buttons
  //see App.jsx where set header buttons
  useEffect(() => {
    navigation.setParams({ friendid: id });
  }, [id]);

  if (!isLoaded || !isFriendsLoaded || !isSharedDaysLoaded) {
    return <Loading />;
  }

  if (habits.length === 0) {
    return (
      <ZeroState
        header={(friend?.name ?? "Friend") + " has not shared anything yet."}
      />
    );
  }

  return (
    <View style={styles.container}>
      <HabitList
        friendId={friend.id}
        ownHabit={false}
        friendAvatar={friend.avatar}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
  },
});
