import React from "react";
import {
  View,
  StyleSheet,
  KeyboardAvoidingView,
  Platform,
  SafeAreaView,
  TouchableWithoutFeedback,
  Keyboard,
} from "react-native";

const styles = StyleSheet.create({
  container: { justifyContent: "center", flex: 1, margin: 10 },
});

/**
 * Keyboard handling for a view that renders an input
 */
export const InputFieldView = ({ children }) => {
  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : null}
      style={styles.container}
    >
      <SafeAreaView style={styles.container}>
        <TouchableWithoutFeedback
          {...Platform.select({
            web: {},
            default: {
              onPress: Keyboard.dismiss,
            },
          })}
        >
          <View style={styles.container}>{children}</View>
        </TouchableWithoutFeedback>
      </SafeAreaView>
    </KeyboardAvoidingView>
  );
};
