import React, { useState, useCallback, useEffect } from "react";
import { parseEmail } from "utils/strings";
import { isMobilePlatform } from "utils/helpers";
import { View, Platform, Text, StyleSheet } from "react-native";
import AsyncStorage from "@react-native-community/async-storage";
import {
  ASYNC_STORAGE_EMAIL_KEY,
  API_HOST,
  SIGN_IN_URL,
} from "utils/constants";
import axios from "utils/axios";
import { Logger } from "utils/Logger";
import {
  Body,
  Footnote,
  Subtitle,
  Loading,
  KButton,
  KTextInput,
  InputFieldView,
} from "components";
import { iOSColors } from "react-native-typography";
import { OpenEmailButton } from "screens/Auth/OpenEmailButton";
import { Linking } from "expo";
import { KTextButton } from "components/UI/KTextButton";
import { ConEmailWarning } from "components/ConEmailWarning";

const TRY_AGAIN_SECONDS = 10;

const REDIRECT_URL =
  Platform.OS === "web"
    ? `${window.location.protocol}//${window.location.host}${SIGN_IN_URL}`
    : Linking.makeUrl(SIGN_IN_URL);

export const EmailSignInView = ({
  setIsEmailSelected,
  webRedirectTo,
}: {
  setIsEmailSelected?: React.Dispatch<React.SetStateAction<boolean>>;
  webRedirectTo?: string;
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [email, setEmail] = useState("");
  const { isEmail, emailTLD } = parseEmail(email);
  const [showMessage, setShowMessage] = useState(false);
  const [showError, setShowError] = useState(false);
  const [tryAgainButtonIsDisabled, setTryAgainButtonIsDisabled] = useState(
    true
  );
  const [tryAgainTime, setTryAgainTime] = useState(TRY_AGAIN_SECONDS);

  const onSubmit = useCallback(async () => {
    if (isEmail) {
      const processedEmail = email.trim();
      setIsLoading(true);
      try {
        if (isMobilePlatform) {
          await AsyncStorage.setItem(ASYNC_STORAGE_EMAIL_KEY, processedEmail);
        } else {
          window.localStorage.setItem(ASYNC_STORAGE_EMAIL_KEY, processedEmail);
        }

        await axios.post(`${API_HOST}/auth`, {
          url: `${API_HOST}/signin?redirectUrl=${REDIRECT_URL}${
            webRedirectTo ? `&webRedirectTo=${webRedirectTo}` : ""
          }`,
          email: processedEmail,
        });

        setIsLoading(false);
        setShowMessage(true);
      } catch (error) {
        Logger.error(error);
        setIsLoading(false);
        setShowError(true);
      }
    }
  }, [email, isEmail, webRedirectTo]);

  useEffect(() => {
    let mounted = true;
    AsyncStorage.getItem(ASYNC_STORAGE_EMAIL_KEY).then(email => {
      if (email && mounted) {
        setEmail(email);
      }
      if (mounted) {
        setIsLoading(false);
      }
    });

    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    if (showMessage || showError) {
      setTryAgainButtonIsDisabled(true);
      setTryAgainTime(TRY_AGAIN_SECONDS);
    }
  }, [showMessage, showError]);

  useEffect(() => {
    let mounted = true;
    if (tryAgainButtonIsDisabled) {
      if (tryAgainTime > 0) {
        setTimeout(() => {
          if (mounted) {
            setTryAgainTime(time => time - 1);
          }
        }, 1000);
      } else {
        setTryAgainButtonIsDisabled(false);
      }
    }

    return () => {
      mounted = false;
    };
  }, [tryAgainButtonIsDisabled, tryAgainTime]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <InputFieldView>
      {showMessage || showError ? (
        <>
          {showError ? (
            <Body style={styles.text}>
              There was an error processing the request. Please try again in a
              few minutes.
            </Body>
          ) : showMessage ? (
            <>
              <Subtitle style={[styles.nonCenter, { fontWeight: "400" }]}>
                We sent an email to{" "}
                <Text style={{ fontWeight: "700" }}>{email.trim()}</Text>. Check
                your inbox on this device to sign in.
              </Subtitle>
              <Footnote
                style={[
                  styles.nonCenter,
                  { marginBottom: 20, color: iOSColors.gray },
                ]}
              >
                If you don't see an email, look in your spam folder for an email
                from info@snaphabit.app.
              </Footnote>
            </>
          ) : null}
          {!showError && <OpenEmailButton />}
          <KButton
            label={
              tryAgainButtonIsDisabled
                ? `No email? Resend again in ${tryAgainTime}...`
                : "I did not receive an email"
            }
            disabled={tryAgainButtonIsDisabled}
            onPress={() => {
              setShowMessage(false);
              setShowError(false);
            }}
          />
          <KTextButton
            onPress={() => {
              setShowMessage(false);
              setShowError(false);
            }}
            label="Use a different email address"
          />
        </>
      ) : (
        <View>
          <KTextInput
            {...Platform.select({
              default: {
                label: "Your email",
                placeholder: "readytostart@snaphabit.app",
                value: email,
                textContentType: "emailAddress",
                keyboardType: "email-address",
                autoCorrect: true,
                autoCompleteType: "email",
                onChangeText: text => setEmail(text),
                autoCapitalize: "none",
                onSubmitEditing: onSubmit,
                returnKeyType: "go",
              },
              web: {
                label: "Your email",
                placeholder: "readytostart@snaphabit.app",
                value: email,
                onChangeText: text => setEmail(text),
                onSubmitEditing: onSubmit,
              },
            })}
          />
          {isEmail && emailTLD === ".con" && <ConEmailWarning />}
          <KButton
            label="Continue"
            style={styles.button}
            onPress={onSubmit}
            disabled={!isEmail}
          />
        </View>
      )}
      {!!setIsEmailSelected && (
        <KTextButton
          onPress={() => {
            setIsEmailSelected(false);
          }}
          label="Sign in a different way"
        />
      )}
    </InputFieldView>
  );
};

const styles = StyleSheet.create({
  button: {
    marginTop: 5,
    marginBottom: 10,
  },
  text: { margin: 20, textAlign: "center" },
  nonCenter: { margin: 10 },
});
