import React from "react";
import { View, Image, Share } from "react-native";
import { Subtitle, KButton, BRANCH_LINK } from "components";
import { groupIcon } from "assets/images";
import { COLORS } from "utils/appStyles";
import { useGetAuth, useGetOrganization } from "redux/selectors";
import { FirestoreUserData } from "types/users";
import { useNavigation } from "@react-navigation/native";
import { AddFriendWidget } from "components/Friends/AddFriendWidget";

export const doShare = (profile: FirestoreUserData) =>
  Share.share({
    message:
      `Help me reach my goals! Add me as a friend via my username: ${profile.handle}.` +
      `\n\nClick here to join - it's free: ${BRANCH_LINK}`,
    title: "Join me on SnapHabit",
  });

export const AddFriendPromo = ({ style }) => {
  const { auth } = useGetAuth();
  const navigation = useNavigation();
  const { enableProfileLink } = useGetOrganization();

  return enableProfileLink ? (
    <View style={style}>
      <View style={{ marginBottom: 5, alignItems: "center" }}>
        <Subtitle style={{ textAlign: "center", marginBottom: 14 }}>
          SnapHabit is better together.
        </Subtitle>
        {auth.isAnonymous ? (
          <KButton
            label="Invite a friend"
            style={{
              borderRadius: 8,
            }}
            color={COLORS.logoBlue}
            onPress={() => {
              navigation.navigate("LoginScreen", {
                reason: "addFriend",
              });
            }}
          />
        ) : (
          <AddFriendWidget />
        )}
      </View>
      <View style={{ height: 110 }}>
        <Image
          source={groupIcon}
          resizeMode="contain"
          style={{ flex: 1, height: undefined, width: undefined }}
        />
      </View>
    </View>
  ) : null;
};
