import React from "react";
import { Text, StyleSheet } from "react-native";
import get from "lodash/get";

import { AddFriendButton, InviteButton, FriendItem } from "components";
import { useString } from "hooks";
import { logTryAddFriend } from "utils/analytics";
import { useAddFriend } from "hooks/friends/useAddFriend";

const styles = StyleSheet.create({
  boldText: { fontWeight: "bold" },
});

export type ContactItem = {
  avatar: {
    uri: any;
  };
  emails: any[];
  existingUser: boolean;
  id: any;
  phoneNumbers: any[];
  primaryEmail: any;
  subtitle: string;
  friendOfFriendSuggestion?: boolean;
  title: any;
};

export const ContactRow = ({
  item,
  searchText,
  fetchState,
  alreadyInvited,
  addFriend,
  smsSupported,
}: {
  item: ContactItem;
  searchText;
  fetchState;
  alreadyInvited;
  addFriend: ReturnType<typeof useAddFriend>;
  smsSupported;
}) => {
  const s = useString("contactScreen");
  let searchResult: { type: "EMAIL" | "NUMBER"; value: string };
  if (searchText) {
    const email = get(item, "emails", []).find(current =>
      current.toLowerCase().includes(searchText)
    );
    if (email) {
      searchResult = { type: "EMAIL", value: email };
    } else {
      const phoneNumber = get(item, "phoneNumbers", []).find(current =>
        current.includes(searchText)
      );
      if (phoneNumber) {
        searchResult = { type: "NUMBER", value: phoneNumber };
      }
    }
  }

  return (
    <FriendItem
      id={item.id}
      avatar={item.avatar}
      title={item.title}
      subtitle={() => {
        if (!searchResult) {
          return <Text>{item.subtitle}</Text>;
        }
        const index = searchResult.value.indexOf(searchText);
        const prefix = searchResult.value.slice(0, index);
        let suffix = searchResult.value.slice(index + searchText.length);
        if (searchResult.type === "EMAIL" && item.emails.length > 1) {
          suffix += ` ${s("andNumMore", item.emails.length - 1)}`;
        } else if (
          searchResult.type === "NUMBER" &&
          item.phoneNumbers.length > 1
        ) {
          suffix += ` ${s("andNumMore", item.phoneNumbers.length - 1)}`;
        }
        return (
          <Text>
            <Text>{prefix}</Text>
            <Text style={styles.boldText}>{searchText}</Text>
            <Text>{suffix}</Text>
          </Text>
        );
      }}
      contentRight={
        <AddFriendButton
          fetchState={fetchState}
          alreadyInvited={alreadyInvited}
          Button={
            <InviteButton
              icon={item.existingUser ? "person-add" : "email"}
              label={item.existingUser ? s("addFriend") : s("inviteToApp")}
              onPress={() => {
                // If existing user, invite by ID
                // This is received from the /contacts endpoint
                // or friends of friends
                if (item.existingUser) {
                  addFriend({ id: item.id, name: item.title }, { id: item.id });
                  logTryAddFriend(
                    { id: item.id, name: item.title },
                    "ContactsListScreen",
                    item.friendOfFriendSuggestion,
                    //if user adds a known user, from contacts list, and is not a friends of friend
                    //then it must be a suggestedContact from upload comparison
                    !item.friendOfFriendSuggestion
                  );

                  return;
                }

                const payload = { id: item.id };
                const body: {
                  email?: string;
                  number?: string;
                } = {};

                body.email =
                  searchResult && searchResult.type === "EMAIL"
                    ? searchResult.value.toLowerCase()
                    : item.primaryEmail.toLowerCase();

                if (item.phoneNumbers && smsSupported) {
                  body.number =
                    searchResult && searchResult.type === "NUMBER"
                      ? searchResult.value
                      : item.phoneNumbers[0];
                }

                addFriend(body, payload);
                logTryAddFriend(body, "ContactsListScreen");
              }}
            />
          }
        />
      }
    />
  );
};
