import React from "react";
import Logo from "assets/images/snaphabit-icon.png";
import { View, StyleSheet, Image } from "react-native";
import { Subtitle } from "components/UI/Typography";
const styles = StyleSheet.create({
  aboutTheAppContainer: {
    marginVertical: 20,
    marginHorizontal: 20,
    flexDirection: "row",
    alignItems: "center",
  },
  logo: { width: 80, height: 80, margin: 20 },
});

export const AboutTheAppSection = () => (
  <View style={styles.aboutTheAppContainer}>
    <Image source={Logo} style={styles.logo} />
    <Subtitle style={{ textAlign: "center" }}>
      SnapHabit: the free social habit platform
    </Subtitle>
  </View>
);
