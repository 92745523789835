import moment from "moment";
import React, { ReactNode } from "react";
import { View, Text, StyleSheet, TouchableOpacity } from "react-native";

import { useGetDay } from "redux/selectors";
import { Note } from "types/habits";
import { getDayStatusColor } from "utils/habits";
import { renderNote, renderNoteMediaString } from "utils/strings";

export const NoteItem = React.memo(
  ({
    note,
    habitid,
    onPress,
    ownHabit,
    contentRight,
  }: {
    note: Pick<Note, "note" | "media" | "date">;
    habitid: string;
    onPress?: () => void;
    ownHabit: boolean;
    contentRight?: ReactNode;
  }) => {
    const day = useGetDay({ habitid, date: note.date, ownHabit });
    const statusColor =
      day && day.status ? getDayStatusColor(day.status) : "black";
    const content = (
      <>
        <View style={styles.noteDateContainer}>
          <Text
            style={[
              styles.noteDate,
              {
                color: statusColor,
              },
            ]}
          >
            {moment(note.date, "MM-DD-YYYY").format("MMM DD")}
          </Text>
        </View>
        <Text
          numberOfLines={3}
          style={[
            styles.noteText,
            !ownHabit && !contentRight && { width: "80%" },
          ]}
        >
          {/* TODO - render thumbnails + viewing from this screen */}
          <Text style={{ fontStyle: "italic" }}>
            {renderNoteMediaString({ media: note.media })}
          </Text>
          {(note.note && renderNote(note.note)) || (
            <Text style={{ fontStyle: "italic" }}>[empty note]</Text>
          )}
        </Text>
        {contentRight}
      </>
    );

    return (
      <TouchableOpacity style={styles.note} onPress={onPress}>
        {content}
      </TouchableOpacity>
    );
  }
);

const styles = StyleSheet.create({
  separator: {
    borderBottomWidth: 1,
  },
  note: {
    flexDirection: "row",
    marginVertical: 8,
    paddingHorizontal: 4,
    alignItems: "center",
    width: "100%",
  },
  noteDateContainer: {
    width: "15%",
  },
  noteDate: {
    fontSize: 20,
    fontWeight: "700",
    textAlign: "center",
  },
  noteText: {
    paddingLeft: 10,
    width: "75%",
    fontSize: 16,
  },
});
