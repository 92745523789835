import React from "react";
import { Text, StyleSheet } from "react-native";

import { ContentCenteredView } from "components/UI/ContentCenteredView";

export const ZeroState = props => {
  return (
    <ContentCenteredView style={styles.container}>
      <Text style={styles.title}>{props.header}</Text>
    </ContentCenteredView>
  );
};

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 10,
  },
  title: {
    fontSize: 16,
    fontWeight: "500",
    marginBottom: 3,
    textAlign: "center",
  },
});
