import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NoteMap, Note } from "types/habits";
import { AppThunk } from "redux/store";
import { API_HOST } from "utils/constants";
import axios from "utils/axios";
import { Logger } from "utils/Logger";

const notesSlice = createSlice({
  name: "notes",
  initialState: {
    isLoading: false,
    error: null,
    habitToNoteMap: {} as {
      [habitid: string]: NoteMap;
    },
  },
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setError(state, action: PayloadAction<Error>) {
      Logger.error(action.payload);
      state.error = action.payload;
    },
    setNotesFromServer(state, action: PayloadAction<[string, NoteMap][]>) {
      state.error = null;
      for (let i = 0; i < action.payload.length; i++) {
        const [habitid, notes] = action.payload[i];
        state.habitToNoteMap[habitid] = notes;
      }
    },
    setNotesFromListener(
      { habitToNoteMap },
      action: PayloadAction<{
        ordered: Note[];
      }>
    ) {
      const { ordered } = action.payload;
      for (let i = 0; i < ordered.length; i++) {
        const note = ordered[i];
        if (note.habitid in habitToNoteMap) {
          habitToNoteMap[note.habitid][note.date] = note;
        } else {
          habitToNoteMap[note.habitid] = {
            [note.date]: note,
          };
        }
      }
    },
    setSingleNote({ habitToNoteMap }, action: PayloadAction<Note>) {
      const { payload: note } = action;
      if (note.habitid in habitToNoteMap) {
        habitToNoteMap[note.habitid][note.date] = note;
      } else {
        habitToNoteMap[note.habitid] = {
          [note.date]: note,
        };
      }
    },
  },
});

const { setLoading, setError, setNotesFromServer } = notesSlice.actions;

export const { setNotesFromListener, setSingleNote } = notesSlice.actions;

export const notesSliceReducer = notesSlice.reducer;

export const getSharedNotes = (
  habitIds: string[]
): AppThunk => async dispatch => {
  dispatch(setLoading(true));
  try {
    const start = Date.now();
    const res = await axios.post(`${API_HOST}/getSharedNotes`, {
      habitIds,
    });
    Logger.warning(
      `[Get shared notes] Took ${(Date.now() - start) / 1000} for ${
        habitIds.length
      } habits.`
    );
    dispatch(setNotesFromServer(res.data));
  } catch (err) {
    dispatch(setError(err));
  } finally {
    dispatch(setLoading(false));
  }
};
