// TODO - rename this file
// TODO - refactor to use react-redux-firebase?
import uuid from "uuid/v1";

import { StorageReference } from "types/firebase";
import firebase from "./firebase";

//!When convert to React Native FB, use putFile
// //!https://rnfirebase.io/docs/v5.x.x/storage/reference/Reference#putFile

//Why this weird implementation
//1) putFile (putFile(localFileURI, options) does not exist for web SDK, so need to use putString
//2) putString had issues with Base64 upload (.putString(decodedImage, "base64", options), so instead need to create a blob and use put
const uploadBlob = async (
  ref: StorageReference,
  uri: string
): Promise<string> => {
  //XHTMLRequest: https://medium.com/@joananespina/uploading-to-firebase-storage-with-react-native-39f4a500dbcb
  //fetch: https://medium.com/@wcandillon/uploading-images-to-firebase-with-expo-a913c9f8e98d
  try {
    const response = await fetch(uri);
    const blob = await response.blob();
    const options = { cacheControl: "max-age=86400" };

    try {
      const downloadURL = await new Promise(
        (res: (value: string) => void, rej) => {
          const uploadTask = ref.put(blob, options);
          // Register three observers:
          // 1. 'state_changed' observer, called any time the state changes
          // 2. Error observer, called on failure
          // 3. Completion observer, called on successful completion
          uploadTask.on(
            "state_changed",
            function (snapshot) {
              // Observe state change events such as progress, pause, and resume
              // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
              var progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log("Upload is " + progress + "% done");
              switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                  console.log("Upload is paused");
                  break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                  console.log("Upload is running");
                  break;
              }
            },
            function (error) {
              // Handle unsuccessful uploads
              console.log("failed to upload");
              rej(error);
            },
            function () {
              // Handle successful uploads on complete
              // For instance, get the download URL: https://firebasestorage.googleapis.com/...
              uploadTask.snapshot.ref
                .getDownloadURL()
                .then(function (downloadURL: string) {
                  res(downloadURL);
                })
                .catch(err => {
                  rej(err);
                });
            }
          );
        }
      );

      return downloadURL;
    } catch (err) {
      console.log("Error uploading");
      console.log(err);
    }
  } catch (err) {
    console.log("Error converting to Blob");
    console.log(err);
  }
};

export const uploadImage = async (image, path: string): Promise<string> => {
  //TODO: Add appendix to image
  const imageName = `${uuid()}`;
  //TODO: Add security rule for storage includes https://firebase.google.com/docs/reference/security/database
  const ref = firebase.storage().ref(path).child(imageName);

  return uploadBlob(ref, image.uri);
};
