import React, { useEffect, useState } from "react";
import { StyleSheet, Button, SafeAreaView, View } from "react-native";
import { IconButton } from "react-native-paper";
import { ContentCenteredView } from "components/UI/ContentCenteredView";
import { reloadApp } from "utils/reload";
import { useFirebase } from "react-redux-firebase";
import { Logger } from "utils/Logger";
import { AUTH_PROVIDERS } from "utils/auth";
import {
  SignInWithGoogleButton,
  SignInWithFacebookButton,
  SignInWithAppleButton,
} from "components/Login/LoginButtons";
import { Loading, Body, Subtitle } from "components";
import { EmailSignInView } from "components/Login/EmailSignInView";

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainer: {
    margin: 20,
    alignItems: "stretch",
  },
  center: {
    alignSelf: "center",
  },
  close: {
    alignSelf: "flex-end",
    zIndex: 10,
  },
  text: {
    margin: 10,
    textAlign: "center",
  },
});

const SUPPORTED_AUTH_PROVIDERS = Object.values(AUTH_PROVIDERS);

export const LoginError = ({ authError, setPreviousCredential }) => {
  const firebase = useFirebase();
  const [isLoading, setIsLoading] = useState(false);
  const [methods, setMethods] = useState(undefined);

  useEffect(() => {
    if (authError?.code === "auth/account-exists-with-different-credential") {
      firebase
        .auth()
        .fetchSignInMethodsForEmail(authError.email)
        .then(methods => setMethods(methods))
        .catch(err => {
          Logger.error(err);
          setMethods([]);
        });
      setPreviousCredential({
        email: authError.email,
        credential: authError.credential,
      });
    }
  }, [authError, firebase, setPreviousCredential]);

  if (isLoading) {
    return <Loading />;
  }

  let content;

  if (authError?.code === "auth/account-exists-with-different-credential") {
    if (methods === undefined) {
      // wait for useEffect to resolve
      return null;
    }

    if (
      methods?.length > 0 &&
      SUPPORTED_AUTH_PROVIDERS.find(p => p === methods[0])
    ) {
      let action = null;
      switch (methods[0]) {
        case AUTH_PROVIDERS.EMAIL:
          action = <EmailSignInView />;
          break;
        case AUTH_PROVIDERS.GOOGLE:
          action = (
            <View style={styles.center}>
              <SignInWithGoogleButton
                setIsLoading={setIsLoading}
                firebase={firebase}
              />
            </View>
          );
          break;
        case AUTH_PROVIDERS.FACEBOOK:
          action = (
            <View style={styles.center}>
              <SignInWithFacebookButton
                setIsLoading={setIsLoading}
                firebase={firebase}
              />
            </View>
          );
          break;
        case AUTH_PROVIDERS.APPLE:
          action = (
            <View style={styles.center}>
              <SignInWithAppleButton
                setIsLoading={setIsLoading}
                firebase={firebase}
              />
            </View>
          );
      }

      content = (
        <ContentCenteredView style={styles.contentContainer}>
          <Body style={styles.text}>
            You've already signed into SnapHabit before. Sign in with your
            previous method to continue.
          </Body>
          {action}
        </ContentCenteredView>
      );
    }
  } else {
    content = (
      <ContentCenteredView style={styles.contentContainer}>
        <Subtitle style={styles.text}>Error Logging In :(</Subtitle>
        <Body style={styles.text}>{JSON.stringify(authError)}</Body>
        <Button
          title="Try Again"
          onPress={() => {
            reloadApp();
          }}
        />
      </ContentCenteredView>
    );
  }

  return (
    <SafeAreaView style={styles.container}>
      <IconButton
        icon="close"
        onPress={() => {
          reloadApp();
        }}
        style={styles.close}
      />
      {content}
    </SafeAreaView>
  );
};
