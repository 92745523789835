import React from "react";
import { Text, StyleSheet, View } from "react-native";

export const ClipboardToast = () => {
  return (
    <View style={styles.container}>
      <Text style={styles.title}>Copied to clipboard</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: { flex: 1, margin: 20, alignItems: "center" },
  title: { fontSize: 22, fontWeight: "700" },
});
