export default {
  thePointOfThisApp: {
    en: "Add a habit! That's the point of this app!",
    ja: "ハビットを追加してください！ それがこのアプリのポイントです！",
    "zh-Hans": "添加习惯！这就是这个应用的重点！",
    "zh-Hant": "添加習慣！這就是這個應用的重點！",
  },
  addHabit: {
    en: "Add Habit",
    ja: "ハビットを追加",
    "zh-Hans": "添加习惯",
    "zh-Hant": "添加習慣",
  },
  dailyFeedTitle: {
    en: "Today's Inspiration",
  },
  dailyFeedSubtitle: {
    en: "Daily curated content from the SnapHabit team.",
  },
  nothingHere: {
    en: "Ain't nothing here.",
    ja: "何もありません",
    "zh-Hans": "这里设么都没有",
    "zh-Hant": "這裡設麼都沒有",
  },
};
