import { Field } from "react-final-form";
import React from "react";

// Uses react-final-form's subscription feature to dynamically render
// form fields
export const Condition = ({ when, is, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value === is ? children : null)}
  </Field>
);

export const Watch = ({ name, render }) => (
  <Field name={name} subscription={{ value: true }}>
    {({ input: { value } }) => render(value)}
  </Field>
);
