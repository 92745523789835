import React from "react";
import { View, Text, StyleSheet, TextInput } from "react-native";
import moment from "moment";
import Victory from "utils/Victory";
import { COLORS } from "utils/appStyles";

const {
  VictoryChart,
  VictoryLine,
  VictoryAxis,
  VictoryScatter,
  VictoryLabel,
  VictoryVoronoiContainer,
  VictoryTooltip,
} = Victory;

const InputExample = ({ value }) => (
  <View style={styles.inputExampleContainer}>
    <Text>e.g. </Text>
    <TextInput
      style={styles.inputExample}
      value={value}
      editable={false}
      scrollEnabled={false}
      multiline
    />
  </View>
);

export const Instructions = () => (
  <View style={styles.textContainer}>
    <Text style={styles.textHeader}>
      Auto-generate graphs to track progress
    </Text>
    <Text style={styles.textSuggestion}>
      Useful for improvement-based habits such as weight loss, physical
      endurance, sleep hours, etc.
    </Text>
    <Text style={styles.textContent}>
      Start your notes with a number. Add a unit to the first note:
    </Text>
    <InputExample value="150 lbs" />
    <Text style={styles.textContent}>
      To exclude a note from the graph, just don't start it with a number
    </Text>
    <InputExample value="slow pace today (10.4 mph)" />
    <Text style={styles.textHeader}>Multiple Graphs</Text>
    <Text style={styles.textContent}>
      Track different metrics by adding multiple lines to your note
    </Text>
    <InputExample value={"25 mins reading\n12 pages"} />
    <Text style={styles.textContent}>
      You can always skip a particular metric by leaving an empty line or adding
      text instead
    </Text>
    <InputExample value={"(forgot time read)\n15 pages"} />
  </View>
);

export const Graph = ({
  height,
  unit,
  data,
}: {
  height: number;
  unit: string;
  data: {
    [value: string]: any;
    date: Date;
  }[];
}) => {
  return (
    <View style={styles.chartContainer} key={unit}>
      <VictoryChart
        height={height}
        padding={{ left: 52, right: 30, bottom: 40, top: 30 }}
        domainPadding={20}
        containerComponent={<VictoryVoronoiContainer />}
      >
        <VictoryAxis tickFormat={x => moment(x).format(`MMM D`)} />
        <VictoryAxis
          dependentAxis
          label={unit}
          fixLabelOverlap
          tickFormat={t => Math.round(t * 10) / 10}
          axisLabelComponent={<VictoryLabel dy={-12} />}
        />
        {/* VictoryLine crashes when only one data point */}
        {data.length > 1 && (
          <VictoryLine
            interpolation="natural"
            data={data}
            x="date"
            y={unit}
            style={{ data: { stroke: "#c43a31" } }}
            animate={
              data.length > 1 && {
                duration: 2000,
                onLoad: { duration: 1000 },
              }
            }
          />
        )}
        <VictoryScatter
          data={data}
          x="date"
          y={unit}
          labels={({ datum }) =>
            `${moment(datum._x).format(`MMM D`)}: ${
              Math.round((datum._y + Number.EPSILON) * 100) / 100
            } ${unit}`
          }
          style={{
            data: {
              fill: "blue",
              stroke: "blue",
              strokeWidth: 1.5,
              fillOpacity: 0.2,
            },
          }}
          size={3.5}
          labelComponent={
            <VictoryTooltip
              flyoutStyle={{ stroke: "#c43a31" }}
              renderInPortal={false}
            />
          }
          animate={
            data.length > 1 && {
              duration: 0,
              onLoad: { duration: 0 },
            }
          }
        />
      </VictoryChart>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    margin: 4,
  },
  textContainer: {
    margin: 12,
  },
  textContent: {
    marginVertical: 8,
    fontSize: 15,
  },
  textSuggestion: {
    marginVertical: 8,
    fontSize: 13,
    color: COLORS.darkGray,
  },
  textHeader: {
    fontSize: 20,
    fontWeight: "600",
    marginTop: 5,
  },
  bold: {
    fontWeight: "bold",
  },
  chartContainer: {
    flex: 1,
  },
  inputExample: {
    flex: 1,
    padding: 8,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: COLORS.disabled,
  },
  inputExampleContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 8,
  },
});
