import { useNavigation, CommonActions } from "@react-navigation/native";
import {
  useGetFriends,
  useGetAuth,
  useGetSpecificHabit,
  useGetOrganization,
} from "redux/selectors";
import React, {
  useState,
  useRef,
  useMemo,
  useCallback,
  useLayoutEffect,
} from "react";
import { FormRenderProps } from "react-final-form";
import { useHeaderHeight } from "@react-navigation/stack";
import {
  isHabitChallengeParticipant,
  HabitChallenge,
  FormHabitData,
  SaveHabitData,
  Habit,
  isHabitRoutine,
} from "types/habits";
import {
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  View,
  StyleSheet,
} from "react-native";
import axios from "utils/axios";
import { API_HOST } from "utils/constants";
import { Appbar, Portal, Dialog, useTheme, Modal } from "react-native-paper";
import {
  ContentCenteredView,
  Body,
  Loading,
  HabitForm,
  KButton,
  Footnote,
  Subtitle,
} from "components";

import { ScrollView } from "react-native-gesture-handler";
import { firstName } from "utils/strings";
import { useString } from "hooks";
import {
  archiveHabit,
  editHabit,
  addHabit,
  deleteHabit,
  isHabitEditable,
} from "utils/habits";
import { Logger } from "utils/Logger";
import { COLORS } from "utils/appStyles";
import { iOSColors } from "react-native-typography";
import { useLayoutContext, Layouts } from "contexts";
import { useHistory } from "utils/react-router";
import { joinRoutine } from "utils/routines";
import { MainNavigation } from "layouts/MobileLayout";
import { Routine } from "types/routines";
import { logStartRoutine, logClickHabitButton } from "utils/analytics";

const ChallengeParticipantDescription = ({
  habit,
  habitChallengeId,
}: {
  habit?: Habit;
  habitChallengeId?: string;
}) => {
  const s = useString("addHabitScreen");
  const { friends } = useGetFriends();

  // Only render something if we're creating/editing a habit participant
  // So if no mainHabitId based off this logic, we don't render anything
  let mainHabitId;
  if (habit && isHabitChallengeParticipant(habit)) {
    mainHabitId = habit.challengeHabitId;
  } else if (habitChallengeId) {
    mainHabitId = habitChallengeId;
  }

  const { habit: mainChallengeHabit } = useGetSpecificHabit(mainHabitId) as {
    isLoaded: boolean;
    habit: HabitChallenge;
  };

  const challengeOwnerFriend = useMemo(
    () =>
      mainChallengeHabit
        ? friends.find(f => f.id === mainChallengeHabit.uid)
        : null,
    [mainChallengeHabit, friends]
  );

  if (!mainChallengeHabit) {
    return null;
  }

  let ownerName = challengeOwnerFriend
    ? challengeOwnerFriend.name
    : mainChallengeHabit.challengerInfo[mainChallengeHabit.uid].name;

  ownerName = firstName(ownerName);

  return (
    <View style={styles.infoContainer}>
      <Subtitle style={{ marginVertical: 10 }}>
        {challengeOwnerFriend
          ? s("someoneChallenge", ownerName)
          : s("someoneChallengeNotFriends", ownerName)}
      </Subtitle>
      <View style={{ marginBottom: 15, marginTop: 5 }}>
        <Body>{s("onlyOwnerCanInvite", ownerName)}</Body>
      </View>
    </View>
  );
};

export const DeleteHabitDialog = ({
  title,
  habitid,
  setDialog,
  navigation,
  history,
}) => {
  const s = useString("addHabitScreen");
  const layout = useLayoutContext();

  return (
    <Dialog visible onDismiss={() => {}} dismissable={false}>
      <Dialog.Title>{s("confirmDelete")}</Dialog.Title>
      <Dialog.Content>
        <Body>{s("areYouSureDelete", title)}</Body>
      </Dialog.Content>
      <Dialog.Actions>
        <KButton
          label={s("yes")}
          mode="text"
          color={COLORS.error}
          onPress={() => {
            setDialog(null);
            // deleting the item and navigating with break app
            // something to do with fact HabitDetails is still on stack with existing
            // item. send params back to HabitListScreen to actually do the delete
            if (layout === Layouts.WIDE) {
              deleteHabit(habitid);
              history.push("/me");
            } else {
              navigation.navigate("Home", {
                screen: "Habits",
                params: {
                  shouldDelete: true,
                  habitid,
                },
              });
            }
          }}
        />
        <KButton
          label={s("no")}
          mode="text"
          color="black"
          onPress={() => {
            setDialog(null);
          }}
        />
      </Dialog.Actions>
    </Dialog>
  );
};

export const AddHabitLayout = ({
  habit,
  habitid,
  habitChallenge,
  habitChallengeId,
  routineId,
  routine,
}: {
  habit?: Habit;
  habitid?: string;
  habitChallenge?: HabitChallenge;
  habitChallengeId?: string;
  routine?: Routine;
  routineId?: string;
}) => {
  const navigation = useNavigation<MainNavigation>();
  // State
  const s = useString("addHabitScreen");
  const theme = useTheme();
  const { friends } = useGetFriends();
  const { auth: user } = useGetAuth();
  const [dialog, setDialog] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const handleSubmitRef = useRef<FormRenderProps["handleSubmit"]>();
  const layout = useLayoutContext();
  // useHistory can only be called in the component in which react-router context is set (via <Router />)
  const history = useHistory();

  // For navigation
  const { enableAddFriendScreen } = useGetOrganization();

  const isJoiningChallenge = !!habitChallenge;
  const isEditable = isHabitEditable(habit) && !isJoiningChallenge;
  const isStartingRoutine = !habit && !!routineId;
  const isEditingRoutine = habit && isHabitRoutine(habit);

  // For styling
  const headerHeight = useHeaderHeight();

  const persistHabit = useCallback(
    async (habitData: SaveHabitData) => {
      setIsLoading(true);
      try {
        if (habitData?.habitid) {
          await editHabit({ ...habitData, isEditable });
          return habitData.habitid;
        } else {
          if (isStartingRoutine) {
            logStartRoutine(habitData, routineId);
            // don't persist friends!
            delete habitData.friends;
            const res = await joinRoutine({
              habitData,
              routineId,
              shareProgressWithOwner: false, // TODO - read this from form
            });

            return res?.data?.habitId;
          } else if (habitChallenge) {
            const { sharing, visibility } = habitData;
            const res = await axios.post(`${API_HOST}/joinChallenge`, {
              habitid: habitChallengeId,
              owner: habitChallenge.uid,
              sharing,
              visibility,
            });

            return res?.data?.habitId;
          } else {
            return await addHabit(habitData);
          }
        }
      } catch (error) {
        Logger.error(error);
        return false;
      } finally {
        setIsLoading(false);
      }
    },
    [habitChallenge, habitChallengeId, isEditable, isStartingRoutine, routineId]
  );

  const saveHabit = useCallback(
    async (formData: FormHabitData) => {
      Keyboard.dismiss();

      const habitData: SaveHabitData = {
        habitid,
        uid: user.uid,
        friends,
        ...formData,
      };

      const newHabitId = await persistHabit(habitData);

      if (!newHabitId) {
        setHasError(true);
        return;
      }

      if (user && user.isAnonymous && layout === Layouts.MOBILE) {
        return navigation.dispatch(
          CommonActions.reset({
            index: 1,
            routes: [
              { name: "Home" },
              {
                name: "LoginScreen",
                params: { reason: "addFriend" },
              },
            ],
          })
        );
      } else {
        if (layout === Layouts.WIDE) {
          return history.push("/me/addFriend");
        } else {
          if (enableAddFriendScreen) {
            return navigation.dispatch(
              CommonActions.reset({
                index: 1,
                routes: [
                  { name: "Home" },
                  {
                    name: "AddFriendScreen",
                    params: {
                      challengeHabitId: habitData.isChallenge && newHabitId,
                      addHabitFlow: true,
                    },
                  },
                ],
              })
            );
          } else {
            return navigation.dispatch(
              CommonActions.reset({
                index: 0,
                routes: [{ name: "Home" }],
              })
            );
          }
        }
      }
    },
    [
      habitid,
      user,
      friends,
      persistHabit,
      layout,
      navigation,
      history,
      enableAddFriendScreen,
    ]
  );

  useLayoutEffect(() => {
    let title = s("addHabit");

    if (isStartingRoutine) {
      title = s("startJourney");
    } else if (habitChallenge) {
      title = s("joinChallenge");
    } else if (habit) {
      title = s("editHabit");
    }
    navigation.setOptions({
      title,
      headerBackTitle: s("cancel"),
      headerRight: () => (
        <Appbar.Action
          icon="content-save"
          disabled={!canSubmit}
          color={theme.colors.accent}
          onPress={() => {
            logClickHabitButton("save");
            handleSubmitRef?.current();
          }}
        />
      ),
    });
  }, [
    canSubmit,
    saveHabit,
    habitChallenge,
    habit,
    s,
    navigation,
    theme,
    isStartingRoutine,
  ]);

  if (hasError) {
    return (
      <ContentCenteredView>
        <Body>{s("errorAddingHabit")}</Body>
      </ContentCenteredView>
    );
  }

  return (
    // Handled means keyboard will close unless something else handles the tap
    // This means that we need to explicitly close the keyboard on things that
    // handle the tap e.g a switch
    <KeyboardAvoidingView
      style={styles.keyboardContainer}
      behavior={Platform.OS === "ios" ? "padding" : null}
      keyboardVerticalOffset={headerHeight}
    >
      <ScrollView
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={styles.contentContainer}
      >
        <Portal>
          {isLoading ? (
            <Modal visible dismissable={false}>
              <Loading />
            </Modal>
          ) : (
            dialog
          )}
        </Portal>
        <View>
          <ChallengeParticipantDescription
            habit={habit}
            habitChallengeId={habitChallengeId}
          />
          <HabitForm
            habit={habit || habitChallenge}
            routine={routine}
            isEditable={isEditable}
            isHabitChallenge={
              isJoiningChallenge ||
              (habit && isHabitChallengeParticipant(habit))
            }
            isStartingRoutine={isStartingRoutine}
            isEditingRoutine={isEditingRoutine}
            isJoiningChallenge={isJoiningChallenge}
            friends={friends}
            onSubmit={saveHabit}
            handleSubmitRef={handleSubmitRef}
            setCanSubmit={setCanSubmit}
          />
        </View>
        <View style={styles.bottomContainer}>
          <View style={styles.buttonsContainer}>
            <KButton
              icon="content-save"
              labelStyle={styles.buttonText}
              style={styles.button}
              label={isJoiningChallenge ? s("join") : s("save")}
              disabled={!canSubmit}
              onPress={() => {
                logClickHabitButton("save");
                handleSubmitRef?.current();
              }}
            />
            {habit && (
              <>
                <KButton
                  icon="archive"
                  label={isEditable ? s("archive") : s("disable")}
                  labelStyle={styles.buttonText}
                  style={styles.button}
                  color={!isEditable && "red"}
                  onPress={() => {
                    try {
                      archiveHabit(habitid);
                      navigation.popToTop();
                    } catch (err) {
                      Logger.error(err);
                    }
                  }}
                />
                <Footnote style={styles.text}>
                  {isEditable ? s("archivedHabitsDoNotShow") : s("toReEnable")}
                </Footnote>

                {isEditable && (
                  <KButton
                    icon="trash-can-outline"
                    label={s("delete")}
                    labelStyle={styles.buttonText}
                    style={styles.button}
                    onPress={() =>
                      setDialog(
                        <DeleteHabitDialog
                          title={habit.title}
                          habitid={habitid}
                          setDialog={setDialog}
                          navigation={navigation}
                          history={history}
                        />
                      )
                    }
                    color="red"
                  />
                )}
              </>
            )}
          </View>
        </View>
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  keyboardContainer: { flex: 1 },
  contentContainer: {
    marginTop: 20,
    marginHorizontal: 20,
  },
  infoContainer: {
    marginBottom: 10,
  },
  bottomContainer: {
    marginBottom: 20,
  },
  buttonsContainer: {
    justifyContent: "space-around",
    marginVertical: 10,
  },
  button: {
    margin: 5,
  },
  buttonText: {
    fontSize: 13,
    fontFamily: "OpenSans",
  },
  text: {
    color: iOSColors.gray,
    textAlign: "center",
    marginBottom: 15,
  },
});
