import React from "react";
import { Text, StyleSheet, TextInput } from "react-native";

import { truncate } from "utils/strings";

//new stuff
export const HeaderText = ({ children, ...props }) => (
  <Text style={[styles.mediumFont, props.bold && styles.bold]}>{children}</Text>
);

//old stuff
export const ListItemTitle = ({ style, children }) => (
  <MainText style={[styles.itemTitle, style]}>
    {truncate(children, 40)}
  </MainText>
);

export const MainTextInput = props => (
  <TextInput {...props} style={styles.input} />
);

export const Subtitle = props => (
  <MainText style={styles.subtitle}>{props.children}</MainText>
);
export const MainText = props => (
  <Text
    {...props}
    style={[styles.mainText, props.style, styles[props.direction]]}
  >
    {props.children}
  </Text>
);

const styles = StyleSheet.create({
  itemTitle: {
    marginBottom: 3,
  },
  mainText: {
    color: "black",
    backgroundColor: "transparent",
    fontSize: 16,
  },
  positive: {
    color: "green",
  },
  negative: {
    color: "red",
  },
  subtitle: {
    fontSize: 10,
  },
  input: {
    fontSize: 16,
  },
  bold: {
    fontWeight: "bold",
  },
  mediumFont: {
    fontSize: 18,
  },
});
