import React from "react";
import { useTheme } from "react-native-paper";
import { TabBar } from "react-native-tab-view";
import { GroupIcon } from "icons/GroupIcon";
export const KTabBar = props => {
  const theme = useTheme();

  return (
    <TabBar
      {...props}
      indicatorStyle={{ backgroundColor: theme.colors.accent }}
      labelStyle={{
        color: theme.colors.accent,
      }}
      style={{
        backgroundColor: theme.colors.primary,
      }}
      renderIcon={({ route }) =>
        route.key === "details" &&
        props.isChallenge && (
          <GroupIcon
            height={16}
            width={16}
            style={{
              marginRight: 2,
              marginBottom: 4, //Hacky vertical centering
            }}
          />
        )
      }
      tabStyle={{ flexDirection: "row" }}
    />
  );
};
