import React, { useEffect, useRef, LegacyRef, useState } from "react";
import { Text, View, StyleSheet, Platform } from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";

import * as WebBrowser from "expo-web-browser";
import { RoutineContent, SelectedRoutineDay, Routine } from "types/routines";
import { HabitRoutine } from "types/habits";
import { FeedbackSource } from "types/feedback";
import { RoutineDayMainContent } from "components/Routines/RoutineDayMainContent";
import { KScrollView, KAvatar } from "components";
import { DaySwitcher } from "components/Routines/DaySwitcher";
import { MediaCarousel } from "components/Routines/MediaCarousel";
import { ReflectSection } from "components/Routines/ReflectSection";
import { FeedbackModal } from "components/Feedback/FeedbackModal";
import { useString } from "hooks";
import { useDimensionsContext } from "contexts";
import { ScrollView, TouchableOpacity } from "react-native-gesture-handler";
import { useKeyboardHeight } from "hooks/useKeyboardHeight";
import { isMobilePlatform } from "utils/helpers";
import { InviteSection } from "components/Routines/InviteSection";
import { MainNavigation } from "layouts/MobileLayout";
import { COLORS } from "utils/appStyles";

import { useNavigation } from "@react-navigation/native";

const styles = StyleSheet.create({
  ownerInfoRow: {
    flexDirection: "row",
    marginHorizontal: 20,
    marginVertical: 10,
    alignItems: "center",
  },
  titleText: {
    marginHorizontal: 20,
    marginTop: 10,
    fontWeight: "300",
  },
  rowLayout: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  chatIcon: { paddingRight: 20 },
});

export const RoutineContentDay = ({
  habitid,
  habit,
  friendid,
  routineContent,
  selectedRoutineDay,
  setSelectedRoutineDay,
  routine,
}: {
  habitid: string;
  habit: HabitRoutine;
  friendid?: string;
  routine: Routine;
  routineContent: RoutineContent;
  selectedRoutineDay: SelectedRoutineDay;
  setSelectedRoutineDay: React.Dispatch<
    React.SetStateAction<SelectedRoutineDay>
  >;
}) => {
  const navigation = useNavigation<MainNavigation>();
  const {
    day,
    title,
    body,
    urls,
    media,
    notePlaceholder,
    photosForSharing,
  } = routineContent;
  const scrollViewRef = useRef<LegacyRef<ScrollView>>();

  useEffect(() => {
    if (isMobilePlatform) {
      WebBrowser.warmUpAsync();

      return () => WebBrowser.coolDownAsync();
    }
  }, []);

  const { getScaledFontSize } = useDimensionsContext();
  const s = useString("routine");
  const keyboardHeight = useKeyboardHeight();
  const [y, setY] = useState<number>();
  const [shouldShowModal, setShouldShowModal] = useState<boolean>(false);

  return (
    <KScrollView
      render={ref => {
        scrollViewRef.current = ref;

        return (
          <>
            <DaySwitcher
              habitid={habitid}
              friendid={friendid}
              selectedRoutineDay={selectedRoutineDay}
              setSelectedRoutineDay={setSelectedRoutineDay}
            />
            <View style={styles.rowLayout}>
              <Text
                style={[
                  styles.titleText,
                  { fontSize: getScaledFontSize(2), flex: 1 },
                ]}
              >
                {s("titleDayIndicator")} {day}: {title}
              </Text>
              <TouchableOpacity
                onPress={() => {
                  setShouldShowModal(true);
                }}
                style={styles.chatIcon}
              >
                <MaterialCommunityIcons
                  name="chat"
                  size={26}
                  color={COLORS.logoBlue}
                />
              </TouchableOpacity>
            </View>

            <TouchableOpacity
              style={styles.ownerInfoRow}
              disabled={Platform.OS === "web"}
              onPress={() =>
                navigation.navigate("RoutineOverviewScreen", {
                  routineId: habit.routineId,
                  profileView: true,
                })
              }
            >
              <KAvatar
                source={{ uri: routine.ownerAvatar }}
                size={getScaledFontSize(1.5)}
                style={{ marginRight: 10 }}
              />
              <Text style={{ fontSize: getScaledFontSize(1) }}>
                {routine.ownerName}
              </Text>
            </TouchableOpacity>
            <MediaCarousel urls={urls} media={media} />
            <RoutineDayMainContent body={body} />
            {/*
              Scroll to the input field when focused
              We can't put this in the component itself because onLayout gives
              a relative measure to parent view, not absolute. Need to do it here
              to get the y relative to the scroll view.
            */}
            <View
              onLayout={({
                nativeEvent: {
                  layout: { y },
                },
              }) => setY(y)}
            >
              <ReflectSection
                habitid={habitid}
                habit={habit}
                friendid={friendid}
                selectedRoutineDay={selectedRoutineDay}
                notePlaceholder={notePlaceholder}
                photosForSharing={photosForSharing}
                onFocus={() => {
                  if (ref) {
                    ref.scrollTo({ x: 0, y, animated: true });
                  }
                }}
              />
            </View>
            <InviteSection
              habitid={habitid}
              habit={habit}
              friendid={friendid}
            />
            {/*
              When keyboard comes up, add padding so that there is room to scroll
              where text input is at top of screen.
              TODO: animate this transition
            */}
            <View style={{ height: keyboardHeight }} />
            {shouldShowModal && (
              <FeedbackModal
                setShouldShowModal={setShouldShowModal}
                source={FeedbackSource.ROUTINE_DAY}
                title="Send questions or feedback to the creator"
                placeholder="Tell us about your journey experience so far..."
                feedbackPrepend={`Day ${day} of ${routine.name}: `}
              />
            )}
          </>
        );
      }}
    />
  );
};
