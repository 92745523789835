import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import React, { useState, useEffect } from "react";
import { View, Button, StyleSheet, Alert, Text, AppState } from "react-native";
import * as Permissions from "expo-permissions";
import * as Haptics from "expo-haptics";

import { OpenMoreSetting, SettingSection } from "components";
import { GrantNotificationsMessage } from "components/Notifications/GrantNotificationsMessage";
import { KSegmentedControl } from "components/UI/KSegmentedControl";

import { useString } from "hooks";
import { useGetAuth, useGetFriend, useGetHabits } from "redux/selectors";
import { NotificationType } from "types/friends";
import { toggleNotifications } from "utils/friends";
import { StackProps } from "layouts/MobileLayout";
import { SEGMENTED_CONTROL_OPTIONS } from "utils/notifications";
import { pluralize } from "utils/strings";
import { isMobilePlatform } from "utils/helpers";
import { useNotificationPermissions } from "hooks/notifications/useNotificationPermissions";

const deleteAlert = (friend, navigation, s) => () => {
  Alert.alert(
    s("deleteFriendConfirm", friend.name),
    "",
    [
      {
        text: s("no"),
        style: "cancel",
      },
      {
        text: s("yes"),
        onPress: () => {
          // deleting the item and navigating with break app
          // something to do with fact HabitDetails is still on stack with existing
          // item. send params back to FriendListScreen to actually do the delete
          navigation.navigate("Home", {
            screen: "Friends",
            params: {
              shouldDelete: true,
              friend,
            },
          });
        },
      },
    ],
    { cancelable: false }
  );
};

export const FriendSettingsScreen = ({
  navigation,
  route,
}: {
  navigation: StackNavigationProp<StackProps, "FriendSettingsScreen">;
  route: RouteProp<StackProps, "FriendSettingsScreen">;
  friendid?: string;
}) => {
  const s = useString("friendSettingScreen");
  const { friendid } = route.params;
  const habits = useGetHabits();
  const { auth } = useGetAuth();
  const notificationsGranted = useNotificationPermissions();

  const { friend } = useGetFriend(friendid);
  const yourSharedHabits = habits.filter(
    habit => friendid in habit.sharing && habit.sharing[friendid]
  );

  return (
    <View>
      <SettingSection style={styles.marginTopOnly}>
        <View style={{ flex: 1 }}>
          {!notificationsGranted && <GrantNotificationsMessage />}
          <Text style={[styles.text]}>{s("manageNotifications")}</Text>
          <KSegmentedControl
            disabled={!notificationsGranted}
            values={SEGMENTED_CONTROL_OPTIONS}
            initialSelectedName={
              SEGMENTED_CONTROL_OPTIONS[friend.wantNotifications]
            }
            onChangeValue={(selected: string) => {
              const newVal = SEGMENTED_CONTROL_OPTIONS.indexOf(
                selected
              ) as NotificationType;

              if (isMobilePlatform) {
                Haptics.selectionAsync();
              }

              if (newVal !== -1) {
                toggleNotifications(auth.uid, friendid, newVal);
              }
            }}
            containerStyle={styles.margin}
          />
        </View>
      </SettingSection>
      <SettingSection>
        {(!friend.type || true) && (
          <OpenMoreSetting
            label={s("habitsSharedWithFriend")}
            caption={`${yourSharedHabits.length} Habit${pluralize(
              yourSharedHabits.length
            )}`}
            onPress={() => {
              navigation.navigate("SharedHabitsToggleScreen", {
                friendid,
                title: friend.name,
              });
            }}
          />
        )}
      </SettingSection>

      <View style={styles.button}>
        <Button
          title={s("deleteFriend")}
          onPress={deleteAlert(friend, navigation, s)}
          color="red"
        />
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  button: {
    marginTop: 30,
  },
  text: {
    textAlign: "center",
    margin: 5,
    marginTop: 10,
    fontWeight: "500",
  },
  margin: {
    margin: 10,
  },
  marginTopOnly: {
    marginTop: 10,
    marginBottom: 0,
  },
});
