import { Channel } from "stream-chat";
import { useGetFriends, useGetAuth } from "redux/selectors";
import { useState, useEffect } from "react";
import { FriendStatus } from "types/friends";

export const useFriendIdsWithoutChat = (channels: Channel[]) => {
  const { friends } = useGetFriends();
  const { auth } = useGetAuth();
  const [friendIdsToChatWith, setFriendIdsToChatWith] = useState<string[]>([]);

  // TODO - change logic when have group chat
  useEffect(() => {
    const friendIdsThatAlreadyHaveChannels = new Set<string>();

    //!Channels don't exist on web
    //!only checking for channels to stop crash
    channels &&
      channels.forEach(c => {
        const members = c.state ? Object.keys(c.state.members) : [];
        if (members.length === 2) {
          const notMe = members.find(id => id !== auth.uid);
          if (notMe) {
            friendIdsThatAlreadyHaveChannels.add(notMe);
          }
        }
      });

    const filteredFriendIds = friends
      .filter(
        f =>
          !friendIdsThatAlreadyHaveChannels.has(f.id) &&
          (f.status === FriendStatus.Accepted ||
            f.status === FriendStatus.AcceptedRequestor)
      )
      .map(f => f.id);

    setFriendIdsToChatWith(filteredFriendIds);
  }, [auth.uid, friends, channels]);

  return friendIdsToChatWith;
};
