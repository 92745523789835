import React, { useEffect, useState } from "react";
import { View, Text, StyleSheet } from "react-native";
import moment from "moment";

import { HabitRoutine } from "types/habits";
import { Loading } from "components/Loading";
import { useGetSpecificHabit } from "redux/selectors";
import { RoutineContentDay } from "components/Routines/RoutineContentDay";
import { SelectedRoutineDay } from "types/routines";
import { useTodaysRoutineContentIndex } from "hooks/routines/useTodaysRoutineContentIndex";

const styles = StyleSheet.create({
  center: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  startDateMessage: {
    fontSize: 16,
    fontWeight: "bold",
  },
});

export const RoutineView = ({
  habitid,
  friendid,
}: {
  habitid: string;
  friendid?: string;
}) => {
  const { habit } = useGetSpecificHabit(habitid) as {
    isLoaded: boolean;
    habit: HabitRoutine;
  };

  const {
    isLoaded,
    routineContent,
    routine,
    maxIndex,
    routineDays,
  } = useTodaysRoutineContentIndex(habit);

  const [selectedRoutineDay, setSelectedRoutineDay] = useState<
    SelectedRoutineDay
  >();

  useEffect(() => {
    if (maxIndex !== undefined && routineDays) {
      setSelectedRoutineDay({
        index: maxIndex,
        maxIndex,
        routineDays,
      });
    }
  }, [maxIndex, routineDays]);

  if (!isLoaded || !selectedRoutineDay) {
    return <Loading />;
  }

  if (maxIndex < 0) {
    return (
      <View style={styles.center}>
        <Text style={styles.startDateMessage}>
          This journey will start on{" "}
          {moment(habit.startTime.toDate()).format("MMM DD")}
        </Text>
      </View>
    );
  }

  const selectedContent = routineContent[selectedRoutineDay.index];

  return (
    <RoutineContentDay
      habitid={habitid}
      habit={habit}
      friendid={friendid}
      routine={routine}
      routineContent={selectedContent}
      selectedRoutineDay={selectedRoutineDay}
      setSelectedRoutineDay={setSelectedRoutineDay}
    />
  );
};
