import { useMemo } from "react";

import { LanguageCode } from "types/language";
import { getStringMap } from "assets/i18n";
import { useLanguageContext } from "contexts";
import { Logger } from "utils/Logger";

const createGetStringFunction = (nameSpace: string, language: LanguageCode) => {
  const stringMap = getStringMap(nameSpace);

  /**
   * @param key used to identify a string
   * @param args if the string depends on any parameters
   */
  return (key: string, ...args: any[]) => {
    // Fallback to English
    const value = stringMap[key];
    if (!value) {
      Logger.warning(`STRING NOT FOUND: ${key}`);
      return "";
    }
    const s = value[language] || value.en;
    return typeof s === "string" ? s : s(...args);
  };
};

/**
 * Example usage:
 *
 * assets/i18n/homeScreen.json
 * {
 *   greeting: {
 *     en: name => `Hello ${name}!`,
 *     ja: name => `こんにちは${name}さん！`,
 *   }
 * }
 *
 * language = "en"
 *
 * import { useString } from 'i18n';
 * export const HelloWorld = ({ name }) => {
 *   const s = useString("homeScreen");
 *   return <Text>{s("greeting", name)}<Text/>
 * }
 *
 * Output:
 * "Hello Bill!"
 */
export const useString = (nameSpace: string = "") => {
  const { language } = useLanguageContext();

  return useMemo(() => {
    return createGetStringFunction(nameSpace, language);
  }, [language, nameSpace]);
};
