import React, { memo, useEffect, useState } from "react";
import { FriendItem } from "components";
import { StyleSheet, Text, View } from "react-native";
import { Channel, Message, MessageResponse } from "stream-chat";
import { useString } from "hooks";
import { useGetAuth } from "redux/selectors";
import moment from "moment";
import { useTimeContext } from "contexts";

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
  },
  timestampContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    minWidth: 65,
    width: "15%",
  },
  timestamp: {
    color: "#888",
    textAlign: "right",
    width: "100%",
  },
});

/**
 * Reference
 * https://github.com/GetStream/stream-chat-react-native/blob/fc07b174adb8e432b5ec743e17fd9b441ea0cb00/src/components/ChannelPreview.js#L28-L54
 */
const ChatPreview = ({
  channel,
  setActiveChannel,
}: {
  channel: Channel;
  setActiveChannel: (c: Channel) => void;
}) => {
  const { today } = useTimeContext();
  const s = useString("chatScreen");
  const { auth } = useGetAuth();
  const [lastMessage, setLastMessage] = useState<MessageResponse | Message>(
    channel.lastMessage()
  );
  const [unread, setUnread] = useState<number>(channel.countUnread());

  useEffect(() => {
    const handleNewMessage = event => {
      setLastMessage(event.message);
      setUnread(channel.countUnread());
    };

    const handleRead = event => {
      if (event.user.id === auth.uid) {
        setUnread(channel.countUnread());
      }
    };

    channel.on("message.new", handleNewMessage);
    channel.on("message.read", handleRead);

    return () => {
      channel.off("message.new", handleNewMessage);
      channel.off("message.read", handleRead);
    };
  }, [auth.uid, channel]);

  const dayAgo = today.clone().subtract(1, "day");
  const weekAgo = today.clone().subtract(1, "week");

  const members = channel.state ? Object.values(channel.state.members) : [];
  const otherMembers = members.filter(member => member.user.id !== auth.uid);

  let name = channel.data.name;
  const isValidName = name && typeof name === "string";
  if (!isValidName) {
    name = otherMembers
      .map(member => member.user.name || member.user.id || "Unnamed User")
      .join(", ");
  }

  let timeDisplay = "";
  if (lastMessage) {
    const sentTime = moment(lastMessage.created_at);
    if (sentTime > dayAgo) {
      timeDisplay = sentTime.format("h:mm a"); // 3:04 pm
    } else if (sentTime > weekAgo) {
      timeDisplay = sentTime.format("ddd"); // Sun
    } else {
      timeDisplay = sentTime.format("MMM D"); // Feb 28
    }
  }

  return (
    <FriendItem
      avatar={{
        uri:
          channel.data.image || otherMembers.length > 0
            ? otherMembers[0].user.image
            : "",
      }}
      id={channel.id}
      title={
        <Text style={{ fontWeight: unread ? "700" : "400" }}>{`${name}${
          __DEV__ ? ` (${channel.id})` : ""
        }`}</Text>
      }
      contentRight={
        <View style={styles.timestampContainer}>
          <Text style={styles.timestamp}>{timeDisplay}</Text>
        </View>
      }
      subtitle={lastMessage ? lastMessage.text : s("noMessage")}
      containerStyle={{
        paddingVertical: 10,
      }}
      onPress={() => setActiveChannel(channel)}
    />
  );
};

export const KChatPreview = memo(ChatPreview);
